import { useSelector } from 'react-redux';

export const useUserPermissionGranted = ({
  enabledUserTypes,
  disabledUserTypes,
}: {
  enabledUserTypes?: string[];
  disabledUserTypes?: string[];
}) => {
  const userType = useSelector((state: any) => state.auth.authUserRole);

  if (enabledUserTypes) {
    return enabledUserTypes.includes(userType);
  }

  if (disabledUserTypes) {
    return !disabledUserTypes.includes(userType);
  }

  return false;
};
