import { FunctionComponent, useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { Button, Collapse, Drawer, Select, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import globalStyles from 'shared/styles/styles.module.scss';
import clsx from 'clsx';
import { LevelChange } from './components/LevelChange';
import { DroneLicense } from './components/DroneLicense';
import { NestedDrawer } from './components/NestedDrawer';
import { useNavigate } from 'react-router-dom';
import { APP_PATH } from 'features/Routes/pathes';
import { Comments } from 'components/ui_components/Comments';
import { APP_CONSTANS } from 'shared/constants';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { ExtendTime } from './components/ExtendTime';
import { RequestStatuseAsSelectItemsT, RequestStatuseT } from './types';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { usePostData } from 'api/usePostData';

const { Panel } = Collapse;

export const DrawerRequest: FunctionComponent<any> = ({
  open,
  setOpen,
  setRefetchData,
  req,
  setCurrentReq,
  setRequestId,
  refetchContent,
  objectType,
  objectId,
  relatives,
}) => {
  const [reqExtraData, setReqExtraData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestStatuses, setRequestStatuses] = useState<
    RequestStatuseAsSelectItemsT[]
  >([]);
  const [openedNestedDrawer, setOpenedNestedDrawer] = useState<boolean>(false);
  const [openedNestedDrawerType, setOpenedNestedDrawerType] =
    useState<string>('');
  const navigate = useNavigate();

  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const isSuperuser = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  });

  const { data: dataFulfilmentRequestHeader } = useQuery({
    queryKey: [queryNames.FULFILMENT_REQUESTS_HEADER],
    meta: {
      token,
      getAllData,
    },
    queryFn: useGetData,
  });

  const { data: dataUrserRequestHeader } = useQuery({
    queryKey: [queryNames.USER_getUrserRequestHeader],
    meta: {
      token,
      getAllData,
    },
    queryFn: useGetData,
  });

  const { data: dataPrefs } = useQuery({
    queryKey: [queryNames.USERS_getUserPreferencesById],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?user_id=${req.user.id}`,
    },
    queryFn: useGetData,
    enabled: req.request_type !== 'ExtendTime' && !!req?.user?.id,
  });

  const { data: dataComments } = useQuery({
    queryKey: [`${queryNames.COMMENTS}/${objectType}/${objectId}`, objectId],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true`,
    },
    queryFn: useGetData,
    enabled: !!objectId && !!objectType,
    refetchInterval: 20000,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      refetchContent();
      setRefetchData(true);
    },
    onSettled: () => setIsLoading(false),
  });

  const changeRequestState = (stateId: string) => {
    setIsLoading(true);
    mutate({
      data: {
        id: req.id,
        state_id: stateId,
      },
      token: token,
      otherProps:
        req.request_type === 'ExtendTime'
          ? queryNames.FULFILMENT_REQUESTS
          : queryNames.USERS_getUserRequests,
      method: 'PUT',
    });
  };

  const onClose = () => {
    setOpen(false);
    setCurrentReq(null);
    setRequestId(null);
    if (window.location.pathname.includes(APP_PATH.USERS_REQUESTS)) {
      navigate(`/${APP_PATH.USERS_REQUESTS}`, { replace: true });
    }
    if (window.location.pathname.includes(APP_PATH.FULFILMENT_REQUESTS)) {
      navigate(`/${APP_PATH.FULFILMENT_REQUESTS}`, { replace: true });
    }
  };

  const formatTitle = (requestType: string) => {
    let title = '';
    if (requestType.includes('drone')) {
      title = 'Drone license approvement';
    }
    if (requestType.includes('level')) {
      title = 'Level change';
    }
    if (requestType.includes('time')) {
      title = 'Time Extension';
    }
    return title;
  };

  const openNestedDrawer = (type: string) => {
    setOpenedNestedDrawer(true);
    setOpenedNestedDrawerType(type);
  };

  useEffect(() => {
    if (req && dataFulfilmentRequestHeader && dataUrserRequestHeader) {
      const headers: any =
        req.request_type === 'ExtendTime'
          ? dataFulfilmentRequestHeader
          : dataUrserRequestHeader;
      const statuses: RequestStatuseT[] = headers?.data?.columns?.find(
        (e: { name: string }) => e.name === 'State.id'
      )?.filter_items;
      if (statuses && statuses.length) {
        const sorted = statuses.sort((a: any, b: any) => {
          const nameA = a.display.toUpperCase();
          const nameB = b.display.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
        const statusesAsSelectItems = sorted.map((state) => ({
          value: state.value,
          label: state.display,
        }));
        statusesAsSelectItems && setRequestStatuses(statusesAsSelectItems);
      }
    }
    if (req && req.request_type !== 'ExtendTime') {
      const extraData = JSON.parse(req.extra_data);
      setReqExtraData(extraData);
    }
    if (req && req.request_type === 'ExtendTime') {
      const extraData = {
        jobCode: req.fulfilment.code,
        orderCode: req.fulfilment.order.code,
        orderTitle: req.fulfilment.order.title,
        jobExpiredAt: req.fulfilment.expired_at,
        extendTime: req.extend_hours,
        jobId: req.fulfilment.id,
        orderId: req.fulfilment.order.id,
      };
      setReqExtraData(extraData);
    }
  }, [dataFulfilmentRequestHeader, dataUrserRequestHeader, req]);

  const calcNewMsg = (comments: any) => {
    let unreadedComments = 0;
    if (
      comments &&
      comments.hasOwnProperty('data') &&
      comments.data.length > 0
    ) {
      unreadedComments = comments?.data.filter(
        (comment: any) => !comment.hasOwnProperty('exposed_at')
      ).length;
    }
    return unreadedComments;
  };

  return (
    req && (
      <Drawer
        title={null}
        placement="right"
        open={open}
        onClose={onClose}
        className={styles.drawer}
        width={520}
        closable={false}
      >
        <div className={styles.drawer__header}>
          <div>
            <Button
              className={styles.drawer__close}
              type="text"
              onClick={onClose}
            >
              <CloseOutlined />
            </Button>
            <span className={styles.drawer__title}>
              {formatTitle(req.request_type.toLowerCase())}
            </span>
          </div>
          <div>
            {req.state_id !== APP_CONSTANS.REQUESTS_STATUSES.REJECTED && (
              <Button
                className={clsx(globalStyles.btn, globalStyles.reject__btn)}
                onClick={() => openNestedDrawer('Reject')}
              >
                Reject
              </Button>
            )}
            {req.state_id !== APP_CONSTANS.REQUESTS_STATUSES.APPROVED && (
              <Button
                className={clsx(globalStyles.btn, globalStyles.success__btn)}
                onClick={() => openNestedDrawer('Approve')}
              >
                Approve
              </Button>
            )}
          </div>
        </div>

        <div className={styles.drawer__content}>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            ghost={true}
            className={styles.drawer__collapse}
          >
            <Panel
              header={
                <span className={styles.drawer__collapse_header}>
                  {req.request_type === 'ExtendTime'
                    ? `Fulfilment's request`
                    : `Photographer's request`}
                </span>
              }
              key="1"
            >
              <div className={styles.extraData__item}>
                <span className={styles.extraData__label}>Request status:</span>
                {isSuperuser ? (
                  <Select
                    className={clsx(
                      'selectJobOrderStatus',
                      styles.select,
                      `${`selectJobStatus__${req.state.name}`}`
                    )}
                    popupClassName={clsx('selectJobOrderStatus__dropdown')}
                    value={req.state_id}
                    options={requestStatuses}
                    onChange={changeRequestState}
                    loading={isLoading}
                  />
                ) : (
                  <span className={styles.extraData__value}>
                    {req.state.name}
                  </span>
                )}
              </div>

              {req.request_type.includes('drone') && reqExtraData && (
                <DroneLicense
                  userId={req.user.id}
                  firstName={req.user.first_name}
                  middleName={req.user?.middle_name || ''}
                  lastName={req.user.last_name}
                  droneData={reqExtraData}
                />
              )}
              {req.request_type.includes('level') && reqExtraData && (
                <LevelChange
                  userId={req.user.id}
                  firstName={req.user.first_name}
                  middleName={req.user?.middle_name || ''}
                  lastName={req.user.last_name}
                  levelData={reqExtraData}
                />
              )}
              {req.request_type.includes('Extend') && reqExtraData && (
                <ExtendTime
                  userId={req.user_id}
                  firstName={req.user.first_name}
                  middleName={req.user?.middle_name || ''}
                  lastName={req.user.last_name}
                  extraData={reqExtraData}
                  description={req.description}
                />
              )}
            </Panel>
            <Panel
              header={
                <span
                  className={clsx(
                    styles.drawer__collapse_header,
                    calcNewMsg(dataComments) > 0 && styles.newMessage
                  )}
                >
                  Comments
                </span>
              }
              key="2"
            >
              <Comments
                hasReqItem={true}
                customClassname={styles.fullWidth}
                hasInternalTab={true}
                inDrawer={true}
                isInternal={false}
                fixedHeight={true}
                objectType={objectType}
                objectId={objectId}
                relatives={
                  objectType.includes('fulfilment')
                    ? [req?.fulfilment?.order?.id]
                    : [req?.user?.id]
                }
              />
            </Panel>
          </Collapse>
        </div>

        <NestedDrawer
          openedNestedDrawer={openedNestedDrawer}
          setOpenedNestedDrawer={setOpenedNestedDrawer}
          reqId={req.id}
          reqType={req.request_type}
          reqAction={openedNestedDrawerType}
          setOpen={setOpen}
          setRefetchData={setRefetchData}
          refetchContent={refetchContent}
          level={reqExtraData?.level || null}
          maxJobCount={
            req.request_type !== 'ExtendTime' &&
            dataPrefs &&
            dataPrefs?.hasOwnProperty('data')
              ? dataPrefs.data.find(
                  (e: { preference_id: string }) =>
                    e.preference_id === APP_CONSTANS.USER_PREFS_ID.MaxJobCount
                ).value
              : null
          }
          jobExpiredAt={req?.fulfilment?.expired_at || null}
          orderExpiredAt={req?.fulfilment?.order?.expired_at || null}
          timeExtend={req?.extend_hours || null}
          relatives={relatives}
          onCloseFirstDrawer={onClose}
        />
      </Drawer>
    )
  );
};
