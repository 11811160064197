import {
  GoogleMap,
  GoogleMapProps,
  useJsApiLoader,
  Autocomplete,
  MarkerF,
  // Libraries,
} from '@react-google-maps/api';
import { Loader } from 'components/ui_components/Loader';
import {
  FC,
  SetStateAction,
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { APP_CONSTANS } from 'shared/constants';
import { Address } from 'types/global';
import { Bounds, GoogleMapType } from './types';
import { Form, message } from 'antd';
import { NewOrderContext } from 'components/pages/main/orders_section/CreateOrderPage/context';

const containerStyle = {
  width: '100%',
  height: '100%',
};

export const dummyMapData = {
  center: { lat: 39.0997, lng: -94.5786 },
  zoom: 4,
};

export type MapProps = {
  bounds?: Bounds;
  center?: Address | null;
  getMapReference?: (map: GoogleMapType | undefined) => void;
  autocomlete?: boolean;
} & Omit<GoogleMapProps, 'center'>;

const librariesForMap: (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[] = ['places'];

const Map: FC<MapProps> = ({
  children,
  getMapReference,
  mapContainerStyle,
  zoom,
  center,
  bounds,
  autocomlete = false,
  ...rest
}) => {
  const { center: dummyCenter, zoom: dummyZoom } = dummyMapData;
  const isNewOrder = useContext(NewOrderContext);
  const [searchResult, setSearchResult] = useState<any>(null);
  const [position, setPosition] = useState<google.maps.LatLng | Address>(
    center || dummyCenter
  );
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: APP_CONSTANS.GOOGLE_MAP_API_KEY,
    nonce: APP_CONSTANS.GOOGLE_MAP_NONCE,
    // @ts-ignore
    libraries: librariesForMap,
  });
  const [mapRef, setMapRef] = useState<GoogleMapType>();

  const form = Form.useFormInstance();
  const address = Form.useWatch('address', form);

  const loadHandler = useCallback((mapInstance: GoogleMapType) => {
    setMapRef(mapInstance);
  }, []);

  const fitBounds = useCallback(
    ({ maxLat, minLat, maxLng, minLng }: Bounds) => {
      if (mapRef) {
        const latLngBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(minLat, minLng),
          new google.maps.LatLng(maxLat, maxLng)
        );
        mapRef.fitBounds(latLngBounds);
      }
    },
    [mapRef]
  );

  useEffect(() => {
    if (getMapReference) {
      getMapReference(mapRef);
    }
  }, [mapRef, getMapReference]);

  useEffect(() => {
    if (center) {
      setPosition(center);
    }
  }, [center]);

  useEffect(() => {
    if (bounds) {
      fitBounds(bounds);
    }
  }, [fitBounds, bounds]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  if (!isLoaded) {
    return <Loader />;
  }

  const mapZoom = zoom && center ? zoom : dummyZoom;

  const onLoad = (autocomplete: SetStateAction<any>) => {
    setSearchResult(autocomplete);
    console.log('Map API last 4: ', APP_CONSTANS.GOOGLE_MAP_API_KEY.slice(-4));
  };

  const onPlaceChanged = () => {
    if (searchResult !== null && mapRef) {
      const place = searchResult.getPlace();
      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // eslint-disable-next-line
        message.warning("No details available for input: '" + place.name + "'");
        return;
      }
      if (place.geometry.viewport) {
        mapRef.fitBounds(place.geometry.viewport);
      } else {
        mapRef.setCenter(place.geometry.location);
      }

      form.setFieldValue('location_lat', place.geometry.location.lat());
      form.setFieldValue('location_long', place.geometry.location.lng());
      form.setFieldValue('address', place.formatted_address);
      setPosition(place.geometry.location || dummyCenter);
      // console.log('plsearchResultace', searchResult);
      // console.log('place', place);
    } else {
      // eslint-disable-next-line
      message.warning('Please enter address');
    }
  };

  const handleClickOrDragEnd = (event: google.maps.MapMouseEvent) => {
    if (event) {
      const lat = event.latLng?.lat();
      const lng = event.latLng?.lng();
      if (lat && lng) {
        form.setFieldValue('location_lat', lat);
        form.setFieldValue('location_long', lng);
        setPosition({
          lat,
          lng,
        });
      }
    }
  };

  return (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, ...mapContainerStyle }}
      onLoad={loadHandler}
      center={center || dummyCenter}
      zoom={mapZoom}
      onClick={handleClickOrDragEnd}
      {...rest}
    >
      {autocomlete && (
        <>
          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <input
              type="text"
              placeholder={address}
              autoFocus={!isNewOrder}
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `50%`,
                height: `40px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 1px 4px rgba(0, 0, 0, 0.2)`,
                fontSize: `16px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '10px',
              }}
            />
          </Autocomplete>
          <MarkerF
            position={position}
            clickable={false}
            draggable
            onDragEnd={handleClickOrDragEnd}
            title="Order location"
          />
        </>
      )}
      {children}
    </GoogleMap>
  );
};

const MemoizedMap = memo(Map);
export { MemoizedMap as Map };
