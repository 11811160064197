import { ColumnsState } from '@ant-design/pro-table';

export const defaultEnabledSettingsCountry = {
  code: { order: 1 },
  name: { order: 2 },
  continent: { order: 3 },
  capital: { order: 4 },
  is_supported: { order: 5 },
};

export const defaultEnabledSettingsRegion = {
  region_name: { order: 1 },
  region_code: { order: 2 },
  is_supported: { order: 3 },
};

export const defaultEnabledSettingsGeonames: Record<any, ColumnsState> = {
  place_name: { order: 1 },
  state_name: { order: 2 },
  county_name: { order: 3 },
  postal_code: { order: 4 },
};

export const defaultEnabledSettingsTimezones: Record<any, ColumnsState> = {
  country_code: { order: 1 },
  timezone_id: { order: 2 },
  gmt_offset: { order: 3 },
  is_supported: { order: 4 },
};

export const defaultEnabledSettingsCurrency: Record<any, ColumnsState> = {
  name: { order: 1 },
  numeric: { order: 2 },
  entity: { order: 3 },
  is_supported: { order: 4 },
};

export const defaultEnabledSettingsPurchaseMethods: Record<any, ColumnsState> =
  {
    name: { order: 1 },
    status: { order: 2 },
    reward_type: { order: 3 },
    reward_id: { order: 4 },
  };

export const defaultEnabledSettingsAnyStates: Record<any, ColumnsState> = {
  name: { order: 1 },
  description: { order: 2 },
};

export const defaultEnabledSettingsPaymentMethods: Record<any, ColumnsState> = {
  name: { order: 1 },
  status: { order: 2 },
  'payment_provider.name': { order: 3 },
  'is_expired!': { order: 4 },
};
