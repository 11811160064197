import clsx from 'clsx';
// import { Footer } from 'components/app/AdminPageContainer/components/Footer';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import styles from './styles.module.scss';

type MainContainerProps = DetailedHTMLProps<
  HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  title?: string;
  additionalItems?: ReactNode;
  expanded?: boolean;
  children?: ReactNode;
  footerClassName?: string;
  headerClassName?: string;
};

export const MainContainer = ({
  expanded,
  title,
  additionalItems,
  children,
  className,
  headerClassName,
  ...rest
}: MainContainerProps) => {
  const pageClassName = clsx(
    styles.rootPage,
    expanded ? styles.pageExpanded : styles.page
  );
  const containerClassName = className
    ? clsx(pageClassName, className)
    : pageClassName;
  const headerExist = title || additionalItems;

  return (
    <article className={containerClassName} {...rest}>
      <div>
        {headerExist && (
          <div
            className={clsx(styles.header, headerClassName && headerClassName)}
          >
            {title && <h1 className={styles.title}>{title}</h1>}
            {additionalItems}
          </div>
        )}
        {children}
      </div>
      {/* <Footer className={styles.footer} /> */}
    </article>
  );
};
