export const InvoiceLineCols = [
  'id',
  'code',
  'title',
  'amount',
  'discount',
  'discount_sum',
  'Order.code',
  'Order.id',
];
