import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  authData: {},
  authUserRole: '',
  token: '',
  id: '',
  manualLogout: false,
  isLoadingToken: false,
  isSigningOut: false,
  isSigningOutFinish: false,
  is_authorizated: false,
  isVirtual: false,
  lastStepBreadcrumb: '',
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_TOKEN_PENDING:
      return { ...state, isLoadingUser: true };

    case ACTION_TYPES.IS_AUTHORIZATED:
      return { ...state, is_authorizated: payload.status };

    case ACTION_TYPES.GET_TOKEN_FULFILLED:
      return { ...state, isLoadingUser: false };
    case ACTION_TYPES.GET_TOKEN_REJECTED:
      return { ...state, isLoadingUser: false };

    case ACTION_TYPES.SIGN_OUT_PENDING:
      return { ...state, isSigningOut: true };
    case ACTION_TYPES.SIGN_OUT_FULFILLED:
      return {
        ...state,
        authData: {},
        token: '',
        id: '',
        isSigningOut: false,
        isSigningOutFinish: true,
      };
    case ACTION_TYPES.SIGN_OUT_REJECTED:
      return { ...state, isSigningOut: false };

    case ACTION_TYPES.SET_TOKEN:
      return { ...state, token: payload };
    case ACTION_TYPES.SET_LAST_BREADCRUBMS:
      return { ...state, lastStepBreadcrumb: payload };
    case ACTION_TYPES.SET_AUTH_USER_ROLE:
      return { ...state, authUserRole: payload };
    case ACTION_TYPES.SET_MANUAL_LOGOUT:
      return { ...state, manualLogout: payload };

    case ACTION_TYPES.SET_AUTH_DATA:
      return { ...state, authData: payload };

    case ACTION_TYPES.IS_VITUAL:
      return { ...state, isVirtual: payload };

    default:
      return state;
  }
};
