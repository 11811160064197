import { APP_CONSTANS } from 'shared/constants';

export const defaultJobStatuses = [
  {
    name: 'Accepted',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.ACCEPTED_JOB,
    jobs: 0,
  },
  {
    name: 'Pending',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.PENDING_JOB,
    jobs: 0,
  },
  {
    name: 'Submitted',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.SUBMITTED_JOB,
    jobs: 0,
  },
  {
    name: 'Completed',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB,
    jobs: 0,
  },
  {
    name: 'Rejected',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.REJECTED_JOB,
    jobs: 0,
  },
  {
    name: 'Returned',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.RETURNED_JOB,
    jobs: 0,
  },
  {
    name: 'Declined',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.DECLINED_JOB,
    jobs: 0,
  },
  {
    name: 'Cancelled',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.CANCELLED_JOB,
    jobs: 0,
  },
  {
    name: 'Expired',
    quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.EXPIRED_JOB,
    jobs: 0,
  },
];
