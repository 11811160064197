import Table from '../Table';
import { columns } from './columns';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { InvoiceLinesTableT } from './types';
import { Button, Dropdown, Tag, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import styles from './styles.module.scss';
import {
  renderActionLink,
  renderTitleLink,
} from 'shared/utils/DataInTables/renderLinks';
import { DrawerInvoiceLines } from './components/DrawerInvoiceLines';
import { APP_CONSTANS } from 'shared/constants';
import globalStyles from 'shared/styles/styles.module.scss';

export const InvoiceLinesTable: React.FC<InvoiceLinesTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'invoiceLinesTable',
  currencyCode,
  disableFilteringAndSearching = false,
  refetchInvoice,
  ...rest
}) => {
  const queryClient: any = useQueryClient();
  const token = useSelector((state: any) => state.auth.token);
  const [currentId, setCurrentId] = useState<undefined | string>();
  const [show, setShow] = useState<boolean>(false);

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables: any) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Deleting line error. ${data.error?.message}`);
        return;
      } else {
        queryClient.invalidateQueries(queryKey);
        refetchInvoice();
        message.success(`Line ${variables?.data?.code || ''} was deleted`);
      }
    },
  });

  const handleDelete = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    if (!id) {
      message.error('Undefined Invoice line ID');
      return;
    }
    if (!queryKey[1]) {
      message.error('Undefined Invoice ID');
      return;
    }
    mutate({
      data: { invoice_id: queryKey[1], move_line_ids: [id] },
      token,
      otherProps: queryNames.INVOICE_UPDATE,
    });
  };

  const showDrawerAndLink = (value: any, id: string) => {
    const onClick = () => {
      setCurrentId(id);
      setShow(true);
    };
    return renderActionLink(value, onClick);
  };

  const renderOrderStatus = useCallback((value: string, record: any) => {
    const orderStatusId = record?.['order.state.id'];
    const orderStatusName = record?.['order.state.name'];
    return (
      <Tag
        className={globalStyles.contentStatus}
        data-order-status={orderStatusId}
      >
        {orderStatusName}
      </Tag>
    );
  }, []);

  const renderData = (value: any, record: any) => {
    const id: string = record?.id;
    const currency = record?.['currency.code'] || currencyCode;
    const orderId = record?.['order.id'];

    return [
      {
        name: 'code',
        action: showDrawerAndLink(value, id),
      },
      {
        name: 'title',
        action: renderTitleLink(value, `/orders/all/${orderId}`, true),
      },
      {
        name: 'order.code',
        action: renderTitleLink(value, `/orders/all/${orderId}`, true),
      },
      {
        name: 'order.state_id',
        action: renderOrderStatus(value, record),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'discount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'discount_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
    ];
  };

  const actionsColumn = [
    {
      title: 'Action',
      width: 70,
      fixed: 'right',
      hideInSetting: true,
      render: (record: any) => (
        <Dropdown
          key="dropdown"
          trigger={['click']}
          menu={{
            items: [
              {
                key: 'DeleteLine',
                label: (
                  <Button
                    type="text"
                    onClick={(e) => handleDelete(e, record.id)}
                    block
                    danger
                    disabled={false}
                  >
                    Delete Line
                  </Button>
                ),
              },
            ],
          }}
          overlayClassName={styles.dropdown}
        >
          <Button
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              event.stopPropagation();
            }}
            type="text"
          >
            ...
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        //@ts-ignore
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="lines"
        columnsForRequest={columns}
        disableFilteringAndSearching={disableFilteringAndSearching}
        defaultSorter={{
          field: 'inv_line.code',
          order: 'descend',
        }}
        {...rest}
      />
      {show && (
        <DrawerInvoiceLines
          show={show}
          setShow={setShow}
          id={currentId}
          currencyCode={currencyCode}
        />
      )}
    </>
  );
};
