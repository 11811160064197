export const userTableCols = [
  'id',
  'user_id',
  'updated_at',
  'State.name',
  'created_at',
];

export const jobTableCols = [
  'id',
  'Fulfilment.code',
  'user_id',
  'Fulfilment.id',
  'Fulfilment.Order.id',
  'User.first_name',
  'User.last_name',
  'state_id',
  'State.name',
  'request_type',
  'created_at',
];

export const jobReqCols = [
  'id',
  'user_id',
  'User.first_name',
  'User.last_name',
  'state_id',
  'State.id',
  'State.name',
  'extend_hours',
  'request_type',
  'Fulfilment.code',
  'Fulfilment.Order.id',
  'Fulfilment.Order.code',
  'Fulfilment.Order.title',
  'Fulfilment.Order.expired_at',
  'Fulfilment.id',
  'Fulfilment.code',
  'Fulfilment.expired_at',
  'description',
  'created_at',
];

export const userReqCols = [
  'extra_data',
  'id',
  'request_type',
  'state_id',
  'State.name',
  'User.id',
  'User.first_name',
  'User.middle_name',
  'User.last_name',
  'created_at',
];
