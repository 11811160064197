import { Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { APP_CONSTANS } from 'shared/constants';
import globalStyles from 'shared/styles/styles.module.scss';
import styles from '../../styles.module.scss';
import { WarningTwoTone } from '@ant-design/icons';

export const JobContentStates: React.FC<any> = ({
  contents,
  quantityFiles,
}) => {
  const [processingStatus, setProcessingStatus] = useState<number>(0);
  const [processedStatus, setProcessedStatus] = useState<number>(0);
  const [submittedStatus, setSubmittedStatuss] = useState<number>(0);
  const [approvedStatus, setApprovedStatus] = useState<number>(0);
  const [rejectedStatus, setRejectedStatus] = useState<number>(0);
  const [deliveredStatus, setDeliveredStatus] = useState<number>(0);
  const [failedStatus, setFailedStatus] = useState<number>(0);
  const [convertingStatus, setConvertingStatus] = useState<number>(0);
  const [duplicatedStatus, setDuplicatedStatus] = useState<number>(0);
  const [unfocusedStatus, setUnfocusedStatus] = useState<number>(0);

  const {
    PROCESSING_CONTENT,
    PROCESSED_CONTENT,
    SUBMITTED_CONTENT,
    APPROVED_CONTENT,
    REJECTED_CONTENT,
    DELIVERED_CONTENT,
    FAILED_CONTENT,
    CONVERTING_CONTENT,
    DUPLICATED_CONTENT,
    OUT_OF_FOCUS_CONTENT,
  } = APP_CONSTANS.FULFILMENT_CONTENT_STATUSES_GUID_BY_CODE;

  const getCountOfContents = useCallback(
    (status: string) => {
      const filteredContents = contents.filter(
        (state: { state_id: string }) => {
          return state.state_id === status;
        }
      );
      return filteredContents.length;
    },
    [contents]
  );

  useEffect(() => {
    if (contents) {
      setProcessingStatus(getCountOfContents(PROCESSING_CONTENT));
      setProcessedStatus(getCountOfContents(PROCESSED_CONTENT));
      setSubmittedStatuss(getCountOfContents(SUBMITTED_CONTENT));
      setApprovedStatus(getCountOfContents(APPROVED_CONTENT));
      setRejectedStatus(getCountOfContents(REJECTED_CONTENT));
      setDeliveredStatus(getCountOfContents(DELIVERED_CONTENT));
      setFailedStatus(getCountOfContents(FAILED_CONTENT));
      setConvertingStatus(getCountOfContents(CONVERTING_CONTENT));
      setDuplicatedStatus(getCountOfContents(DUPLICATED_CONTENT));
      setUnfocusedStatus(getCountOfContents(OUT_OF_FOCUS_CONTENT));
    }
  }, [
    getCountOfContents,
    contents,
    PROCESSING_CONTENT,
    PROCESSED_CONTENT,
    SUBMITTED_CONTENT,
    APPROVED_CONTENT,
    REJECTED_CONTENT,
    DELIVERED_CONTENT,
    FAILED_CONTENT,
    CONVERTING_CONTENT,
    DUPLICATED_CONTENT,
    OUT_OF_FOCUS_CONTENT,
  ]);

  return (
    <div>
      {contents && contents.length > 0 && (
        <>
          {processingStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={PROCESSING_CONTENT}
            >
              {processingStatus} Processing
            </Tag>
          )}
          {processedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={PROCESSED_CONTENT}
            >
              {processedStatus} Processed
            </Tag>
          )}
          {submittedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={SUBMITTED_CONTENT}
            >
              {submittedStatus} Submitted
            </Tag>
          )}
          {approvedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={APPROVED_CONTENT}
            >
              {approvedStatus} Approved
            </Tag>
          )}
          {rejectedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={REJECTED_CONTENT}
            >
              {rejectedStatus} Rejected
            </Tag>
          )}
          {duplicatedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={DUPLICATED_CONTENT}
            >
              {duplicatedStatus} Duplicated
            </Tag>
          )}
          {unfocusedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={OUT_OF_FOCUS_CONTENT}
            >
              {unfocusedStatus} Out of focus
            </Tag>
          )}
          {deliveredStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={DELIVERED_CONTENT}
            >
              {deliveredStatus} Delivered
            </Tag>
          )}
          {failedStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={FAILED_CONTENT}
            >
              {failedStatus} Failed
            </Tag>
          )}
          {convertingStatus > 0 && (
            <Tag
              className={clsx(globalStyles.contentStatus, styles.orderStatus)}
              data-content-status={CONVERTING_CONTENT}
            >
              {convertingStatus} Converting
            </Tag>
          )}
          {quantityFiles >
            processingStatus +
              processedStatus +
              submittedStatus +
              approvedStatus +
              deliveredStatus +
              convertingStatus && (
            <Tooltip title="Minimum number of files is not met">
              <WarningTwoTone twoToneColor="#D67500" />
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};
