import React from 'react';
import styles from './styles.module.scss';
import { Form, Input } from 'antd';

export const OrderJobMemo: React.FC<any> = ({ isFormDisabled, isPurchase }) => {
  return (
    <section className={styles.memo}>
      <h3 className={styles.memo__title}>
        {isPurchase ? 'Memo' : 'Internal Memo/P.O.'}
      </h3>
      <Form.Item
        name={isPurchase ? 'memo' : 'external_id'}
        className={styles.memo__formItem}
      >
        {isPurchase ? (
          <Input.TextArea
            autoSize={{ minRows: 5, maxRows: 10 }}
            disabled={isFormDisabled}
          />
        ) : (
          <Input disabled={isFormDisabled} />
        )}
      </Form.Item>
    </section>
  );
};
