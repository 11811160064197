import { FunctionComponent } from 'react';
import { tablesNames } from '../../../../../../../../../api/tablesNames';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { TabAccountListT } from './types';
import { queryNames } from 'api/queryNames';
import { UserAccountsTable } from 'components/ui_components/UserAccountsTable';

export const TabAccountList: FunctionComponent<TabAccountListT> = ({
  userId,
}) => {
  const meta = { additionalSearchParams: `?user_ids=${userId}` };
  return (
    <UserAccountsTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={[queryNames.USER_ACCOUNTS, 'user_accounts_page_user']}
      meta={meta}
      headerName={tablesNames.UserAccountsHeader}
      toolbar={null}
      actionsColumn={null}
      tableName={tablesNames.UserAccountsPageUserCol}
      hideInSetting={hideInSetting}
      quantityTitleText="accounts"
      resetAllFiltering={true}
    />
  );
};
