export const PaymentOperationCols = [
  'id',
  'created_at',
  'amount',
  'provider_tx_status',
  'operation_type',
  'Currency.code',
  'PaymentStatus.name',
  'PaymentProvider.name',
  'PaymentMethod.status',
  'Invoice.code',
  'Invoice.id',
  'User.id',
  'Account.id',
];
