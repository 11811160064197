import { FunctionComponent, useCallback, useRef, useState } from 'react';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ModalUserAddress } from '../../../../../../../../modals/user_address/user_address';
import { TabAddressT } from './types';
import styles from './styles.module.scss';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from '../../../../../../../../../api/usePostData';
import { queryNames } from 'api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { AddressTable } from 'components/ui_components/AddressTable';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import clsx from 'clsx';

export const TabAddresses: FunctionComponent<TabAddressT> = ({
  userId,
  mainAddressId,
  refetchUser,
  refetchUserAddress,
}) => {
  const [modeAddressModal, setModeAddressModal] = useState<string>('');
  const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const [editedAddress, setEditedAddress] = useState({});
  const currentAddress = useRef<any>();
  const token = useSelector((state: any) => state.auth.token);
  const meta = {
    additionalSearchParams: `?user_ids=${userId}`,
    getAllData: true,
  };

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data.hasOwnProperty('error')) {
        message.error(`Server Error ${data.error?.message}`);
      } else {
        message.success('Address updated');
        refetchUser();
        setRefetchData(true);
        // refetchUserAddress();
      }
    },
  });

  const editAddress = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setEditedAddress(currentAddress.current);
    setModeAddressModal('Change');
    setShowAddressModal(true);
  };

  const setMainAddress = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (currentAddress.current.id !== mainAddressId) {
      const data = {
        id: userId,
        address_id: currentAddress.current.id,
      };
      mutate({ data, token, otherProps: 'users', method: 'PUT' });
    }
  };

  const addAddress = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setModeAddressModal('Add new');
    setShowAddressModal(true);
  }, []);

  const toolbarRender = [
    <Button
      className={styles.btnAddNewAddress}
      key="button"
      icon={<PlusOutlined />}
      onClick={addAddress}
    >
      Add new address
    </Button>,
  ];

  const restoreAddress = useCallback(() => {
    const data = {
      id: currentAddress.current.id,
    };
    mutate({
      data,
      token,
      otherProps: queryNames.CLEANER_RESTORE_USER_ADDRESS,
      method: 'POST',
    });
  }, [mutate, token]);

  const deleteAddress = useCallback(
    (type: string) => {
      const data = {
        id: currentAddress.current.id,
      };
      if (type === 'soft') {
        mutate({
          data,
          token,
          otherProps: queryNames.CLEANER_SOFT_DELETE_USER_ADDRESS,
          method: 'POST',
        });
      } else {
        mutate({
          data,
          token,
          otherProps: queryNames.CLEANER_HARD_DELETE_USER_ADDRESS,
          method: 'POST',
        });
      }
    },
    [mutate, token]
  );

  const deletedItems: MenuProps['items'] = [
    {
      key: 'Restore',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            restoreAddress();
          }}
        >
          Restore
        </Button>
      ),
    },
    {
      key: 'Hard_Delete',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            deleteAddress('hard');
          }}
        >
          Hard Delete
        </Button>
      ),
    },
  ];

  const items: MenuProps['items'] = [
    {
      key: 'Edit',
      label: (
        <Button type="text" onClick={editAddress}>
          Edit
        </Button>
      ),
    },
    {
      key: 'Main_address',
      label: (
        <Button type="text" onClick={setMainAddress}>
          Set as Main address
        </Button>
      ),
    },
    {
      key: 'Soft_Delete',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            deleteAddress('soft');
          }}
        >
          Soft Delete
        </Button>
      ),
    },
  ];

  const actionsColumn = {
    title: 'addressesAction',
    width: 100,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => (
      <Dropdown
        key="dropdown"
        trigger={['click']}
        menu={{
          items:
            record.hasOwnProperty('deleted_at') && record?.deleted_at > 0
              ? deletedItems
              : items,
        }}
        overlayClassName={clsx(
          styles.action,
          record.id === mainAddressId && styles.hideMainAddrr
        )}
      >
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            currentAddress.current = record;
          }}
          type="text"
        >
          ...
        </Button>
      </Dropdown>
    ),
  };

  return (
    <>
      <AddressTable
        defaultEnabledSettings={defaultEnabledSettings}
        queryKey={queryNames.USERS_getAddressById}
        meta={meta}
        headerName={tablesNames.UserAddressesHeader}
        toolbar={null}
        toolBarRender={toolbarRender}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
        mainAddressId={mainAddressId}
        actionsColumn={actionsColumn}
        tableName={tablesNames.UserAddressesCol}
        hideInSetting={hideInSetting}
        resetAllFiltering={true}
      />

      <ModalUserAddress
        mode={modeAddressModal}
        visible={showAddressModal}
        setHidden={setShowAddressModal}
        address={modeAddressModal === 'Change' ? editedAddress : null}
        userId={userId}
        refetchUserAddress={refetchUserAddress}
        setRefetchData={setRefetchData}
        addressType="user"
      />
    </>
  );
};
