export const hideInSetting = [
  'id',
  'user_id',
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'account_id',
  'account.status',
  'payment_provider_id',
  'payment_provider.description',
];
