import { logging } from 'shared/utils/logging';
import { APP_CONSTANS } from '../shared/constants';

export const usePostData = async (props: {
  data: object;
  formData?: any;
  token?: string;
  otherProps?: string;
  method?: string;
  url?: string;
}) => {
  const postDataURL = props.url
    ? props.url
    : `${APP_CONSTANS.BASE_API_URL_V2}/${props.otherProps}`;

  const headers: any = props.hasOwnProperty('token')
    ? {
        Authorization: `Bearer ${props.token}`,
      }
    : {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      };

  const method = `${props.method ? props.method : 'POST'}`;
  const body = props.hasOwnProperty('formData')
    ? props.formData
    : JSON.stringify(props.data);

  try {
    const response: Response = await fetch(`${postDataURL}`, {
      method: method,
      headers: headers,
      body: body,
    });
    if (response.ok && response.status === 204) {
      return await response.text();
    }
    try {
      const data = await response.json();
      if (data.hasOwnProperty('error')) {
        logging(postDataURL, data.error?.message, method, body, headers);
      }
      return data;
    } catch {
      console.log('Request Body parsing problem');
      return '';
    }
  } catch (err) {
    const errorObj = {
      error: {
        code: 'FrontEnd Error',
        message: "Request didn't reach Server",
      },
    };
    return errorObj;
  }
};
