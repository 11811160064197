export enum CompletedJobsType {
  ALL = 'all',
  PAYPAL = 'paypal',
  VENMO = 'venmo',
}

export type JobPayouts = {
  order: {
    payout: number;
    currency: {
      code: string;
    };
  };
  account: {
    purchase_method: {
      purchase_target_id: string;
    };
  };
  [key: string]: any;
};

export type SummaryByCode = {
  all: (string | number | undefined)[];
  paypal: (string | number | undefined)[];
  venmo: (string | number | undefined)[];
};
