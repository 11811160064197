import { CalendarOutlined, SearchOutlined } from '@ant-design/icons';
import { FilterDropdownRender } from 'components/ui_components/FilterDropdownRender';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { widthPresets } from './styles';
import { ColumnHeaderElement, FilterItems } from './types';
import { ProColumns } from '@ant-design/pro-table';
import { DataTableT } from '../../types';
import { TimeTooltip } from 'components/ui_components/TimeTooltip';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';

const rangeIcon = (filtered: boolean) => (
  <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
);

const filterDropdown = (
  setSelectedKeys: any,
  selectedKeys: any,
  confirm: any,
  clearFilters: any,
  dataIndex: any,
  type: string,
  setSearchKeys: any,
  setRangeKeys: any,
  setCodeKeys: any,
  searchInput: any,
  setSearchParams: any,
  setFilters: any,
  filtersWithPrefix: any
) => {
  return (
    <FilterDropdownRender
      setSelectedKeys={setSelectedKeys}
      selectedKeys={selectedKeys}
      confirm={confirm}
      clearFilters={clearFilters}
      dataIndex={dataIndex}
      searchInputRef={searchInput}
      setSearchKeys={setSearchKeys}
      setRangeKeys={setRangeKeys}
      setCodeKeys={setCodeKeys}
      type={type}
      setSearchParams={setSearchParams}
      setFilters={setFilters}
      filtersWithPrefix={filtersWithPrefix}
    />
  );
};

const searchIcon = (filtered: boolean, selectInput: any) => (
  <SearchOutlined
    onClick={selectInput}
    className={clsx(styles.searchIcon, filtered ? styles.activeSearchIcon : '')}
  />
);

const formatFilterItems: any = (items: FilterItems[]) => {
  items.forEach((item: FilterItems) => (item.text = item.display));
  return items;
};

export const columnsGenerator = (
  cols: any[],
  disableFilteringAndSearching: boolean,
  hideInSetting: any,
  renderColumns: any,
  renderDataColumn: any,
  actionsColumn: any,
  setSearchKeys: any,
  setRangeKeys: any,
  setCodeKeys: any,
  selectInput: any,
  searchInput: any,
  tableFilters: any,
  numbered: boolean,
  sorter: any,
  defaultSortingElems: any,
  filtersWithoutPrefix: any,
  searchKeys: any,
  setSearchParams: any,
  setFilters: any,
  filtersWithPrefix: any
) => {
  const columnSorter = () => {
    return disableFilteringAndSearching ? false : { multiple: 1 };
  };
  const columnFilterIcon = (searchable: any, type: string, alias: string) => {
    return !disableFilteringAndSearching && searchable
      ? () => {
          if (searchKeys.hasOwnProperty(alias)) {
            return searchIcon(true, selectInput);
          } else {
            return searchIcon(false, selectInput);
          }
        }
      : type === 'datetime'
      ? (filtered: boolean) => rangeIcon(filtered)
      : false;
  };
  const columnFilterDropdown = (searchable: any, type: string, field: any) => {
    return (searchable || type === 'datetime') && !disableFilteringAndSearching
      ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) =>
          filterDropdown(
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            field,
            type,
            setSearchKeys,
            setRangeKeys,
            setCodeKeys,
            searchInput,
            setSearchParams,
            setFilters,
            filtersWithPrefix
          )
      : false;
  };
  const columnFilters = (filter_items: any) => {
    return filter_items && !disableFilteringAndSearching
      ? formatFilterItems(filter_items)
      : false;
  };
  const columnDefaultSorter = (e: any) => {
    return sorter.find((sortingElem: any) => {
      if (e.hasOwnProperty('sortingAlias')) {
        return sortingElem.field === e.sortingAlias;
      } else {
        return sortingElem.field === e.alias;
      }
    })?.order;
  };
  const columnDefaultFiltering = (e: any) => {
    if (e.hasOwnProperty('filterAlias')) {
      return filtersWithoutPrefix?.[e?.filterAlias]?.key || null;
    } else if (!e.hasOwnProperty('filterAlias')) {
      return filtersWithoutPrefix?.[e?.alias]?.key || null;
    } else {
      return null;
    }
  };

  const columnCreator = (e: ColumnHeaderElement) => ({
    title: e.title,
    dataIndex: e.field,
    key: e.field,
    width: Object.hasOwn(widthPresets, e.field) ? widthPresets[e.field] : 200,
    sorter: e.orderable ? columnSorter() : false,
    filterIcon: columnFilterIcon(e.searchable, e.type, e.alias),
    filteredValue:
      filtersWithoutPrefix &&
      Object.keys(filtersWithoutPrefix)?.includes(
        e?.filterAlias ? e.filterAlias : e.alias
      )
        ? columnDefaultFiltering(e)
        : null,
    filterDropdown: columnFilterDropdown(e.searchable, e.type, e.alias),
    filters: columnFilters(e.filter_items),
    filterMode: 'tree',
    filterMultiple: true,
    alias: e.alias,
    isJson: e.hasOwnProperty('isJson') ? e.isJson : false,
    name: e.name,
    type: e.type,
    sortOrder:
      sorter?.length > 0 ||
      defaultSortingElems?.includes(e?.sortingAlias ? e.sortingAlias : e.alias)
        ? columnDefaultSorter(e)
        : null,
    defaultSortOrder:
      sorter?.length > 0 &&
      defaultSortingElems?.includes(e?.sortingAlias ? e.sortingAlias : e.alias)
        ? columnDefaultSorter(e)
        : null,
    hideInSetting: hideInSetting
      ? hideInSetting.find((el: string) => el === e.field)
      : false,
    render: (value: any, record: any, index: any) => {
      if (
        e.type === 'datetimetz' &&
        record?.timezone &&
        value &&
        value !== '-'
      ) {
        return (
          <TimeTooltip
            date={value}
            timezone={record.timezone}
            timezoneDisplayPreset={'long'}
          />
        );
      }
      if (e.type === 'datetimetz' && !record?.timezone) {
        return convertUNIXToLocale(value, null);
      }
      if (e.type === 'datetime') {
        return convertUNIXToLocale(value, null);
      }
      const currency =
        record?.['currency.code'] || record?.['order.currency.code'];
      if (e.type === 'amount' && currency) {
        return renderMoneyWithCurrency(value, currency);
      }
      return renderColumns &&
        renderColumns.some((column: any) => column === e.field)
        ? renderDataColumn &&
            renderDataColumn(value, record, index).find(
              (el: any) => el.name === e.field
            ).action
        : value;
    },
  });

  const numberColumn: DataTableT = {
    title: '#',
    search: true,
    valueType: 'index',
    width: 48,
  };

  const arr: ProColumns<DataTableT, 'text'>[] | any = numbered
    ? [numberColumn]
    : [];

  if (cols) {
    for (let i = 0; i < cols.length; i++) {
      const e = cols[i];
      if (
        e?.json_fields &&
        Array.isArray(e.json_fields) &&
        e.json_fields.length
      ) {
        for (let index = 0; index < e.json_fields.length; index++) {
          const el = e.json_fields[index];
          const updatedPref = {
            ...e,
            ...el,
            field: `${e.field}.${el.name}`,
            isJson: true,
            json_fields: null,
            filterAlias: `${e.alias}%3A%3A${el.name}%3A%3A`,
            sortingAlias: `${e.alias}::${el.name}`,
          };
          arr.push(columnCreator(updatedPref));
        }
      } else {
        arr.push(columnCreator(e));
      }
    }
    if (actionsColumn) {
      if (Array.isArray(actionsColumn)) {
        arr.push(...actionsColumn);
      } else {
        arr.push(actionsColumn);
      }
    }
  }

  return arr;
};
