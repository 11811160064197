import { Form, Input } from 'antd';
import clsx from 'clsx';
import ImageCompress from 'quill-image-compress';
import { FC, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.module.scss';
import { DeltaStatic, Sources } from 'quill';
import { formats, modules } from 'shared/utils/QuillEditorToolbar';

Quill.register('modules/imageCompress', ImageCompress);

export const AntQuill: FC<any> = ({ isJob, isFormDisabled }) => {
  const form = Form.useFormInstance();
  const quillRef = useRef<ReactQuill | null>(null);
  const [topBounds, setTopBounds] = useState(0);
  const [editorEnabled, setEditorEnabled] = useState(false);

  useEffect(() => {
    if (editorEnabled) {
      const bodyHeight = document.body.scrollHeight;
      const quillEditorHeight =
        (quillRef.current?.getEditingArea().scrollHeight || 0) + 400;

      window.scrollTo({
        behavior: 'smooth',
        top: bodyHeight - quillEditorHeight + topBounds,
      });
    }
  });

  const handleChange = (
    value: string,
    delta: DeltaStatic,
    source: Sources,
    editor: ReactQuill.UnprivilegedEditor
  ): void => {
    console.log('size', (value.length / 1000000) * 20);
    form.setFieldValue('quillSource', source);
    // if (source === 'api') return;
    // console.log('.getContents', editor.getContents());
    // console.log('delta', delta);
    console.log('.getLength', editor.getLength());
    // console.log('.getText', editor.getText());
  };

  return (
    <>
      <Form.Item hidden name={'quillSource'} initialValue={'api'}>
        <Input disabled></Input>
      </Form.Item>
      <Form.Item name={'description'}>
        <ReactQuill
          onChange={handleChange}
          onBlur={() => {
            setEditorEnabled(false);
          }}
          onFocus={() => {
            setEditorEnabled(true);
          }}
          theme={isJob ? 'bubble' : 'snow'}
          readOnly={isJob || isFormDisabled}
          // value={value || ''}
          ref={(el) => {
            quillRef.current = el;
          }}
          onChangeSelection={(newSelection) => {
            const bounds = quillRef.current?.editor?.getBounds(
              newSelection?.index || 0,
              0
            );
            const boundsTop = bounds?.top;
            if (boundsTop) {
              setTopBounds(boundsTop);
            }
          }}
          className={clsx(
            styles.quill,
            (isJob || isFormDisabled) && styles.jobQuill
          )}
          scrollingContainer="html"
          modules={modules}
          formats={formats}
        />
      </Form.Item>
    </>
  );
};
