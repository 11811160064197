import { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import { Drawer } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { tablesNames } from 'api/tablesNames';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';

export const DrawerHistory: FC<any> = ({ open, setOpen, id, setId }) => {
  const token = useSelector((state: any) => state.auth.token);

  const { isLoading, data, remove } = useQuery({
    queryKey: [`${queryNames.HISTORIES}/get?`],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `id=${id}`,
    },
    queryFn: useGetData,
    enabled: open,
  });

  const {
    isLoading: isLoadingHeaders,
    data: headers,
    remove: removeHeaders,
  } = useQuery({
    queryKey: [tablesNames.HistoriesHeader],
    meta: {
      token,
      getAllData: true,
    },
    queryFn: useGetData,
    enabled: open,
  });

  useEffect(() => {
    return () => {
      remove();
      removeHeaders();
    };
  }, [remove, removeHeaders]);

  const itemsRender = (obj: any) => {
    if (!obj || typeof obj !== 'object') return '';

    const entries = Object.entries(obj);
    const items = entries.map(([key, value]: any) => {
      const itemTitle = headers?.data?.columns?.find(
        (e: { name: any; title: string }) => e.name === key
      ).title;
      const type = headers?.data?.columns?.find(
        (e: { name: any; title: string }) => e.name === key
      ).type;
      const isObject = type === 'object' || typeof value === 'object';
      const convertedValue = isObject
        ? JSON.stringify(value)
        : type === 'datetime'
        ? convertUNIXToLocale(value, null)
        : value;
      return (
        <li className={styles.item} key={key + value}>
          <h4 className={styles.title}>{itemTitle}:</h4>
          <p className={styles.value}>
            {isObject ? (
              <pre>{JSON.stringify(JSON.parse(convertedValue), null, 2)}</pre>
            ) : (
              convertedValue
            )}
          </p>
        </li>
      );
    });

    return items;
  };

  const title = () => {
    return <span style={{ fontSize: '16px' }}>History info</span>;
  };

  const handleCloseDrawer = () => {
    setId('');
    setOpen(false);
  };

  return (
    id &&
    !isLoading &&
    !isLoadingHeaders &&
    data &&
    headers && (
      <Drawer
        width={window.innerWidth > 500 ? 500 : '95vw'}
        title={title()}
        placement="right"
        onClose={handleCloseDrawer}
        open={open}
      >
        {data?.data?.id ? (
          <div className={styles.wrapper}>
            <ul>{itemsRender(data?.data)}</ul>
          </div>
        ) : (
          <p>No data</p>
        )}
      </Drawer>
    )
  );
};
