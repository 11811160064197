export const hideInSettings = [
  'id',
  'middle_name',
  'address_id',
  'address.id',
  // 'address.country',
  // 'address.address1',
  // 'address.city',
  'address.region',
  // 'address.zipcode',
  'industry.id',
  'industry_id',
  'industry.name',
  'industry.is_active',
  'industry.description',
  'address.lat',
  'address.lon',
  'phone_verified',
  'user_type',
  'email_verified',
  'type',
  'updated_at',
  'status',
  'timezone',

  'order_count.expired',
  'order_count.draft',
  'order_count.completed',
  'order_count.pending',
  'order_count.published',
  'order_count.refunded',
  'order_count.rejected',
  'order_count.submitted',
  'order_count.accepted',
  'order_count.cancelled',
  'order_count.refund_failed',
  'order_count.refund_pending',
  'order_count.charge_pending',
  'order_count.charge_failed',
  'order_count.auth_failed',
  'order_count.auth_pending',
  'order_count.approved',

  'job_count.rejected',
  'job_count.accepted',
  'job_count.submitted',
  'job_count.pending',
  'job_count.returned',
  // 'job_count.completed',
  'job_count.declined',
  'job_count.cancelled',
  'job_count.expired',

  'preferences.af26a292-cf2e-4d22-b923-e409496dfdeb',
  'preferences.a0815379-37cf-4a3e-88e6-c0dcba9dbf5b',
  'preferences.a3d596a6-25ed-450a-bf57-b2e6cfd35ed6',
  'preferences.90b184a9-e4d9-4166-a117-a69b25cb9b4b', //Notify
  'preferences.8f8323cf-4cf9-4136-a8cb-5f9d27988ac1',
  'preferences.8e8c9727-70c9-4c8a-8329-4fe17b004215',
  'preferences.8c2261cd-7478-48c7-876d-a02bc258fd76',
  'preferences.83829ec8-6051-4f12-a611-bc8e2173399b',
  'preferences.8c0bce5e-c748-4f3a-a070-bdb1534381c8',
  'preferences.8b7a8a43-e844-45e0-8d6d-61e59f1f6a0f',
  'preferences.74fb954f-1b6e-44ee-9baf-db3be3c7c642',
  'preferences.8a8fa266-cc56-45b7-b953-67768813695a',
  'preferences.896e8667-fe17-4197-9292-f66159e7db17',
  'preferences.865d5030-c981-400f-b678-b9a8ccbd4270',
  'preferences.85ebfc17-e6de-42b8-b8d4-d1baf4aced79',
  'preferences.85739016-c464-4c00-b767-eea5f8eecf12',
  'preferences.8553c946-d9b6-44b6-8025-d784f242eb38',
  'preferences.83008119-31cf-4f72-9d1c-2e9b7a9508f9',
  'preferences.81450748-38a0-4ea0-ac53-046e61daa099',
  'preferences.54742f32-5e01-4aed-ada8-2e323c3c9c87',
  'preferences.7d47d758-81f7-41cc-8d89-fe153e7fd548',
  'preferences.7bbba837-9145-410b-a1bc-abe9d3dd419e',
  'preferences.7f20bdc9-003a-452c-8ef8-cfcb1c0e9a3f',
  'preferences.7d91eb40-88ab-42f0-8ecc-7c680a9f734a',
  'preferences.809879ae-0414-4893-a3f6-bbe69492d6a6',
  'preferences.7fce27aa-0619-41e1-a4af-5553cf6d8b62',
  'preferences.7b931b3a-acd1-4c39-b368-0f107038dc13',
  'preferences.7a5f77b8-a8bc-4a70-b094-1b0287e89235',
  'preferences.7877c89d-4f9f-420e-ba8c-eff1e907d318',
  'preferences.762549f6-bed5-46c6-8165-72c0f0debfa1',
  // 'preferences.a1e677d3-252e-48ef-a794-c57b521d54aa',
  // 'preferences.a162356e-6436-4b5c-96f1-29bb0ca2604b',
  'preferences.a4917f4f-a949-4038-b9cb-e946ebe660b8',
  'preferences.a4d03c9b-550d-404f-ae59-02fe0872cec9',
  // 'preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2',
  // 'preferences.a9176838-2960-4629-b45a-ea6434b79eaa',
  // 'preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c',
  // 'preferences.a13e8a1f-d133-431b-8304-eb402ea91c40',
  'preferences.a11e1d21-50d7-4058-a31e-42135f09c218',
  // 'preferences.a398164f-fa18-4606-b77e-787ad95e7d9b',
];
