import { tablesNames } from 'api/tablesNames';
import { FC } from 'react';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { queryNames } from 'api/queryNames';
import { PurchaseItemsTable } from 'components/ui_components/PurchaseItemsTable';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';

export interface PurchaseItemsT {
  id: string;
  currencyCode?: string;
}

export const PurchaseItems: FC<PurchaseItemsT> = ({ id, currencyCode }) => {
  const meta = { additionalSearchParams: `?purchase_ids=${id}` };

  const actionsColumn = {
    key: 'orderCodeInItem',
    title: 'Order Code',
    width: 100,
    hideInSetting: true,
    fixed: 'left',
    render: (record: any) => {
      const orderId: string = record?.['fulfilment.order_id'];
      const orderCode: string = record?.['fulfilment.order.code'];
      return renderLink(orderCode, `/orders/all/${orderId}`);
    },
  };

  return (
    <PurchaseItemsTable
      tableIndicatorClassName={'purchaseItemsInPurchaseTab'}
      queryKey={[queryNames.PurchaseItems, id]}
      tableName={tablesNames.PurchaseItemsCol}
      toolbar={null}
      actionsColumn={actionsColumn}
      meta={meta}
      headerName={tablesNames.PurchaseItemsHeader}
      defaultEnabledSettings={defaultEnabledSettings}
      hideInSetting={hideInSetting}
      currencyCode={currencyCode}
      resetAllFiltering={true}
    />
  );
};
