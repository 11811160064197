import { tablesNames } from 'api/tablesNames';
import { OrdersTable } from 'components/ui_components/OrdersTable';
import { OrdersTableT } from 'components/ui_components/OrdersTable/types';
import { Dispatch, FC } from 'react';
import { additionalSearchParamsToQueryKeys } from 'shared/utils/api/additionalSearchParamsToQueryKeys';
import { Address } from 'types/global';
import { getCenterAndRadiusAdditionalSearchParams } from '../../utils/getCenterAndRadiusAdditionalSearchParams';
import { defaultEnabledSettings } from './defaultEnabledSettings';
import { hideInSettings } from './hideInSettings';
import styles from './styles.module.scss';
import { defaultSelectedJobFilters } from '../../utils/constants';
import { queryNames } from 'api/queryNames';

type GlobalMapOrdersTableProps = Partial<OrdersTableT> & {
  radius?: number;
  center?: Address;
  setFilters: Dispatch<any>;
  setIsDeleted: Dispatch<string | null>;
  dataRequestEnabled: boolean;
  show: boolean;
  resetAllFiltering: boolean;
  selectedTables: string[];
};

export const GlobalMapOrdersTable: FC<GlobalMapOrdersTableProps> = ({
  radius,
  center,
  setFilters,
  setIsDeleted,
  dataRequestEnabled,
  show,
  resetAllFiltering,
  selectedTables,
}) => {
  const additionalSearchParams = getCenterAndRadiusAdditionalSearchParams({
    center,
    radius,
  });
  const parsedSearchParams = additionalSearchParamsToQueryKeys(
    additionalSearchParams
  );

  const tableContainerClassName = !show ? styles.hidden : '';

  return (
    <div className={tableContainerClassName}>
      {show && (
        <OrdersTable
          getFilters={setFilters}
          getIsDeleted={setIsDeleted}
          defaultEnabledSettings={defaultEnabledSettings}
          queryKey={[queryNames.ORDERS, ...parsedSearchParams]}
          meta={null}
          headerName={tablesNames.OrdersHeader}
          tableName={tablesNames.GlobalMapOrdersCol}
          hideInSetting={hideInSettings}
          additionalSearchParams={`${additionalSearchParams}`}
          defaultTableFilters={defaultSelectedJobFilters.tableFilters}
          defaultFilters={defaultSelectedJobFilters.filters}
          dataRequestEnabled={dataRequestEnabled}
          resetAllFiltering={resetAllFiltering}
          hideSortingTags={selectedTables.length === 2}
        />
      )}
    </div>
  );
};
