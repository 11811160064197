import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  users: {},
  isLoadingUsers: null,
  user: {},
  isLoadingUser: null,
  userPrefs: {},
  isLoadingUserPrefs: null,
  userAddress: {},
  userRequests: {},
  isLoadingUserAddress: null,
  isPostingNewAddress: null,
  isUpdatingAddress: null,
  isUpdatingUserPrefs: null,
  isLoadingUserRequests: null,
  userFromAccountId: {},
  isLoadingUserFromAccountId: null,
  savingData: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_USERS_PENDING:
      return { ...state, isLoadingUsers: true };
    case ACTION_TYPES.GET_USERS_FULFILLED:
      return { ...state, users: payload, isLoadingUsers: false };
    case ACTION_TYPES.GET_USERS_REJECTED:
      return { ...state, users: payload, isLoadingUsers: false };

    case ACTION_TYPES.GET_USERS_BY_ID_PENDING:
      return { ...state, isLoadingUser: true };
    case ACTION_TYPES.GET_USERS_BY_ID_FULFILLED:
      return { ...state, user: payload, isLoadingUser: false };
    case ACTION_TYPES.GET_USERS_BY_ID_REJECTED:
      return { ...state, user: payload, isLoadingUser: false };

    case ACTION_TYPES.GET_USERS_PREFS_PENDING:
      return { ...state, isLoadingUserPrefs: true };
    case ACTION_TYPES.GET_USERS_PREFS_FULFILLED:
      return { ...state, userPrefs: payload, isLoadingUserPrefs: false };
    case ACTION_TYPES.GET_USERS_PREFS_REJECTED:
      return { ...state, userPrefs: payload, isLoadingUserPrefs: false };

    case ACTION_TYPES.GET_USER_ADDRESS_BY_USER_ID_PENDING:
      return { ...state, isLoadingUserAddress: true };
    case ACTION_TYPES.GET_USER_ADDRESS_BY_USER_ID_FULFILLED:
      return { ...state, userAddress: payload, isLoadingUserAddress: false };
    case ACTION_TYPES.GET_USER_ADDRESS_BY_USER_ID_REJECTED:
      return { ...state, userAddress: payload, isLoadingUserAddress: false };

    case ACTION_TYPES.GET_USERS_REQUESTS_PENDING:
      return { ...state, isLoadingUserRequests: true };
    case ACTION_TYPES.GET_USERS_REQUESTS_FULFILLED:
      return { ...state, userRequests: payload, isLoadingUserRequests: false };
    case ACTION_TYPES.GET_USERS_REQUESTS_REJECTED:
      return { ...state, userRequests: payload, isLoadingUserRequests: false };

    case ACTION_TYPES.GET_USERS_BY_ACCOUNT_ID_PENDING:
      return { ...state, isLoadingUserFromAccountId: true };
    case ACTION_TYPES.GET_USERS_BY_ACCOUNT_ID_FULFILLED:
      return {
        ...state,
        userFromAccountId: payload,
        isLoadingUserFromAccountId: false,
      };
    case ACTION_TYPES.GET_USERS_BY_ACCOUNT_ID_REJECTED:
      return {
        ...state,
        userFromAccountId: payload,
        isLoadingUserFromAccountId: false,
      };

    case ACTION_TYPES.POST_NEW_USER_ADDRESS_PENDING:
      return { ...state, isPostingNewAddress: true };
    case ACTION_TYPES.POST_NEW_USER_ADDRESS_FULFILLED:
      return { ...state, isPostingNewAddress: false };
    case ACTION_TYPES.POST_NEW_USER_ADDRESS_REJECTED:
      return { ...state, isPostingNewAddress: false };

    case ACTION_TYPES.PUT_USER_ADDRESS_PENDING:
      return { ...state, isUpdatingAddress: true };
    case ACTION_TYPES.PUT_USER_ADDRESS_FULFILLED:
      return { ...state, isUpdatingAddress: false };
    case ACTION_TYPES.PUT_USER_ADDRESS_REJECTED:
      return { ...state, isUpdatingAddress: false };

    case ACTION_TYPES.POST_USER_PREFS_PENDING:
      return { ...state, isUpdatingUserPrefs: true };
    case ACTION_TYPES.POST_USER_PREFS_FULFILLED:
      return { ...state, isUpdatingUserPrefs: false };
    case ACTION_TYPES.POST_USER_PREFS_REJECTED:
      return { ...state, isUpdatingUserPrefs: false };

    case ACTION_TYPES.REMOVE_USERS_BY_ID:
      return { ...state, user: {}, isLoadingUser: null };

    case ACTION_TYPES.UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA:
      return { ...state, savingData: {} };

    default:
      return state;
  }
};
