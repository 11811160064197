import { calculateDistanceBetweenTwoPointsOnMap } from 'shared/utils/calculateDistanceBetweenTwoPointsOnMap';
import { convertMetersToMiles } from 'shared/utils/convertMetersToMiles';
import { Address } from 'types/global';

export const calculateRadiusFromBounds = (
  bounds?: google.maps.LatLngBoundsLiteral
): number | undefined => {
  if (!bounds) {
    return undefined;
  }

  const { north, south, east, west } = bounds;
  const lngCenter = (north + south) / 2;
  const latCenter = (west + east) / 2;

  const center: Address = {
    lat: latCenter,
    lng: lngCenter,
  };

  const lngRadiusInMeters = calculateDistanceBetweenTwoPointsOnMap({
    lat1: center.lat,
    lng1: center.lng,
    lat2: center.lat,
    lng2: north,
  });
  const latRadiusInMeters = calculateDistanceBetweenTwoPointsOnMap({
    lat1: center.lat,
    lng1: center.lng,
    lat2: west,
    lng2: center.lng,
  });

  const lngRadiusInMiles = convertMetersToMiles(lngRadiusInMeters);
  const latRadiusInMiles = convertMetersToMiles(latRadiusInMeters);
  const maxRadius = Math.max(lngRadiusInMiles, latRadiusInMiles);

  return maxRadius;
};
