import { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

const useConfirmExit = (confirmExit: () => boolean, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    //eslint-disable-next-line
    const { push } = navigator;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    //eslint-disable-next-line
    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (
  message: string,
  when = true,
  customFunction?: () => void
) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
      if (customFunction) {
        customFunction();
      }
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when, customFunction]);

  const confirmExit = useCallback(() => {
    //eslint-disable-next-line
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  useConfirmExit(confirmExit, when);
};
