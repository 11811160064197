import { Tooltip } from 'antd';
import clsx from 'clsx';
import { UserType } from 'components/pages/main/users_section/Users/types';
import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Table from '../Table';
import styles from './styles.module.scss';
import { APP_CONSTANS } from 'shared/constants';
import { UserAccountsTableT } from './types';
import { columns } from './columns';

export const UserAccountsTable: FunctionComponent<UserAccountsTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  tableClassName,
  hideInSetting,
  type = UserType.ALL,
  getFilters,
  ...rest
}) => {
  const renderTooltip = (value: string) => {
    return (
      <Tooltip overlayClassName={styles.tooltipEmail} title={value}>
        <div className={styles.tooltipBlock}>
          <span className={styles.email}>{value}</span>
        </div>
      </Tooltip>
    );
  };

  const renderCapitalize = (value: string) => {
    return <p className={styles.capitalizeText}>{value}</p>;
  };

  const renderLink = (value: any, path: string) => {
    return (
      <Link
        to={path}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          event.stopPropagation()
        }
      >
        {value}
      </Link>
    );
  };

  const renderTrait = (value: string) => {
    return (
      <p className={styles.capitalizeText}>
        {APP_CONSTANS.TRAIT_LABEL_BY_VALUE[value]}
      </p>
    );
  };

  const renderData = (value: string, record: any) => {
    const accountId = record?.['account.id'];
    const userId = record?.['user.id'];
    const followInstructionsTrait =
      record?.['user.preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2'];
    const reliableTrait =
      record?.['user.preferences.a9176838-2960-4629-b45a-ea6434b79eaa'];
    const workQualityTrait =
      record?.['user.preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c'];

    return [
      {
        name: 'user.email',
        action: renderLink(value, `/users/${type}/${userId}`),
      },
      {
        name: 'phone',
        action: renderTooltip(value),
      },
      {
        name: 'user.type',
        action: renderCapitalize(value),
      },
      {
        name: 'role',
        action: renderCapitalize(value),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/${type}/${accountId}`),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/${type}/${accountId}`),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/${type}/${accountId}`),
      },
      {
        name: 'user.preferences.a0815379-37cf-4a3e-88e6-c0dcba9dbf5b',
        action: renderCapitalize(value),
      },
      {
        name: 'user.preferences.a4d03c9b-550d-404f-ae59-02fe0872cec9',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'user.preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2',
        action: renderTrait(followInstructionsTrait),
      },
      {
        name: 'user.preferences.a9176838-2960-4629-b45a-ea6434b79eaa',
        action: renderTrait(reliableTrait),
      },
      {
        name: 'user.preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c',
        action: renderTrait(workQualityTrait),
      },
    ];
  };

  return (
    <Table
      toolbar={toolbar}
      rowSelection={null}
      rowSelectionType={null}
      customClassName={clsx('tabsAndSettings')}
      tableIndicatorClassName={clsx(tableClassName)}
      queryKey={queryKey}
      meta={meta}
      headerName={headerName}
      renderDataColumn={renderData}
      actionsColumn={actionsColumn}
      defaultEnabledSettings={defaultEnabledSettings}
      parametrForSavingSettings={tableName}
      hideInSetting={hideInSetting}
      getFilters={getFilters}
      columnsForRequest={columns}
      defaultSorter={{
        field: 'usr_acc.created_at',
        order: 'descend',
      }}
      {...rest}
    />
  );
};
