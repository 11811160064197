import { Form, InputNumber, Select, Tabs, TabsProps, Tooltip } from 'antd';
import clsx from 'clsx';
import { useCallback, useEffect, useState } from 'react';
import { APP_CONSTANS } from 'shared/constants';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { Loader } from '../Loader';
import { accountPrefsIdToDrawer, userPrefsIdToDrawer } from './constants';
import styles from './styles.module.scss';
import { SalesPersonT } from './types';
import { preferenceGenerator } from './preferenceGenerator';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { tablesNames } from 'api/tablesNames';
const { Option } = Select;

export const UserPrefs = ({
  prefs,
  createdAt,
  updatedAt,
  deletedAt,
  lastLogin,
  taxRate,
  numberOfCompletedJobs,
  type,
  userId,
  pageName,
  industrys,
  industryId,
  subscriptionId,
  subscriptions,
  dataAllSales,
  salesPerson,
  invoicedSum,
  defaultAccId,
  defaultAccName,
  userAccounts,
  accountId,
  defaultPurchaseMethodId,
  defaultPurchaseMode,
}: any) => {
  const [formattedPrefs, setFormattedPrefs] = useState<any[]>([]);
  const [itemsInDrawer, setItemsInDrawer] = useState<string[]>([]);
  const [shouldBeInvoiced, setShouldBeInvoiced] = useState<string[]>([]);
  const token = useSelector((state: any) => state.auth.token);

  useEffect(() => {
    pageName === 'user'
      ? setItemsInDrawer(userPrefsIdToDrawer)
      : setItemsInDrawer(accountPrefsIdToDrawer);
  }, [pageName]);

  useEffect(() => {
    if (prefs && Array.isArray(prefs) && prefs.length && type) {
      const filteredWithoutNotify = prefs.filter(
        (pref: any) => pref.preference.pref_group !== 'notify'
      );
      const filteredByCommon = filteredWithoutNotify.filter((pref: any) => {
        return (
          pref.preference.pref_usage?.toLowerCase() === 'common' &&
          pref.preference_id !== 'af26a292-cf2e-4d22-b923-e409496dfdeb'
        );
      });
      const filteredByTypePrefs = filteredWithoutNotify.filter(
        (pref: any) =>
          pref.preference.pref_usage?.toLowerCase() === type.toLowerCase()
      );
      if (pageName === 'user') {
        if (
          type === APP_CONSTANS.USER_TYPES.CUSTOMER ||
          type === APP_CONSTANS.USER_TYPES.PHOTOGRAPHER
        ) {
          setFormattedPrefs([...filteredByCommon, ...filteredByTypePrefs]);
        } else {
          setFormattedPrefs(filteredWithoutNotify);
        }
      }
      if (pageName === 'account') {
        if (type === APP_CONSTANS.ACCOUNT_TYPES.INTERNAL) {
          setFormattedPrefs(filteredWithoutNotify);
        } else {
          setFormattedPrefs([...filteredByCommon, ...filteredByTypePrefs]);
        }
      }
    }
  }, [prefs, type, pageName]);

  const salesPersonFormItem = useCallback(() => {
    const filteredSales =
      dataAllSales &&
      Array.isArray(dataAllSales) &&
      dataAllSales.length &&
      dataAllSales.filter((e: SalesPersonT) => e?.status === 'active');
    const salesOptions =
      filteredSales && Array.isArray(filteredSales) && filteredSales.length
        ? filteredSales.map((e: SalesPersonT) => ({
            value: e.id,
            label: `${e.first_name} ${e.last_name}`,
          }))
        : undefined;
    return (
      <Form.Item
        name={'sales_person_id'}
        className={styles.prefs__item}
        initialValue={salesPerson ? salesPerson : null}
      >
        <Select
          popupClassName={styles.prefs__dropdown}
          className={styles.prefs__select}
          showSearch
          placeholder="Select a person"
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '')
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={salesOptions}
        ></Select>
      </Form.Item>
    );
  }, [dataAllSales, salesPerson]);

  useEffect(() => {
    if (invoicedSum && invoicedSum.hasOwnProperty('data')) {
      const invoicedOn = invoicedSum.data.should_be_invoiced_on;
      if (Object.keys(invoicedOn).length > 0) {
        const formattedInvoicedOnArr = [];
        for (let currencyCode in invoicedOn) {
          if (currencyCode) {
            const value = invoicedOn[currencyCode];
            const formattedSum = renderMoneyWithCurrency(value, currencyCode);
            formattedInvoicedOnArr.push(formattedSum);
          }
        }
        setShouldBeInvoiced(formattedInvoicedOnArr);
      } else {
        setShouldBeInvoiced(['0.00']);
      }
    }
  }, [invoicedSum]);

  const { data: purchaseMethods } = useQuery({
    queryKey: [queryNames.PurchaseMethods],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
      additionalSearchParams: `${
        accountId ? `?account_ids=${accountId}` : `?user_ids=${userId}`
      }`,
    },
    queryFn: useGetData,
    enabled: !!accountId || !!userId,
  });

  const { data: accountHeader } = useQuery({
    queryKey: [tablesNames.AccountsHeader],
    meta: {
      token,
      getAllData: true,
    },
    queryFn: useGetData,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const createListOptions = (data: any) => {
    return data.map((elem: any) => {
      return {
        value: elem.id,
        label: elem.name,
      };
    });
  };

  const createListOptionsFromHeader = (
    data: { value: string | number; display: string }[]
  ) => {
    return data?.map((elem: { value: string | number; display: string }) => {
      return {
        value: elem.value,
        label: elem.display,
      };
    });
  };

  const userPreferenceTabs: TabsProps['items'] = [
    {
      className: styles.tabContent,
      key: 'general',
      label: `General Info`,
      children: (
        <div>
          {pageName === 'user' && type === 'customer' && (
            <div className={styles.prefs__block}>
              <p className={styles.label}>ID:</p>
              <p className={styles.textValue}>{userId}</p>
            </div>
          )}

          {/* {extraPrefs && extraPrefs.length > 0 &&  (
            <>
              <p className={styles.prefs__title}>Account Payment Info</p>
              {extraPrefs.map((pref: any) =>
                preferenceGenerator(pref, { type, numberOfCompletedJobs })
              )}
            </>
          )} */}

          {type === APP_CONSTANS.ACCOUNT_TYPES.PHOTOGRAPHER && (
            <>
              {purchaseMethods?.data && (
                <Form.Item
                  name="purchase_method_id"
                  label="Default purchase method"
                >
                  <Select
                    defaultValue={defaultPurchaseMethodId}
                    options={createListOptions(purchaseMethods?.data)}
                  />
                </Form.Item>
              )}
              {accountHeader && accountHeader.hasOwnProperty('data') && (
                <Form.Item name="purchase_mode" label="Default purchase mode">
                  <Select
                    defaultValue={defaultPurchaseMode}
                    options={createListOptionsFromHeader(
                      accountHeader?.data?.columns?.find(
                        (header: any) => header.field === 'purchase_mode'
                      )?.filter_items
                    )}
                  />
                </Form.Item>
              )}
            </>
          )}

          {formattedPrefs && formattedPrefs.length > 0 ? (
            <>
              {type !== APP_CONSTANS.ACCOUNT_TYPES.PHOTOGRAPHER && (
                <>
                  {formattedPrefs.map(
                    (pref: any) =>
                      !itemsInDrawer.includes(pref.preference_id) &&
                      pref.preference_id !==
                        'a3cc8111-d107-49a8-a5e8-4bce9b1cf341' &&
                      pref.preference_id !==
                        'a4917f4f-a949-4038-b9cb-e946ebe660b8' && //Default account id in prefs
                      preferenceGenerator(pref, { type, numberOfCompletedJobs })
                  )}
                </>
              )}

              {formattedPrefs.find(
                (pref: any) =>
                  pref.preference_id === 'a4917f4f-a949-4038-b9cb-e946ebe660b8'
              ) && (
                <Form.Item
                  name={['account', 'id']}
                  className={styles.prefs__item}
                  initialValue={defaultAccId}
                  label="Your Default Account ID"
                >
                  <Select
                    status={`${!defaultAccId ? 'error' : ''}`}
                    placeholder={`${
                      !defaultAccId
                        ? 'Default account is not have this user.'
                        : ''
                    }`}
                    value={defaultAccName}
                    popupClassName={styles.prefs__dropdown}
                    className={styles.prefs__select}
                  >
                    {userAccounts &&
                      userAccounts.map((acc: any) => (
                        <Option key={acc.id} value={acc.id}>
                          {acc.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}

              {pageName === 'account' &&
                type ===
                  (APP_CONSTANS.ACCOUNT_TYPES.CUSTOMER ||
                    APP_CONSTANS.ACCOUNT_TYPES.INTERNAL) && (
                  <>
                    <div className={styles.prefs__block}>
                      <p className={styles.label}>Tax rate, %</p>
                      <Form.Item
                        name={'tax_rate'}
                        className={styles.prefs__item}
                        initialValue={taxRate ? taxRate : 0}
                      >
                        <InputNumber
                          value={taxRate ? taxRate : 0}
                          min={0}
                          max={10000}
                          formatter={(value: any): string =>
                            (value / 100).toString()
                          }
                          parser={(value: any) =>
                            Math.round(Number(value) * 100)
                          }
                          className={styles.prefs__input}
                          precision={2}
                          step={100}
                        />
                      </Form.Item>
                    </div>

                    <div className={styles.prefs__block}>
                      <p className={styles.label}>Subscription Plan</p>
                      <Form.Item
                        name={'subscription_id'}
                        className={styles.prefs__item}
                        initialValue={subscriptionId}
                      >
                        <Select
                          popupClassName={styles.prefs__dropdown}
                          className={styles.prefs__select}
                        >
                          {subscriptions &&
                            Array.isArray(subscriptions) &&
                            subscriptions.map((e) =>
                              e.is_active ? (
                                <Option key={e.id} value={e.id}>
                                  {e.name}
                                  {e?.discount ? ` - ${e.discount / 100}%` : ''}
                                </Option>
                              ) : null
                            )}
                        </Select>
                      </Form.Item>
                    </div>
                  </>
                )}

              {pageName === 'account' && (
                <>
                  {type !== APP_CONSTANS.ACCOUNT_TYPES.PHOTOGRAPHER && (
                    <div className={styles.prefs__block}>
                      <p className={styles.label}>Sales person:</p>
                      {salesPersonFormItem()}
                    </div>
                  )}
                </>
              )}

              {((pageName === 'user' && type === 'customer') ||
                pageName === 'account') && (
                <div className={clsx(styles.prefs__block, styles.prefs__other)}>
                  <p>
                    <span>Created at:</span>
                    <span>{convertUNIXToLocale(createdAt)}</span>
                  </p>

                  {updatedAt && (
                    <p>
                      <span>Updated at:</span>
                      <span>{convertUNIXToLocale(updatedAt)}</span>
                    </p>
                  )}

                  {deletedAt && (
                    <p>
                      <span>Deleted at:</span>
                      <span>{convertUNIXToLocale(deletedAt)}</span>
                    </p>
                  )}

                  {lastLogin && (
                    <p>
                      <span>Last login:</span>
                      <span>{convertUNIXToLocale(lastLogin)}</span>
                    </p>
                  )}
                </div>
              )}

              {pageName === 'user' && type !== 'customer' && (
                <div className={clsx(styles.prefs__block, styles.prefs__other)}>
                  <p>
                    <span>Created at:</span>
                    <span>{convertUNIXToLocale(createdAt)}</span>
                  </p>

                  {lastLogin && (
                    <p>
                      <span>Last login:</span>
                      <span>{convertUNIXToLocale(lastLogin)}</span>
                    </p>
                  )}
                </div>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      ),
    },

    ...((pageName === 'user' && type !== 'customer') ||
    (pageName === 'account' && type !== APP_CONSTANS.ACCOUNT_TYPES.PHOTOGRAPHER)
      ? [
          {
            className: styles.tabContent,
            key: 'additional',
            label: `Additional Info`,
            children: (
              <div>
                {pageName === 'account' && (
                  <div className={styles.prefs__block}>
                    <p className={styles.label}>Industry</p>
                    <Form.Item
                      name={'industry_id'}
                      className={styles.prefs__item}
                      initialValue={industryId}
                    >
                      <Select
                        popupClassName={styles.prefs__dropdown}
                        className={styles.prefs__select}
                      >
                        {industrys &&
                          Array.isArray(industrys) &&
                          industrys.map((e) =>
                            e.is_active ? (
                              <Option key={e.id} value={e.id}>
                                {e.name}
                              </Option>
                            ) : null
                          )}
                      </Select>
                    </Form.Item>
                  </div>
                )}

                {pageName === 'user' && (
                  <div
                    className={clsx(styles.prefs__block, styles.prefs__other)}
                  >
                    {type.toLowerCase() === 'photographer' && (
                      <>
                        {formattedPrefs.map(
                          (pref: any) =>
                            itemsInDrawer.includes(pref.preference_id) &&
                            preferenceGenerator(pref, {
                              type,
                              numberOfCompletedJobs,
                            })
                        )}
                      </>
                    )}

                    <div style={{ display: 'flex' }}>
                      <span
                        className={styles.label}
                        style={{ lineHeight: '18px' }}
                      >
                        ID:
                      </span>
                      <Tooltip title={userId}>
                        <span className={clsx(styles.textValue, styles.userId)}>
                          {userId}
                        </span>
                      </Tooltip>
                    </div>

                    {updatedAt && (
                      <div className={styles.updatedAtContainer}>
                        <span
                          className={styles.label}
                          style={{ lineHeight: '18px' }}
                        >
                          Updated at:
                        </span>
                        <span className={styles.textValue}>
                          {convertUNIXToLocale(updatedAt)}
                        </span>
                      </div>
                    )}

                    {deletedAt && (
                      <p>
                        <span>Deleted at:</span>
                        <span>{convertUNIXToLocale(deletedAt)}</span>
                      </p>
                    )}
                  </div>
                )}

                {pageName === 'account' &&
                  type === 'Customer' &&
                  invoicedSum &&
                  invoicedSum.hasOwnProperty('data') && (
                    <div className={styles.prefs__other}>
                      <p className={styles.label}>
                        Completed not Invoiced Orders
                      </p>
                      <p className={styles.textValue}>
                        {invoicedSum.data.orders_count_without_invoice}
                      </p>
                      <p className={styles.label}>Sum to Invoice</p>
                      <p className={styles.textValue}>
                        {shouldBeInvoiced.join(' , ')}
                      </p>
                    </div>
                  )}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <section className={styles.prefs}>
      <Tabs className={styles.tabs} items={userPreferenceTabs} />
    </section>
  );
};
