import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import styles from './renderLink.module.scss';

export const renderActionLink = (value: any, action: any) => {
  return (
    <span
      className={styles.link}
      onClick={(event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (typeof action === 'function') {
          action();
        }
      }}
    >
      {value}
    </span>
  );
};

export const renderLink = (
  value: any,
  path: string,
  pathname?: any,
  inNewTab?: boolean
) => {
  return path ? (
    <Link
      to={path}
      state={{ previousPath: pathname }}
      onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
      {...(inNewTab && { target: '_blank', rel: 'noopener noreferrer' })}
    >
      {value}
    </Link>
  ) : (
    value
  );
};

export const renderTitleLink = (
  value: any,
  path: string,
  inNewTab?: boolean
) => {
  return (
    <b style={{ textTransform: 'capitalize' }}>
      <Link
        to={path}
        onClick={(event: MouseEvent<HTMLElement>) => event.stopPropagation()}
        {...(inNewTab && { target: '_blank', rel: 'noopener noreferrer' })}
      >
        {value && typeof value === 'string' && value.includes('_')
          ? value.replace('_', ' ')
          : value}
      </Link>
    </b>
  );
};
