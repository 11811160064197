import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Collapse, CollapseProps, message } from 'antd';
import { Input } from 'antd';
import {
  MailOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { convertUnixToLocalDate } from 'shared/utils/convertUnixTime';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { Link } from 'react-router-dom';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { APP_CONSTANS } from 'shared/constants';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { useSelector } from 'react-redux';
import { wrapLinks } from 'shared/utils/wrapLinks';

const { TextArea } = Input;

export const CommentsBlock: React.FC<any> = ({
  haveInfoUser = false,
  fullName = null,
  email = null,
  phone = null,
  comments,
  refetchComments,
  objectType,
  objectId,
  isInternal = false,
  userId,
  commentStartText = '',
  fixedHeight = false,
  hideSendingComments = false,
  relatives = [],
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [commentTextarea, setCommentTextarea] =
    useState<string>(commentStartText);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const internalUser = 'superuser';
  const commentIdsToBack = useRef<any>([]);

  const refetchDataComments = (refetchCommentsList: any) => {
    if (refetchCommentsList && Array.isArray(refetchCommentsList)) {
      refetchCommentsList.forEach((refetchComment: any) => refetchComment());
      return;
    }
    refetchComments();
    return;
  };

  const mutationNewComment = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
      setCommentTextarea(commentStartText);
      refetchDataComments(refetchComments);
    },
  });

  const mutationReadedComment = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error. Reading status for new messages not set');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
      refetchDataComments(refetchComments);
    },
  });

  useEffect(() => {
    if (comments && comments.length > 0) {
      const unreadedCommentsIds = comments
        .filter((comment: any) => !comment.hasOwnProperty('exposed_at'))
        .map((comment: any) => comment.id);
      messagesEndRef?.current?.scrollTo({
        top: 1000000,
        left: 0,
        behavior: 'smooth',
      });
      const withoutDuplicateCom = unreadedCommentsIds.filter(
        (comId: string) => !commentIdsToBack.current.includes(comId)
      );
      commentIdsToBack.current.push(...withoutDuplicateCom);
      if (withoutDuplicateCom.length > 0) {
        setTimeout(() => {
          const dataToBack = {
            ids: withoutDuplicateCom,
          };
          mutationReadedComment.mutate({
            data: dataToBack,
            token,
            otherProps: `office/comment/read`,
            method: 'POST',
          });
        }, 4000);
      }
    }
  }, [comments, mutationReadedComment, commentIdsToBack, token]);

  const isSuperuserOrSupport = useUserPermissionGranted({
    enabledUserTypes: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  });

  const headerText = isSuperuserOrSupport ? (
    <Link to={`/users/all/${userId}`}>
      <UserOutlined className={styles.comment__icon} />
      {fullName}
    </Link>
  ) : (
    <span>
      <UserOutlined className={styles.comment__icon} />
      {fullName}
    </span>
  );

  const difBetweenDate = (prevDate: any, currentDate: any) => {
    if (prevDate && currentDate) {
      const prevConvertedDate = convertUnixToLocalDate(prevDate.created_at);
      const nextConvertedDate = convertUnixToLocalDate(currentDate);
      if (prevConvertedDate !== nextConvertedDate) {
        return nextConvertedDate;
      } else {
        return false;
      }
    }
    return undefined;
  };

  const setComment = (event: any) => {
    setCommentTextarea(event.target.value);
  };

  const sendComment = () => {
    if (mutationNewComment.isLoading) return;

    const dataToBack = {
      comment: commentTextarea,
      id: objectId,
      internal: isInternal,
      object: objectType,
      relatives: relatives,
      // silent: true, // don't send message to mail, by default - sending
    };

    mutationNewComment.mutate({
      data: dataToBack,
      token,
      otherProps: `office/comment`,
      method: 'POST',
    });
  };

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: headerText,
      className: styles.comment__panel,
      children: (
        <>
          <p>
            <Link to={`mailto:${email}`}>
              <MailOutlined className={styles.comment__icon} />
              {email}
            </Link>
          </p>
          <p>
            <Link to={`tel:${phone}`}>
              <PhoneOutlined className={styles.comment__icon} />
              {phone}
            </Link>
          </p>
        </>
      ),
    },
  ];

  return (
    <div className={clsx(styles.comment, fixedHeight && styles.fixedComments)}>
      {haveInfoUser && (
        <Collapse
          className={styles.comment__header}
          expandIconPosition="end"
          ghost
          items={items}
        ></Collapse>
      )}

      <div className={styles.comment__body} ref={messagesEndRef}>
        {comments &&
          comments.map((comment: any, index: number) => (
            <div
              key={comment.created_at}
              className={clsx(
                styles.comment__item,
                comment.user_type === internalUser
                  ? styles.internal
                  : styles.user
              )}
            >
              <div className={styles.comment__date}>
                {index === 0 && comment.created_at
                  ? convertUnixToLocalDate(comment.created_at)
                  : difBetweenDate(comments[index - 1], comment.created_at)}
              </div>
              <div className={styles.comment__content}>
                <span className={styles.comment__sender}>
                  {comment.user_name}
                </span>
                <span className={styles.comment__target}>
                  ({comment.user_type})
                </span>
                <p className={styles.comment__msg}>
                  {comment.hasOwnProperty('relatives') && (
                    <span className={styles.relativeLink}>
                      {comment.object_type.replace('_', ' ')}
                    </span>
                  )}
                  {wrapLinks(comment.message)}
                </p>
                {/* <p className={styles.comment__time}>{getDateComment(index, comment)}, {getCommentTime(comment)}</p> */}
                <p className={styles.comment__time}>
                  {convertUNIXToLocale(comment.created_at)}
                </p>
              </div>
            </div>
          ))}
      </div>

      {!hideSendingComments && (
        <div className={styles.comment__footer}>
          <TextArea
            value={commentTextarea}
            onChange={setComment}
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder="Write your comment"
            disabled={false}
          />
          <Button
            disabled={!commentTextarea}
            shape="circle"
            icon={<RightOutlined />}
            onClick={sendComment}
          ></Button>
        </div>
      )}
    </div>
  );
};
