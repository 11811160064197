import { QueryMeta } from '@tanstack/react-query';
import { defaultEnabledSettings } from 'components/pages/main/finances_section/CompletedNotPaidJobs/defaultEnableSettings';
import { useGetUsers } from './useGetUsers';
import { queryNames } from 'api/queryNames';

export const useGetPhotographers = ({
  additionalSearchParams,
  meta,
  enabled,
}: {
  additionalSearchParams?: string;
  meta?: QueryMeta;
  enabled?: boolean;
}) => {
  const defaultMeta = {
    includedDeleted: false,
    onlyDeleted: false,
    types: 'photographer',
  };

  const baseQueryKey = meta?.getAllData
    ? queryNames.USERS_ALL_DATA
    : queryNames.USERS_ALL;

  const metaValue = meta ? { ...defaultMeta, ...meta } : defaultEnabledSettings;

  return useGetUsers({
    meta: metaValue,
    baseQueryKey,
    ...(additionalSearchParams ? { additionalSearchParams } : {}),
    enabled,
  });
};
