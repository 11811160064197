import { ReactElement } from 'react';

export const wrapLinks = (str: string): ReactElement => {
  if (!str) return <></>;
  // Regular expression pattern to match URLs
  const urlPattern =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  const arr = str.split(urlPattern);
  return (
    <>
      {arr.map((item, index) => {
        if (item.match(urlPattern)) {
          return (
            <a
              key={index}
              href={item.startsWith('http') ? item : `//${item}`}
              target="_blank"
            >
              {item}
            </a>
          );
        } else {
          return <span key={index}>{item}</span>;
        }
      })}
    </>
  );
};
