export const columnsNotInvoicedByAccount = [
  'id',
  'code',
  'title',
  'total',
  'payment_type',
  'State.id',
  'Currency.code',
  'Currency.id',
];

export const columnsAllNotInvoiced = ['State.id', 'Account.id', 'Account.name'];
