import React from 'react';
import styles from './styles.module.scss';
import ReactPlayer from "react-player/file";

type ContentVideoT = {
  file: any,
  url: string,
}

export const ContentVideo: React.FC<ContentVideoT> = ({ file, url }) => {
  
  return (
    <div className={styles.video}>
      <ReactPlayer 
        key={file.id} 
        light={<img src={file.preview_url}/>} 
        url={url}
        playing={true}
        controls={true}
        width='100%'
        height='100%'
        alt='Video preview'
      />
    </div>
  );
};
