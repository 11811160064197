import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  fulfilments: {},
  isLoadingFulfilments: null,
  orders: {},
  isLoadingOrders: null,
  fulfilmentsByAccounts: {},
  isLoadingFulfilmentsByAccounts: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_FULFILMENTS_PENDING:
      return { ...state, isLoadingFulfilments: true };
    case ACTION_TYPES.GET_FULFILMENTS_FULFILLED:
      return { ...state, fulfilments: payload, isLoadingFulfilments: false };
    case ACTION_TYPES.GET_FULFILMENTS_REJECTED:
      return { ...state, fulfilments: payload, isLoadingFulfilments: false };

    case ACTION_TYPES.GET_FULFILMENTS_FOR_ACCOUNTS_PENDING:
      return { ...state, isLoadingFulfilmentsByAccounts: true };
    case ACTION_TYPES.GET_FULFILMENTS_FOR_ACCOUNTS_FULFILLED:
      return {
        ...state,
        fulfilmentsByAccounts: payload,
        isLoadingFulfilmentsByAccounts: false,
      };
    case ACTION_TYPES.GET_FULFILMENTS_FOR_ACCOUNTS_REJECTED:
      return {
        ...state,
        fulfilmentsByAccounts: payload,
        isLoadingFulfilmentsByAccounts: false,
      };

    case ACTION_TYPES.GET_ORDERS_PENDING:
      return { ...state, isLoadingOrders: true };
    case ACTION_TYPES.GET_ORDERS_FULFILLED:
      return { ...state, orders: payload, isLoadingOrders: false };
    case ACTION_TYPES.GET_ORDERS_REJECTED:
      return { ...state, orders: payload, isLoadingOrders: false };

    case ACTION_TYPES.REMOVE_ORDERS: {
      return { ...state, orders: {}, isLoadingOrders: null };
    }
    case ACTION_TYPES.REMOVE_JOBS: {
      return { ...state, fulfilments: {}, isLoadingFulfilments: null };
    }

    default:
      return state;
  }
};
