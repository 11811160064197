import React, { useRef } from 'react';
import { queryNames } from 'api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { PaymentMethodsTable } from 'components/ui_components/PaymentMethodsTable';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { usePostData } from 'api/usePostData';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';

export const TabPaymentMethods: React.FC<any> = ({ userId }) => {
  const queryClient: any = useQueryClient();
  const currentPurchase = useRef<any>();
  const token = useSelector((state: any) => state.auth.token);

  const meta = {
    additionalSearchParams: `?user_ids=${userId}`,
    paymentsApi: true,
    getAllData: true,
  };

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      } else {
        if (
          variables?.otherProps?.includes('accounts') ||
          variables?.url?.includes('accounts')
        ) {
          // refetchAccount();
        } else {
          queryClient.invalidateQueries(
            `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.PaymentMethods}`
          );
          message.success('Payment method was successfully deleted');
        }
      }
    },
  });

  const softDeleteMethod = () => {
    const dataToBack = {
      id: currentPurchase.current.id,
    };
    mutation.mutate({
      data: dataToBack,
      token,
      url: queryNames.CLEANER_SOFT_DELETE_PAYMENT_METHOD,
      method: 'POST',
    });
  };

  const restoreMethod = () => {
    const dataToBack = {
      id: currentPurchase.current.id,
    };
    mutation.mutate({
      data: dataToBack,
      token,
      url: queryNames.CLEANER_RESTORE_PAYMENT_METHOD,
      method: 'POST',
    });
  };

  const items: MenuProps['items'] = [
    {
      key: 'Soft_Delete',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            softDeleteMethod();
          }}
        >
          Soft Delete
        </Button>
      ),
    },
  ];

  const deletedItems: MenuProps['items'] = [
    {
      key: 'Restore',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            restoreMethod();
          }}
        >
          Restore
        </Button>
      ),
    },
  ];

  const actionsColumn = {
    title: 'Action',
    width: 100,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => (
      <Dropdown
        key="dropdown"
        trigger={['click']}
        menu={{
          items:
            record.hasOwnProperty('deleted_at') && record?.deleted_at > 0
              ? deletedItems
              : items,
        }}
        overlayClassName={styles.action}
      >
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            currentPurchase.current = record;
          }}
          type="text"
        >
          ...
        </Button>
      </Dropdown>
    ),
  };

  return (
    <PaymentMethodsTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.PaymentMethods}
      meta={meta}
      headerName={tablesNames.PaymentMethodsHeader}
      toolbar={null}
      toolBarRender={null}
      actionsColumn={actionsColumn}
      tableName={tablesNames.PaymentMethodsCol}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
