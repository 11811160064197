import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_CONSTANS } from 'shared/constants';
import { signOutUser } from 'shared/utils/api/signOutUser';
import { isSessionLife } from 'shared/utils/isSessionLife';
import { setSessionLifeTime } from 'shared/utils/setSessionLifeTime';
import { setToken, set_is_authorizated } from 'store';

export const useAuthorize = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const authorizeHandler = () => {
    const tokenFromStorage = localStorage.getItem(APP_CONSTANS.LS_TOKEN);
    const tokenFromUrl = queryParams?.get(APP_CONSTANS.TOKEN_URL);
    const sessionExpirationTime = localStorage.getItem(
      APP_CONSTANS.LS_SESSION_EXP
    );
    const sessionTokenExpired = Boolean(
      sessionExpirationTime && +sessionExpirationTime <= Date.now()
    );

    if (tokenFromUrl) {
      dispatch(setToken(tokenFromUrl));
      setTimeout(() => {
        queryParams.delete(APP_CONSTANS.TOKEN_URL);
        navigate(
          {
            search: queryParams.toString(),
          },
          { replace: true }
        );
      }, 300);
      if (tokenFromUrl) {
        localStorage.setItem(APP_CONSTANS.LS_TOKEN, tokenFromUrl);
      }
      setSessionLifeTime();
      isSessionLife();
      dispatch(set_is_authorizated(true));
    } else if (!tokenFromUrl && sessionTokenExpired) {
      signOutUser();
    } else if (tokenFromStorage) {
      dispatch(setToken(tokenFromStorage));
      dispatch(set_is_authorizated(true));
      isSessionLife();
    } else {
      dispatch(set_is_authorizated(false));
      signOutUser();
    }
  };

  return authorizeHandler;
};
