import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setAuthUserRole } from 'store';

// TODO: Replace response type with type from real data
type DummyGetAuthUserResponse =
  | {
      data: {
        address: any;
        industry: any;
        preferences: any;
        address_id: string;
        created_at: number;
        email: string;
        email_verified: number;
        first_name: string;
        id: string;
        industry_id: string;
        last_login: number;
        last_name: string;
        // TODO: Add enum instead of string
        status: string;
        timezone: string;
        // TODO: Add enum instead of string
        type: string;
        updated_at: number;
      };
    }
  | undefined;

export const useGetAuthUser = () => {
  const token = useSelector((state: any) => state.auth.token);
  const dispatch = useDispatch();

  return useQuery<DummyGetAuthUserResponse, Error>({
    queryKey: [queryNames.AUTH_getCurrentUser],
    meta: {
      // TODO: Pass token to base query
      token,
      getAllData: true,
    },
    // TODO: Pass useGetData to base query
    queryFn: useGetData,
    onSuccess: ({ data }: any) => {
      dispatch(setAuthUserRole(data?.type));
    },
  });
};
