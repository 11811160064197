import { DownOutlined, FilterFilled } from '@ant-design/icons';
import { Button, Checkbox, Dropdown, MenuProps, Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import clsx from 'clsx';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { APP_CONSTANS } from '../../../../../../../../../shared/constants';
import general_styles from '../../../../../../../../../shared/styles/styles.module.scss';
import { TabJobsMapJobsMap } from './components/TabJobsMapJobsMap';
import { defaultJobStatuses } from './defaultJobStatuses';
import styles from './styles.module.scss';
import { StatusesJobsObjT, StatusesJobsT, TabJobsMapT } from './types';

export const TabJobsMap: FunctionComponent<TabJobsMapT> = ({
  jobs,
  quantityByStatusesJobs,
  userId,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedStateIds, setSelectedStateIds] = useState<any>([]);
  const [showingJobsOnMap, setShowingJobsOnMap] = useState<any>([]);
  const [statusesJob, setStatusesJob] =
    useState<StatusesJobsT>(defaultJobStatuses);

  useEffect(() => {
    if (jobs) {
      setStatusesJob([
        {
          name: 'Accepted',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.ACCEPTED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.ACCEPTED_JOB
          ),
        },
        {
          name: 'Pending',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.PENDING_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.PENDING_JOB
          ),
        },
        {
          name: 'Submitted',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.SUBMITTED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.SUBMITTED_JOB
          ),
        },
        {
          name: 'Completed',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB
          ),
        },
        {
          name: 'Rejected',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.REJECTED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.REJECTED_JOB
          ),
        },
        {
          name: 'Returned',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.RETURNED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.RETURNED_JOB
          ),
        },
        {
          name: 'Declined',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.DECLINED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.DECLINED_JOB
          ),
        },
        {
          name: 'Cancelled',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.CANCELLED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.CANCELLED_JOB
          ),
        },
        {
          name: 'Expired',
          quid: APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.EXPIRED_JOB,
          jobs: quantityByStatusesJobs(
            jobs,
            APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.EXPIRED_JOB
          ),
        },
      ]);
      setShowingJobsOnMap(jobs);
    }
  }, [jobs, quantityByStatusesJobs]);

  const changeStatus = useCallback((checkedValues: CheckboxValueType[]) => {
    setSelectedStateIds(checkedValues);
  }, []);

  const applyFilters = useCallback(() => {
    setOpen(false);
    const filteredJobs: any = [];
    jobs.map((job: any) => {
      selectedStateIds.forEach((stateId: string) => {
        if (job.state_id === stateId) {
          filteredJobs.push(job);
        }
      });
    });
    setShowingJobsOnMap(filteredJobs);
  }, [selectedStateIds, jobs]);

  const visibleDropdown = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const defaultValueCheckboxGroup = useMemo(() => {
    return [
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.ACCEPTED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.SUBMITTED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.PENDING_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.REJECTED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.RETURNED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.DECLINED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.CANCELLED_JOB,
      APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.EXPIRED_JOB,
    ];
  }, []);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className={styles.filterList}>
          <b className={styles.filterTitle}>Jobs filter</b>

          <Checkbox.Group
            onChange={changeStatus}
            className={styles.filterGroup}
            defaultValue={defaultValueCheckboxGroup}
          >
            {statusesJob.map((status: StatusesJobsObjT) => (
              <div key={status.quid} className={styles.filterBlock}>
                <Checkbox value={status.quid}>{status.name}</Checkbox>
                <span className={styles.filterJobs}>{status.jobs}</span>
              </div>
            ))}
          </Checkbox.Group>

          <Button
            type="link"
            onClick={applyFilters}
            className={clsx(general_styles.link__btn, styles.filterApplyBtn)}
          >
            Apply filters
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div>
        <Dropdown
          menu={{ items }}
          trigger={['click']}
          onOpenChange={visibleDropdown}
          open={open}
        >
          <Space className={styles.header}>
            <FilterFilled className={styles.iconFilter} />
          </Space>
        </Dropdown>
      </div>

      <div className={styles.jobMapContainer}>
        <TabJobsMapJobsMap
          jobs={showingJobsOnMap}
          mapLocation="address"
          userId={userId}
        />
      </div>
    </>
  );
};
