import { FC } from 'react';
import styles from './styles.module.scss';
import { AntQuill } from './components/AntQuill';

export const OrderJobInstructions: FC<any> = ({ isJob, isFormDisabled }) => {
  return (
    <section className={styles.instructions}>
      <h3 className={styles.instructions__title}>Instructions</h3>
      <AntQuill isJob={isJob} isFormDisabled={isFormDisabled} />
    </section>
  );
};
