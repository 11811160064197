import { FunctionComponent, useState, useEffect, useCallback } from 'react';
import styles from './styles.module.scss';
import { PhotographerRate } from 'shared/constants';
import {
  Alert,
  Button,
  Drawer,
  message,
  Input,
  Form,
  Select,
  InputNumber,
  DatePicker,
} from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import globalStyles from 'shared/styles/styles.module.scss';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { convertDateToTimestamp } from 'shared/utils/convertDateToTimestamp';

const { TextArea } = Input;

export const NestedDrawer: FunctionComponent<any> = ({
  openedNestedDrawer,
  setOpenedNestedDrawer,
  reqId,
  reqType,
  reqAction,
  setRefetchData,
  refetchContent,
  level,
  maxJobCount,
  setOpen,
  jobExpiredAt,
  orderExpiredAt,
  timeExtend,
  onCloseFirstDrawer,
}) => {
  const [comment, setComment] = useState<string>('');
  const [extendHours, setExtendHours] = useState<number | null>(timeExtend);
  const token = useSelector((state: any) => state.auth.token);
  const userType = useSelector((state: any) => state.auth.authUserRole);
  const [userReqForm] = Form.useForm();
  const [jobReqForm] = Form.useForm();

  const phRates = [
    {
      value: PhotographerRate.UNRATED,
      label: PhotographerRate.UNRATED,
    },
    {
      value: PhotographerRate.ENTHUSIAST,
      label: PhotographerRate.ENTHUSIAST,
    },
    {
      value: PhotographerRate.PROFESSIONAL,
      label: PhotographerRate.PROFESSIONAL,
    },
  ];

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
      onCloseNestedDrawer();
    },
    onSuccess: (data, variables) => {
      onCloseNestedDrawer();
      setOpen(false);
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
      if (variables?.otherProps?.includes('reject')) {
        message.success('Request was successfully rejected!');
      }
      if (variables?.otherProps?.includes('approve')) {
        message.success('Request was successfully approved!');
      }
      setComment('');
      setRefetchData(true);
      refetchContent();
      onCloseFirstDrawer();
    },
  });

  useEffect(() => {
    if (userReqForm) {
      const initialValues = reqType.includes('level')
        ? {
            level: level,
            job_count: maxJobCount,
            comments: '',
          }
        : {};
      userReqForm.setFieldsValue(initialValues);
    }
  }, [reqType, userReqForm, level, maxJobCount]);

  useEffect(() => {
    if (jobReqForm && reqType.includes('Extend')) {
      const initialValues = {
        expire_date: dayjs().add(timeExtend, 'hour'),
      };
      jobReqForm.setFieldsValue(initialValues);
    }
  }, [reqType, jobReqForm, timeExtend]);

  const onCloseNestedDrawer = () => {
    setOpenedNestedDrawer(false);
    setComment('');
  };

  const onChangeComment = (event: any) => {
    setComment(event.target.value);
  };

  const rejectOrApproveReq = useCallback(() => {
    const commentatorType =
      userType && userType === 'superuser' ? 'admin' : 'user';
    if (reqAction === 'Reject' && reqType === 'ExtendTime') {
      mutation.mutate({
        data: {
          comments: comment,
          id: reqId,
        },
        token,
        otherProps: 'office/fulfilment_request_reject',
        method: 'POST',
      });
    }
    if (reqAction === 'Reject' && reqType !== 'ExtendTime') {
      mutation.mutate({
        data: {
          commentator_type: commentatorType,
          comments: comment,
          id: reqId,
        },
        token,
        otherProps: 'office/user_request_reject',
        method: 'POST',
      });
    }
    if (reqAction === 'Approve' && reqType === 'ExtendTime') {
      const values = jobReqForm.getFieldsValue();
      const expireDate = values.expire_date;
      const dataToBack = {
        ...values,
        force: true,
        id: reqId,
        expire_date: convertDateToTimestamp(expireDate),
      };
      mutation.mutate({
        data: dataToBack,
        token,
        otherProps: 'office/fulfilment_request_approve',
        method: 'POST',
      });
    }
    if (reqAction === 'Approve' && reqType !== 'ExtendTime') {
      let values;
      if (reqType.includes('level')) {
        values = userReqForm.getFieldsValue();
      } else {
        values = { comments: comment };
      }
      if (values && values.hasOwnProperty('job_count')) {
        values.job_count = values.job_count.toString();
      }
      mutation.mutate({
        data: {
          commentator_type: commentatorType,
          id: reqId,
          ...values,
        },
        token,
        otherProps: 'office/user_request_approve',
        method: 'POST',
      });
    }
  }, [
    reqAction,
    reqType,
    userType,
    userReqForm,
    jobReqForm,
    comment,
    mutation,
    reqId,
    token,
  ]);

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().endOf('day');
  };

  return (
    <Drawer
      title={null}
      width={480}
      closable={false}
      onClose={onCloseNestedDrawer}
      open={openedNestedDrawer}
      className={styles.drawer}
    >
      <div className={styles.drawer__header}>
        <div>
          <Button
            className={styles.drawer__close}
            type="text"
            onClick={onCloseNestedDrawer}
          >
            <CloseOutlined />
          </Button>
          <span className={styles.drawer__title}>{reqAction} Request</span>
        </div>
        <div>
          <Button onClick={onCloseNestedDrawer}>Cancel</Button>
          <Button
            className={clsx(
              globalStyles.btn,
              reqAction === 'Reject' &&
                comment.length === 0 &&
                globalStyles.reject__btn_disabled,
              reqAction === 'Reject'
                ? globalStyles.reject__btn
                : globalStyles.success__btn
            )}
            onClick={rejectOrApproveReq}
            htmlType="submit"
            disabled={reqAction === 'Reject' && comment.length === 0}
          >
            {reqAction}
          </Button>
        </div>
      </div>

      {(reqAction !== 'Approve' || reqType.includes('drone')) && (
        <div className={styles.drawer__innerContent}>
          {reqAction === 'Reject' && (
            <Alert
              className={styles.drawer__alert}
              message="This request will be rejected"
              icon={
                <ExclamationCircleOutlined
                  style={{ color: '#D67500', marginRight: '10px' }}
                />
              }
              banner
            />
          )}

          <div className={styles.commentBlock}>
            <TextArea
              value={comment}
              onChange={onChangeComment}
              rows={4}
              placeholder="Write your comment..."
            />
          </div>
        </div>
      )}

      {reqAction === 'Approve' && reqType.includes('level') && (
        <div className={styles.drawer__innerContent}>
          <Form form={userReqForm} layout="vertical">
            <Form.Item name="level" label="Photographer level">
              <Select
                defaultActiveFirstOption={false}
                filterOption={false}
                options={phRates}
              />
            </Form.Item>

            <Form.Item name="job_count" label="Max job count">
              <InputNumber min={0} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name="comments">
              <div className={styles.commentBlock}>
                <TextArea
                  value={comment}
                  onChange={onChangeComment}
                  rows={4}
                  placeholder="Write your comment..."
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      )}

      {reqAction === 'Approve' && reqType.includes('Extend') && (
        <div className={styles.drawer__innerContent}>
          <Form form={jobReqForm} layout="vertical">
            <p className={styles.extraData__item}>
              <span className={styles.extraData__label}>Order expired at:</span>
              <span className={styles.extraData__value}>
                {convertUNIXToLocale(orderExpiredAt)}
              </span>
            </p>

            <p className={styles.extraData__item}>
              <span className={styles.extraData__label}>
                Fulfilment expired at:
              </span>
              <span className={styles.extraData__value}>
                {convertUNIXToLocale(jobExpiredAt)}
              </span>
            </p>

            <p className={styles.extraData__item}>
              <span className={styles.extraData__label}>Extend hours:</span>
              <span className={styles.extraData__value}>{extendHours}</span>
            </p>

            <Form.Item name="expire_date" label="Time extension:">
              <DatePicker
                format="YYYY-MM-DD HH:mm:ss"
                disabledDate={disabledDate}
                onChange={(date: any) => {
                  if (date) {
                    const today = dayjs();
                    const diffDatesH = date.diff(today, 'hour', true);
                    setExtendHours(Math.ceil(diffDatesH));
                  } else {
                    setExtendHours(0);
                  }
                }}
                showTime
              />
            </Form.Item>

            <Form.Item name="comments">
              <div className={styles.commentBlock}>
                <TextArea
                  value={comment}
                  onChange={onChangeComment}
                  rows={4}
                  placeholder="Write your comment..."
                />
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </Drawer>
  );
};
