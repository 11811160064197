import React, { useState } from 'react';
import styles from './styles.module.scss';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { ModalContentTitle } from '../ModalContentTitle';
import { columns } from './columns';
import { ContentVideo } from 'components/ui_components/ContentVideo';
import { Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { ContentImg } from 'components/ui_components/ContentItem/components/ContentImg';
import { ModalContentDrawer } from '../ModalContentDrawer';

export const Carousel: React.FC<any> = ({
  contentIds,
  sliderIndex,
  setSliderIndex,
  closeModal,
  isActions,
  replaceInModal,
  setReplaceInModal,
  isCarousel,
  commentSection,
  refetchContentJob = null,
  containerCurrentWidth,
  quantityContents,
  refetchContentBarView,
  setRefetchTableViewData,
}) => {
  const [scaleFactor, setScaleFactor] = useState(1);
  const [scaleSign, setScaleSign] = useState<string>('');
  const [isHighQuality, setIsHighQuality] = useState<boolean>(false);
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const { data: dataContent, refetch: refetchContent } = useQuery({
    queryKey: [queryNames.FULFILMENT_CONTENTS_GET, 'carousel', sliderIndex],
    meta: {
      token,
      getAllData,
      columnParams: columns,
      additionalSearchParams: `?id=${contentIds[sliderIndex]}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!contentIds,
  });

  const handleZoomInOut = (sign: string) => {
    if (sign === 'plus') {
      setScaleFactor(scaleFactor + 1);
      setScaleSign(sign);
    } else {
      if (scaleFactor > 1) {
        setScaleFactor(scaleFactor - 1);
      }
      setScaleSign(sign);
    }
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.carousel__slide}>
        <ModalContentTitle
          content={dataContent?.data}
          closeModal={closeModal}
          currentIndex={sliderIndex + 1}
          countOfFiles={quantityContents ? quantityContents : contentIds.length}
          isActions={isActions}
          setReplaceInModal={setReplaceInModal}
          sliderIndex={sliderIndex}
          setSliderIndex={setSliderIndex}
          refetchContent={refetchContent}
          isCarousel={isCarousel}
          refetchContentJob={refetchContentJob}
          refetchContentBarView={refetchContentBarView}
          setRefetchTableViewData={setRefetchTableViewData}
          isHighQuality={isHighQuality}
          setIsHighQuality={setIsHighQuality}
        />
        {dataContent &&
        dataContent.hasOwnProperty('data') &&
        (dataContent?.data?.meta?.is_video ||
          dataContent?.data?.content_type?.includes('video')) ? (
          <ContentVideo
            file={dataContent?.data}
            url={
              isHighQuality
                ? dataContent?.data?.release_url
                : dataContent?.data?.content_url
            }
          />
        ) : (
          <div className={styles.carousel__block}>
            <span className={styles.carousel__tools}>
              <Button
                type="text"
                onClick={() => handleZoomInOut('plus')}
                icon={<PlusOutlined />}
              />

              <span className={styles.line}></span>

              <Button
                type="text"
                onClick={() => handleZoomInOut('minus')}
                icon={<MinusOutlined />}
              />
            </span>

            <ContentImg
              key={sliderIndex}
              previewUrl={
                isHighQuality
                  ? dataContent?.data?.release_url
                  : dataContent?.data?.content_url
              }
              updatedAt={dataContent?.data?.updated_at}
              id={dataContent?.data?.id}
              replaceInModal={replaceInModal}
              isModal={true}
              scaleFactor={scaleFactor}
              scaleSign={scaleSign}
            />
          </div>
        )}
      </div>

      <ModalContentDrawer
        content={dataContent?.data}
        isCarousel={isCarousel}
        commentSection={commentSection}
        containerCurrentWidth={containerCurrentWidth}
      />
    </div>
  );
};
