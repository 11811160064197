import { tablesNames } from 'api/tablesNames';
import { FC } from 'react';
import { hideInSetting } from './hideInSettings';
import styles from './styles.module.scss';
import { queryNames } from 'api/queryNames';
import { PurchaseOperationsTable } from 'components/ui_components/PurchaseOperationsTable';
import { defaultEnabledSettings } from './defaultEnabledSettings';

export interface PurchaseOperationsT {
  id: string;
}

export const PurchaseOperationsInPurchase: FC<PurchaseOperationsT> = ({
  id,
}) => {
  const meta = {
    additionalSearchParams: `?purchase_ids=${id}`,
    paymentsApi: true,
  };

  return (
    <PurchaseOperationsTable
      tableIndicatorClassName={styles.table}
      queryKey={[queryNames.PurchaseOperations, id]}
      tableName={tablesNames.PurchaseOperationsCol}
      toolbar={null}
      meta={meta}
      headerName={tablesNames.PurchaseOperationsHeader}
      defaultEnabledSettings={defaultEnabledSettings}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
