export const defaultEnabledSettings = {
  code: { order: 1 },
  title: { order: 2 },
  'account.name': { order: 3 },
  state_id: { order: 4 },
  content_type: { order: 5 },
  payment_type: { order: 6 },
  'payment_method.status': { order: 7 },
  'currency.code': { order: 8 },
  'payment_status.name': { order: 9 },
  addr_zip: { order: 10 },
};
