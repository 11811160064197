import { Button, Input, DatePicker, Space } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { convertDateToTimestamp } from 'shared/utils/convertDateToTimestamp';
import { deleteFilters } from '../Table/utils/deleteFilters';
const { RangePicker } = DatePicker;

export const FilterDropdownRender = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  searchInputRef,
  setSearchKeys,
  setRangeKeys,
  setCodeKeys,
  type,
  setSearchParams,
  setFilters,
  filtersWithPrefix,
}: any) => {
  const [valueForRange, setValueForRange] = useState<any>(['', '']);
  const [datesAsString, setdatesAsString] = useState<string[]>(['', '']);

  useEffect(() => {
    if (dataIndex.includes('code')) {
      setCodeKeys((prevState: any) => [...prevState, dataIndex]);
    } else if (type === 'datetime') {
      setRangeKeys((prevState: any) => [...prevState, dataIndex]);
    }
    // else if (type !== 'datetime') {
    //   setSearchKeys((prevState: any) => [...prevState, dataIndex]);
    // }
  }, [dataIndex, setCodeKeys, setRangeKeys, setSearchKeys, type]);

  const deleteSearchFromUrl = () => {
    const keys: string[] | undefined = filtersWithPrefix
      ? Object.keys(filtersWithPrefix)
      : undefined;
    const key =
      keys || Array.isArray(keys)
        ? keys.find((k) => k.includes(`${dataIndex}__`))
        : undefined;
    if (key) {
      const urlParams = new URLSearchParams(window.location.search);
      const removeCurrentKey = (
        prevKeys: { [key: string]: string[] | null } | null | undefined
      ) => {
        if (
          prevKeys !== null &&
          typeof prevKeys === 'object' &&
          prevKeys.hasOwnProperty(dataIndex)
        ) {
          delete prevKeys[dataIndex];
        }
        return prevKeys;
      };
      setFilters((prevFilter: any) => deleteFilters(prevFilter, key));
      key.includes('__search') && setSearchKeys(removeCurrentKey);
      key.includes('__code') && setCodeKeys(removeCurrentKey);
      urlParams.delete(key);
      const urlParamsString = urlParams.toString();
      setSearchParams(urlParamsString, { replace: true });
    }
  };

  const handleSearch = () => {
    confirm();
  };

  const handleReset = () => {
    clearFilters();
    confirm();
    deleteSearchFromUrl();
  };

  const closeFilter = () => {
    confirm();
  };

  const resetRange = () => {
    clearFilters();
    confirm();
    setValueForRange(['', '']);
  };

  const onCalendarChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[],
    info: { range: 'start' | 'end' } | any
  ) => {
    if (dateStrings?.[0] && dateStrings?.[1]) {
      setdatesAsString([dateStrings[0], dateStrings[1]]);
      // If select preset range "Info.range" will equal "start"
      if (info.range === 'start') {
        onOk(dates, dateStrings[0], dateStrings[1]);
      }
    }
  };

  const onOk = (
    dates: null | (Dayjs | null)[],
    dateStart?: string,
    dateEnd?: string
  ) => {
    if (dates?.[0] && dates?.[1]) {
      setValueForRange(dates);
      const fromDate = convertDateToTimestamp(dateStart || datesAsString[0]);
      const toDate = convertDateToTimestamp(dateEnd || datesAsString[1]);
      setSelectedKeys([fromDate, toDate]);
      closeFilter();
      setdatesAsString(['', '']);
    }
  };

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    {
      label: 'Today',
      value: [dayjs().startOf('d'), dayjs().endOf('d')],
    },
    {
      label: 'This Month',
      value: [dayjs().startOf('M'), dayjs().endOf('M')],
    },
    {
      label: 'Last 7 Days',
      value: [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('d')],
    },
    {
      label: 'Last 14 Days',
      value: [dayjs().add(-14, 'd').startOf('d'), dayjs().endOf('d')],
    },
    {
      label: 'Last 30 Days',
      value: [dayjs().add(-30, 'd').startOf('d'), dayjs().endOf('d')],
    },
    {
      label: 'Last 90 Days',
      value: [dayjs().add(-90, 'd').startOf('d'), dayjs().endOf('d')],
    },
  ];

  const resetButtonDisabled = useMemo(() => !selectedKeys[0], [selectedKeys]);

  return (
    <div
      className={styles.wrapper}
      onKeyDown={(e) => e.stopPropagation()}
      key={searchInputRef}
    >
      {type === 'datetime' ? (
        <>
          <RangePicker
            size="small"
            presets={rangePresets}
            onCalendarChange={onCalendarChange}
            onOk={onOk}
            value={valueForRange}
            style={{ display: 'flex', marginBottom: '10px' }}
            popupClassName={styles.rangePickerDropdown}
            inputReadOnly={true}
            showTime={{ format: 'HH:mm' }}
            format={'YYYY-MM-DD HH:mm'}
          />

          <div className={styles.rangeAction}>
            <Button onClick={resetRange} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        </>
      ) : (
        <>
          <Input
            ref={searchInputRef}
            placeholder="Search"
            value={selectedKeys[0]}
            onChange={(e) => {
              const { value } = e.target;
              setSearchKeys(
                (
                  prevSearchKeys:
                    | { [key: string]: string[] | null }
                    | null
                    | undefined
                ) => {
                  const searchObj = { dataIndex: value ? [value] : null };
                  if (prevSearchKeys && typeof prevSearchKeys === 'object') {
                    if (!Object.keys(prevSearchKeys)) return searchObj;
                    const newSearchKeys = Object.assign(prevSearchKeys, {});
                    newSearchKeys[dataIndex] = value ? [value] : null;
                    return newSearchKeys;
                  }
                  return searchObj;
                }
              );
              setSelectedKeys(value ? [value] : []);
            }}
            onPressEnter={handleSearch}
            style={{ marginBottom: 8, display: 'block' }}
            autoFocus={true}
            data-e2e="inputSearch"
          />

          <Space className={styles.buttonsContainer}>
            <Button
              onClick={handleReset}
              size="small"
              type="link"
              className={styles.resetButton}
              disabled={resetButtonDisabled}
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={handleSearch}
              icon={<SearchOutlined />}
              size="small"
              data-e2e="btnSearch"
            >
              Search
            </Button>
          </Space>
        </>
      )}
    </div>
  );
};
