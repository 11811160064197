import { FunctionComponent, useMemo } from 'react';
import { Breadcrumb } from 'antd';
import styles from './bread_crumbs.styles.module.scss';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

const BreadCrumbs: FunctionComponent<any> = ({ crumbs = [] }) => {
  const lastBreadcrumb = useSelector(
    (state: any) => state.auth.lastStepBreadcrumb
  );
  const breadcrumbNames = useMemo(() => {
    return crumbs.map((crumb: any) => crumb?.key).join('_');
  }, [crumbs]);
  return (
    <Breadcrumb
      className={styles.breadcrumb}
      data-e2e={`breadcrumb_${breadcrumbNames}`}
    >
      {crumbs.map((crumb: any, index: number) => {
        return (
          <Breadcrumb.Item
            key={crumb.match.pathname + `${index}`}
            data-last-step={lastBreadcrumb}
          >
            <NavLink to={crumb.match.pathname}>{crumb.breadcrumb}</NavLink>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
