import { FunctionComponent, useRef, useState } from 'react';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostData } from '../../../../../../../../../api/usePostData';
import { queryNames } from 'api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import clsx from 'clsx';
import { PurchaseMethodsTable } from 'components/ui_components/PurchaseMethodsTable';
import { APP_CONSTANS } from 'shared/constants';
import { PlusOutlined } from '@ant-design/icons';
import { ModalPurchaseMethod } from 'components/modals/ModalPurchaseMethod';

export const TabPurchaseMethods: FunctionComponent<any> = ({
  userId,
  refetchAccount,
  defaultPurchaseMethod,
}) => {
  const queryClient: any = useQueryClient();
  const currentPurchase = useRef<any>();
  const token = useSelector((state: any) => state.auth.token);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [purchaseData, setPurchaseData] = useState<any>(null);

  const meta = {
    additionalSearchParams: `?user_ids=${userId}`,
    paymentsApi: true,
    getAllData: true,
  };

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      } else {
        if (
          variables?.otherProps?.includes('accounts') ||
          variables?.url?.includes('accounts')
        ) {
          refetchAccount();
        } else {
          queryClient.invalidateQueries(
            `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.PurchaseMethods}`
          );
          message.success('Purchase method was successfully deleted');
        }
      }
    },
  });

  const addPurchase = () => {
    setPurchaseData(null);
    setVisibleModal(true);
  };

  const editPurchase = () => {
    setVisibleModal(true);
  };

  const deletePurchase = () => {
    const dataToBack = {
      id: currentPurchase.current.id,
    };
    mutation.mutate({
      data: dataToBack,
      token,
      url: queryNames.CLEANER_SOFT_DELETE_PURCHASE_METHOD,
      method: 'POST',
    });
  };

  const toolbarRender = [
    <Button
      key="button"
      type="default"
      icon={<PlusOutlined />}
      onClick={addPurchase}
    >
      Add new Method
    </Button>,
  ];

  const items: MenuProps['items'] = [
    {
      key: 'Edit',
      label: (
        <Button type="text" onClick={editPurchase}>
          Edit
        </Button>
      ),
    },
    {
      key: 'Soft_Delete',
      label: (
        <Button
          type="text"
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            deletePurchase();
          }}
        >
          Soft Delete
        </Button>
      ),
    },
  ];

  const actionsColumn = {
    title: 'Action',
    width: 100,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => (
      <Dropdown
        key="dropdown"
        trigger={['click']}
        menu={{ items }}
        overlayClassName={clsx(
          // record.id === defaultPurchaseId && styles.hideMainAddrr),
          styles.action
        )}
      >
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            currentPurchase.current = record;
            setPurchaseData(record);
          }}
          type="text"
        >
          ...
        </Button>
      </Dropdown>
    ),
  };

  return (
    <>
      <PurchaseMethodsTable
        defaultEnabledSettings={defaultEnabledSettings}
        queryKey={queryNames.PurchaseMethods}
        meta={meta}
        headerName={tablesNames.PurchaseMethodsHeader}
        toolbar={null}
        toolBarRender={toolbarRender}
        actionsColumn={actionsColumn}
        tableName={tablesNames.PurchaseMethodsCol}
        hideInSetting={hideInSetting}
        defaultPurchaseId={defaultPurchaseMethod}
        resetAllFiltering={true}
      />

      {visibleModal && (
        <ModalPurchaseMethod
          visible={visibleModal}
          setVisible={setVisibleModal}
          userId={userId}
          data={purchaseData}
        />
      )}
    </>
  );
};
