import { ThemeConfig } from 'antd/es/config-provider';
import { colors } from './variables';

const { primary, neutral, error } = colors;

export const theme: ThemeConfig = {
  token: {
    borderRadius: 2,
    colorPrimary: primary[500],
    colorText: neutral[700],
    colorError: error[500],

    colorLink: primary[600],
    colorLinkHover: primary[500],
    colorLinkActive: primary[500],
    linkHoverDecoration: 'underline',
    linkFocusDecoration: 'underline',

    controlHeightSM: 36,
    controlHeight: 40,
    controlHeightLG: 48,
    fontSize: 16,
  },
  components: {
    Button: {
      fontSize: 16,
      colorPrimaryHover: primary[600],
      colorPrimaryActive: primary[700],
      colorBgContainerDisabled: neutral[50],

      colorLink: primary[600],
      colorBgContainer: 'transparent',
      colorLinkHover: primary[500],
      colorText: neutral[700],
      // TODO: Change color after design updates

      colorBorder: neutral[400],

      colorTextDisabled: neutral[300],
      colorBgTextHover: neutral[50],
      colorBgTextActive: neutral[50],
    },
    Input: {
      controlHeightSM: 38,
    },
  },
};
