import ProTable from '@ant-design/pro-table';
import { FunctionComponent, useState } from 'react';
import { columnsNested } from './columnsNested';
import { queryNames } from 'api/queryNames';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { columns } from './columns';
import { DrawerPurchaseTransactions } from '../DrawerPurchaseTransactions';

type NestedTableT = {
  purchaseOperationId: string | undefined;
  currencyCode?: string;
};

export const NestedTable: FunctionComponent<NestedTableT> = ({
  purchaseOperationId,
  currencyCode,
}) => {
  const [show, setShow] = useState<any>(null);
  const [currentOption, setCurrentOption] = useState<any>(null);
  const token = useSelector((state: any) => state.auth.token);

  const { data: dataOperarion, isFetching: isLoadingOperarion } = useQuery({
    queryKey: [queryNames.PaymentTransactions, purchaseOperationId],
    meta: {
      token,
      paymentsApi: true,
      getAllData: true,
      columnParams: columns,
      additionalSearchParams: `?payment_operation_ids=${purchaseOperationId}`,
    },
    queryFn: useGetData,
    enabled: !!purchaseOperationId,
  });

  if (!purchaseOperationId) return <></>;
  return (
    <div>
      <ProTable
        columns={columnsNested(
          currencyCode,
          purchaseOperationId,
          setCurrentOption,
          setShow
        )}
        dataSource={dataOperarion?.data}
        options={false}
        pagination={false}
        headerTitle={null}
        bordered={false}
        toolbar={undefined}
        search={false}
        loading={isLoadingOperarion}
        size="small"
      />

      {show && (
        <DrawerPurchaseTransactions
          show={show}
          setShow={setShow}
          id={currentOption?.id}
          currencyCode={currencyCode}
        />
      )}
    </div>
  );
};
