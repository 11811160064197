import { FC, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Dropdown, message } from 'antd';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { APP_CONSTANS } from 'shared/constants';
import { EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import globalStyles from 'shared/styles/styles.module.scss';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import { ModalActionConfirm } from 'components/modals/ModalActionConfirm';
import { HeaderActionsModalActionT } from './types';

export const InvoiceHeaderActions: FC<any> = ({
  id,
  stateId,
  isDeleted,
  isPaymentTypeInvoice,
  isRefundAllowed,
  setShowModal,
  setShowModalEdit,
  setShowModalRefund,
  setShowModalChangePaymentType,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const queryClient: any = useQueryClient();

  const [showActionModal, setShowActionModal] = useState<boolean>(false);
  const [actionModalAction, setActionModalAction] =
    useState<HeaderActionsModalActionT>({ call: null });
  const [actionModalMessage, setActionModalMessage] = useState<string>('');
  const [actionModalTitle, setActionModalTitle] = useState<string>('');
  const [actionModalOkText, setActionModalOkText] = useState<string>('');
  const [actionModalCancelText, setActionModalCancelText] =
    useState<string>('');

  const isSuperuserOrFinance = useUserPermissionGranted({
    enabledUserTypes: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
    ],
  });
  const isPayAllowed = [
    APP_CONSTANS.INVOICE_STATUSES_BY_NAME.NotPaid,
    APP_CONSTANS.INVOICE_STATUSES_BY_NAME.PartialPayed,
  ].includes(stateId);

  const isSuperuser = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables: any) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      if (variables.otherProps.includes(queryNames.INVOICE_CHARGE)) {
        message.success('Invoice was chargeed');
      }
      if (variables.otherProps.includes(queryNames.INVOICE_CANCEL)) {
        message.success('Invoice was canceled');
      }
      setShowActionModal(false);
      queryClient.invalidateQueries([queryNames.INVOICES_BY_ID, id]);
      queryClient.invalidateQueries([queryNames.INVOICE_LINES, id]);
    },
  });

  useEffect(() => {
    if (showActionModal === false) {
      setActionModalOkText('');
      setActionModalCancelText('');
      setActionModalTitle('');
      setActionModalMessage('');
      setActionModalAction({ call: null });
    }
  }, [showActionModal]);

  const cancelModal = () => {
    setActionModalOkText('Cancel Invoice');
    setActionModalCancelText('No');
    setActionModalTitle('Cancel action');
    setActionModalMessage('Do you want to cancel this Invoice?');
    setActionModalAction({ call: cancelAction });
    setShowActionModal(true);
  };

  const chargeModal = () => {
    setActionModalOkText('Charge Invoice');
    setActionModalCancelText('Cancel');
    setActionModalTitle('Charge action');
    setActionModalMessage(
      'Do you want to start charging process for this Invoice?'
    );
    setActionModalAction({ call: chargeAction });
    setShowActionModal(true);
  };

  const cancelAction = useCallback(() => {
    mutate({
      data: { invoice_id: id },
      token,
      otherProps: queryNames.INVOICE_CANCEL,
      method: 'POST',
    });
  }, [id, mutate, token]);

  const chargeAction = useCallback(() => {
    mutate({
      data: { invoice_id: id },
      token,
      otherProps: queryNames.INVOICE_CHARGE,
      method: 'POST',
    });
  }, [id, mutate, token]);

  const items = [
    {
      key: 'Charge Invoice',
      label: (
        <Button
          type="text"
          onClick={chargeModal}
          disabled={[
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.PartialPayed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Payed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Refunded,
          ].includes(stateId)}
        >
          Charge Invoice
        </Button>
      ),
    },
    {
      key: 'Change Invoice Payment Type',
      label: (
        <Button
          type="text"
          onClick={() => setShowModalChangePaymentType(true)}
          disabled={[
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.PartialPayed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Payed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Refunded,
          ].includes(stateId)}
        >
          Change Payment Type
        </Button>
      ),
    },
    {
      key: 'Cancel Invoice',
      label: (
        <Button
          type="text"
          onClick={cancelModal}
          danger
          disabled={[
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.PartialPayed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Payed,
            APP_CONSTANS.INVOICE_STATUSES_BY_NAME.Refunded,
          ].includes(stateId)}
        >
          Cancel Invoice
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.actions}>
      {isSuperuserOrFinance && (
        <div className={styles.actions__btns}>
          <div>
            {
              <>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={() => setShowModalEdit(true)}
                  disabled={isDeleted || !isPaymentTypeInvoice}
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={() => setShowModalRefund(true)}
                  className={clsx(globalStyles.btn, globalStyles.gray__btn)}
                  disabled={!isRefundAllowed}
                >
                  Refund
                </Button>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={() => setShowModal(true)}
                  className={clsx(globalStyles.btn, globalStyles.success__btn)}
                  disabled={!isPayAllowed}
                >
                  Set Paid
                </Button>
                {isSuperuser && (
                  <Dropdown.Button
                    size={window.innerWidth > 768 ? 'middle' : 'small'}
                    overlayClassName={styles.list}
                    className={styles.actions__dropdown}
                    menu={{ items }}
                    trigger={['click']}
                  ></Dropdown.Button>
                )}
              </>
            }
          </div>
        </div>
      )}
      {showActionModal && (
        <ModalActionConfirm
          action={actionModalAction}
          title={actionModalTitle}
          okText={actionModalOkText}
          message={actionModalMessage}
          cancelText={actionModalCancelText}
          showModal={showActionModal}
          setShowModal={setShowActionModal}
        />
      )}
    </div>
  );
};
