import Table from '../Table';
import { columns } from './columns';
import { PrefTableT } from './types';
import styles from './styles.module.scss';
import { useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { NestedTable } from './components/NestedTable';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { DrawerPurchaseOperation } from './components/DrawerPurchaseOperation';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';

export const PurchaseOperationsTable: React.FC<PrefTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'purchaseOperationsTable',
  ...rest
}) => {
  const [show, setShow] = useState<any>(null);
  const [purchaseOperationId, setPurchaseOperationId] = useState<any>(null);

  const updatePurchase = (id: string) => {
    setPurchaseOperationId(id);
    setShow(true);
  };

  const expandedRow = (record: any) => {
    const id = record?.id;
    const currency = record?.['currency.code'];
    return <NestedTable purchaseOperationId={id} currencyCode={currency} />;
  };

  const renderData = (value: any, record: any) => {
    const id = record?.id;
    const currency = record?.['currency.code'];
    const accountId = record?.['account.id'];

    return [
      {
        name: 'id',
        action: (
          <p className={styles.link} onClick={() => updatePurchase(id)}>
            {value}
          </p>
        ),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_total',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_fee',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="operations"
        columnsForRequest={columns}
        expandedRowRender={(record: any) => expandedRow(record)}
        defaultSorter={{
          field: 'pur_oper.created_at',
          order: 'descend',
        }}
        expandIcon={({ expanded, onExpand, record }: any) =>
          expanded ? (
            <MinusOutlined
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <PlusOutlined
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          )
        }
        {...rest}
      />

      {show && (
        <DrawerPurchaseOperation
          show={show}
          setShow={setShow}
          id={purchaseOperationId}
        />
      )}
    </>
  );
};
