export const defaultEnabledSettings = {
  preview_url: { order: 1 },
  content_filename: { order: 2 },
  'state.id': { order: 3 },
  content_size: { order: 4 },
  'meta.resolution': { order: 5},
  'meta.iso_speed': { order: 6},
  'meta.shutter': { order: 7},
  'meta.full_size': { order: 8},
  'meta.date_time': { order: 9},
  'fulfilment.code': { order: 10 },
  'order.code': { order: 11 },
  created_at: { order: 12 },
};
