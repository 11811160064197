import {
  FunctionComponent,
  useState,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Button, message, Popover } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import ModalNotification from './components/ModalNotification/modalNotification';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import ModalError from 'components/modals/ModalError';
import { useNavigate } from 'react-router-dom';
import { tablesNames } from 'api/tablesNames';
import useGetData from 'api/useGetData';
import { queryNames } from 'api/queryNames';

type HeaderTitleComponentPropsT = {
  type: string;
  email: string;
  userId: string;
  status: string;
  isDeleted: boolean;
  dataPrefs: any;
  setWithDeleted: any;
  refetchUser?: any;
  refetchPrefs?: any;
};

const Header: FunctionComponent<HeaderTitleComponentPropsT> = ({
  type,
  email,
  userId,
  status,
  isDeleted,
  dataPrefs,
  setWithDeleted,
  refetchUser,
  refetchPrefs,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [showedNotification, setShowedNotification] = useState<boolean>(false);
  const [showedModalError, setShowedModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [actionName, setActionName] = useState<string>('');
  const [errorAction, setErrorAction] = useState<any>(null);
  const [isBtnDanger, setIsBtnDanger] = useState<boolean>(false);
  const [isForceAction, setIsForceAction] = useState<boolean>(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(false);
  const [openedPopover, setOpenedPopover] = useState<boolean>(false);
  const navigate = useNavigate();
  const forceRequest = useRef<boolean>(false);

  const [isCheckRelations, setIsCheckRelations] = useState<boolean>(false);
  const [relationsList, setRelationsList] = useState<any>(null);

  const hidePopover = () => {
    setOpenedPopover(false);
  };

  const handleOpenPopover = (newOpen: boolean) => {
    setOpenedPopover(newOpen);
  };

  const {
    isLoading: isLoadingCheckingRelations,
    isError: isErrorCheckingRelations,
    data: dataCheckingRelations,
    error: errorCheckingRelations,
  } = useQuery({
    queryKey: [queryNames.UserCheckingRelations, userId],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `/${userId}`,
    },
    queryFn: useGetData,
    enabled: isCheckRelations,
  });

  const textActivateUser = 'Are you sure you want to activate current user?';
  const textDisableUser =
    'User will be disabled from all his Accounts, loses access to the System and is not available for further selection. You can revert this action.';
  const textUndeleteUser = 'Are you sure you want to restore current user?';
  const textSoftDeleteUser =
    'You are going to soft delete User. User will be suspended from all his Accounts, loses access to the System and is not available for further selection. You can revert this action.';
  const textHardDeleteUser =
    'You are going to hard delete User. User will be suspended from all his Accounts, loses access to the System and is not available for further selection. This action is not reversible.';
  const textResetPassword =
    'Are you sure you want to reset password current user?';

  const resetModal = useCallback(() => {
    setShowedModal(false);
    setIsForceAction(false);
    setIsCheckRelations(false);
    setIsBtnDisabled(false);
    setErrorMsg('');
    forceRequest.current = false;
  }, []);

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: (error, variables, context) => {
      message.error('Network Error');
    },
    onSuccess: (data, variables: any, context) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
      if (variables.otherProps.includes('activate')) {
        message.success('User activated');
      } else if (variables.otherProps.includes('suspend')) {
        message.success('User disabled');
      } else if (variables.otherProps.includes('soft')) {
        message.success('User soft deleted');
        setWithDeleted(true);
      } else if (variables.otherProps.includes('hard')) {
        message.success('User hard deleted');
        setWithDeleted(true);
        navigate('/users');
      } else if (variables.otherProps.includes('signup/reset_password')) {
        message.success(
          'Resetting password email was successfully sent to user'
        );
      } else if (variables.otherProps.includes('abort')) {
        message.success('User session was successfully cancelled');
      }
      refetchUser();
    },
  });

  useEffect(() => {
    if (!showedModalError) {
      resetModal();
    }
    if (!showedModalError && isForceAction === true) {
      resetModal();
    }
  }, [showedModalError, isForceAction]);

  const activate = useCallback(() => {
    mutation.mutate({
      data: { id: userId },
      token,
      otherProps: queryNames.CLEANER_ACTIVATE_USER,
      method: 'POST',
    });
    setShowedModal(false);
  }, [userId, mutation, token]);

  const disable = useCallback(() => {
    mutation.mutate({
      data: { id: userId },
      token,
      otherProps: queryNames.CLEANER_SUSPEND_USER,
      method: 'POST',
    });
    setShowedModal(false);
  }, [userId, mutation, token]);

  const undelete = useCallback(() => {
    mutation.mutate({
      data: { id: userId },
      token,
      otherProps: queryNames.CLEANER_RESTORE_USER,
      method: 'POST',
    });
    setShowedModal(false);
  }, [userId, mutation, token]);

  const softDelete = useCallback(() => {
    mutation.mutate({
      data: { id: userId, force: forceRequest.current },
      token,
      otherProps: queryNames.CLEANER_SOFT_DELETE_USER,
      method: 'POST',
    });
    setShowedModal(false);
  }, [userId, mutation, token]);

  const hardDelete = useCallback(() => {
    mutation.mutate({
      data: { id: userId, force: forceRequest.current },
      token,
      otherProps: queryNames.CLEANER_HARD_DELETE_USER,
      method: 'POST',
    });
    setShowedModal(false);
  }, [userId, mutation, token]);

  const resetPassword = useCallback(() => {
    mutation.mutate({
      data: { email: email },
      token,
      otherProps: 'signup/reset_password',
      method: 'POST',
    });
    setShowedModal(false);
  }, [email, mutation, token]);

  const abortSession = useCallback(() => {
    hidePopover();
    mutation.mutate({
      data: { user_id: userId },
      token,
      otherProps: queryNames.ABORT_USER,
      method: 'POST',
    });
  }, [userId, mutation, token]);

  const showNotificationForm = useCallback(() => {
    setShowedNotification(true);
    hidePopover();
  }, []);

  const getUserRelations = useCallback(() => {
    setIsCheckRelations(true);
  }, []);

  useEffect(() => {
    if (
      (actionName && actionName.toLowerCase().includes('soft')) ||
      actionName.toLowerCase().includes('hard')
    ) {
      // if data relations was taken
      if (
        !isLoadingCheckingRelations &&
        dataCheckingRelations &&
        dataCheckingRelations.hasOwnProperty('data')
      ) {
        // if data relations have children list of nested entites
        const data = dataCheckingRelations.data;
        const childrenRelations = dataCheckingRelations.data?.children;
        if (Object.keys(childrenRelations).length > 0) {
          setErrorMsg('list');
          const relationsArray = [];
          for (let relation in childrenRelations) {
            relationsArray.push({
              name: relation,
              value: childrenRelations[relation],
            });
          }
          setRelationsList(relationsArray);
          // soft delete action
          if (actionName.toLowerCase().includes('soft')) {
            const canBeSoftDelete = data.soft_delete;
            const canForceSoftDelete = data.soft_force;
            // show of hide 'Force delete'
            // disable or enable 'Soft Delete'
            if (!canBeSoftDelete && canForceSoftDelete) {
              setIsBtnDisabled(true);
              setIsForceAction(true);
            } else if (!canBeSoftDelete && !canForceSoftDelete) {
              setIsBtnDisabled(true);
              setIsForceAction(false);
            } else {
              setIsBtnDisabled(false);
              setIsForceAction(false);
            }
          }
          //hard delete action
          if (actionName.toLowerCase().includes('hard')) {
            const canBeHardDelete = data.hard_delete;
            const canForceHardDelete = data.hard_force;
            // show of hide 'Force delete'
            // disable or enable 'Hard Delete'
            if (!canBeHardDelete && canForceHardDelete) {
              setIsBtnDisabled(true);
              setIsForceAction(true);
            } else if (!canBeHardDelete && !canForceHardDelete) {
              setIsBtnDisabled(true);
              setIsForceAction(false);
            } else {
              setIsBtnDisabled(false);
              setIsForceAction(false);
            }
          }
        }
      } else if (
        !isLoadingCheckingRelations &&
        isErrorCheckingRelations &&
        errorCheckingRelations
      ) {
        message.error('Server error');
      }
    }
  }, [
    isCheckRelations,
    actionName,
    dataCheckingRelations,
    isLoadingCheckingRelations,
    isErrorCheckingRelations,
    errorCheckingRelations,
  ]);

  const showModalError = (name: string) => {
    hidePopover();
    setShowedModal(true);
    switch (name) {
      case 'disable':
        setErrorMsg(textDisableUser);
        setActionName('Disable');
        setErrorAction(() => disable);
        setIsBtnDanger(true);
        break;
      case 'soft':
        getUserRelations();
        setErrorMsg(textSoftDeleteUser);
        setActionName('Soft delete');
        setErrorAction(() => softDelete);
        setIsBtnDanger(true);
        break;
      case 'hard':
        getUserRelations();
        setErrorMsg(textHardDeleteUser);
        setActionName('Hard delete');
        setErrorAction(() => hardDelete);
        setIsBtnDanger(true);
        break;
      case 'activate':
        setErrorMsg(textActivateUser);
        setActionName('Activate');
        setErrorAction(() => activate);
        setIsBtnDanger(false);
        break;
      case 'reset':
        setErrorMsg(textResetPassword);
        setActionName('Reset password');
        setErrorAction(() => resetPassword);
        setIsBtnDanger(false);
        break;
      case 'undelete':
        setErrorMsg(textUndeleteUser);
        setActionName('Restore');
        setErrorAction(() => undelete);
        setIsBtnDanger(false);
        break;
      default:
        setErrorMsg('');
        setActionName('');
    }
  };

  const content = (
    <div className={styles.header__block}>
      <Button
        type="text"
        onClick={showNotificationForm}
        className={styles.header__menu_item}
      >
        Notifications settings
      </Button>

      <Button
        type="text"
        onClick={() => showModalError('reset')}
        className={styles.header__menu_item}
      >
        Reset password
      </Button>

      {isDeleted && (
        <Button
          onClick={() => showModalError('undelete')}
          type="text"
          className={styles.header__menu_item}
        >
          Restore deleted user
        </Button>
      )}

      {status.toLowerCase() === 'active' && (
        <Button
          type="text"
          onClick={() => showModalError('disable')}
          className={styles.header__menu_item}
          danger
        >
          Disable user
        </Button>
      )}

      {status.toLowerCase() === 'disabled' && (
        <Button
          type="text"
          onClick={() => showModalError('activate')}
          className={styles.header__menu_item}
        >
          Activate user
        </Button>
      )}

      <Button
        type="text"
        onClick={abortSession}
        className={styles.header__menu_item}
        danger
      >
        Abort session
      </Button>

      {!isDeleted && (
        <Button
          type="text"
          onClick={() => showModalError('soft')}
          className={styles.header__menu_item}
          danger
        >
          Soft delete user
        </Button>
      )}

      <Button
        type="text"
        onClick={() => showModalError('hard')}
        className={styles.header__menu_item}
        danger
      >
        Hard delete user
      </Button>
    </div>
  );

  return (
    <div>
      <Popover
        content={content}
        placement="bottomRight"
        trigger="click"
        open={openedPopover}
        onOpenChange={handleOpenPopover}
      >
        <Button className={styles.showMoreButton}>
          <MoreOutlined rotate={90} className={styles.showMoreButtonIcon} />
        </Button>
      </Popover>

      <ModalNotification
        showedNotification={showedNotification}
        setShowedNotification={setShowedNotification}
        type={type}
        email={email}
        userId={userId}
        dataPrefs={dataPrefs}
        refetchPrefs={refetchPrefs}
      />

      {showedModalError && (
        <ModalError
          visibleModal={showedModalError}
          setVisibleModal={setShowedModal}
          message={errorMsg}
          actionText={actionName}
          action={errorAction}
          isBtnDanger={isBtnDanger}
          isForceAction={isForceAction}
          forceRequest={forceRequest}
          relationsList={relationsList}
          isBtnDisabled={isBtnDisabled}
          setIsBtnDisabled={setIsBtnDisabled}
          type="User"
        />
      )}
    </div>
  );
};

export default Header;
