export function getTimeZoneName(
  date: Date = new Date(),
  timeZone?: string | undefined,
  locales: string | string[] = 'en-US',
  type:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric' = 'short'
) {
  const tzName = new Intl.DateTimeFormat(locales, {
    timeZoneName: type,
    timeZone: timeZone,
  })
    ?.formatToParts(date)
    ?.find((part) => part.type === 'timeZoneName')?.value;
  return tzName || undefined;
}
