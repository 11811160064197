import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  countries: {},
  isLoadingCountries: null,
  countryInfo: {},
  isLoadingCountryInfo: null,
  countryRegions: {},
  isLoadingCountryRegions: null,
  countryRegionInfo: {},
  isLoadingCountryRegionInfo: null,
  geonames: {},
  isLoadingGeonames: null,
  geonameInfo: {},
  isLoadingGeonameInfo: null,
  timezones: {},
  isLoadingTimezones: null,
  timezoneInfo: {},
  isLoadingTimezoneInfo: null,
  currencies: {},
  isLoadingCurrencies: null,
  currencyInfo: {},
  isLoadingCurrencyInfo: null,
  orderStates: {},
  isLoadingOrderStates: null,
  orderStatesInfo: {},
  isLoadingOrderStatesInfo: null,
  jobStates: {},
  isLoadingJobStates: null,
  jobStatesInfo: {},
  isLoadingJobStatesInfo: null,
  orderPaymentStates: {},
  isLoadingOrderPaymentStates: null,
  orderPaymentStatesInfo: {},
  isLoadingOrderPaymentStatesInfo: null,
  contentStates: {},
  isLoadingContentStates: null,
  contentStateInfo: {},
  isLoadingContentStateInfo: null,
  requestStates: {},
  isLoadingRequestStates: null,
  requestStateInfo: {},
  isLoadingRequestStateInfo: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_COUNTRIES_PENDING:
      return { ...state, isLoadingCountries: true };
    case ACTION_TYPES.GET_COUNTRIES_FULFILLED:
      return { ...state, countries: payload, isLoadingCountries: false };
    case ACTION_TYPES.GET_COUNTRIES_REJECTED:
      return { ...state, countries: payload, isLoadingCountries: false };

    case ACTION_TYPES.GET_COUNTRY_BY_ID_PENDING:
      return { ...state, isLoadingCountryInfo: true };
    case ACTION_TYPES.GET_COUNTRY_BY_ID_FULFILLED:
      return { ...state, countryInfo: payload, isLoadingCountryInfo: false };
    case ACTION_TYPES.GET_COUNTRY_BY_ID_REJECTED:
      return { ...state, countryInfo: payload, isLoadingCountryInfo: false };

    case ACTION_TYPES.GET_COUNTRY_REGIONS_PENDING:
      return { ...state, isLoadingCountryRegions: true };
    case ACTION_TYPES.GET_COUNTRY_REGIONS_FULFILLED:
      return {
        ...state,
        countryRegions: payload,
        isLoadingCountryRegions: false,
      };
    case ACTION_TYPES.GET_COUNTRY_REGIONS_REJECTED:
      return {
        ...state,
        countryRegions: payload,
        isLoadingCountryRegions: false,
      };

    case ACTION_TYPES.GET_COUNTRY_REGIONS_BY_ID_PENDING:
      return { ...state, isLoadingCountryRegionInfo: true };
    case ACTION_TYPES.GET_COUNTRY_REGIONS_BY_ID_FULFILLED:
      return {
        ...state,
        countryRegionInfo: payload,
        isLoadingCountryRegionInfo: false,
      };
    case ACTION_TYPES.GET_COUNTRY_REGIONS_BY_ID_REJECTED:
      return {
        ...state,
        countryRegionInfo: payload,
        isLoadingCountryRegionInfo: false,
      };

    case ACTION_TYPES.GET_GEONAMES_PENDING:
      return { ...state, isLoadingGeonames: true };
    case ACTION_TYPES.GET_GEONAMES_FULFILLED:
      return { ...state, geonames: payload, isLoadingGeonames: false };
    case ACTION_TYPES.GET_GEONAMES_REJECTED:
      return { ...state, geonames: payload, isLoadingGeonames: false };

    case ACTION_TYPES.GET_GEONAMES_BY_ID_PENDING:
      return { ...state, isLoadingGeonameInfo: true };
    case ACTION_TYPES.GET_GEONAMES_BY_ID_FULFILLED:
      return { ...state, geonameInfo: payload, isLoadingGeonameInfo: false };
    case ACTION_TYPES.GET_GEONAMES_BY_ID_REJECTED:
      return { ...state, geonameInfo: payload, isLoadingGeonameInfo: false };

    case ACTION_TYPES.GET_TIMEZONES_PENDING:
      return { ...state, isLoadingTimezones: true };
    case ACTION_TYPES.GET_TIMEZONES_FULFILLED:
      return { ...state, timezones: payload, isLoadingTimezones: false };
    case ACTION_TYPES.GET_TIMEZONES_REJECTED:
      return { ...state, timezones: payload, isLoadingTimezones: false };

    case ACTION_TYPES.GET_TIMEZONES_BY_ID_PENDING:
      return { ...state, isLoadingTimezoneInfo: true };
    case ACTION_TYPES.GET_TIMEZONES_BY_ID_FULFILLED:
      return { ...state, timezoneInfo: payload, isLoadingTimezoneInfo: false };
    case ACTION_TYPES.GET_TIMEZONES_BY_ID_REJECTED:
      return { ...state, timezoneInfo: payload, isLoadingTimezoneInfo: false };

    case ACTION_TYPES.GET_CURRENCIES_PENDING:
      return { ...state, isLoadingCurrencies: true };
    case ACTION_TYPES.GET_CURRENCIES_FULFILLED:
      return { ...state, currencies: payload, isLoadingCurrencies: false };
    case ACTION_TYPES.GET_CURRENCIES_REJECTED:
      return { ...state, currencies: payload, isLoadingCurrencies: false };

    case ACTION_TYPES.GET_CURRENCY_BY_ID_PENDING:
      return { ...state, isLoadingCurrencyInfo: true };
    case ACTION_TYPES.GET_CURRENCY_BY_ID_FULFILLED:
      return { ...state, currencyInfo: payload, isLoadingCurrencyInfo: false };
    case ACTION_TYPES.GET_CURRENCY_BY_ID_REJECTED:
      return { ...state, currencyInfo: payload, isLoadingCurrencyInfo: false };

    case ACTION_TYPES.GET_ORDER_STATES_PENDING:
      return { ...state, isLoadingOrderStates: true };
    case ACTION_TYPES.GET_ORDER_STATES_FULFILLED:
      return { ...state, orderStates: payload, isLoadingOrderStates: false };
    case ACTION_TYPES.GET_ORDER_STATES_REJECTED:
      return { ...state, orderStates: payload, isLoadingOrderStates: false };

    case ACTION_TYPES.GET_ORDER_STATES_BY_ID_PENDING:
      return { ...state, isLoadingOrderStatesInfo: true };
    case ACTION_TYPES.GET_ORDER_STATES_BY_ID_FULFILLED:
      return {
        ...state,
        orderStatesInfo: payload,
        isLoadingOrderStatesInfo: false,
      };
    case ACTION_TYPES.GET_ORDER_STATES_BY_ID_REJECTED:
      return {
        ...state,
        orderStatesInfo: payload,
        isLoadingOrderStatesInfo: false,
      };

    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_PENDING:
      return { ...state, isLoadingOrderPaymentStates: true };
    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_FULFILLED:
      return {
        ...state,
        orderPaymentStates: payload,
        isLoadingOrderPaymentStates: false,
      };
    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_REJECTED:
      return {
        ...state,
        orderPaymentStates: payload,
        isLoadingOrderPaymentStates: false,
      };

    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_BY_ID_PENDING:
      return { ...state, isLoadingOrderPaymentStatesInfo: true };
    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_BY_ID_FULFILLED:
      return {
        ...state,
        orderPaymentStatesInfo: payload,
        isLoadingOrderPaymentStatesInfo: false,
      };
    case ACTION_TYPES.GET_ORDER_PAYMENT_STATES_BY_ID_REJECTED:
      return {
        ...state,
        orderPaymentStatesInfo: payload,
        isLoadingOrderPaymentStatesInfo: false,
      };

    case ACTION_TYPES.GET_FULFILMENT_STATES_PENDING:
      return { ...state, isLoadingJobStates: true };
    case ACTION_TYPES.GET_FULFILMENT_STATES_FULFILLED:
      return { ...state, jobStates: payload, isLoadingJobStates: false };
    case ACTION_TYPES.GET_FULFILMENT_STATES_REJECTED:
      return { ...state, jobStates: payload, isLoadingJobStates: false };

    case ACTION_TYPES.GET_FULFILMENT_STATES_BY_ID_PENDING:
      return { ...state, isLoadingJobStatesInfo: true };
    case ACTION_TYPES.GET_FULFILMENT_STATES_BY_ID_FULFILLED:
      return {
        ...state,
        jobStatesInfo: payload,
        isLoadingJobStatesInfo: false,
      };
    case ACTION_TYPES.GET_FULFILMENT_STATES_BY_ID_REJECTED:
      return {
        ...state,
        jobStatesInfo: payload,
        isLoadingJobStatesInfo: false,
      };

    case ACTION_TYPES.GET_CONTENT_STATES_PENDING:
      return { ...state, isLoadingContentStates: true };
    case ACTION_TYPES.GET_CONTENT_STATES_FULFILLED:
      return {
        ...state,
        contentStates: payload,
        isLoadingContentStates: false,
      };
    case ACTION_TYPES.GET_CONTENT_STATES_REJECTED:
      return {
        ...state,
        contentStates: payload,
        isLoadingContentStates: false,
      };

    case ACTION_TYPES.GET_CONTENT_STATES_BY_ID_PENDING:
      return { ...state, isLoadingContentStateInfo: true };
    case ACTION_TYPES.GET_CONTENT_STATES_BY_ID_FULFILLED:
      return {
        ...state,
        contentStateInfo: payload,
        isLoadingContentStateInfo: false,
      };
    case ACTION_TYPES.GET_CONTENT_STATES_BY_ID_REJECTED:
      return {
        ...state,
        contentStateInfo: payload,
        isLoadingContentStateInfo: false,
      };

    case ACTION_TYPES.GET_REQUEST_STATES_PENDING:
      return { ...state, isLoadingRequestStates: true };
    case ACTION_TYPES.GET_REQUEST_STATES_FULFILLED:
      return {
        ...state,
        requestStates: payload,
        isLoadingRequestStates: false,
      };
    case ACTION_TYPES.GET_REQUEST_STATES_REJECTED:
      return {
        ...state,
        requestStates: payload,
        isLoadingRequestStates: false,
      };

    case ACTION_TYPES.GET_REQUEST_STATES_BY_ID_PENDING:
      return { ...state, isLoadingRequestStateInfo: true };
    case ACTION_TYPES.GET_REQUEST_STATES_BY_ID_FULFILLED:
      return {
        ...state,
        requestStateInfo: payload,
        isLoadingRequestStateInfo: false,
      };
    case ACTION_TYPES.GET_REQUEST_STATES_BY_ID_REJECTED:
      return {
        ...state,
        requestStateInfo: payload,
        isLoadingRequestStateInfo: false,
      };

    default:
      return state;
  }
};
