import { queryNames } from 'api/queryNames';
import { Drawer, Form } from 'antd';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { Loader } from 'components/ui_components/Loader';
import { PurchaseOperationCols } from './columns';
import { ItemsGenerator } from '../ItemsGenerator';
import { ItemT } from '../ItemsGenerator/types';
import { tablesNames } from 'api/tablesNames';

export const DrawerPurchaseOperation: React.FC<any> = ({
  show,
  setShow,
  id,
}) => {
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const queryClient = useQueryClient();

  const headers = queryClient.getQueryData([
    tablesNames.PurchaseOperationsHeader,
  ]) as any;

  const {
    data: dataPurchaseOperation,
    isFetching: isLoadingPurchaseOperation,
  } = useQuery({
    queryKey: [queryNames.PurchaseOperationsById],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
      columnParams: PurchaseOperationCols,
      additionalSearchParams: `?id=${id}`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const onClose = () => {
    setShow(false);
  };

  console.log(
    'dd',
    dataPurchaseOperation && Object.keys(dataPurchaseOperation?.data)
  );

  useEffect(() => {
    if (
      dataPurchaseOperation &&
      dataPurchaseOperation?.data &&
      headers &&
      headers?.data
    ) {
      form.setFieldsValue(dataPurchaseOperation.data);
    }
  }, [form, dataPurchaseOperation, headers]);

  return (
    <Drawer
      width={window.innerWidth < 700 ? '100%' : '700px'}
      title="Purchase operation details"
      placement="right"
      onClose={onClose}
      open={show}
    >
      {!isLoadingPurchaseOperation &&
      headers &&
      headers?.data &&
      dataPurchaseOperation?.data ? (
        <Form
          className={styles.form}
          layout="vertical"
          form={form}
          autoComplete="off"
          disabled
        >
          {Object.keys(dataPurchaseOperation?.data).map((k, i) => (
            <ItemsGenerator
              key={k + i}
              item={{ [k]: dataPurchaseOperation?.data[k] } as ItemT}
              headers={headers}
              currencyCode={dataPurchaseOperation?.data?.currency?.code}
            />
          ))}
        </Form>
      ) : (
        <Loader />
      )}
    </Drawer>
  );
};
