import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Table from '../Table';
import { RequestsTableT } from './types';
import styles from './styles.module.scss';
import { APP_CONSTANS } from 'shared/constants';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import { Button, message } from 'antd';
import { DrawerRequest } from './components/DrawerRequest';
import { useNavigate, useParams } from 'react-router-dom';
import { APP_PATH } from 'features/Routes/pathes';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import {
  userTableCols,
  jobTableCols,
  jobReqCols,
  userReqCols,
} from './columns';
import { isGuid } from 'shared/utils/isGuid';

export const RequestsTable: FunctionComponent<RequestsTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'requestOne',
  defaultSorter = null,
  defaultFilters = null,
  defaultTableFilters = null,
  objectType,
  ...rest
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const [currentReq, setCurrentReq] = useState<any>(null);
  const [requestId, setRequestId] = useState<string | null>(null);
  const navigate = useNavigate();
  const params = useParams();
  let { id } = params;
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;
  const currentURL = window.location.pathname;

  const {
    isLoading: isLoadingRequest,
    isError: isErrorRequest,
    data: dataRequest,
    refetch: refetchRequest,
  } = useQuery({
    queryKey: [
      objectType && objectType.includes('fulfilment')
        ? queryNames.FULFILMENT_REQUESTS_GET_BY_ID
        : queryNames.USER_getUrserRequestById,
      requestId,
    ],
    meta: {
      token,
      getAllData,
      columnParams:
        objectType && objectType.includes('fulfilment')
          ? jobReqCols
          : userReqCols,
      additionalSearchParams: `?id=${requestId}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!requestId,
  });

  useEffect(() => {
    if (
      (currentURL.includes(APP_PATH.USERS_REQUESTS) ||
        currentURL.includes(APP_PATH.FULFILMENT_REQUESTS)) &&
      id &&
      isGuid.test(id)
    ) {
      setRequestId(id);
      if (!open) {
        showDrawer();
      }
    }
  }, [currentURL, id, open]);

  const COLUMNS = objectType?.includes('fulfilment')
    ? jobTableCols
    : userTableCols;

  const showDrawer = (e?: any) => {
    e && e.stopPropagation();
    setOpen(true);
  };

  const renderRequestModal = useCallback((value: string) => {
    return (
      <p className={styles.requestTitle} data-style="job_title">
        {value.includes('_') ? value.replace('_', ' ') : value}
      </p>
    );
  }, []);

  const renderRequestStatus = useCallback((value: string, record: any) => {
    const statusName = record?.['state.name'];
    return (
      <span className={styles.requestStatus}>
        <span
          className={styles.iconStatus}
          data-status={`status__${APP_CONSTANS.REQUESTS_STATUSES_BY_GUID[value]}`}
        ></span>
        {statusName}
      </span>
    );
  }, []);

  const renderRequestDrawer = useCallback(
    (value: any, record: any) => {
      return (
        <Button
          type="link"
          className={styles.drawerLink}
          onClick={(e) => {
            showDrawer(e);
            if (
              currentURL.includes(APP_PATH.USERS_REQUESTS) ||
              currentURL.includes(APP_PATH.FULFILMENT_REQUESTS)
            ) {
              navigate(`${record.id}`);
            } else {
              setRequestId(record.id);
            }
          }}
        >
          <span>{value}</span>
        </Button>
      );
    },
    [currentURL, navigate]
  );

  const renderData = (value: string, record: any) => {
    const userId = record?.['user_id'];
    const orderId = record?.['fulfilment.order.id'];
    return [
      {
        name: 'fulfilment.code',
        action: renderRequestDrawer(value, record),
      },
      {
        name: 'code',
        action: renderRequestDrawer(value, record),
      },
      {
        name: 'fulfilment.order.code',
        action: renderLink(value, `/orders/all/${orderId}`),
      },
      {
        name: 'request_type',
        action: renderRequestModal(value),
      },
      {
        name: 'state.id',
        action: renderRequestStatus(value, record),
      },
      {
        name: 'user.first_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'user.last_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
    ];
  };

  useEffect(() => {
    if (!isLoadingRequest && dataRequest && !isErrorRequest) {
      if (
        ((currentURL.includes(APP_PATH.USERS_REQUESTS) ||
          currentURL.includes(APP_PATH.FULFILMENT_REQUESTS)) &&
          id &&
          isGuid.test(id)) ||
        !currentURL.includes(APP_PATH.USERS_REQUESTS)
      ) {
        setCurrentReq(dataRequest?.data);
      }
    }
    if (
      (currentURL.includes(APP_PATH.USERS_REQUESTS) ||
        currentURL.includes(APP_PATH.FULFILMENT_REQUESTS)) &&
      id &&
      !isGuid.test(id)
    ) {
      message.error(`Not avialable request by id - ${id}`);
      navigate(`/${APP_PATH.USERS_REQUESTS}`, { replace: true });
      // id = undefined;
    }
  }, [
    isLoadingRequest,
    dataRequest,
    isErrorRequest,
    id,
    open,
    currentURL,
    navigate,
  ]);

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        headerName={headerName}
        renderDataColumn={renderData}
        actionsColumn={actionsColumn}
        parametrForSavingSettings={tableName}
        defaultEnabledSettings={defaultEnabledSettings}
        hideInSetting={hideInSetting}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
        defaultSorter={defaultSorter}
        defaultFilters={defaultFilters}
        defaultTableFilters={defaultTableFilters}
        quantityTitleText="requests"
        columnsForRequest={COLUMNS}
        {...rest}
      />

      {currentReq && (
        <DrawerRequest
          open={open}
          setOpen={setOpen}
          setRefetchData={setRefetchData}
          req={currentReq}
          setCurrentReq={setCurrentReq}
          setRequestId={setRequestId}
          refetchContent={refetchRequest}
          objectId={currentReq.id}
          objectType={objectType}
          relatives={
            objectType?.includes('fulfilment')
              ? [currentReq?.fulfilment?.id]
              : [currentReq?.user?.id]
          }
        />
      )}
    </>
  );
};
