import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Form, InputNumber, message } from 'antd';
import { usePostData } from 'api/usePostData';
import clsx from 'clsx';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import globalStyles from '../../../shared/styles/styles.module.scss';
import { ModalInvoicePayedT } from './types';
import { queryNames } from 'api/queryNames';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { moneyFromCurrency } from 'shared/utils/DataInTables/renderMoneyFromCurrency';

export const ModalInvoicePayRefund: FunctionComponent<ModalInvoicePayedT> = ({
  showModal,
  setShowModal,
  id,
  code,
  currencyCode,
  total,
  payedSum,
  isRefund,
}) => {
  const [setPaidForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const [disabledPayout, setDisabledPayout] = useState<boolean>(false);
  const queryClient: any = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      message.success('Invoice refunded');
      queryClient.invalidateQueries([queryNames.INVOICES_BY_ID, id]);
      queryClient.invalidateQueries([queryNames.INVOICE_LINES, id]);
      closeModal();
    },
  });

  const handleFormChange = useCallback(() => {
    const hasErrors = setPaidForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setDisabledPayout(hasErrors);
  }, [setPaidForm]);

  useEffect(() => {
    if (setPaidForm) {
      let calculatedTotal = total;
      if (payedSum) {
        calculatedTotal -= payedSum;
      }
      setPaidForm.setFieldValue(
        'amount',
        isRefund ? payedSum : calculatedTotal
      );
      setPaidForm.validateFields();
    }
  }, [setPaidForm, total, payedSum, handleFormChange, isRefund]);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleFinish = (allFields: any) => {
    allFields.invoice_id = id;
    if (!allFields?.amount) {
      message.error('Paid sum - undefined');
      return;
    }
    if (!allFields?.invoice_id) {
      message.error('Invoice ID - undefined');
      return;
    }

    mutate({
      data: allFields,
      token,
      otherProps: isRefund
        ? queryNames.INVOICE_REFUND
        : queryNames.INVOICE_CHARGE,
    });
  };

  return (
    <ModalWindow
      title={`Invoice ${code}`}
      visible={showModal}
      width={window.innerWidth > 700 ? '600px' : '95vw'}
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      <Form
        form={setPaidForm}
        onFinish={handleFinish}
        onFieldsChange={handleFormChange}
        className={styles.form}
      >
        <Form.Item
          name="amount"
          label="Paid sum"
          rules={[
            {
              type: 'number',
              min: 0,
              required: true,
              message: 'A value must be entered',
            },
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value)) {
                  return Promise.reject('Value has to be a number');
                }
                if (typeof value !== 'number') {
                  return Promise.reject('Value has to be a number');
                }
                if (value < 0) {
                  return Promise.reject("Value can't be less than 0");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            min={0}
            max={isRefund ? payedSum : Infinity}
            formatter={(valueFormatter: any) =>
              renderMoneyWithCurrency(valueFormatter, currencyCode)
            }
            parser={(valueParser: any) => {
              const usd = moneyFromCurrency(valueParser);
              let roundedUSD = Math.round(Number(usd) * 1000);
              return roundedUSD;
            }}
            precision={2}
            step={1000}
          />
        </Form.Item>

        <div className={styles.actions}>
          <Button
            className={clsx(globalStyles.btn, globalStyles.secondary__btn)}
            style={{ marginRight: '8px' }}
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            type="primary"
            onClick={() => setPaidForm.submit()}
            disabled={disabledPayout}
          >
            {isRefund ? 'Refund' : 'Set paid'}
          </Button>
        </div>
      </Form>
    </ModalWindow>
  );
};
