import { ACTION_TYPES as Auth } from '../auth/types';
import { ACTION_TYPES as Invoices } from '../invoices/types';
import { ACTION_TYPES as Users } from '../users/types';
import { APP_CONSTANS } from '../../shared/constants/index';
import { set_is_authorizated, setToken, setAuthToken } from '../auth';
import { ACTION_TYPES as Accounts } from '../accounts/types';
import { ACTION_TYPES as REFERENCE } from '../reference/types';
import { ACTION_TYPES as JOBS } from '../jobs/types';
import { getUserAddress, getUserPrefs, getUserById } from '../users';
import { getListOfAccountPrefs } from '../accounts';

const httpGlobalFetch = (store) => (next) => (action) => {
  const urlV2 = APP_CONSTANS.BASE_API_URL_V2;

  switch (action.type) {
    case Auth.GET_TOKEN: {
      const urlSearch = new URLSearchParams();
      urlSearch.append('username', action.payload.username);
      urlSearch.append('password', action.payload.password);

      const myHeaders = new Headers();
      myHeaders.append('accept', 'application/json');
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      return next({
        type: Auth.GET_TOKEN,
        payload: fetch(urlV2 + '/auth/login', {
          method: 'POST',
          headers: myHeaders,
          body: urlSearch,
        })
          .then((response) => response.json())
          .then((res) => {
            if (res?.data?.access_token) {
              localStorage.setItem(
                APP_CONSTANS.LS_TOKEN,
                res.data.access_token
              );
              store.dispatch(setToken(res.data.access_token));
              store.dispatch(setAuthToken(res));
              store.dispatch(set_is_authorizated(true));
            }
          })
          .catch((e) => {
            console.log(e.message);
          }),
      });
    }

    case Invoices.GET_INVOICES: {
      const token = localStorage.getItem('FF_TOKEN');
      const req_params = action.payload;
      const order = req_params.order ? `order=${req_params.order}` : '';
      const like = req_params.search_like
        ? `like=${req_params.search_like}`
        : '';
      const relations = req_params.relations
        ? `relations=${req_params.relations}`
        : '';
      const limit = req_params.limit ? `limit=${req_params.limit}` : '';
      const offset = `offset=${req_params.offset}`;
      const url =
        urlV2 + `/invoices?${relations}&${like}&${order}&${limit}&${offset}`;

      return next({
        type: Invoices.GET_INVOICES,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let prev_data = req_params.is_force_update
              ? []
              : [...store.getState().invoices.invoices];

            return prev_data.concat(data.data);
          }),
      });
    }

    case Invoices.GET_NOT_INVOICE_ORDERS: {
      const token = localStorage.getItem('FF_TOKEN');
      const req_params = action.payload;
      const order = req_params.order ? `order=${req_params.order}` : '';
      const like = req_params.search_like
        ? `like=${req_params.search_like}`
        : '';
      const relations = req_params.relations
        ? `relations=${relations.join('&relations=')}`
        : '';
      const limit = req_params.limit ? `limit=${req_params.limit}` : '';
      const offset = `offset=${req_params.offset}`;
      const url =
        urlV2 +
        `/orders?without_invoice=true&${like}&${order}&${limit}&${offset}`; //${relations}

      return next({
        type: Invoices.GET_NOT_INVOICE_ORDERS,
        payload: fetch(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let prev_data = req_params.is_force_update
              ? []
              : [...store.getState().invoices.notInvoicedOrders];

            return prev_data.concat(data.data);
          }),
      });
    }

    case Invoices.SET_INVOICE_MEMO: {
      const token = localStorage.getItem('FF_TOKEN');
      const changed_data = action.payload;

      const url = urlV2 + `/invoices`;

      return next({
        type: Invoices.SET_INVOICE_MEMO,
        payload: fetch(url, {
          method: 'PUT',
          body: JSON.stringify(changed_data),
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((respData) => respData),
      });
    }

    case Accounts.GET_ACCOUNTS: {
      const data = action.payload;
      const relations = data?.relations;

      return next({
        type: Accounts.GET_ACCOUNTS,
        payload: fetch(
          `${urlV2}/accounts?relations=${
            relations ? relations.join('&relations=') : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Accounts.GET_ACCOUNT_BY_ID: {
      const data = action.payload;
      const { id } = data;
      if (!id) return new Error({ message: 'Account ID undefined' });
      const relations = data.hasOwnProperty('relations') ? data.relations : [];
      return next({
        type: Accounts.GET_ACCOUNT_BY_ID,
        payload: fetch(
          `${urlV2}/accounts/get?id=${id}&relations=${relations.join(
            '&relations='
          )}`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Accounts.GET_LIST_OF_ACCOUNTS: {
      const data = action.payload;
      const userId = data.userId ? data.userId : null;
      return next({
        type: Accounts.GET_LIST_OF_ACCOUNTS,
        payload: fetch(`${urlV2}/accounts${userId && '?user_ids=' + userId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Accounts.GET_LIST_OF_ACCOUNT_PREF: {
      const data = action.payload;
      const accountId = data.accountId ? data.accountId : null;
      return next({
        type: Accounts.GET_LIST_OF_ACCOUNT_PREF,
        payload: fetch(
          `${urlV2}/account_preferences${
            accountId && '?account_id=' + accountId
          }`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.GET_USERS: {
      const data = action.payload;
      const accountIds = data.hasOwnProperty('account_ids')
        ? data.account_ids
        : [];
      const relations = data.hasOwnProperty('relations') ? data.relations : [];
      const url = `${
        '?account_ids=' +
        accountIds.join('&account_ids=') +
        '&relations=' +
        relations.join('&relations=')
      }`;
      return next({
        type: Users.GET_USERS,
        payload: fetch(`${urlV2}/users${url}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.GET_USERS_BY_ID: {
      const data = action.payload;
      const { id } = data;
      if (!id) return new Error({ message: 'User ID undefined' });
      const relations = data.hasOwnProperty('relations') ? data.relations : [];
      return next({
        type: Users.GET_USERS_BY_ID,
        payload: fetch(
          `${urlV2}/users/get?id=${id}${
            '&relations=' + relations.join('&relations=')
          }`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.UPDATE_USER: {
      const data = action.payload;
      return next({
        type: Users.UPDATE_USER,
        payload: fetch(`${urlV2}/users`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(
              () =>
                store.dispatch(
                  getUserById({ id: data.id, relations: ['Industry'] })
                ),
              10
            );
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.SOFT_DELETE_USER: {
      const data = action.payload;
      return next({
        type: Users.SOFT_DELETE_USER,
        payload: fetch(`${urlV2}/users/soft`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(
              () =>
                store.dispatch(
                  getUserById({ id: data.id, relations: ['Industry'] })
                ),
              10
            );
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.HARD_DELETE_USER: {
      const data = action.payload;
      return next({
        type: Users.HARD_DELETE_USER,
        payload: fetch(`${urlV2}/users/hard`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(
              () =>
                store.dispatch(
                  getUserById({ id: data.id, relations: ['Industry'] })
                ),
              10
            );
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.GET_USERS_PREFS: {
      const userId = action.payload;
      return next({
        type: Users.GET_USERS_PREFS,
        payload: fetch(`${urlV2}/user_preferences?user_id=${userId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case Users.GET_USER_ADDRESS_BY_USER_ID: {
      const userId = action.payload;
      return next({
        type: Users.GET_USER_ADDRESS_BY_USER_ID,
        payload: fetch(`${urlV2}/user_addresses?user_ids=${userId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case Users.GET_USERS_REQUESTS: {
      const data = action.payload;
      const user_ids = data && data.user_ids;
      const relations =
        data && data.hasOwnProperty('relations') && data.relations;
      return next({
        type: Users.GET_USERS_REQUESTS,
        payload: fetch(
          `${urlV2}/user_requests?user_ids=${user_ids}${
            relations && '&relations=' + relations.join('&relations=')
          }`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.GET_USERS_BY_ACCOUNT_ID: {
      const data = action.payload;
      const id = data.accountId;
      return next({
        type: Users.GET_USERS_BY_ACCOUNT_ID,
        payload: fetch(`${urlV2}/users${id && '?account_ids=' + id}`, {
          headers: { Authorization: `Bearer ${store.getState().auth.token}` },
        })
          .then((response) => response.json())
          .then((respData) => respData),
      });
    }

    case Users.POST_NEW_USER_ADDRESS: {
      const data = action.payload;
      return next({
        type: Users.POST_NEW_USER_ADDRESS,
        payload: fetch(`${urlV2}/user_addresses`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserAddress(data.user_id)), 10);
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.POST_USER_PREFS: {
      const data = action.payload;
      return next({
        type: Users.POST_USER_PREFS,
        payload: fetch(`${urlV2}/user_preferences/bulk`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserPrefs(data[0].user_id)), 10);
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.PUT_USER_ADDRESS: {
      const data = action.payload;
      return next({
        type: Users.PUT_USER_ADDRESS,
        payload: fetch(`${urlV2}/user_addresses`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(() => store.dispatch(getUserAddress(data.user_id)), 10);
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.SOFT_DELETE_USER_ADDRESS: {
      const data = action.payload;
      return next({
        type: Users.SOFT_DELETE_USER_ADDRESS,
        payload: fetch(`${urlV2}/user_addresses/soft`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(
              () => store.dispatch(getUserAddress(data.user_ids[0])),
              10
            );
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.HARD_DELETE_USER_ADDRESS: {
      const data = action.payload;
      return next({
        type: Users.HARD_DELETE_USER_ADDRESS,
        payload: fetch(`${urlV2}/user_addresses/hard`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(data),
        })
          .then((response) => {
            setTimeout(
              () => store.dispatch(getUserAddress(data.user_ids[0])),
              10
            );
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case Users.UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA: {
      const data = action.payload;
      let method = '';
      let url = '';
      let body = {};
      let accountId = '';
      let userId = store.getState().users.user?.data?.id;

      let firstData = !Array.isArray(data) ? data : data[0];

      for (let key in firstData) {
        if (key) {
          // eslint-disable-next-line
          method = key.split('%')[0];
          // eslint-disable-next-line
          url = key.split('%').pop().split('&')[0];

          if (key.includes('preferencesId')) {
            if (!Array.isArray(data)) {
              // eslint-disable-next-line
              const preferenceId = key
                .split('preferencesId*')
                .pop()
                .split('*')[0];

              body = {
                preferences: [
                  {
                    preference_id: preferenceId,
                    value: data[key],
                  },
                ],
              };
            } else {
              const preferences = [];
              data.forEach((pref) => {
                for (let keyPref in pref) {
                  if (keyPref) {
                    // eslint-disable-next-line
                    const preferenceId = keyPref
                      .split('preferencesId*')
                      .pop()
                      .split('*')[0];

                    preferences.push({
                      preference_id: preferenceId,
                      value: pref[keyPref],
                    });
                  }
                }
              });
              body = { preferences };
            }

            if (key.includes('user')) {
              body.user_id = userId;
            }

            if (key.includes('account')) {
              const listOfAccounts =
                store.getState().accounts.listOfAccounts?.data;
              accountId =
                listOfAccounts.length === 1 ? listOfAccounts[0].id : '';
              body.account_id = accountId;
            }
          } else if (key.split('&').length === 2) {
            if (!Array.isArray(data)) {
              const dataKey = key.split('&').pop();
              if (dataKey) body[dataKey] = data[key];
            } else {
              Array.isArray(data) &&
                data.length &&
                data.map((obj) => { // eslint-disable-line
                  for (let objKey in obj) {
                    if (objKey) {
                      const dataKey = objKey.split('&').pop();
                      if (dataKey) body[dataKey] = obj[objKey];
                    }
                  }
                });
            }
          }
        }
      }

      return next({
        type: Users.UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA,
        payload: fetch(`${urlV2}/${url}`, {
          method: method,
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
          body: JSON.stringify(body),
        })
          .then((response) => {
            for (let key in data) {
              if (key) {
                if (key.includes('preferencesId') && key.includes('user')) {
                  setTimeout(() => store.dispatch(getUserPrefs(userId)), 10);
                }
                if (key.includes('preferencesId') && key.includes('account')) {
                  setTimeout(
                    () =>
                      store.dispatch(
                        getListOfAccountPrefs({ accountId: accountId })
                      ),
                    10
                  );
                }
                if (key.includes('users')) {
                  setTimeout(
                    () =>
                      store.dispatch(
                        getUserById({ id: userId, relations: ['Industry'] })
                      ),
                    10
                  );
                }
              }
            }
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case JOBS.GET_FULFILMENTS: {
      const data = action.payload;
      const user_ids = data ? data.user_ids : null;
      const relations = data ? data.relations : null;
      return next({
        type: JOBS.GET_FULFILMENTS,
        payload: fetch(
          `${urlV2}/fulfilments${data ? '?' : ''}${
            user_ids ? 'user_ids=' + user_ids : ''
          }${relations && '&relations=' + relations.join('&relations=')}`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case JOBS.GET_FULFILMENTS_FOR_ACCOUNTS: {
      const data = action.payload;
      const user_ids = data && data.user_ids;
      return next({
        type: JOBS.GET_FULFILMENTS_FOR_ACCOUNTS,
        payload: fetch(
          `${urlV2}/fulfilments${data ? '?' : ''}${
            user_ids ? 'user_ids=' + user_ids.join('&user_ids=') : ''
          }`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case JOBS.GET_ORDERS: {
      const data = action.payload;
      const user_ids = data && data.user_ids;
      const relations = data ? data.relations : null;
      return next({
        type: JOBS.GET_ORDERS,
        payload: fetch(
          `${urlV2}/orders${data ? '?' : ''}${
            user_ids ? 'user_ids=' + user_ids.join('&user_ids=') : ''
          }${relations && '&relations=' + relations.join('&relations=')}`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((respData) => {
            return respData;
          }),
      });
    }

    case REFERENCE.GET_COUNTRIES: {
      return next({
        type: REFERENCE.GET_COUNTRIES,
        payload: fetch(`${urlV2}/countries`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_COUNTRY_BY_ID: {
      const countryId = action.payload;
      return next({
        type: REFERENCE.GET_COUNTRY_BY_ID,
        payload: fetch(`${urlV2}/countries/get?id=${countryId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_COUNTRY_REGIONS: {
      return next({
        type: REFERENCE.GET_COUNTRY_REGIONS,
        payload: fetch(`${urlV2}/country_regions`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_COUNTRY_REGIONS_BY_ID: {
      const regionId = action.payload;
      return next({
        type: REFERENCE.GET_COUNTRY_REGIONS_BY_ID,
        payload: fetch(`${urlV2}/country_regions/get?id=${regionId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_GEONAMES: {
      return next({
        type: REFERENCE.GET_GEONAMES,
        payload: fetch(`${urlV2}/geonames?limit=100`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_GEONAMES_BY_ID: {
      const geonameId = action.payload;
      return next({
        type: REFERENCE.GET_GEONAMES_BY_ID,
        payload: fetch(`${urlV2}/geonames/get?id=${geonameId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_TIMEZONES: {
      return next({
        type: REFERENCE.GET_TIMEZONES,
        payload: fetch(`${urlV2}/country_timezones`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_TIMEZONES_BY_ID: {
      const timezoneId = action.payload;
      return next({
        type: REFERENCE.GET_TIMEZONES_BY_ID,
        payload: fetch(`${urlV2}/country_timezones/get?id=${timezoneId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_CURRENCIES: {
      return next({
        type: REFERENCE.GET_CURRENCIES,
        payload: fetch(`${urlV2}/currencies`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_CURRENCY_BY_ID: {
      const currencyId = action.payload;
      return next({
        type: REFERENCE.GET_CURRENCY_BY_ID,
        payload: fetch(`${urlV2}/currencies/get?id=${currencyId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_ORDER_STATES: {
      return next({
        type: REFERENCE.GET_ORDER_STATES,
        payload: fetch(`${urlV2}/order_states`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_ORDER_STATES_BY_ID: {
      const orderStateId = action.payload;
      return next({
        type: REFERENCE.GET_ORDER_STATES_BY_ID,
        payload: fetch(`${urlV2}/order_states/get?id=${orderStateId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_FULFILMENT_STATES: {
      return next({
        type: REFERENCE.GET_FULFILMENT_STATES,
        payload: fetch(`${urlV2}/fulfilment_states`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_FULFILMENT_STATES_BY_ID: {
      const jobStateId = action.payload;
      return next({
        type: REFERENCE.GET_FULFILMENT_STATES_BY_ID,
        payload: fetch(`${urlV2}/fulfilment_states/get?id=${jobStateId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_ORDER_PAYMENT_STATES: {
      return next({
        type: REFERENCE.GET_ORDER_PAYMENT_STATES,
        payload: fetch(`${urlV2}/order_payment_states`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_ORDER_PAYMENT_STATES_BY_ID: {
      const orderPaymentStateId = action.payload;
      return next({
        type: REFERENCE.GET_ORDER_PAYMENT_STATES_BY_ID,
        payload: fetch(
          `${urlV2}/order_payment_states/get?id=${orderPaymentStateId}`,
          {
            headers: {
              Authorization: `Bearer ${store.getState().auth.token}`,
            },
          }
        )
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_CONTENT_STATES: {
      return next({
        type: REFERENCE.GET_CONTENT_STATES,
        payload: fetch(`${urlV2}/content_states`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_CONTENT_STATES_BY_ID: {
      const contentStateId = action.payload;
      return next({
        type: REFERENCE.GET_CONTENT_STATES_BY_ID,
        payload: fetch(`${urlV2}/content_states/get?id=${contentStateId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_REQUEST_STATES: {
      return next({
        type: REFERENCE.GET_REQUEST_STATES,
        payload: fetch(`${urlV2}/request_states`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    case REFERENCE.GET_REQUEST_STATES_BY_ID: {
      const requestStateId = action.payload;
      return next({
        type: REFERENCE.GET_REQUEST_STATES_BY_ID,
        payload: fetch(`${urlV2}/request_states/get?id=${requestStateId}`, {
          headers: {
            Authorization: `Bearer ${store.getState().auth.token}`,
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            return data;
          }),
      });
    }

    default:
      return next(action);
  }
};

export default httpGlobalFetch;
