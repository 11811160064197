import { FC, ReactElement } from 'react';
import { MapMarkerClusterer } from '../MapMarkerClusterer';
import { MapPhotographerMarkerProps } from '../MapPhotographerMarker';

const CLUSTERER_OPTIONS = {
  // eslint-disable-next-line no-undef
  imagePath: `${process.env.PUBLIC_URL}/map-clusters/photographer-cluster`,
  minimumClusterSize: 2,
  maxZoom: 21,
};

interface MapPhotographerMarkerClustererProps {
  children:
    | ReactElement<MapPhotographerMarkerProps>
    | ReactElement<MapPhotographerMarkerProps>[];
}

export const MapPhotographerMarkerClusterer: FC<
  MapPhotographerMarkerClustererProps
> = ({ children }) => (
  <MapMarkerClusterer options={CLUSTERER_OPTIONS}>
    {children}
  </MapMarkerClusterer>
);
