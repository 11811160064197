import { Tooltip } from 'antd';
import clsx from 'clsx';
import { UserType } from 'components/pages/main/users_section/Users/types';
import { FunctionComponent } from 'react';
import Table from '../Table';
import styles from './styles.module.scss';
import { UsersTableT } from './types';
import { APP_CONSTANS } from 'shared/constants';
import { columns } from './columns';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';

export const UsersTable: FunctionComponent<UsersTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar = null,
  actionsColumn,
  tableName,
  tableClassName,
  hideInSetting,
  type = UserType.ALL,
  getFilters,
  openLinkInNewTab,
  ...rest
}) => {
  const renderTooltip = (value: string) => {
    return (
      <Tooltip overlayClassName={styles.tooltipEmail} title={value}>
        <div className={styles.tooltipBlock}>
          <span className={styles.email}>{value}</span>
        </div>
      </Tooltip>
    );
  };

  const renderCapitalize = (value: string) => {
    return <p className={styles.capitalizeText}>{value}</p>;
  };

  const renderTrait = (value: string) => {
    return (
      <p className={styles.capitalizeText}>
        {APP_CONSTANS.TRAIT_LABEL_BY_VALUE[value]}
      </p>
    );
  };

  const renderData = (value: string, record: any) => {
    const accountId = record?.['account.id'];
    const userId = record?.id;
    const followInstructionsTrait =
      record?.['preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2'];
    const reliableTrait =
      record?.['preferences.a9176838-2960-4629-b45a-ea6434b79eaa'];
    const workQualityTrait =
      record?.['preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c'];

    return [
      {
        name: 'email',
        action: renderLink(
          value,
          `/users/${type}/${userId}`,
          null,
          openLinkInNewTab
        ),
      },
      {
        name: 'phone',
        action: renderTooltip(value),
      },
      {
        name: 'type',
        action: renderCapitalize(value),
      },
      {
        name: 'account.name',
        action: renderLink(
          value,
          `/accounts/all/${accountId}`,
          null,
          openLinkInNewTab
        ),
      },
      {
        name: 'account.id',
        action: renderLink(
          value,
          `/accounts/all/${accountId}`,
          null,
          openLinkInNewTab
        ),
      },
      {
        name: 'account.code',
        action: renderLink(
          value,
          `/accounts/all/${accountId}`,
          null,
          openLinkInNewTab
        ),
      },
      {
        name: 'preferences.a4d03c9b-550d-404f-ae59-02fe0872cec9',
        action: renderLink(
          value,
          `/accounts/all/${accountId}`,
          null,
          openLinkInNewTab
        ),
      },
      {
        name: 'preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2',
        action: renderTrait(followInstructionsTrait),
      },
      {
        name: 'preferences.a9176838-2960-4629-b45a-ea6434b79eaa',
        action: renderTrait(reliableTrait),
      },
      {
        name: 'preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c',
        action: renderTrait(workQualityTrait),
      },
    ];
  };

  return (
    <Table
      toolbar={toolbar}
      rowSelection={null}
      rowSelectionType={null}
      customClassName={clsx('tabsAndSettings')}
      tableIndicatorClassName={clsx(tableClassName)}
      queryKey={queryKey}
      meta={meta}
      headerName={headerName}
      renderDataColumn={renderData}
      actionsColumn={actionsColumn}
      defaultEnabledSettings={defaultEnabledSettings}
      parametrForSavingSettings={tableName}
      hideInSetting={hideInSetting}
      getFilters={getFilters}
      quantityTitleText="users"
      columnsForRequest={columns}
      defaultSorter={{
        field: 'usr.created_at',
        order: 'descend',
      }}
      {...rest}
    />
  );
};
