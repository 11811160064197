import { Address } from 'types/global';

export const getCenterAndRadiusAdditionalSearchParams = ({
  center,
  radius,
}: {
  center?: Address;
  radius?: number;
}) => {
  const data = {
    ...(center ? { location_lat: `${center.lat}` } : {}),
    ...(center ? { location_lng: `${center.lng}` } : {}),
    ...(radius ? { radius: `${radius}` } : {}),
  };
  const searchParams = new URLSearchParams(data);
  return searchParams ? `?${searchParams}` : '';
};
