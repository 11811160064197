export const renderMoneyWithCurrency = (
  value: string | number = 0,
  currency?: string
) => {
  if (!value || value === '-') return '0';

  if (currency) {
    let currencyValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(Number(value) / 1000);
    return currencyValue;
  }

  const valueFixed = (Number(value) / 1000).toFixed(2);
  return value !== 0 ? `${valueFixed} (no currency)` : '0';

  //intl from $ to number
  // or formatter InputNumber
};
