import React from 'react';
import styles from './styles.module.scss';
import { TabDescriptionT } from './types';
import { OrderJobInstructions } from 'components/ui_components/OrderJobInstructions';
import { OrderJobComplications } from '../OrderJobComplications';
import { OrderJobMemo } from '../OrderJobMemo';
import { OrderJobLocation } from '../OrderJobLocation';

export const TabOrderJobDescription: React.FC<TabDescriptionT> = ({
  objectType,
  isJob = true,
  isPurchase = false,
  isFormDisabled = true,
  currencyCode = '',
  complicationsByType,
  timezone,
  id = '',
}) => {
  return (
    <article className={styles.desc}>
      {!isPurchase && (
        <OrderJobComplications
          objectType={objectType || ''}
          isFormDisabled={isFormDisabled}
          isJob={isJob}
          currencyCode={currencyCode}
          complicationsByType={complicationsByType}
          timezone={timezone || ''}
        />
      )}
      {(objectType !== 'tmpl' || !objectType) && (
        <OrderJobMemo isFormDisabled={isFormDisabled} isPurchase={isPurchase} />
      )}
      {!isPurchase && (
        <>
          <OrderJobLocation
            isFormDisabled={isFormDisabled}
            isJob={isJob}
            id={id}
          />
          <OrderJobInstructions isFormDisabled={isFormDisabled} isJob={isJob} />
        </>
      )}
    </article>
  );
};
