import { message } from 'antd';
import { APP_CONSTANS } from '../constants';
import { signOutUser } from './api/signOutUser';

export const isSessionLife = () => {
  const delay =
    APP_CONSTANS.SESSION_LIFE_IN_MINUTES * APP_CONSTANS.ONE_MINUTE_IN_MS -
    APP_CONSTANS.ONE_MINUTE_IN_MS;

  let timerId = setTimeout(function tick() {
    const now = Date.now();
    const sessionExpTime = +(
      localStorage.getItem(APP_CONSTANS.LS_SESSION_EXP) || 0
    );
    if (sessionExpTime <= now + APP_CONSTANS.ONE_MINUTE_IN_MS) {
      message.config({
        maxCount: 1,
      });
      let isDestroy = false;
      // eslint-disable-next-line
      message.warning({
        key: now,
        content: 'Current session will expire after 1 minute',
        duration: 60,
        onClick: () => {
          message.destroy(now);
          isDestroy = true;
        },
        onClose: () => {
          if (isDestroy) return;
          clearTimeout(timerId);
          signOutUser();
        },
      });
    } else {
      timerId = setTimeout(
        tick,
        sessionExpTime > now + APP_CONSTANS.ONE_MINUTE_IN_MS * 2
          ? sessionExpTime - APP_CONSTANS.ONE_MINUTE_IN_MS
          : APP_CONSTANS.ONE_MINUTE_IN_MS
      );
    }
  }, delay);
};
