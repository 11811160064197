import Table from '../Table';
import { columns } from './columns';
import { PrefTableT } from './types';
import styles from './styles.module.scss';
import { useState } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { NestedTable } from './components/NestedTable';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { DrawerPaymentOperation } from './components/DrawerPurchaseOperation';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import { APP_CONSTANS } from 'shared/constants';
import { useLocation } from 'react-router-dom';
import generalStyles from 'shared/styles/styles.module.scss';

export const PaymentOperationsTable: React.FC<PrefTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'paymentOperationsTable',
  ...rest
}) => {
  const [show, setShow] = useState<any>(null);
  const [paymentOperationId, setPaymentOperationId] = useState<string>('');
  const { pathname } = useLocation();

  const openDrawer = (id: string) => {
    setPaymentOperationId(id);
    setShow(true);
  };

  const expandedRow = (record: any) => {
    const id = record?.id;
    const currency = record?.['currency.code'];
    return <NestedTable purchaseOperationId={id} currencyCode={currency} />;
  };

  const renderData = (value: any, record: any) => {
    const id = record?.id;
    const currency = record?.['currency.code'];
    const invoiceId = record?.['invoice.id'];
    const orderId = record?.['order.id'];
    const userId = record?.['user.id'];
    const accountId = record?.['account.id'];

    return [
      {
        name: 'id',
        action: (
          <p className={styles.link} onClick={() => openDrawer(id)}>
            {value}
          </p>
        ),
      },
      {
        name: 'payment_method.name',
        action: (
          <p className={styles.link} onClick={() => openDrawer(id)}>
            {value}
          </p>
        ),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'invoice.code',
        action:
          value && value?.length > 1 ? (
            renderLink(value, `/invoices/all/${invoiceId}`, pathname)
          ) : (
            <></>
          ),
      },
      {
        name: 'order.code',
        action:
          value && value?.length > 1 ? (
            renderLink(value, `/orders/all/${orderId}`, pathname)
          ) : (
            <></>
          ),
      },
      {
        name: 'user.email',
        action: renderLink(value, `/users/all/${userId}`, pathname),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/all/${accountId}`, pathname),
      },
      {
        name: 'account_id',
        action: renderLink(value, `/accounts/all/${accountId}`, pathname),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/all/${accountId}`, pathname),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/all/${accountId}`, pathname),
      },
      {
        name: 'payment_status_id',
        action: (
          <span
            className={generalStyles.payOperationsStatus}
            data-payoperationstatus-id={value}
          >
            {APP_CONSTANS.PAYMENT_OPERATIONS_STATUSES_BY_GUID[value]}
          </span>
        ),
      },
      {
        name: 'operation_type',
        action: <span className={styles.operationType}>{value}</span>,
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="operations"
        columnsForRequest={columns}
        expandedRowRender={(record: any) => expandedRow(record)}
        defaultSorter={{
          field: 'pay_oper.created_at',
          order: 'descend',
        }}
        expandIcon={({ expanded, onExpand, record }: any) =>
          expanded ? (
            <MinusOutlined
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          ) : (
            <PlusOutlined
              className={styles.expandIcon}
              onClick={(e) => onExpand(record, e)}
            />
          )
        }
        {...rest}
      />

      {show && (
        <DrawerPaymentOperation
          show={show}
          setShow={setShow}
          id={paymentOperationId}
        />
      )}
    </>
  );
};
