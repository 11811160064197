export const defaultEnabledSettings = {
  code: { order: 1 },
  title: { order: 2 },
  state_id: { order: 3 },
  payout: { order: 4 },
  total: { order: 5 },
  content_type: { order: 6 },
  'account.name': { order: 7 },
  'user.first_name': { order: 8 },
  'user.last_name': { order: 9 },
  created_at: { order: 10 },
  expired_at: { order: 11 },
};
