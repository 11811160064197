export const defaultSettings = {
  created_at: { order: 1 },
  title: { order: 2 },
  object: { order: 3 },
  domain: { order: 4 },
  command: { order: 5 },
  old_value: { order: 6 },
  new_value: { order: 7 },
  'actor.first_name': { order: 8 },
  'actor.last_name': { order: 9 },
};
