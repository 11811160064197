import { getTimeZoneName } from 'shared/utils/getTimezoneName';
import styles from './styles.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { tablesNames } from 'api/tablesNames';
import { Tooltip } from 'antd';
import { APP_CONSTANS } from 'shared/constants';

export const JobFooter: React.FC<any> = ({
  objectType,
  createdAt,
  expiredAt,
  updatedAt,
  publishedAt = null,
  completedAt,
  deletedAt,
  paidAt,
  refundedAt,
  purchaseStartDate,
  purchaseEndDate,
  timezone,
}) => {
  const queryClient = useQueryClient();

  const headers = queryClient.getQueryData([
    tablesNames.PurchasesHeader,
  ]) as any;

  return (
    <div className={styles.header__info} style={{ columnGap: '16px' }}>
      <div>
        <span className={styles.header__text}>Created at: </span>
        <span className={styles.header__value}>{createdAt}</span>
      </div>

      {objectType !== 'tmpl' &&
        objectType !== APP_CONSTANS.PURCHASE &&
        objectType !== APP_CONSTANS.INVOICE && (
          <div>
            <span className={styles.header__text}>Expired at: </span>
            <span className={styles.header__value}>{expiredAt}</span>
          </div>
        )}

      <div>
        <span className={styles.header__text}>Updated at: </span>
        <span className={styles.header__value}>
          {updatedAt ? updatedAt : '-'}
        </span>
      </div>

      {objectType === APP_CONSTANS.ORDER && (
        <div>
          <span className={styles.header__text}>Published at: </span>
          <span className={styles.header__value}>
            {publishedAt ? publishedAt : '-'}
          </span>
        </div>
      )}

      {objectType !== 'tmpl' &&
        objectType !== APP_CONSTANS.PURCHASE &&
        objectType !== APP_CONSTANS.INVOICE && (
          <div>
            <span className={styles.header__text}>Completed at: </span>
            <span className={styles.header__value}>
              {completedAt ? completedAt : '-'}
            </span>
          </div>
        )}

      <div>
        <span className={styles.header__text}>Deleted at: </span>
        <span className={styles.header__value}>
          {deletedAt ? deletedAt : '-'}
        </span>
      </div>

      {(objectType === APP_CONSTANS.ORDER ||
        objectType === APP_CONSTANS.PURCHASE ||
        objectType === APP_CONSTANS.INVOICE) && (
        <div>
          <span className={styles.header__text}>Paid at: </span>
          <span className={styles.header__value}>{paidAt ? paidAt : '-'}</span>
        </div>
      )}

      {objectType === APP_CONSTANS.INVOICE && (
        <div>
          <span className={styles.header__text}>Refunded at: </span>
          <span className={styles.header__value}>
            {refundedAt ? refundedAt : '-'}
          </span>
        </div>
      )}

      {objectType === APP_CONSTANS.PURCHASE && (
        <div>
          <Tooltip
            title={
              headers?.data?.columns.find(
                (e: { name: string }) => e.name === 'start_date'
              ).description
            }
          >
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'start_date'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {purchaseStartDate ? purchaseStartDate : '-'}
            </span>
          </Tooltip>
        </div>
      )}

      {objectType === APP_CONSTANS.PURCHASE && (
        <div>
          <Tooltip
            title={
              headers?.data?.columns.find(
                (e: { name: string }) => e.name === 'end_date'
              ).description
            }
          >
            <span className={styles.header__text}>
              {
                headers?.data?.columns.find(
                  (e: { name: string }) => e.name === 'end_date'
                ).title
              }
              :{' '}
            </span>
            <span className={styles.header__value}>
              {purchaseEndDate ? purchaseEndDate : '-'}
            </span>
          </Tooltip>
        </div>
      )}

      {objectType === APP_CONSTANS.ORDER && (
        <div>
          <span className={styles.header__text}>Timezone: </span>
          <span className={styles.header__value}>
            {timezone
              ? `
                  ${getTimeZoneName(undefined, timezone, undefined, 'long')}
                  (${getTimeZoneName(
                    undefined,
                    timezone,
                    undefined,
                    'longOffset'
                  )})
                `
              : '-'}
          </span>
        </div>
      )}
    </div>
  );
};
