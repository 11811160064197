export const APP_PATH = {
  ROOT: '/',

  FULFILMENTS: 'fulfilments/',
  FULFILMENTS_ALL: 'fulfilments/all',
  FULFILMENTS_TAB: 'fulfilments/:type',
  FULFILMENT: 'fulfilments/:type/:id',
  getFulfilment: (fulfilmentId: string) => `/fulfilments/all/${fulfilmentId}`,

  ACCOUNTS: 'accounts/',
  ACCOUNTS_ALL: 'accounts/all',
  ACCOUNTS_TAB: 'accounts/:type',
  ACCOUNT: 'accounts/:type/:id',
  PENDING_REGIST_INV: 'pending_register_invite',
  USERS_API_KEYS: 'apikeys',

  ORDERS: 'orders/',
  ORDERS_ALL: 'orders/all',
  ORDERS_TAB: 'orders/:type',
  ORDER: 'orders/:type/:id',
  ORDER_CREATE: 'orders/create',
  getOrder: (orderId: string) => `/orders/all/${orderId}`,

  TEMPLATES: 'templates/',
  TEMPLATE: 'templates/:id',

  INVOICES: 'invoices/',
  INVOICES_NOT_PAID: 'invoices/not_paid',
  INVOICES_TAB: 'invoices/:type',
  INVOICE: 'invoices/:type/:id',

  //Finances
  COMPLETED_JOBS: 'completed_jobs/',
  COMPLETED_JOB: 'completed_jobs/:type/:id',
  COMPLETED_JOBS_ALL: 'completed_jobs/all',
  COMPLETED_JOBS_TAB: 'completed_jobs/:type',

  PURCHASES: 'purchases/',
  PURCHASES_NOT_PAID: 'purchases/not_paid',
  PURCHASES_TAB: 'purchases/:type',
  PURCHASE: 'purchases/:type/:id',

  PURCHASE_TRANSACTIONS: 'purchase_transactions/',
  PURCHASE_OPERATIONS: 'purchase_operations/',
  PAYMENT_OPERATIONS: 'payment_operations/',
  PAYMENT_OPERATIONS_TAB: 'payment_operations/:type',
  PAYMENT_OPERATIONS_PAYMENT: 'payment_operations/payment',
  SUBSCRIPTIONS: 'subscriptions/',

  // System Settings
  INDUSTRIES: '/industries',
  INDUSTRIES_RELATIONS: '/industries/relations',
  COMPLICATIONS: '/complications',
  COMPLICATIONS_RELATIONS: '/complications/relations',
  HISTORY: 'history/',
  PREFERENCES: 'preferences',
  PREFERENCES_SYSTEM: 'preferences/system',
  PREFERENCES_TAB: 'preferences/:type',
  PREFERENCES_OPTIONS: 'preference_options',
  MANAGE_SYSTEM: 'manage_system/',

  // References
  COUNTRIES: 'countries/',
  COUNTRY: 'countries/:id',

  REGIONS: 'country_regions/',
  REGION: 'country_regions/:id',
  REGION_BY_COUNTRY_ID: 'country_regions&country_ids=:id',

  GEONAMES: 'geonames/',
  GEONAME: 'geonames/:id',
  GEONAME_BY_COUNTRY_ID: 'geonames&country_ids=:id',

  INVOICE_STATES: 'invoice_states/',
  INVOICE_STATE: 'invoice_states/:id',

  TIMEZONES: 'country_timezones',
  TIMEZONE: 'country_timezones/:id',
  TIMEZONE_BY_COUNTRY_ID: 'country_timezones&country_ids=:id',

  CURRENCIES: 'currencies/',
  CURRENCY: 'currencies/:id',

  ORDER_STATES: 'order_states/',
  ORDER_STATE: 'order_states/:id',

  JOB_STATES: 'fulfilment_states/',
  JOB_STATE: 'fulfilment_states/:id',

  CONTENT_STATES: 'content_states/',
  CONTENT_STATE: 'content_states/:id',

  REQUEST_STATES: 'request_states/',
  REQUEST_STATE: 'request_states/:id',

  PAYMENT_STATES: 'payment_statuses/',
  PAYMENT_STATE: 'payment_statuses/:id',

  PAYMENT_METHODS: 'payment_methods/',
  PAYMENT_METHOD: 'payment_methods/:id',

  PAYMENT_PROVIDERS: 'payment_providers/',
  PAYMENT_PROVIDER: 'payment_providers/:id',

  PURCHASE_STATES: 'purchase_states/',
  PURCHASE_STATE: 'purchase_states/:id',

  PURCHASE_PROVIDERS: 'purchase_providers/',
  PURCHASE_PROVIDER: 'purchase_providers/:id',

  PURCHASE_METHODS: 'purchase_methods/',
  PURCHASE_METHOD: 'purchase_methods/:id',

  PAYOUT_STATES: 'payout_statuses/',
  PAYOUT_STATE: 'payout_statuses/:id',

  PROVIDER_TARGETS: 'provider_targets/',
  PURCHASE_TARGETS: 'purchase_targets/',
  PURCHASE_TARGET: 'purchase_targets/:id',

  USERS: 'users/',
  USERS_ALL: 'users/all',
  USERS_TAB: 'users/:type',
  USER: 'users/:type/:id',
  USERS_REQUESTS: 'user_requests/',
  USERS_REQUEST: 'user_requests/:id',
  getUser: (userId: string) => `/users/all/${userId}`,

  GLOBAL_MAP: 'map/',
  REFERENCES: 'references/',

  REVIEW_FULFILMENT: 'review_fulfilment',
  DROP_REVIEW_FULFILMENT: 'drop_fulfilment_review',
  REVIEW_ORDER: 'review_order',
  DROP_REVIEW_ORDER: 'drop_order_review',
  EDIT_ORDER: 'office/object_lock',

  CONTENTS: 'fulfilment_contents',
  CONTENT: 'fulfilment_contents/:id',

  FULFILMENT_REQUESTS: 'fulfilment_requests',
  FULFILMENT_REQUEST: 'fulfilment_requests/:id',

  CUSTOMER: 'orders',

  ERROR: 'error/',
  WILDCARD: '*',
};
