import { UserOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Button, MenuProps } from 'antd';
import { Avatar, Dropdown } from 'antd';
import { queryNames } from 'api/queryNames';
import { tablesNames } from 'api/tablesNames';
import useGetData from 'api/useGetData';
import { LoaderPage } from 'components/pages/main/LoaderPage';
import { useGetAuthUser } from 'hooks/api/user/useGetAuthUser';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import { signOutUser } from 'shared/utils/api/signOutUser';
import { setManualLogout, setVitual } from 'store/auth/actions';
import styles from './styles.module.scss';
import { MainHeaderT } from './types';
import { debounce } from 'lodash';
import { ModalUserSettings } from 'components/modals/ModalUserSettings';

export const UserMenu: FunctionComponent<MainHeaderT> = () => {
  const homeItems: MenuProps['items'] = [
    {
      key: 'settings',
      label: 'Settings',
      onClick: () => showSettingsModal(),
    },
    {
      key: 'logout',
      label: 'Logout',
      onClick: () => logout(),
    },
  ];

  const virtualLogoutItem: MenuProps['items'] = [
    {
      key: 'virtualLogout',
      label: 'Virtual Logout',
      onClick: () => setIsVirtualLogout(true),
    },
  ];

  const dispatch = useDispatch();
  const [isLogout, setIsLogout] = useState(false);
  const [isVirtualLogout, setIsVirtualLogout] = useState(false);
  const [items, setItems] = useState([...homeItems]);
  const token = useSelector((state: any) => state.auth.token);
  const isVitual = useSelector((state: any) => state.auth.isVirtual);
  const [width, setWidth] = useState(window.innerWidth);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100)

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  const { isLoading, isError, data, error, status: statusLogout } = useQuery({
    queryKey: [queryNames.AUTH_logout],
    meta: { token , getAllData: true },
    queryFn: useGetData,
    enabled: isLogout,
  });

  const { isError: isErroruserVirtualLogout, data: dataVirtualLogout } =
    useQuery({
      queryKey: [queryNames.AUTH_virtualLogout],
      meta: {
        token: localStorage.getItem(APP_CONSTANS.LS_VL_TOKEN),
        getAllData: true,
      },
      queryFn: useGetData,
      enabled: isVirtualLogout,
      onSuccess(data) {
        dispatch(setVitual(false));
        localStorage.removeItem(APP_CONSTANS.LS_VL_TOKEN);
        if (data?.data?.access_token) {
          //TODO set new token
        }
      },
    });

  const {
    data: userInfo,
    isLoading: userInfoIsLoading,
    isError: userInfoIsError,
  } = useGetAuthUser();

  const { first_name: firstName, last_name: lastName } = userInfo?.data || {};

  const fullName = useMemo(
    () => (firstName && lastName ? `${firstName} ${lastName}` : null),
    [firstName, lastName]
  );

  useEffect(() => {
    if (statusLogout === 'success') {
      signOutUser(true);
      dispatch(setManualLogout(true));
    }
  }, [statusLogout]);

  const logout = useCallback(() => {
    setIsLogout(true);
  }, []);

  const showSettingsModal = useCallback(() => {
    setShowSettings(true);
  }, []);

  useEffect(() => {
    // console.log('asd', isVitual);
    isVitual
      ? setItems([...homeItems, ...virtualLogoutItem])
      : setItems([...homeItems]);
  }, [isVitual]);

  // TODO: Move error handling in separate interceptor
  if (userInfoIsError) {
    signOutUser();
  }

  return userInfoIsLoading ? (
    <></>
  ) : (
    <>
      <Dropdown
        className={styles.profile_block}
        menu={{ items }}
        placement="bottom"
        trigger={['click']}
      >
        <Button type="link" className={styles.dropdownButton}>
          {fullName && width > 767 && (
            <p className={styles.profile_block__name} data-e2e="userProfile">
              Hello, <span>{fullName}</span>
            </p>
          )}
          <Avatar
            className={styles.profile_block__avatar}
            size="large"
            icon={<UserOutlined />}
          />
        </Button>
      </Dropdown>

      {showSettings && (
        <ModalUserSettings
          visibleModal={showSettings}
          setVisibleModal={setShowSettings}
        />
      )}
    </>
  );
};
