import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styles from './styles.module.scss';
import { Spin } from "antd";
import { LoadingOutlined, FileImageOutlined } from "@ant-design/icons";
import clsx from "clsx";
import { useInView } from 'react-intersection-observer';
import { useQuery } from "@tanstack/react-query";

export const ContentImg: React.FC<any> = ({
  currentContentUrl = null,
  previewUrl,
  updatedAt,
  id,
  replaceInModal = null,
  sliderIndex = null,
  arrIndex = null,
  isModal = false,
  scaleFactor = null,
  scaleSign = null,
  setDisableCarousel = null,
}) => {
  const [blob, setBlob] = useState<string>('loader');
  const [width, setWidth] = useState<number | string>('');
  const [height, setHeight] = useState<number | string>('');
  const token = useSelector((state: any) => state.auth.token);
  const antIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const { ref :imgRef, inView: imgInView, entry: entryImg } = useInView({
    /* Optional options */
    threshold: 0,
  });  

  const {
    isLoading,
    isError,
    data,
    error,
    refetch,
  } = useQuery({
    queryKey: [previewUrl, {id}, updatedAt],
    queryFn: () => {
      const options = {
        headers: {
          cache: "no-cache",
          Authorization: `Bearer ${token}`,
        },
      };      
      return (
        fetch(previewUrl, options)
          .then(res => res.blob())
          .then(blob => blob)
          .catch(err => setBlob('error'))
      )
    },
    cacheTime: 300000,
    staleTime: 300000,
    enabled: !!previewUrl && inView,
  });

  // useEffect(() => {refetch()}, [updatedAt])

  useEffect(() => {
  
    return () => {
      blob && URL.revokeObjectURL(blob)
    }
  }, [])

  useEffect(() => {
    if(!data?.size) return
    blob && URL.revokeObjectURL(blob)
    setBlob(URL.createObjectURL(data))
  }, [data]);

  useEffect(() => {
    if (isModal && imgInView && entryImg && entryImg.target && entry && entry.target ) {
      const currentWidth: any = entryImg.target.clientWidth;
      const currentHeight: any = entryImg.target.clientHeight;
      const scale = scaleFactor - 1;
      let zoomInWidth: number = 0;
      let zoomInHeight: number = 0;
      if (scale && scaleSign && scaleSign === 'plus') {
        zoomInWidth = currentWidth + (currentWidth * 0.2);
        zoomInHeight = currentHeight + (currentHeight * 0.2);
        setWidth(zoomInWidth);
        setHeight(zoomInHeight);
      } else if (scaleSign && scaleSign === 'minus' && currentWidth > 300) {
        zoomInWidth = currentWidth - (currentWidth * 0.2);
        zoomInHeight = currentHeight - (currentHeight * 0.2);
        setWidth(zoomInWidth);
        setHeight(zoomInHeight);
      }
    }
  }, [isModal, scaleFactor, imgInView, entryImg, entry]);

  // Logic for Disable arrows when image isn't loaded yet
  useEffect(() => {    
    if (isModal && currentContentUrl && currentContentUrl === previewUrl) {
      console.log('currentContentUrl ', currentContentUrl);
      if (isLoading && blob !== 'error' && setDisableCarousel) {
        console.log('CONTENT LOADED');
        setDisableCarousel(false);
      } else if (isLoading && setDisableCarousel) {
        console.log('CONTENT LOADING...');
        setDisableCarousel(true);
      }
    }
  }, [isModal, blob, setDisableCarousel]);

  return (
    <div className={styles.imgBlock} key={`${id}${updatedAt}`} ref={ref}>
      {isLoading
        ? (
          <div className={clsx(styles.block, styles.loader)}>
            <Spin indicator={antIcon} />
          </div>
        ) : blob === 'error'
          ? (
            <div className={clsx(styles.block, styles.error)}>
              <FileImageOutlined />
              <p>Replacing failed</p>
            </div>
          )
          : !isModal
            ? (
              <img
                key={`${id}${updatedAt}`}
                className={styles.img}
                style={{ backgroundImage: `url(${blob})` }}
              /> 
            ) : (
              <img
                key={`${id}${updatedAt}`}
                className={clsx(styles.imgInModal,
                  !isLoading && blob !== 'error'
                    ? styles.dBlock
                    : styles.dNone
                )}
                ref={imgRef}
                src={blob}
                style={{ 
                  width: width ? `${width}px` : 'auto',
                  height: height ? `${height}px` : 'auto',
                  maxWidth: width ? `${width}px` : 'auto',
                  maxHeight: height ? `${height}px` : 'auto',
                }}
              />
            )
      }
    </div>
  );
};
