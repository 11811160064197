import { APP_CONSTANS } from 'shared/constants';

export const signOutUser = (isManualLogout: boolean = false) => {
  const redirectURL = isManualLogout
    ? ''
    : `?redirect=${encodeURIComponent(window.location.href)}`;
  window.localStorage.removeItem(APP_CONSTANS.LS_TOKEN);
  window.localStorage.removeItem(APP_CONSTANS.LS_SESSION_EXP);
  localStorage.removeItem(APP_CONSTANS.LS_VL_TOKEN);
  window.location.href = `${APP_CONSTANS.BASE_LOGIN_URL}${redirectURL}`;
  return null;
};
