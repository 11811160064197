import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Form,
  Radio,
  RadioChangeEvent,
  Space,
  message,
} from 'antd';
import { usePostData } from 'api/usePostData';
import clsx from 'clsx';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import { FunctionComponent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import globalStyles from '../../../shared/styles/styles.module.scss';
import { ModalInvoiceChangePaymentTypeT } from './types';
import { queryNames } from 'api/queryNames';
import { PaymentTypeSelect } from 'components/pages/main/finances_section/Invoices/components/PaymentTypeSelect';

export const ModalInvoiceChangePaymentType: FunctionComponent<
  ModalInvoiceChangePaymentTypeT
> = ({ showModal, setShowModal, id, accountId, invoicePaymentType }) => {
  const [invoiceChangePaymentTypeForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const [disabledPayout, setDisabledPayout] = useState<boolean>(false);
  const [newHoldValue, setNewHoldValue] = useState(true);

  const queryClient: any = useQueryClient();

  const selectedPaymentType = Form.useWatch(
    'payment_type',
    invoiceChangePaymentTypeForm
  );

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      message.success('Invoice paid');
      queryClient.invalidateQueries([queryNames.INVOICES_BY_ID, id]);
      closeModal();
    },
  });

  const handleFormChange = useCallback(() => {
    const hasErrors = invoiceChangePaymentTypeForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setDisabledPayout(hasErrors);
  }, [invoiceChangePaymentTypeForm]);

  const handleNewHoldChange = (e: RadioChangeEvent) => {
    setNewHoldValue(e.target.value);
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleFinish = (allFields: any) => {
    if (allFields?.payment_type === 'card' && !allFields.method_id) {
      message.error('Card is not selected');
      return;
    }
    if (!id) {
      message.error('Invoice ID - undefined');
      return;
    }
    if (!allFields?.payment_type) {
      message.error('Choose payment type');
      return;
    }

    allFields.invoice_id = id;
    allFields.payment_type.toLowerCase() === 'invoice' &&
      allFields.new_hold &&
      delete allFields.new_hold;

    mutate({
      data: allFields,
      token,
      otherProps: queryNames.INVOICE_METHOD,
    });
  };

  return (
    <ModalWindow
      title="ChangePaymentType"
      visible={showModal}
      width={window.innerWidth > 700 ? '600px' : '95vw'}
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      <Form
        form={invoiceChangePaymentTypeForm}
        onFinish={handleFinish}
        onFieldsChange={handleFormChange}
        className={styles.form}
      >
        {invoicePaymentType && (
          <Alert
            message={
              <span>
                Current payment type:{' '}
                <span className={styles.paymentType}>{invoicePaymentType}</span>
              </span>
            }
            type="info"
            showIcon
            className={styles.alertInfo}
          />
        )}
        <PaymentTypeSelect
          isHidden={false}
          isDisabled={!accountId}
          accountId={accountId}
          formInstance={invoiceChangePaymentTypeForm}
          methodName="method_id"
          invoicePaymentType={invoicePaymentType}
        />
        <Form.Item
          name="new_hold"
          label="Payment operation process"
          initialValue={true}
          hidden={selectedPaymentType?.toLowerCase() !== 'card'}
        >
          <Radio.Group onChange={handleNewHoldChange} value={newHoldValue}>
            <Space direction="vertical">
              <Radio value={true}>Start with Hold</Radio>
              <Radio value={false}>Make a Charge</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <div className={styles.actions}>
          <Button
            className={clsx(globalStyles.btn, globalStyles.secondary__btn)}
            style={{ marginRight: '8px' }}
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            type="primary"
            onClick={() => invoiceChangePaymentTypeForm.submit()}
            disabled={disabledPayout}
          >
            Change
          </Button>
        </div>
      </Form>
    </ModalWindow>
  );
};
