export const userCols = [
  'first_name',
  'middle_name',
  'last_name',
  'type',
  'email',
  'phone',
  'address_id',
  'id',
  'status',
  'deleted_at',
  'created_at',
  'updated_at',
  'last_login',
  'Industry.name',
  'Account.name',
  'Account.id',
];

export const userAccCols = ['id', 'name', 'purchase_method_id'];

export const userJobCols = [
  'id',
  'state_id',
  'Order.state_id',
  'Order.content_type',
  'Order.complications',
  'Order.location_lat',
  'Order.location_long',
  'Order.payout',
  'Order.title',
];