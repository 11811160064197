export const columns = [
  'id',
  'fulfilment_id',
  'order_id',
  'user_id',
  'State.name',
  'updated_at',
  'taken_at',
  'original_name',
  'deleted_at',
  'Order.User.id',
  'original_url',
  'content_url',
  'content_type',
  'release_url',
  'Fulfilment.State.name',
  'created_at',
];
