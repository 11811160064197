import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import { AppContainer } from 'components/app/AppContainer';
import { APP_CONSTANS } from 'shared/constants';

export enum ffBroadcastChannel {
  name = 'ffChannel',
}
export type ffBroadcastChannelT = {
  version: string;
};

function App() {
  const bc = new BroadcastChannel(BroadcastChannel.name);
  const bcData: ffBroadcastChannelT = {
    version: APP_CONSTANS.VERSION_ADMIN,
  };
  bc.postMessage(bcData);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
