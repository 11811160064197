import { MutateOptions, useMutation } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type UsePostGlobalMapNearbyOptions = MutateOptions<
  any,
  unknown,
  {
    data: object;
    otherProps?: string;
    formData?: any;
    token?: string | undefined;
    method?: string | undefined;
  },
  unknown
>;

export const usePostGlobalMapNearby = () => {
  const token = useSelector((state: any) => state.auth.token);
  const { mutate, ...rest } = useMutation({
    mutationFn: usePostData,
  });

  const sendNotifications = useCallback(
    // eslint-disable-next-line no-empty-pattern
    (
      {
        radius,
        location_lat,
        location_lng,
        photographerFilters,
        jobFilters,
      }: {
        radius: number;
        location_lat: number;
        location_lng: number;
        jobFilters?: object;
        photographerFilters?: object;
      },
      options: UsePostGlobalMapNearbyOptions
    ) => {
      mutate(
        {
          data: {
            orders_find: {
              location_lat,
              location_lng,
              radius,
              ...(jobFilters ? jobFilters : {}),
            },
            users_find: {
              location_lat,
              location_lng,
              radius,
              ...(photographerFilters ? photographerFilters : {}),
            },
          },
          token,
          method: 'POST',
          otherProps: 'customer/global_map_nearby',
        },
        options
      );
    },
    [mutate, token]
  );

  return { sendNotifications, ...rest };
};
