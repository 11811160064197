export const widthPresets = {
  code: 120,
  id: 320,
  'order.code': 120,
  status: 120,
  'state.name': 180,
  'account.name': 300,
  title: 300,
  'order.title': 300,
  email: 300,
  name: 300,
  created_at: 270,
  completed_at: 270,
  'order.completed_at': 270,
  updated_at: 270,
  checked_at: 270,
  check_out_long: 210,
  check_in_dist: 240,
  provider_tx_id: 300,
  'account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd': 300, //paypal info
  'account.preferences.a8612805-f592-449c-a6e5-96f7b780911c': 300, //venmo info
  'account.preferences.a6a3f067-7629-465e-9487-ac2fd7a0a710': 150, //default payout method
  description: 350,
  is_active: 120,
  amount: 120,
  discount: 120,
  discount_sum: 120,
  payed_sum: 120,
  payment_type: 120,
  'account.id': 350,
  account_id: 350,
};
