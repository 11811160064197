export const allSettings = {
  'code': { order: 1 },
  'order.code': { order: 2 },
  'account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd': { order: 3 },
  'account.preferences.a8612805-f592-449c-a6e5-96f7b780911c': { order: 4 },
  'account.preferences.a81ce075-8b13-498f-8906-e2e7d183e38f': { order: 5 },
  'account.preferences.a6a3f067-7629-465e-9487-ac2fd7a0a710': { order: 6 },
  'order.title': { order: 7 },
  'user.first_name': { order: 8 },
  'user.last_name': { order: 9 },
  'order.payout': { order: 10 },
};

export const paypalSettings = {
  'code': { order: 1 },
  'order.code': { order: 2 },
  'account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd': { order: 3 },
  'order.title': { order: 4 },
  'user.first_name': { order: 5 },
  'user.last_name': { order: 6 },
  'order.payout': { order: 7 },
};

export const venmoSettings = {
  'code': { order: 1 },
  'order.code': { order: 2 },
  'account.preferences.a8612805-f592-449c-a6e5-96f7b780911c': { order: 3 },
  'account.preferences.a81ce075-8b13-498f-8906-e2e7d183e38f': { order: 4 },
  'order.title': { order: 5 },
  'user.first_name': { order: 6 },
  'user.last_name': { order: 7 },
  'order.payout': { order: 8 },

};
