import { tablesNames } from 'api/tablesNames';
import { FC } from 'react';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { queryNames } from 'api/queryNames';
import { PaymentOperationsTable } from 'components/ui_components/PaymentOperationsTable';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { usePostData } from 'api/usePostData';
import { Button, Dropdown, message } from 'antd';
import { HandleActtionT } from './types';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';

export interface InvoiceOperationsT {
  id: string;
}

export const InvoiceOperations: FC<InvoiceOperationsT> = ({ id }) => {
  const queryKey = [queryNames.PaymentOperations, id];
  const meta = {
    additionalSearchParams: `?invoice_ids=${id}`,
    paymentsApi: true,
  };

  const queryClient: any = useQueryClient();
  const token = useSelector((state: any) => state.auth.token);

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data, variables: any) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Action error. ${data.error?.message}`);
        return;
      } else {
        queryClient.invalidateQueries(queryKey);
        const operationId = variables?.data?.operation_id?.slice(0, 8);
        const lastAction = variables?.otherProps
          ?.split('/')
          ?.pop()
          ?.toUpperCase();
        message.success(
          `Action ${lastAction || ''} for Operation ${
            operationId ? operationId + '...' : ''
          } was compleded`
        );
      }
    },
  });

  const handleAction = (
    event: React.MouseEvent<HTMLElement>,
    operationId: string,
    actionType?: HandleActtionT
  ) => {
    event.stopPropagation();

    if (!operationId) {
      message.error('Undefined Operation ID');
      return;
    }

    let actionUrl: string | undefined;

    switch (actionType) {
      case 'cancel':
        actionUrl = queryNames.PAYMENTS_CANCEL;
        break;
      case 'charge':
        actionUrl = queryNames.PAYMENTS_CHARGE;
        break;
      case 'check':
        actionUrl = queryNames.PAYMENTS_CHECK;
        break;
      case 'refund':
        actionUrl = queryNames.PAYMENTS_REFUND;
        break;

      default:
        break;
    }

    if (!actionUrl) {
      message.error('Action type - Undefined');
      return;
    }

    mutate({
      data: { operation_id: operationId },
      token,
      url: `${APP_CONSTANS.BASE_PAYMENTS_URL}/${actionUrl}`,
      otherProps: actionUrl,
    });
  };

  const actionsColumn = [
    {
      title: 'Action',
      width: 70,
      fixed: 'right',
      hideInSetting: true,
      render: (record: any) => {
        const statusId = record?.payment_status_id;
        return (
          <Dropdown
            key="dropdown"
            trigger={['click']}
            menu={{
              items: [
                {
                  key: 'CheckOperation',
                  label: (
                    <Button
                      type="text"
                      onClick={(e) => handleAction(e, record.id, 'check')}
                      block
                      disabled={false}
                    >
                      Check
                    </Button>
                  ),
                },
                {
                  key: 'ChargeOperation',
                  label: (
                    <Button
                      type="text"
                      onClick={(e) => handleAction(e, record.id, 'charge')}
                      block
                      disabled={false}
                    >
                      Charge
                    </Button>
                  ),
                },
                {
                  key: 'RefundOperation',
                  label: (
                    <Button
                      type="text"
                      onClick={(e) => handleAction(e, record.id, 'refund')}
                      block
                      disabled={
                        statusId !==
                        APP_CONSTANS.PAYMENT_OPERATIONS_STATUSES.SETTLED
                      }
                    >
                      Refund
                    </Button>
                  ),
                },
                {
                  key: 'CancelOperation',
                  label: (
                    <Button
                      type="text"
                      onClick={(e) => handleAction(e, record.id, 'cancel')}
                      block
                      danger
                      disabled={
                        statusId ===
                        APP_CONSTANS.PAYMENT_OPERATIONS_STATUSES.CANCELLED
                      }
                    >
                      Cancel
                    </Button>
                  ),
                },
              ],
            }}
            overlayClassName={styles.dropdown}
          >
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
              }}
              type="text"
            >
              ...
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <PaymentOperationsTable
      tableIndicatorClassName="payOperationsInvoice"
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryKey}
      meta={meta}
      actionsColumn={actionsColumn}
      headerName={tablesNames.PaymentOperationsHeader}
      tableName={tablesNames.PaymentOperationsPaymentCol}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
