export const defaultEnabledSettings = {
  name: { order: 1 },
  address1: { order: 2 },
  address2: { order: 3 },
  address3: { order: 4 },
  city: { order: 5 },
  region: { order: 6 },
  country: { order: 7 },
  zipcode: { order: 8 },
  lat: { order: 9 },
  lon: { order: 10 },
};
