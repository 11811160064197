import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useEffect, useMemo, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useSelector } from 'react-redux';
import { APP_CONSTANS } from 'shared/constants';
import { getValueByCode } from 'shared/utils/getValueByCode';
import { Address } from 'types/global';

export const useGetUserLocation = (
  userId?: string,
  mapLocation: string = 'address'
) => {
  const { coords, isGeolocationEnabled } = useGeolocated();
  const [userLocationPreference, setUserLocationPreference] = useState(null); //address or location
  const [userLocation, setUserLocation] = useState<any>(null);
  const [userMainAddress, setUserMainAddress] = useState<any>('');
  const [userPoint, setUserPoint] = useState<Address | null>(null);

  const token = useSelector((state: any) => state.auth.token);

  const geolocationLoading = !coords && !!isGeolocationEnabled;

  const geolocation: Address | null = useMemo(() => {
    if (coords) {
      return { lat: coords.latitude, lng: coords.longitude };
    }
    return null;
  }, [coords]);

  const {
    isLoading: isLoadingPrefs,
    isError: isErrorPrefs,
    data: dataPreferences,
  } = useQuery({
    queryKey: [queryNames.USERS_getUserPreferencesById, userId],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `?user_id=${userId}`,
    },
    queryFn: useGetData,
    enabled: userId !== undefined && userId.length > 0,
  });

  const {
    isLoading: isLoadingUserAddress,
    isError: isErrorUserAddress,
    data: userAddress,
  } = useQuery({
    queryKey: [queryNames.USERS_getAddressById, userId],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `?user_ids=${userId}`,
    },
    queryFn: useGetData,
    enabled: userId !== undefined && userId.length > 0,
  });

  useEffect(() => {
    setUserLocation(geolocation);
  }, [geolocation]);

  useEffect(() => {
    if (dataPreferences && dataPreferences.hasOwnProperty('data')) {
      const locationBy = getValueByCode(
        dataPreferences.data,
        APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.CODE
      );
      locationBy && setUserLocationPreference(locationBy);
    }
  }, [dataPreferences]);

  useEffect(() => {
    if (userAddress && userAddress.hasOwnProperty('data')) {
      setUserMainAddress(
        userAddress.data.find((address: any) => address.name === 'Main address')
      );
    }
  }, [userAddress]);

  useEffect(() => {
    if (
      userLocationPreference !== null &&
      userLocationPreference ===
        APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.LOCATION &&
      userLocation !== null &&
      userLocation?.hasOwnProperty('lat') &&
      userLocation?.hasOwnProperty('lng') &&
      mapLocation === APP_CONSTANS.PHOTOGRAPHER_PROPS.LOCATION_FROM.LOCATION
    ) {
      setUserPoint({ lat: userLocation.lat, lng: userLocation.lng });
    } else if (userMainAddress) {
      setUserPoint({ lat: userMainAddress.lat, lng: userMainAddress.lon });
    }
  }, [userLocationPreference, userLocation, userMainAddress, mapLocation]);

  return {
    location: userId ? userPoint : null,
    isLoading: geolocationLoading || isLoadingPrefs || isLoadingUserAddress,
    isError: isErrorPrefs || isErrorUserAddress,
  };
};
