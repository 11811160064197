/* eslint-disable guard-for-in */

export const formatFilters = (filters: any) => {
  const statuses: string[] = [];
  const valueLike: string[] = [];
  const jsonLike: string[] = [];
  const timeRange: string[] = [];
  const valueCode: string[] = [];
  const valueIn: string[] = [];
  const valueEqual: string[] = [];
  const jsonIn: string[] = [];
  const jsonEqual: string[] = [];

  for (let filterObj in filters) {
    const { key, isJson } = filters[filterObj] || {};
    if (key && filterObj === 'status') {
      statuses.push(`${key[0]}`);
    } else if (key && filterObj.includes('__search') && !isJson) {
      valueLike.push(`${filterObj.replace('__search', '')},${key.join(',')}`);
    } else if (key && filterObj.includes('__search') && isJson) {
      jsonLike.push(`${filterObj.replace('__search', '')}:${key.join('')}`);
    } else if (key && filterObj.includes('__range')) {
      timeRange.push(`${filterObj.replace('__range', '')},${key.join(',')}`);
    } else if (key && filterObj.includes('__code')) {
      valueCode.push(`${filterObj.replace('__code', '')},${key.join(',')}`);
    } else if (key && key.length > 1 && !isJson) {
      valueIn.push(`${filterObj},${key.join(',')}`);
    } else if (key && key.length === 1 && !isJson) {
      valueEqual.push(`${filterObj},${key[0]}`);
    } else if (key && key.length > 1 && isJson) {
      jsonIn.push(`${filterObj}${key.join('::')}`);
    } else if (key && key.length === 1 && isJson) {
      jsonEqual.push(`&json_equal=${filterObj}${key}`);
    }
  }

  const filtersArray = {
    ...(statuses?.length ? { statuses } : {}),
    ...(valueLike?.length ? { value_like: valueLike } : {}),
    ...(jsonLike?.length ? { json_like: jsonLike } : {}),
    ...(timeRange?.length ? { time_range: timeRange } : {}),
    ...(valueCode?.length ? { value_code: valueCode } : {}),
    ...(valueIn?.length ? { value_in: valueIn } : {}),
    ...(valueEqual?.length ? { value_equal: valueEqual } : {}),
    ...(jsonIn?.length ? { json_in: jsonIn } : {}),
    ...(jsonEqual?.length ? { json_equal: jsonEqual } : {}),
  };

  return filtersArray;
};
