import { Drawer, Form } from 'antd';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { DrawerPurchaseTransactionsCol } from './columns';
import useGetData from 'api/useGetData';
import { ItemsGenerator } from '../ItemsGenerator';
import { ItemT } from '../ItemsGenerator/types';
import { tablesNames } from 'api/tablesNames';

export const DrawerPurchaseTransactions: React.FC<any> = ({
  show,
  setShow,
  id,
  currencyCode,
}) => {
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const {
    data: dataPurchaseTransactions,
    isFetching: isLoadingPurchaseTransactions,
  } = useQuery({
    queryKey: [queryNames.PurchaseTransactionsById],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
      columnParams: DrawerPurchaseTransactionsCol,
      additionalSearchParams: `?id=${id}`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const { data: dataHeaders, isFetching: isLoadingHeaders } = useQuery({
    queryKey: [tablesNames.PurchaseTransactionsHeader],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (
      dataPurchaseTransactions &&
      dataPurchaseTransactions?.data &&
      dataHeaders &&
      dataHeaders?.data
    ) {
      form.setFieldsValue(dataPurchaseTransactions.data);
    }
  }, [form, dataPurchaseTransactions, dataHeaders]);

  return (
    <Drawer
      width={window.innerWidth < 700 ? '100%' : '700px'}
      title="Purchase transaction details"
      placement="right"
      onClose={onClose}
      open={show}
    >
      {!isLoadingHeaders &&
        !isLoadingPurchaseTransactions &&
        dataPurchaseTransactions?.data &&
        dataHeaders?.data && (
          <Form
            className={styles.form}
            layout="vertical"
            form={form}
            autoComplete="off"
            disabled
          >
            {Object.keys(dataPurchaseTransactions?.data).map((k, i) => (
              <ItemsGenerator
                key={k + i}
                item={{ [k]: dataPurchaseTransactions?.data[k] } as ItemT}
                headers={dataHeaders}
                currencyCode={
                  dataPurchaseTransactions?.data?.currency?.code || currencyCode
                }
              />
            ))}
          </Form>
        )}
    </Drawer>
  );
};
