import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  sendSubscriptionToServer,
  sendUnSubscriptionToServer,
  urlBase64ToUint8Array,
} from './utils';
import { APP_CONSTANS } from 'shared/constants';
import { Switch, message } from 'antd';
import styles from './styles.module.scss';

const titles = {
  enable: 'Enable Push Messages on current browser and device',
  disable: 'Disable Push Messages on current browser and device',
};

export const Notifier = () => {
  const token = useSelector((state) => state.auth.token);
  const [isPushEnabled, setIsPushEnabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [title, setTitle] = useState(titles.enable);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Once the service worker is registered set the initial state
    const initialisePush = () => {
      // Are Notifications supported in the service worker?
      if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        console.warn("Notifications aren't supported.");
        return;
      }

      // Check the current Notification permission.
      // If its denied, it's a permanent block until the
      // user changes the permission
      if (Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        return;
      }

      // Check if push messaging is supported
      if (!('PushManager' in window)) {
        console.warn("Push messaging isn't supported.");
        return;
      }

      // We need the service worker registration to check for a subscription
      navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
        // Do we already have a push message subscription?
        serviceWorkerRegistration.pushManager
          .getSubscription()
          .then((subscription) => {
            // Enable any UI which subscribes / unsubscribes from
            // push messages.
            setIsDisabled(false);

            if (!subscription) {
              // We aren't subscribed to push, so set UI
              // to allow the user to enable push
              return;
            }

            // Keep your server in sync with the latest subscriptionId
            sendSubscriptionToServer(subscription, token);

            // Set your UI to show they have subscribed for
            // push messages
            setTitle(titles.disable);
            setIsPushEnabled(true);
          })
          .catch((err) => {
            console.warn('Error during getSubscription()', err);
          });
      });
    };
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js').then(() => {
        setIsVisible(true);
        return initialisePush();
      });
    } else {
      console.warn("Service workers aren't supported in this browser.");
    }
  }, [token]);

  const handleClick = () => {
    if (isPushEnabled) {
      return unsubscribe();
    }
    return subscribe();
  };

  function subscribe() {
    // Disable the button so it can't be changed while
    // we process the permission request
    setIsDisabled(true);
    setIsLoading(true);

    fetch(`${APP_CONSTANS.BASE_NOTIFY_URL}/notify/push_subscription/key`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (!data?.data) {
          console.error('Subscribtion server key  ot available');
          return;
        }
        navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
          const appServerKey = data.data;
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(appServerKey),
          };
          serviceWorkerRegistration.pushManager
            .subscribe(subscribeOptions)
            .then((subscription) => {
              // The subscription was successful
              setIsPushEnabled(true);
              setTitle(titles.disable);
              setIsDisabled(false);
              setIsLoading(false);

              // TODO: Send the subscription.endpoint to your server
              // and save it to send a push message at a later date
              message.success('Subscription was successfully enabled');
              return sendSubscriptionToServer(subscription, token);
            })
            .catch((e) => {
              setIsLoading(false);
              if (Notification.permission === 'denied') {
                // The user denied the notification permission which
                // means we failed to subscribe and the user will need
                // to manually change the notification permission to
                // subscribe to push messages
                console.warn('Permission for Notifications was denied');
                setIsDisabled(true);
              } else {
                // A problem occurred with the subscription; common reasons
                // include network errors, and lacking gcm_sender_id and/or
                // gcm_user_visible_only in the manifest.
                console.error('Unable to subscribe to push.', e);
                setIsDisabled(false);
                setTitle(titles.enable);
              }
            });
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('Error during get Subscription Key', err);
      });
  }

  const unsubscribe = () => {
    setIsDisabled(true);
    setIsLoading(true);

    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {
      // To unsubscribe from push messaging, you need get the
      // subscription object, which you can call unsubscribe() on.
      serviceWorkerRegistration.pushManager
        .getSubscription()
        .then((pushSubscription) => {
          // Check we have a subscription to unsubscribe
          if (!pushSubscription) {
            // No subscription object, so set the state
            // to allow the user to subscribe to push
            setIsPushEnabled(false);
            setIsDisabled(false);
            setTitle(titles.enable);
            setIsLoading(false);
            message.success('Subscription was successfully disabled');
            return;
          }

          //var subscriptionId = pushSubscription.subscriptionId;
          // TODO: Make a request to your server to remove
          // the subscriptionId from your data store so you
          // don't attempt to send them push messages anymore
          sendUnSubscriptionToServer(pushSubscription, token);

          // We have a subscription, so call unsubscribe on it
          pushSubscription
            .unsubscribe()
            .then(() => {
              setIsLoading(false);
              setIsDisabled(false);
              setTitle(titles.enable);
              setIsPushEnabled(false);
            })
            .catch((e) => {
              // We failed to unsubscribe, this can lead to
              // an unusual state, so may be best to remove
              // the users data from your data store and
              // inform the user that you have done so

              console.log('Unsubscription error: ', e);
              setIsLoading(false);
              setIsDisabled(false);
              setTitle(titles.enable);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          console.error(
            'Error thrown while unsubscribing from push messaging.',
            err
          );
        });
    });
  };

  // function askPermission() {
  //     return new Promise(function (resolve, reject) {
  //         const permissionResult = Notification.requestPermission(function (result) {
  //             resolve(result);
  //         });

  //         if (permissionResult) {
  //             permissionResult.then(resolve, reject);
  //         }
  //     }).then(function (permissionResult) {
  //         if (permissionResult !== 'granted') {
  //             throw new Error("We weren't granted permission.");
  //         }
  //     });
  // }

  // function subscribeUserToPush() {
  //     return navigator.serviceWorker
  //         .register('/service-worker.js')
  //         .then(function (registration) {
  //             const subscribeOptions = {
  //                 userVisibleOnly: true,
  //                 applicationServerKey: urlBase64ToUint8Array(
  //                     '{{.publicVAPIDKey}}',
  //                 ),
  //             };

  //             return registration.pushManager.subscribe(subscribeOptions);
  //         })
  //         .then(function (pushSubscription) {
  //             console.log(
  //                 'Received PushSubscription: ',
  //                 JSON.stringify(pushSubscription),
  //             );
  //             return pushSubscription;
  //         });
  // }

  return isVisible ? (
    <div className={styles.wrapper}>
      <span className={styles.message}>{title}</span>
      <Switch
        onClick={handleClick}
        checkedChildren="On"
        unCheckedChildren="Off"
        disabled={isDisabled}
        checked={isPushEnabled}
        loading={isLoading}
        style={{ backgroundColor: isPushEnabled ? '#0091b5' : 'gray' }}
      />
    </div>
  ) : (
    <></>
  );
};
