import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  invoices: [],
  invoiceItems: null,
  notInvoicedOrders: [],
  memo: null,
  isLoadingInvoices: null,
  isLoadingInvoiceItems: null,
  isLoadingNotInvoicedOrders: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_TYPES.GET_INVOICES_PENDING:
      return { ...state, isLoadingInvoices: true };
    case ACTION_TYPES.GET_INVOICES_FULFILLED:
      return { ...state, invoices: payload, isLoadingInvoices: false };
    case ACTION_TYPES.GET_INVOICES_REJECTED:
      return { ...state, invoices: payload, isLoadingInvoices: false };

    case ACTION_TYPES.GET_INVOICE_ITEMS_PENDING:
      return { ...state, isLoadingInvoiceItems: true };
    case ACTION_TYPES.GET_INVOICE_ITEMS_FULFILLED:
      return { ...state, invoiceItems: payload, isLoadingInvoiceItems: false };
    case ACTION_TYPES.GET_INVOICE_ITEMS_REJECTED:
      return { ...state, invoiceItems: payload, isLoadingInvoiceItems: false };

    case ACTION_TYPES.GET_NOT_INVOICE_ORDERS_PENDING:
      return { ...state, isLoadingNotInvoicedOrders: true };
    case ACTION_TYPES.GET_NOT_INVOICE_ORDERS_FULFILLED:
      return {
        ...state,
        notInvoicedOrders: payload,
        isLoadingNotInvoicedOrders: false,
      };
    case ACTION_TYPES.GET_NOT_INVOICE_ORDERS_REJECTED:
      return {
        ...state,
        notInvoicedOrders: payload,
        isLoadingNotInvoicedOrders: false,
      };

    case ACTION_TYPES.SET_INVOICE_MEMO:
      return { ...state, memo: payload };

    default:
      return state;
  }
};
