export const PurchaseItemCols = [
  'id',
  'fulfilment_id',
  'purchase_id',
  'code',
  'title',
  'amount',
  'Fulfilment.code',
  'Purchase.currency_id',
];
