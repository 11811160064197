import { FunctionComponent } from 'react';
import { TabJobsT } from './types';
import { tablesNames } from '../../../../../../../../../api/tablesNames';
import { JobsTable } from 'components/ui_components/JobsTable';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { queryNames } from 'api/queryNames';

export const TabJobs: FunctionComponent<TabJobsT> = ({ id }) => {
  const meta = { additionalSearchParams: `?user_ids=${id}` };

  return (
    <JobsTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.FULFILMENTS}
      meta={meta}
      headerName={tablesNames.JobsHeader}
      toolbar={null}
      actionsColumn={null}
      tableName={tablesNames.UserJobsCol}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
