import { Divider } from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import { JobHeader } from './components/JobHeader';
import { JobContent } from './components/JobContent';
import { JobFooter } from './components/JobFooter';
import { APP_CONSTANS } from 'shared/constants';

export const JobHeaderWrapper: React.FC<any> = ({
  objectType,
  title,
  jobId,
  jobCode,
  stateId,
  orderId,
  orderCode,
  orderStateId,
  currency,
  orderPayout,
  jobPayout,
  isJobPaid,
  quantityFiles,
  jobContent,
  accountId,
  createdAt,
  expiredAt,
  updatedAt,
  refundedAt,
  publishedAt = null,
  completedAt,
  deletedAt,
  paidAt,
  orderPaymentType,
  orderPrice,
  orderCalcDataPrice,
  invoiceStatus = null,
  invoiceId = null,
  invoiceCode = null,
  tmplId = null,
  tmplCode = null,
  timezone,
  ...rest
}) => {
  const { purchaseId, purchaseStartDate, purchaseEndDate, invoceId } = rest;
  const objectId = orderId || jobId || tmplId || purchaseId || invoceId;

  return (
    <div className={styles.header}>
      {objectType === APP_CONSTANS.PURCHASE ||
      objectType === APP_CONSTANS.INVOICE ? (
        <></>
      ) : (
        <JobHeader
          deletedAt={deletedAt}
          title={title}
          objectType={objectType}
          id={objectId}
          stateId={stateId}
        />
      )}

      <JobContent
        objectType={objectType}
        tmplCode={tmplCode}
        jobCode={jobCode}
        jobId={jobId}
        orderId={orderId}
        orderCode={orderCode}
        orderStateId={orderStateId}
        orderPayout={orderPayout}
        isJobPaid={isJobPaid}
        jobPayout={jobPayout}
        currency={currency}
        quantityFiles={quantityFiles}
        jobContent={jobContent}
        orderPaymentType={orderPaymentType}
        paidAt={paidAt}
        invoiceStatus={invoiceStatus}
        invoiceIdFromOrder={invoiceId}
        invoiceCodeFromOrder={invoiceCode}
        orderPrice={orderPrice}
        orderCalcDataPrice={orderCalcDataPrice}
        accountId={accountId}
        {...rest}
      />

      <Divider className={styles.header__divider} />

      <JobFooter
        objectType={objectType}
        createdAt={createdAt}
        expiredAt={expiredAt}
        updatedAt={updatedAt}
        publishedAt={publishedAt}
        completedAt={completedAt}
        deletedAt={deletedAt}
        paidAt={paidAt}
        refundedAt={refundedAt}
        purchaseStartDate={purchaseStartDate}
        purchaseEndDate={purchaseEndDate}
        timezone={timezone}
      />
    </div>
  );
};
