export const correctMarkerPosition = (
  arrParam: any,
  latParam: number,
  lngParam: number
): any => {
  const arr = arrParam;
  let lat = latParam;
  let lng = lngParam;
  const markerLatCorrection = 0.000007; //this step according to marker height in px on maximum map zoom. IF MARKER SIZE OR GOOGLE MAPS MAX ZOOM CHANGES - FIX THIS VALUE
  const markerLngCorrection = 0; //this step according to marker width in px on maximum map zoom. IF MARKER SIZE OR GOOGLE MAPS MAX ZOOM CHANGES - FIX THIS VALUE
  lat = +lat.toFixed(6);
  lng = +lng.toFixed(6);
  arr.map((e: any, index: number) =>
    lat > e - markerLatCorrection &&
    lat < e + markerLatCorrection &&
    lng > arr[index + 1] - markerLngCorrection &&
    lng < arr[index + 1] + markerLngCorrection
      ? (lat = e)
      : lat
  );

  if (!arr.includes(lat)) {
    arr.push(lat);
    arr.push(lng);
    return { lat, lng };
  }
  return correctMarkerPosition(arr, lat + markerLatCorrection, lng);
};
