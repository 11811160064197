import { Alert, Button, Space } from 'antd';
import { ffBroadcastChannelT } from 'App';
import { FC, useEffect, useState } from 'react';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';

export const VersionAlert: FC = () => {
  const [message, setMessage] = useState<
    MessageEvent['data'] | ffBroadcastChannelT
  >();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const bc = new BroadcastChannel(BroadcastChannel.name);
    bc.onmessage = (event) => {
      setMessage(event.data);
    };
    return () => {
      bc.close();
    };
  }, []);

  useEffect(() => {
    const version = message?.version;
    const { VERSION_ADMIN: localVersion } = APP_CONSTANS;
    if (
      version &&
      version !== localVersion &&
      version.localeCompare(localVersion, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) === 1
    ) {
      setShow(true);
    }
  }, [message]);

  async function handleHardReload(url: string) {
    await fetch(url, {
      headers: {
        Pragma: 'no-cache',
        Expires: '-1',
        'Cache-Control': 'no-cache',
      },
    });
    window.location.href = url;
    window.location.reload();
  }

  return show ? (
    <Alert
      className={styles.alert}
      showIcon={false}
      message={`Latest version is ${message?.version} but this window use ${APP_CONSTANS.VERSION_ADMIN}`}
      banner
      closable
      action={
        <Space>
          <Button
            type="text"
            size="small"
            ghost
            onClick={() => handleHardReload(window.location.href)}
          >
            Update
          </Button>
        </Space>
      }
    />
  ) : (
    <></>
  );
};
