import { queryNames } from 'api/queryNames';
import { APP_CONSTANS } from 'shared/constants';

export const logging = (
  url: string,
  message: string,
  method: string,
  body: string,
  headers: any
) => {
  const dataToBack = {
    method: method || '',
    body: body || '',
    url: url || '',
    message: message || '',
    user_agent: navigator.userAgent,
  };
  const path = `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.LOGGING_FRONT}`;
  fetch(path, {
    method: 'POST',
    headers,
    body: JSON.stringify(dataToBack),
  })
    .then((response) => {
      console.log('response - ', response);
    })
    .catch((error) => console.error(error));
};
