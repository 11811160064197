export const DrawerPurchaseTransactionsCol = [
  'id',
  'transaction_type',
  'status',
  'amount',
  'duration',
  'created_at',
  'updated_at',
  'PurchaseOperation.purchase_method_id',
  'PurchaseOperation.provider_tx_id',
];
