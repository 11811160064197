import { MapJobMarkerClusterer } from 'components/unsorted/Map/components/MapJobMarkerClusterer';
import { MapPositioned } from 'components/unsorted/Map/components/MapPositioned';
import { MapUserLocationMarker } from 'components/unsorted/Map/components/MapUserLocationMarker';
import { getJobMarkers } from 'components/unsorted/Map/utils/getJobMarkers';
import { useGetUserLocation } from 'hooks/general_hooks/useGetUserLocation';
import { FC, useMemo } from 'react';
import { Job } from 'types/global';

interface TabJobsMapJobsMapProps {
  jobs: Job[];
  mapLocation: string;
  userId: string;
}

export const TabJobsMapJobsMap: FC<TabJobsMapJobsMapProps> = ({
  jobs,
  mapLocation,
  userId,
}) => {
  const { location: userPoint } = useGetUserLocation(userId, mapLocation);

  const { markers, coordinates } = useMemo(() => getJobMarkers(jobs), [jobs]);

  return (
    <MapPositioned coordinates={coordinates}>
      {userPoint && <MapUserLocationMarker position={userPoint} />}
      <MapJobMarkerClusterer>{markers}</MapJobMarkerClusterer>
    </MapPositioned>
  );
};
