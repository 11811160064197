export const pointsCol = [
  'check_in_dist',
  'check_out_dist',
  'duration',
  'id',
  'check_in_lat',
  'check_in_long',
  'check_out_lat',
  'check_out_long',
];
