export const priceFloatingPointCorrection = (price) => {
  if (!price || price.length === 0) {
    return '0.00';
  }

  let priceArr = price.toString().split('');
  priceArr = priceArr.map((item) => (item === '.' ? '' : item));

  const lengthPrice = price.toString().length;
  const startCents = lengthPrice - 3;
  const dollars = priceArr.slice(0, startCents);
  const cents = priceArr.slice(startCents);

  const newPrice = +`${dollars.join('')}.${cents.join('')}`;
  return newPrice.toFixed(2);
};
