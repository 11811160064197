export const PurchaseOperationCols = [
  'id',
  'created_at',
  'amount',
  'payed_sum',
  'payed_fee',
  'payed_total',
  'Currency.code',
  'PayoutStatus.name',
  'PurchaseProvider.name',
  'PurchaseMethod.name',
  'User.id',
  'Account.id',
];
