import { FC, useMemo } from 'react';
import { Map, MapProps } from '../..';
import { getMapPosition } from '../../utils/getMapPosition';

interface MapPositionedProps extends MapProps {
  coordinates: { lat: number; lng: number }[];
  autocomlete?: boolean;
}

export const MapPositioned: FC<MapPositionedProps> = ({
  coordinates,
  ...rest
}) => {
  const { zoom, center, bounds } = useMemo(() => {
    return getMapPosition(coordinates) || {};
  }, [coordinates]);

  return <Map zoom={zoom} center={center} bounds={bounds} {...rest} />;
};
