export type ColumnsDataT = {
  title?: string;
  dataIndex?: string | string[];
  key?: string;
  width?: number;
  sorter?: any;
  hideInSetting?: string;
  filters?: boolean;
  onFilter?: boolean;
  valueEnum?: any;
  initialValue?: any | null;
  render?: any;
};

export type UserTableProps = {
  type: UserType;
  resetAllFiltering?: boolean;
};

export enum UserType {
  ALL = 'all',
  PHOTOGRAPHER = 'photographer',
  CUSTOMER = 'customer',
  INTERNAL = 'internal',
  INCLUDE_DELETED = 'include-deleted',
  DELETED_ONLY = 'deleted-only',
}

export type UserT = {
  id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  phone: string;
  type: string;
  timezone: string;
  address_id: string;
  industry_id: string;
  email_verified: number;
  created_at: number;
  updated_at: number;
  preferences: {
    '54742f32-5e01-4aed-ada8-2e323c3c9c87': string;
    '739b3903-4cf2-4b34-b708-a9ed2bcbbdeb': string;
    '74fb954f-1b6e-44ee-9baf-db3be3c7c642': string;
    '762549f6-bed5-46c6-8165-72c0f0debfa1': string;
    '76d4d122-0001-40c8-942d-c3d2ad6016c7': string;
    '7775e5ac-44ab-4569-a129-6bc9a1201d9f': string;
    '7877c89d-4f9f-420e-ba8c-eff1e907d318': string;
    '7a5f77b8-a8bc-4a70-b094-1b0287e89235': string;
    '7b931b3a-acd1-4c39-b368-0f107038dc13': string;
    '7bbba837-9145-410b-a1bc-abe9d3dd419e': string;
    '7d47d758-81f7-41cc-8d89-fe153e7fd548': string;
    '7d91eb40-88ab-42f0-8ecc-7c680a9f734a': string;
    '7f20bdc9-003a-452c-8ef8-cfcb1c0e9a3f': string;
    '7fce27aa-0619-41e1-a4af-5553cf6d8b62': string;
    '809879ae-0414-4893-a3f6-bbe69492d6a6': string;
    '81450748-38a0-4ea0-ac53-046e61daa099': string;
    '83008119-31cf-4f72-9d1c-2e9b7a9508f9': string;
    '83829ec8-6051-4f12-a611-bc8e2173399b': string;
    '8553c946-d9b6-44b6-8025-d784f242eb38': string;
    '85739016-c464-4c00-b767-eea5f8eecf12': string;
    '85ebfc17-e6de-42b8-b8d4-d1baf4aced79': string;
    '865d5030-c981-400f-b678-b9a8ccbd4270': string;
    '896e8667-fe17-4197-9292-f66159e7db17': string;
    '8a8fa266-cc56-45b7-b953-67768813695a': string;
    '8b7a8a43-e844-45e0-8d6d-61e59f1f6a0f': string;
    '8c0bce5e-c748-4f3a-a070-bdb1534381c8': string;
    '8c2261cd-7478-48c7-876d-a02bc258fd76': string;
    '8e8c9727-70c9-4c8a-8329-4fe17b004215': string;
    '8f8323cf-4cf9-4136-a8cb-5f9d27988ac1': string;
    '90b184a9-e4d9-4166-a117-a69b25cb9b4b': string;
    '90fc2403-515d-41f6-b282-30ae7c8bde7a': string;
    '9203fd0c-2511-4eca-be6b-270ebaaecc3a': string;
    '93617b3c-f131-4f6c-9737-72c333bedaf8': string;
    '94291a54-d88d-4ff2-be70-b77b7c46746a': string;
    'a032c8a0-d190-4db5-8f5b-6ba8a3761e3e': string;
    'a0815379-37cf-4a3e-88e6-c0dcba9dbf5b': string;
    'a11e1d21-50d7-4058-a31e-42135f09c218': string;
    'a13e8a1f-d133-431b-8304-eb402ea91c40': string;
    'a162356e-6436-4b5c-96f1-29bb0ca2604b': string;
    'a1e677d3-252e-48ef-a794-c57b521d54aa': string;
    'a398164f-fa18-4606-b77e-787ad95e7d9b': string;
    'a3cc8111-d107-49a8-a5e8-4bce9b1cf341': string;
    'a4917f4f-a949-4038-b9cb-e946ebe660b8': string;
    'af26a292-cf2e-4d22-b923-e409496dfdeb': string;
  };
  industry: {
    id: string;
    name: string;
    description: string;
    is_active: boolean;
  };
};
