import { FunctionComponent, useCallback } from 'react';
import styles from './styles.module.scss';
import { Button, Checkbox } from 'antd';
import { ModalWindow } from '../../ui_components/ModalWindow';
import iconError from '../../../assets/images/icons/Error.svg';
import { ModalErrorT } from './types';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import globalStyles from '../../../shared/styles/styles.module.scss';

const ModalError: FunctionComponent<ModalErrorT> = ({
  visibleModal,
  setVisibleModal,
  message,
  actionText,
  action,
  isBtnDanger,
  isForceAction,
  forceRequest,
  relationsList,
  isBtnDisabled,
  setIsBtnDisabled,
  type,
  isLoading = false,
}) => {
  const closeModal = useCallback(() => {
    setVisibleModal(false);
  }, [setVisibleModal]);

  const onChange = (e: CheckboxChangeEvent) => {
    setIsBtnDisabled(!isBtnDisabled);
    forceRequest.current = e.target.checked;
  };
  return (
    <ModalWindow
      title=""
      visible={visibleModal}
      width="470px"
      okButtonProps={{
        style: {
          background: '#03A3BF',
          borderRadius: 2,
          border: 'none',
        },
      }}
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      <div className={styles.content}>
        <img className={styles.icon} src={iconError} alt="icon error" />
        {message === 'list' ? (
          <div>
            <p className={styles.text}>
              You can't
              {actionText?.toLowerCase().includes('soft') ? ' soft ' : ' hard '}
              delete {type} because of the existing connections with these
              entities.
            </p>
            <ul className={styles.list}>
              {relationsList.map((relation: any) => (
                <li key={relation.name}>
                  {relation.name.includes('_')
                    ? relation.name.replaceAll('_', ' ')
                    : relation.name}
                  : {relation.value}
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p className={styles.text}>{message}</p>
        )}
        <div className={styles.actions}>
          {isForceAction && (
            <Checkbox className={styles.confirmCheckbox} onChange={onChange}>
              Force delete
            </Checkbox>
          )}
          <div className={styles.actions__btns}>
            <Button
              className={globalStyles.btn}
              onClick={closeModal}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={action}
              danger={isBtnDanger}
              disabled={isBtnDisabled}
              className={globalStyles.btn}
              loading={isLoading}
            >
              {actionText}
            </Button>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ModalError;
