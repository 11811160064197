export const columns = [
  'id',
  'purchase_method_id',
  'account_id',
  'created_at',
  'updated_at',
  'deleted_at',
  'code',
  'amount',
  'payed_sum',
  'payed_fee',
  'payed_total',
  'paid_at',
  'start_date',
  'end_date',
  'memo',
  'Currency.code',
  'State.description',
  'State.name',
  'State.id',
  'Account.id',
  'Account.code',
  'Account.name',
];
