import { tablesNames } from 'api/tablesNames';
import { FC } from 'react';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { queryNames } from 'api/queryNames';
import { InvoiceLinesTable } from 'components/ui_components/InvoiceLinesTable';
import { InvoiceLinesT } from './types';

export const InvoiceLines: FC<InvoiceLinesT> = ({
  id,
  currencyCode,
  refetchInvoice,
}) => {
  const meta = { additionalSearchParams: `?invoice_ids=${id}` };

  return (
    <InvoiceLinesTable
      refetchInvoice={refetchInvoice}
      tableIndicatorClassName={'invoiceLinesInInvoiceTab'}
      queryKey={[queryNames.INVOICE_LINES, id]}
      tableName={tablesNames.InvoiceLinesCol}
      toolbar={null}
      meta={meta}
      headerName={tablesNames.InvoiceLinesHeader}
      defaultEnabledSettings={defaultEnabledSettings}
      hideInSetting={hideInSetting}
      currencyCode={currencyCode}
      resetAllFiltering={true}
    />
  );
};
