import { Drawer, Form, Input, InputNumber } from 'antd';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { InvoiceLineCols } from './columns';
import useGetData from 'api/useGetData';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { moneyFromCurrency } from 'shared/utils/DataInTables/renderMoneyFromCurrency';
import { extLinkSvg } from 'shared/images/extLinkSvg';
import { Link } from 'react-router-dom';

export const DrawerInvoiceLines: React.FC<any> = ({
  show,
  setShow,
  id,
  currencyCode,
}) => {
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const { data: dataInvoiceLine } = useQuery({
    queryKey: [queryNames.INVOICE_LINES_BY_ID],
    meta: {
      token,
      getAllData: true,
      columnParams: InvoiceLineCols,
      additionalSearchParams: `?id=${id}`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (dataInvoiceLine && dataInvoiceLine?.data) {
      form.setFieldsValue(dataInvoiceLine.data);
    }
  }, [form, dataInvoiceLine]);

  const InputCurrency = (
    <InputNumber
      className={styles.inputNum}
      min={0}
      formatter={(value: any) => renderMoneyWithCurrency(value, currencyCode)}
      parser={(value: any) => {
        const amount = moneyFromCurrency(value);
        let roundedAmount = Math.round(Number(amount) * 1000);
        return roundedAmount;
      }}
      precision={2}
      step={1000}
    />
  );

  return (
    <Drawer
      width={window.innerWidth < 700 ? '100%' : '700px'}
      title="Invoice line details"
      placement="right"
      onClose={onClose}
      open={show}
    >
      <div className={styles.item}>
        <span>Order: </span>
        <Link
          to={`/orders/all/${dataInvoiceLine?.data?.order?.id}`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {dataInvoiceLine?.data?.order?.code}
          {extLinkSvg()}
        </Link>
      </div>
      <Form
        className={styles.form}
        layout="vertical"
        form={form}
        autoComplete="off"
        disabled
      >
        <Form.Item label="Code" name="code" className={styles.form__item}>
          <Input />
        </Form.Item>
        <Form.Item label="Title" name="title" className={styles.form__item}>
          <Input />
        </Form.Item>
        <Form.Item label="Amount" name="amount" className={styles.form__item}>
          {InputCurrency}
        </Form.Item>
        <Form.Item
          label="Discount"
          name="discount"
          className={styles.form__item}
        >
          {InputCurrency}
        </Form.Item>
        <Form.Item
          label="Total"
          name="discount_sum"
          className={styles.form__item}
        >
          {InputCurrency}
        </Form.Item>
      </Form>
    </Drawer>
  );
};
