export const hideInSetting = [
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'fulfilment.user.id',
  'fulfilment.user.email',
  'fulfilment.user.first_name',
  'fulfilment.user.middle_name',
  'fulfilment.user.last_name',
  'fulfilment.user.type',
  'content_exif',
  'state_id',
  'state.name',
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'fulfilment.user.id',
  'fulfilment.user.email',
  'fulfilment.user.first_name',
  'fulfilment.user.middle_name',
  'fulfilment.user.last_name',
  'fulfilment.user.type',
  'content_exif',
  'state_id',
  'state.name',
  'content_flags',
  'content_key',
  'content_path',
  'fulfilment_id',
  'fulfilment.id',
  'fulfilment.order_id',
  'fulfilment.user_id',
  'fulfilment.account_id',
  'fulfilment.state_id',
  'fulfilment.payout',
  'fulfilment.comments',
  'fulfilment.created_at',
  'fulfilment.updated_at',
  'fulfilment.expired_at',
  'fulfilment.completed_at',
  'fulfilment.deleted_at',
  'fulfilment.state.id',
  'fulfilment.state.name',
  'fulfilment.state.description',
  'fulfilment.user.id',
  'fulfilment.user.email',
  'fulfilment.user.first_name',
  'fulfilment.user.middle_name',
  'fulfilment.user.last_name',
  'fulfilment.user.type',
  'fulfilment.account.id',
  'fulfilment.account.code',
  'fulfilment.account.name',
  'fulfilment.account.status',
  'order.account_id',
  'order_id',
  'order.id',
  'order.user_id',
  'order.base_price_id',
  'order.subscription_id',
  'order.state_id',
  'order.industry_id',
  'order.currency_id',
  'order.country_id',
  'order.payment_status_id',
  'order.payment_method_id',
  'order.payment_type',
  'order.external_id',
  'order.location_lat',
  'order.location_long',
  'order.title',
  'order.address',
  'order.addr_city',
  'order.addr_state',
  'order.addr_zip',
  'order.content_type',
  'order.complications',
  'order.quantity',
  'order.preference',
  'order.payout',
  'order.overhead',
  'order.price',
  'order.discount',
  'order.tax_sum',
  'order.total',
  'order.flags',
  'order.live_date',
  'order.started_at',
  'order.published_at',
  'order.expired_at',
  'order.completed_at',
  'order.paid_at',
  'order.created_at',
  'order.updated_at',
  'order.deleted_at',
  'fulfilment.state.id',
  'fulfilment.state.name',
  'fulfilment.state.description',
  'order.user.id',
  'order.user.email',
  'order.user.first_name',
  'order.user.middle_name',
  'order.user.last_name',
  'order.user.type',
  'order.account.id',
  'order.account.code',
  'order.account.name',
  'order.account.status',
  'state.description',
  'user_id',
];
