export const columnsCards = [
  'id',
  'is_expired!',
  'brand_code',
  'name',
  'exp_month',
  'exp_year',
  'access_type',
  'status',
];
