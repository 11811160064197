import { isObject } from './isObject';

export function handleChangeFields(changedFields: any, dataToBack: any) {
  const correctField =
    changedFields.length > 0 && changedFields[0].errors.length === 0
      ? changedFields[0]
      : 'Error';
  let currentField: { [key: string]: any } = {};
  let currentKey = '';
  if (correctField !== 'Error') {
    if (correctField.name.length === 0) {
      currentField[correctField.name[0]] = correctField.value;
      currentKey = correctField.name[0];
    } else {
      let newObjects = currentField;
      const arrNames = correctField.name;
      for (let i = 0; i < arrNames.length; i++) {
        const lastChild = i === arrNames.length - 1;
        newObjects = newObjects[arrNames[i]] = lastChild
          ? correctField.value
          : {};
      }
      currentKey = arrNames[0];
    }
  }
  // if (currentField && Object.keys(currentField).length > 0) {
  //     dataToBack.forEach((field: any) => {
  //         return {...field, currentKey: currentField[currentKey]};
  //     })
  //     dataToBack.push(currentField);
  // }

  if (
    dataToBack.length > 0 &&
    currentField &&
    Object.keys(currentField).length > 0
  ) {
    const checkKeyPresenceInArray = dataToBack.some((field: any) =>
      Object.keys(field).includes(currentKey)
    );
    if (checkKeyPresenceInArray) {
      dataToBack.forEach((field: any) => {
        for (let key in field) {
          if (key === currentKey) {
            if (isObject(field[currentKey])) {
              field[currentKey] = Object.assign(
                field[currentKey],
                currentField[currentKey]
              );
            } else {
              field[currentKey] = currentField[currentKey];
            }
          }
        }
      });
    } else {
      dataToBack.push(currentField);
    }
  } else if (
    dataToBack.length === 0 &&
    currentField &&
    Object.keys(currentField).length > 0
  ) {
    dataToBack.push(currentField);
  }

  return dataToBack;
}
