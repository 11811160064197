export const defaultEnabledSettings = {
  code: { order: 1 },
  'order.title': { order: 2 },
  'order.code': { order: 3 },
  'state.id': { order: 4 },
  payout: { order: 5 },
  'order.payout': { order: 6 },
  'order.content_type': { order: 7 },
  created_at: { order: 8 },
  expired_at: { order: 9 },
};
