export const ACTION_TYPES = Object.freeze({
  GET_FULFILMENTS: '@@JOBS/GET_FULFILMENTS',
  GET_FULFILMENTS_PENDING: '@@JOBS/GET_FULFILMENTS_PENDING',
  GET_FULFILMENTS_FULFILLED: '@@JOBS/GET_FULFILMENTS_FULFILLED',
  GET_FULFILMENTS_REJECTED: '@@JOBS/GET_FULFILMENTS_REJECTED',

  GET_FULFILMENTS_FOR_ACCOUNTS: '@@JOBS/GET_FULFILMENTS_FOR_ACCOUNTS',
  GET_FULFILMENTS_FOR_ACCOUNTS_PENDING:
    '@@JOBS/GET_FULFILMENTS_FOR_ACCOUNTS_PENDING',
  GET_FULFILMENTS_FOR_ACCOUNTS_FULFILLED:
    '@@JOBS/GET_FULFILMENTS_FOR_ACCOUNTS_FULFILLED',
  GET_FULFILMENTS_FOR_ACCOUNTS_REJECTED:
    '@@JOBS/GET_FULFILMENTS_FOR_ACCOUNTS_REJECTED',

  GET_ORDERS: '@@JOBS/GET_ORDERS',
  GET_ORDERS_PENDING: '@@JOBS/GET_ORDERS_PENDING',
  GET_ORDERS_FULFILLED: '@@JOBS/GET_ORDERS_FULFILLED',
  GET_ORDERS_REJECTED: '@@JOBS/GET_ORDERS_REJECTED',

  REMOVE_ORDERS: '@@JOBS/REMOVE_ORDERS',
  REMOVE_JOBS: '@@JOBS/REMOVE_JOBS',
});
