export const hideInSetting = [
  'id',
  'user_id',
  'user.id',
  // 'account_id',
  'account.id',
  'account.status',
  'currency.id',
  'currency.name',
  'currency.entity',
  'currency.is_supported',
  'provider_tx_id',
  'payment_status.id',
  'payment_status.description',
  'payment_provider.id',
  'payment_provider.description',
  'payment_method.id',
  'invoice.id',
  'order.preference',
  'currency.id',
  'invoice.account_id',
  'currency_id',
  'order.currency_id',
  'invoice.state_id',
  'invoice.memo',
  'invoice.amount',
  'invoice.discount',
  'invoice.discount_sum',
  'invoice.payed_sum',
  'invoice.start_date',
  'invoice.end_date',
  'invoice.paid_at',
  'invoice.currency_id',
  'payment_status.name',
  'order_id',
  'currency_id',
  'payment_provider_id',
  'payment_method_id',
  'payment_operation_id',
  'provider_tx_id',
  'provider_tx_status',
  'currency.id',
  'currency.name',
  'order.id',
  'order.user_id',
  'order.account_id',
  'order.base_price_id',
  'order.subscription_id',
  'order.state_id',
  'order.industry_id',
  'order.currency_id',
  'order.country_id',
  'order.payment_status_id',
  'order.payment_method_id',
  'order.payment_type',
  'order.external_id',
  'order.location_lat',
  'order.location_long',
  'order.address',
  'order.addr_city',
  'order.addr_state',
  'order.addr_zip',
  'order.content_type',
  'order.complications',
  'order.quantity',
  'order.preference',
  'order.payout',
  'order.overhead',
  'order.price',
  'order.discount',
  'order.tax_sum',
  'order.total',
  'order.flags',
  'order.live_date',
  'order.started_at',
  'order.published_at',
  'order.expired_at',
  'order.completed_at',
  'order.paid_at',
  'order.created_at',
  'order.updated_at',
  'order.deleted_at',
];
