import { Tag } from 'antd';
import styles from './styles.module.scss';
import { SearchFilterTagsT } from './types';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { useMemo } from 'react';
import { isGuid } from 'shared/utils/isGuid';
import { deleteFilters } from '../../utils/deleteFilters';

export const SearchFilterTags = ({
  searchParams,
  dataHeader,
  setSearchParams,
  defaultSorter,
  setSorter,
  setDefaultSortingElems,
  setFilters,
  setSearchKeys,
}: SearchFilterTagsT) => {
  const deleteSearchFromUrl = (param: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const { key, value } = param;
    if (value.includes('ascend') || value.includes('descend')) {
      setSorter((prevSorter: any) => {
        return prevSorter.filter((prevSorterElem: any) => {
          return prevSorterElem.field !== key;
        });
      });
      setDefaultSortingElems((prevSorter: any) => {
        return prevSorter.filter((prevSorterElem: any) => {
          return prevSorterElem !== key;
        });
      });
    } else {
      setFilters((prevFilter: any) => deleteFilters(prevFilter, key));
      if (key.includes('search')) {
        setSearchKeys({});
      }
    }
    urlParams.delete(key);
    const urlParamsString = urlParams.toString();
    setSearchParams(urlParamsString, { replace: true });
  };

  const formatParamName = (paramName: string) => {
    let formattedName = '';
    if (paramName.includes('preference')) {
      const paramNameField = paramName.split('::')[0];
      const paramValueField = paramName.split('::')[1];
      const prefJsonObj = dataHeader.data.columns.find(
        (header: any) => header.alias === paramNameField
      )?.json_fields;
      const prefTitle = prefJsonObj.find(
        (item: any) => item.name === paramValueField
      )?.title;
      formattedName = prefTitle;
    } else {
      const sufficses = '__';
      const paramNameField =
        paramName.indexOf(sufficses) > 0
          ? paramName.slice(0, paramName.indexOf(sufficses))
          : paramName;
      formattedName = dataHeader.data.columns.find(
        (header: any) => header?.alias === paramNameField
      )?.title;
    }
    return formattedName;
  };

  const formatParamValue = (paramName: string, paramValue: string) => {
    let formattedValue: any = paramValue;
    const onlyNumbers = /^\d+$/;
    if (isGuid.test(paramValue)) {
      const filterItems = dataHeader.data.columns.find(
        (header: any) => header?.alias === paramName
      )?.filter_items;
      const itemTitle = filterItems
        ? filterItems.find((item: any) => item.value === paramValue)?.display
        : '-';
      formattedValue = itemTitle;
    }
    if (
      (paramName.includes('date') || paramName.includes('at')) &&
      onlyNumbers.test(paramValue)
    ) {
      formattedValue = convertUNIXToLocale(+paramValue, null);
    }
    return formattedValue;
  };

  const tags: { key: string; value: string[] }[] | undefined = useMemo(() => {
    let result;
    if (
      searchParams &&
      searchParams.size > 0 &&
      dataHeader &&
      dataHeader.hasOwnProperty('data')
    ) {
      const searchP = new URLSearchParams(window.location.search);
      let arr: { key: string; value: string }[] = [];

      for (const [key, value] of searchP.entries()) {
        arr.push({ key, value });
      }

      result = Array.from(new Set(arr.map((s) => s.key))).map((lab) => {
        return {
          key: lab,
          value: arr
            .filter((s) => s.key === lab)
            .map((edition) => edition.value),
        };
      });
    }
    return result;
  }, [searchParams, dataHeader]);

  const arrayOfDefaultSorter =
    defaultSorter && Array.isArray(defaultSorter)
      ? defaultSorter
      : defaultSorter
      ? [defaultSorter]
      : [];
  const arrayOfFieldsSorter = arrayOfDefaultSorter.map(
    (sorter: any) => sorter?.field
  );

  return tags && Array.isArray(tags) ? (
    <ul className={styles.paramsBlock}>
      {tags.map((param) => (
        <Tag
          color={
            param.value.toString() === 'descend' ||
            param.value.toString() === 'ascend'
              ? 'green'
              : 'geekblue'
          }
          key={param.key + param.value}
          closable={true}
          onClose={() => deleteSearchFromUrl(param)}
        >
          <span className={styles.paramTitle}>
            {param.value.toString() === 'descend' ||
            param.value.toString() === 'ascend'
              ? 'Sort - '
              : ''}
            {formatParamName(param.key)}:
          </span>
          {param.value.map((value, i) => (
            <span className={styles.paramValue} key={value + i}>
              {formatParamValue(param.key, value)}
              {i + 1 !== param.value.length ? ', ' : ''}
            </span>
          ))}
        </Tag>
      ))}
    </ul>
  ) : (
    <></>
  );
};
