import clsx from 'clsx';
import styles from './styles.module.scss';
import { Form, Input, Select, Tag, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EvaluationBlock } from 'components/ui_components/EvaluationBlock';
import { APP_CONSTANS } from 'shared/constants';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { useEffect, useState } from 'react';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { statesCols } from './columns';

export const JobHeader: React.FC<any> = ({
  deletedAt,
  title,
  objectType,
  id,
  stateId,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [status, setStatus] = useState<string>(stateId);
  const [statuses, setStatuses] = useState<any[] | undefined>();
  const getAllData = true;

  const isSuperuser = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  });

  const isEditor = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.EDITOR],
  });

  useEffect(() => {
    setStatus(stateId);
  }, [stateId]);

  const {
    isLoading: isLoadingStates,
    isError: isErrorStates,
    data: states,
  } = useQuery({
    queryKey: [
      `${
        objectType === 'job'
          ? queryNames.FULFILMENT_STATES
          : objectType === 'order'
          ? queryNames.ORDER_STATES
          : ''
      }`,
    ],
    meta: {
      token,
      getAllData,
      columnParams: statesCols,
    },
    queryFn: useGetData,
    enabled: !!id && objectType !== 'tmpl',
  });

  useEffect(() => {
    if (
      !isLoadingStates &&
      !isErrorStates &&
      states &&
      Array.isArray(states?.data)
    ) {
      const sorted = states?.data.sort((a: any, b: any) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      const formattedStatuses = sorted.map((state: any) => {
        return {
          value: state.id,
          label: <Tooltip title={state.description}>{state.name}</Tooltip>,
        };
      });
      setStatuses(formattedStatuses);
    }
  }, [isLoadingStates, isErrorStates, states]);

  return (
    <div className={styles.header}>
      {objectType === 'job' || isEditor ? (
        <h3
          className={clsx(
            styles.header__title,
            deletedAt && styles.header__title_disabled
          )}
        >
          {title}
        </h3>
      ) : (
        <Form.Item className={styles.header__titleEditable} name={'title'}>
          <Input.TextArea
            className={styles.header__titleEditable__area}
            size="large"
            autoSize={{ minRows: 1, maxRows: 3 }}
          />
        </Form.Item>
      )}
      {statuses &&
        Array.isArray(statuses) &&
        status &&
        objectType !== 'tmpl' && (
          <div className={styles.header__actions}>
            <EvaluationBlock
              isJob={objectType === 'job'}
              stateId={stateId}
              deletedAt={deletedAt}
            />

            {deletedAt ? (
              <Tooltip
                title={
                  objectType === 'job'
                    ? `Previous status: ${APP_CONSTANS.FULFILMENT_STATUSES_BY_GUID[status]}`
                    : `Previous status: ${APP_CONSTANS.ORDER_STATUSES_BY_GUID[status]}`
                }
              >
                <Tag
                  className={styles.header__tag}
                  icon={<ExclamationCircleOutlined />}
                >
                  Deleted
                </Tag>
              </Tooltip>
            ) : (
              <Form.Item name="state_id" className={styles.formItem}>
                <Select
                  className={clsx(
                    'selectJobOrderStatus',
                    styles.select,
                    `${
                      objectType === 'job'
                        ? `selectJobStatus__${APP_CONSTANS.FULFILMENT_STATUSES_BY_GUID[status]}`
                        : `selectOrderStatus__${APP_CONSTANS.ORDER_STATUSES_BY_GUID[status]}`
                    }`
                  )}
                  popupClassName={clsx('selectJobOrderStatus__dropdown')}
                  value={status}
                  options={statuses}
                  disabled={!isSuperuser}
                  data-e2e="jobOrderStatus"
                />
              </Form.Item>
            )}
          </div>
        )}
    </div>
  );
};
