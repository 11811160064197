import { tablesNames } from 'api/tablesNames';
import { UserType } from 'components/pages/main/users_section/Users/types';
import { UsersTable } from 'components/ui_components/UsersTable';
import { UsersTableT } from 'components/ui_components/UsersTable/types';
import { Dispatch, FC } from 'react';
import { additionalSearchParamsToQueryKeys } from 'shared/utils/api/additionalSearchParamsToQueryKeys';
import { Address } from 'types/global';
import { statusActiveSearchParams } from '../../utils/constants';
import { getCenterAndRadiusAdditionalSearchParams } from '../../utils/getCenterAndRadiusAdditionalSearchParams';
import { defaultEnabledSettings } from './defaultEnabledSettings';
import { hideInSettings } from './hideInSettings';
import styles from './styles.module.scss';
import { queryNames } from 'api/queryNames';

const meta = {
  includedDeleted: false,
  onlyDeleted: false,
  types: 'photographer',
  // additionalSearchParams: `&value_equal=acc.status%2Cactive`,
};

type GlobalMapPhotographersTableProps = Partial<UsersTableT> & {
  radius?: number;
  center?: Address;
  setFilters: Dispatch<any>;
  setIsDeleted: Dispatch<string | null>;
  dataRequestEnabled: boolean;
  show: boolean;
  resetAllFiltering: boolean;
  selectedTables: string[];
};

export const GlobalMapPhotographersTable: FC<
  GlobalMapPhotographersTableProps
> = ({
  radius,
  center,
  setFilters,
  dataRequestEnabled,
  show,
  setIsDeleted,
  resetAllFiltering,
  selectedTables,
}) => {
  const centerAndRadiusSearchParams = getCenterAndRadiusAdditionalSearchParams({
    center,
    radius,
  });
  const additionalSearchParams = centerAndRadiusSearchParams
    ? `${centerAndRadiusSearchParams}&${statusActiveSearchParams}`
    : `?${statusActiveSearchParams}`;
  const parsedSearchParams = additionalSearchParamsToQueryKeys(
    additionalSearchParams
  );

  const tableContainerClassName = !show ? styles.hidden : '';

  return (
    <div className={tableContainerClassName}>
      {show && (
        <UsersTable
          getFilters={setFilters}
          getIsDeleted={setIsDeleted}
          defaultEnabledSettings={defaultEnabledSettings}
          queryKey={[queryNames.USERS_ALL, ...parsedSearchParams]}
          meta={meta}
          headerName={tablesNames.UsersHeader}
          toolbar={null}
          tableName={tablesNames.GlobalMapPhotographersCol}
          hideInSetting={hideInSettings}
          type={UserType.PHOTOGRAPHER}
          tableClassName="globalMapPhotographers"
          quantityTitleText="photographers"
          additionalSearchParams={`${additionalSearchParams}`}
          dataRequestEnabled={dataRequestEnabled && !!center}
          openLinkInNewTab={true}
          resetAllFiltering={resetAllFiltering}
          hideSortingTags={selectedTables.length === 2}
        />
      )}
    </div>
  );
};
