export enum tablesNames {
  // HEADERS

  AccountsHeader = 'accounts/header',
  AccountAddressHeader = 'account_addresses/header',
  UsersHeader = 'users/header',
  UserAccountsHeader = 'user_accounts/header',
  CountriesHeader = 'countries/header',
  RegionsHeader = 'country_regions/header',
  GeonamesHeader = 'geonames/header',
  TimezonesHeader = 'country_timezones/header',
  CurrenciesHeader = 'currencies/header',
  OrderStatesHeader = 'order_states/header',
  OrderPaymentStatesHeader = 'order_payment_states/header',
  JobStatesHeader = 'fulfilment_states/header',
  ContentStatesHeader = 'content_states/header',
  RequestStatesHeader = 'request_states/header',
  CompletedButNotPaidJobsHeader = 'fulfilments/header',
  JobsToPaidJobsHeader = 'fulfilments/header',
  OrdersHeader = 'orders/header',
  JobsHeader = 'fulfilments/header',
  InvoicesHeader = 'invoices/header',
  InvoiceItemsHeader = 'invoice_items/header',
  InvoiceLinesHeader = 'invoice_lines/header',
  UserAddressesHeader = 'user_addresses/header',
  UserRequestsHeader = 'user_requests/header',
  FulfilmentContentsHeader = 'fulfilment_contents/header',
  PaymentStatesHeader = 'payment_statuses/header',
  FuflilmentRequestsHeader = 'fulfilment_requests/header',
  CheckPointsHeader = 'check_points/header',
  PaymentTransactionsHeader = 'payment_transactions/header',
  PaymentOperationsHeader = 'payment_operations/header',
  TemplatesHeader = 'templates/header',
  IndustriesHeader = 'industries/header',
  HistoriesHeader = 'histories/header',
  ComplicationsHeader = 'complications/header',
  UserApiKeysHeader = 'api_keys/header',
  PreferencesHeader = 'preferences/header',
  SystemPreferencesHeader = 'system_preferences/header',
  SystemPreferencesOptionsHeader = 'preference_options/header',
  SubscriptionsHeader = 'subscriptions/header',
  PurchasesHeader = 'purchases/header',
  PurchaseTransactionsHeader = 'purchase_transactions/header',
  PurchaseOperationsHeader = 'purchase_operations/header',
  PurchaseItemsHeader = 'purchase_items/header',
  PurchaseStatesHeader = 'purchase_states/header',
  PurchaseProvidersHeader = 'purchase_providers/header',
  PurchaseMethodsHeader = 'purchase_methods/header',
  PayoutStatesHeader = 'payout_statuses/header',
  ProviderTargetsHeader = 'provider_targets/header',
  PurchaseTargetsHeader = 'purchase_targets/header',
  PaymentMethodsHeader = 'payment_methods/header',
  PaymentProvidersHeader = 'payment_providers/header',
  InvoiceStatesHeader = 'invoice_states/header',

  // SETTINGS / COLUMNS

  ManageUsersAllCol = 'users_all',
  ManageUsersPhotographersCol = 'users_photographers',
  ManageUsersCustomersCol = 'users_customers',
  ManageUsersInternalCol = 'users_internal',
  ManageUsersIncludeDeletedCol = 'users_include_deleted',
  ManageUsersOnlyDeletedCol = 'users_only_deleted',

  ManageAccountsAllCol = 'accounts_all',
  ManageAccountsCustomerCol = 'accounts_customer',
  ManageAccountsPhotographerCol = 'accounts_ph',
  ManageAccountsInternalCol = 'accounts_internal',

  FulfilmentsCol = 'fulfilments_all',
  FulfilmentsSubmittedCol = 'fulfilments_submitted',

  OrdersCol = 'orders_all',
  OrdersSubmittedCol = 'orders_submitted',

  GlobalMapOrdersCol = 'global_map_orders',
  GlobalMapPhotographersCol = 'global_map_photographers',

  AccountUsersListCol = 'account_users_list_col',
  AccountOrdersListCol = 'account_orders_list_col',
  AccountJobsListCol = 'account_jobs_list_col',
  AccountInvoicesListCol = 'account_invoices_list_col',
  AccountNotInvoicedListCol = 'account_not_invoiced_list_col',

  UserAccountsPageAccountCol = 'user_accounts_page_account_col',
  UserAccountsPageUserCol = 'user_accounts_page_user_col',

  CountriesCol = 'countries',
  CountryRegionsCol = 'country_regions',
  GeonamesCol = 'geonames',
  CountryTimezonesCol = 'country_timezones',
  CurrenciesCol = 'currencies',
  OrderStatesCol = 'order_states',
  OrderPaymentStatesCol = 'order_payment_states',
  FulfilmentStatesCol = 'fulfilment_states',
  ContentStatesCol = 'content_states',
  RequestStatesCol = 'request_states',
  PaymentStatesCol = 'payment_statuses',
  PurchaseStatesCol = 'purchase_states',
  PurchaseProvidersCol = 'purchase_providers',
  PurchaseMethodsCol = 'purchase_methods',
  PayoutStatesCol = 'payout_statuses',
  PurchaseTargetsCol = 'purchase_targets',
  InvoicesCol = 'invoices',
  PaymentMethodsCol = 'payment_methods',
  PaymentProvidersCol = 'payment_providers',
  InvoiceStatesCol = 'invoice_states',

  CompletedButNotPaidJobsAllCol = 'completedButNotPaidJobsSettingsAll',
  CompletedButNotPaidJobsPaypalCol = 'completedButNotPaidJobsSettingsPaypal',
  CompletedButNotPaidJobsVenmoCol = 'completedButNotPaidJobsSettingsVenmo',

  UserAddressesCol = 'user_addresses',
  UserJobsCol = 'user_jobs',
  UserOrdersCol = 'user_orders',
  UserRequestsCol = 'user_requests',
  UsersManageRequestsCol = 'users_manage_requests',
  AccountAddressesCol = 'account_addresses',

  FulfilmentContentsInFulfilmentPageCol = 'fulfilment_contents_fulfilment_page',
  FulfilmentContentsInUserPageCol = 'fulfilment_contents_user_page',
  FulfilmentContentsGeneralTableCol = 'fulfilmentContentsGeneral',
  FulfilmentRequestsInFulfilmentPageCol = 'fulfilment_requests_fulfilment_page',
  FulfilmentRequestsInGeneralTableCol = 'fulfilment_requests_general',

  TemplatesCol = 'templates',

  CheckPointsCol = 'check_points',

  PaymentOperationsCol = 'payment_operations_col',
  PaymentOperationsPaymentCol = 'payment_operations_payment_col',
  PaymentOperationsRefundedCol = 'payment_operations_refunded_col',
  PaymentOperationsVerificationCol = 'payment_operations_verification_col',

  PaymentTransactionsCol = 'payment_transactions_col',
  PurchasesCol = 'purchases_col',
  PurchaseItemsCol = 'purchase_operations_col',
  PurchaseTransactionsCol = 'purchase_transactions_col',
  PurchaseOperationsCol = 'purchase_operations_col',
  InvoiceItemsCol = 'invoice_items_col',
  InvoiceLinesCol = 'invoice_lines_col',

  IndustriesCol = 'industries_col',
  HistoriesCol = 'histories_col',
  HistoriesInOrderCol = 'histories_in_order_col',
  HistoriesInJobCol = 'histories_in_job_col',
  HistoriesInContentModalCol = 'histories_in_content_modal_col',
  HistoriesInAccountCol = 'histories_in_content_modal_col',
  HistoriesInInvoiceCol = 'histories_in_invoice_col',
  HistoriesInPurchaseCol = 'histories_in_purchase_col',
  ComplicationsCol = 'complications_col',
  UserApiKeysCol = 'user_api_keys_col',
  SystemPrefCols = 'system_preferences_col',
  AccountPrefCols = 'account_preferences_col',
  UserPrefCols = 'user_preferences_col',
  SystemPreferencesOptionsCol = 'preference_options_col',
  SubscriptionsCol = 'subscriptions_col',
  ProviderTargetsCol = 'provider_targets_col',
}
