import { QueryMeta, useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useSelector } from 'react-redux';
import { additionalSearchParamsToQueryKeys } from 'shared/utils/api/additionalSearchParamsToQueryKeys';

export const useGetOrders = ({
  additionalSearchParams,
  meta,
  baseQueryKey,
  enabled = true,
}: {
  additionalSearchParams: string;
  meta?: QueryMeta;
  baseQueryKey?: string;
  enabled?: boolean;
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const parsedSearchParams = additionalSearchParamsToQueryKeys(
    additionalSearchParams
  );

  const baseQueryKeyValue = baseQueryKey || queryNames.ORDERS;

  return useQuery({
    queryKey: [baseQueryKeyValue, ...parsedSearchParams],
    meta: {
      ...(meta ? meta : {}),
      additionalSearchParams,
      // TODO: Pass token to base query
      token,
    },
    enabled: enabled,
    // TODO: Pass useGetData to base query
    queryFn: useGetData,
  });
};
