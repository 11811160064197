import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export const LevelChange: FunctionComponent<any> = ({
  userId,
  firstName,
  middleName,
  lastName,
  levelData,
}) => {
  const returnYesNoAnswer = (field: string) => {
    return field && JSON.parse(field) ? 'Yes' : 'No';
  };

  return (
    levelData && (
      <div className={styles.extraData}>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>User:</span>
          <Link to={`/users/all/${userId}`}>
            {`${firstName} ${middleName} ${lastName}`}
          </Link>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>Upgrade:</span>
          <span className={styles.extraData__value}>{levelData?.level}</span>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>Years of experience:</span>
          <span className={styles.extraData__value}>
            {levelData?.experience}
          </span>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>
            Earn a living by taking photos/videos:
          </span>
          <span className={styles.extraData__value}>
            {returnYesNoAnswer(levelData?.earn_money || '')}
          </span>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>
            Degree in Cinematography/related field:
          </span>
          <span className={styles.extraData__value}>
            {returnYesNoAnswer(levelData?.education || '')}
          </span>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>Portfolio:</span>
          <a
            onClick={() => {
              window.open(
                `${
                  levelData?.portfolio_link.includes('http') ? '' : 'https://'
                }${levelData?.portfolio_link}`,
                '_blank'
              );
            }}
          >
            {levelData?.portfolio_link}
          </a>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>Cameras owned:</span>
          <span className={styles.extraData__value}>{levelData?.camera}</span>
        </p>
        <p className={styles.extraData__item}>
          <span className={styles.extraData__label}>
            Stabilization equipment:
          </span>
          <span className={styles.extraData__value}>
            {returnYesNoAnswer(levelData?.stabilization)}
          </span>
        </p>
      </div>
    )
  );
};
