import { Drawer, Form, Input, InputNumber } from 'antd';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { PurchaseItemCols } from './columns';
import useGetData from 'api/useGetData';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { moneyFromCurrency } from 'shared/utils/DataInTables/renderMoneyFromCurrency';
import { extLinkSvg } from 'shared/images/extLinkSvg';

export const DrawerPurchaseItems: React.FC<any> = ({
  show,
  setShow,
  id,
  currencyCode,
}) => {
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const { data: dataPurchaseItem } = useQuery({
    queryKey: [queryNames.PurchaseItemsById],
    meta: {
      token,
      getAllData: true,
      columnParams: PurchaseItemCols,
      additionalSearchParams: `?id=${id}`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (dataPurchaseItem && dataPurchaseItem?.data) {
      form.setFieldsValue(dataPurchaseItem.data);
    }
  }, [form, dataPurchaseItem]);

  return (
    <Drawer
      width={window.innerWidth < 700 ? '100%' : '700px'}
      title="Purchase item details"
      placement="right"
      onClose={onClose}
      open={show}
    >
      <div className={styles.item}>
        <span>Job: </span>
        <a
          href={`/fulfilments/all/${dataPurchaseItem?.data?.fulfilment_id}`}
          target="_blank"
          className={styles.link}
        >
          {dataPurchaseItem?.data?.fulfilment?.code}
          {extLinkSvg()}
        </a>
      </div>
      <Form
        className={styles.form}
        layout="vertical"
        form={form}
        autoComplete="off"
        disabled
      >
        <Form.Item label="Code" name="code" className={styles.form__item}>
          <Input />
        </Form.Item>
        <Form.Item label="Title" name="title" className={styles.form__item}>
          <Input />
        </Form.Item>

        <Form.Item label="Amount" name="amount" className={styles.form__item}>
          <InputNumber
            className={styles.inputNum}
            min={0}
            formatter={(value: any) =>
              renderMoneyWithCurrency(value, currencyCode)
            }
            parser={(value: any) => {
              const amount = moneyFromCurrency(value);
              let roundedAmount = Math.round(Number(amount) * 1000);
              return roundedAmount;
            }}
            precision={2}
            step={1000}
          />
        </Form.Item>

        <Form.Item
          label="Paid Amount"
          name="payed_sum"
          className={styles.form__item}
        >
          <InputNumber
            className={styles.inputNum}
            min={0}
            formatter={(value: any) =>
              renderMoneyWithCurrency(value, currencyCode)
            }
            parser={(value: any) => {
              const amount = moneyFromCurrency(value);
              let roundedAmount = Math.round(Number(amount) * 1000);
              return roundedAmount;
            }}
            precision={2}
            step={1000}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};
