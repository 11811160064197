import { AdminPageContainer } from 'components/app/AdminPageContainer';
import { Loader } from 'components/ui_components/Loader';
import { useAuthorize } from 'hooks/general_hooks/useAuthorize';
import { lazy, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOnMount } from 'shared/hooks/useOnMount.hook';

const MainRoutes = lazy(() => import('features/Routes/MainRoutes'));

export const AppContainer = () => {
  const [render, setRender] = useState(<Loader />);
  const isAuthorized = useSelector((state: any) => state.auth.is_authorizated);
  const authorizeHandler = useAuthorize();
  const isManualLogout = useSelector((state: any) => state.auth.manualLogout);

  const renderModule = async () => {
    if (!isAuthorized && !isManualLogout) {
      authorizeHandler();
    }

    const ModuleComponent = () => {
      return (
        <AdminPageContainer>
          <MainRoutes />
        </AdminPageContainer>
      );
    };

    setRender(<ModuleComponent />);
  };

  useOnMount(() => {
    renderModule();
  });

  return render;
};
