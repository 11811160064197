import { Form, Select, Tag } from 'antd';
import { APP_CONSTANS } from 'shared/constants';
import { AccountPrefsQueryT, PaymentTypeSelectT } from './types';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { columnsCards } from './columns';

export const PaymentTypeSelect = ({
  accountId,
  isHidden,
  isDisabled,
  formInstance,
  setSelectedOrders,
  typeName = 'payment_type',
  methodName = 'payment_instrument',
  invoicePaymentType,
}: PaymentTypeSelectT) => {
  const token = useSelector((state: any) => state.auth.token);
  const [currentPaymentType, setCurrentPaymentType] = useState<string>('');

  const getAllData = true;

  const { INVOICE_INFO_GUID, CARD_INFO_GUID, DEFAULT_PAY_INSTRUMENT } =
    APP_CONSTANS;
  const accPrefIds = [
    INVOICE_INFO_GUID,
    CARD_INFO_GUID,
    DEFAULT_PAY_INSTRUMENT,
  ];
  const { isInitialLoading: isInitialLoadingAccPrefs, data: accPrefs } =
    useQuery<AccountPrefsQueryT>({
      queryKey: [queryNames.ACCOUNTS_getAccountPreferencesById, accountId],
      meta: {
        token,
        getAllData,
        additionalSearchParams: `?account_id=${accountId}&preference_ids=${accPrefIds.join(
          '&preference_ids='
        )}`,
      },
      queryFn: useGetData,
      enabled: !!accountId,
    });

  const { isLoading: isLoadingCards, data: cards } = useQuery({
    queryKey: [queryNames.PaymentMethods, accountId],
    meta: {
      token,
      getAllData,
      paymentsApi: true,
      additionalSearchParams: `?account_ids=${accountId}${
        columnsCards ? `&columns=${columnsCards.join('&columns=')}` : ''
      }`,
    },
    queryFn: useGetData,
    enabled: !!accountId,
  });

  useEffect(() => {
    if (invoicePaymentType) {
      formInstance.setFieldValue(typeName, invoicePaymentType);
      return;
    }
    if (accPrefs?.data && Array.isArray(accPrefs.data)) {
      const defaultPymentType = accPrefs.data.find(
        (p) => p?.preference_id === DEFAULT_PAY_INSTRUMENT
      )?.value;
      if (defaultPymentType) {
        setCurrentPaymentType(defaultPymentType);
        formInstance.setFieldValue(typeName, defaultPymentType);
      }
    }
  }, [
    DEFAULT_PAY_INSTRUMENT,
    accPrefs,
    formInstance,
    invoicePaymentType,
    typeName,
  ]);

  const handlePaymentTypeChange = (paymentType: string) => {
    setSelectedOrders && setSelectedOrders([]);
    setCurrentPaymentType(paymentType);
  };

  const optionsListCards = (data: any[]) => {
    if (data && data?.length > 0 && data[0]?.id) {
      return data.map((card: any) => ({
        value: card.id,
        label: `
          ${card['is_expired!']}
          ${' - '}
          ${card?.brand_code}
          ${' - '}
          ${card.name}
          ${' - '}
          ${card?.exp_month}/${card?.exp_year}
          ${' - '}
          ${card.access_type}                
          ${' - '}
          ${card.status}
          `,
        disabled: !(
          card.status === 'VERIFIED' && card['is_expired!'] === 'Valid'
        ),
      }));
    }

    return [
      {
        value: '',
        label: 'No cards in account',
        disabled: true,
        verifyLabel: '',
        expiredLabel: '',
      },
    ];
  };

  return (
    <>
      <Form.Item
        name={typeName}
        label={<span>Payment type</span>}
        hidden={isHidden}
      >
        <Select
          onSelect={handlePaymentTypeChange}
          loading={isInitialLoadingAccPrefs}
          size="small"
          disabled={isDisabled}
          options={
            accPrefs && Array.isArray(accPrefs?.data)
              ? accPrefs.data
                  .find(
                    (pref: { preference_id: string }) =>
                      pref.preference_id === DEFAULT_PAY_INSTRUMENT
                  )
                  ?.preference?.options?.map(({ value, title }) => ({
                    value: value,
                    label: title,
                  }))
              : []
          }
        />
      </Form.Item>
      <Form.Item
        name={methodName}
        label={<span>Payment instrument</span>}
        hidden={isHidden || currentPaymentType.toLowerCase() !== 'card'}
      >
        <Select
          loading={isLoadingCards}
          placeholder="Choose card"
          size="small"
          options={optionsListCards(cards?.data)}
        />
      </Form.Item>
    </>
  );
};
