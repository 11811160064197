import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './styles.module.scss';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import clsx from 'clsx';
import { MainContainer } from 'components/ui_components/MainContainer';
import { Form, Spin, Tabs, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { APP_CONSTANS } from 'shared/constants';
import { APP_PATH } from 'features/Routes/pathes';
import { TabOrderJobDescription } from 'components/ui_components/TabOrderJobDescription';
import { JobHeaderWrapper } from 'components/ui_components/JobHeaderWrapper';
import { usePrompt } from 'hooks/general_hooks/usePrompt';
import { usePostData } from 'api/usePostData';
import { PurchaseUpdateObjectT } from './types';
import { setLastStepBreadcrumb } from 'store';
import { PurchaseHeaderActions } from './components/PurchaseHeaderActions';
import { columns } from './columns';
import { FloatComments } from 'components/ui_components/FloatComments';
import { tablesNames } from 'api/tablesNames';
import { PurchaseItems } from './components/PurchaseItems';
import { PurchaseOperationsInPurchase } from './components/PurchaseOperationsInPurchase';
import { ModalPurchasePayout } from 'components/modals/ModalPurchasePayout';
import { HistoryTab } from 'components/ui_components/HistoryTab';

const PurchasePage: React.FC<any> = () => {
  const params = useParams();
  let { id } = params;
  const location = useLocation();
  const locationState = location.state;
  const navigate = useNavigate();
  const dataToStore = useRef<any>([]);
  const [purchaseForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValuesForm, setInitialValuesForm] = useState<any>({});
  const [enabledSaveChanges, setEnabledSaveChange] = useState<boolean>(false);
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const getAllData = true;
  const dispatch = useDispatch();
  const [updatedKeyForTab, setUpdatedKeyForTab] = useState<string | number>(
    'Items'
  );
  const [currencyCode, setCurrencyCode] = useState<any>(null);
  const [previousPath, setPreviousPath] = useState<any>();

  const {
    isLoading: isLoadingPurchase,
    isError: isErrorPurchase,
    data: dataPurchase,
    error: errorPurchase,
    refetch: refetchPurchase,
  } = useQuery({
    queryKey: [queryNames.PurchasesById, id],
    meta: {
      token,
      getAllData,
      columnParams: columns,
      additionalSearchParams: `?id=${id}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  useQuery({
    queryKey: [tablesNames.PurchasesHeader],
    meta: { token, getAllData: true },
    queryFn: useGetData,
  });

  const { data: dataDefaultPayoutProvider } = useQuery({
    queryKey: [queryNames.Preferences, '7d20b429-c631-4592-a0de-976ff2957643'],
    meta: {
      token,
      getAllData: true,
      columnParams: ['value'],
      additionalSearchParams: '?id=7d20b429-c631-4592-a0de-976ff2957643',
    },
    queryFn: useGetData,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 2,
    onError: () => {
      setLoading(false);
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data, variables) => {
      setLoading(false);
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      if (!variables.otherProps?.includes('drop')) {
        setIsFormDisabled(true);
        setEnabledSaveChange(false);
        message.success('All data saved');
        dataToStore.current = [];
        refetchPurchase();
      }
    },
  });

  const { mutate: mutationDropReview } = useMutation({
    mutationFn: usePostData,
    retry: 2,
    onError: () => {
      setLoading(false);
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
    },
  });

  const purchaseObject = useMemo(() => {
    let returnObject;
    if (dataPurchase) {
      returnObject = {
        purchaseCode: dataPurchase?.data?.code,
        purchaseStateId: dataPurchase?.data?.state?.id,
        purchaseStateName: dataPurchase?.data?.state?.name,
        purchaseStateDescription: dataPurchase?.data?.state?.description,
        purchaseAmount: dataPurchase?.data?.amount,
        purchasePayedSum: dataPurchase?.data?.payed_sum,
        purchasePayedFee: dataPurchase?.data?.payed_fee,
        purchasePayedTotal: dataPurchase?.data?.payed_total,
        purchaseStartDate: dataPurchase?.data?.start_date,
        purchaseEndDate: dataPurchase?.data?.end_date,
        purchaseId: dataPurchase?.data?.id,
      };
    }
    return returnObject;
  }, [dataPurchase]);

  useEffect(() => {
    if (locationState?.previousPath) {
      setPreviousPath(locationState.previousPath);
    }
  }, [locationState]);

  useEffect(() => {
    if (searchParams.has('modal_pay')) {
      searchParams.delete('modal_pay');
      setSearchParams(searchParams);
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  }, [searchParams, setSearchParams]);

  const dropReviewing = useCallback(() => {
    mutationDropReview({
      data: { object_id: dataPurchase?.data?.id, object: APP_CONSTANS.ORDER },
      token: token,
      otherProps: `${APP_PATH.EDIT_ORDER}`,
      method: 'DELETE',
    });
  }, [dataPurchase, mutationDropReview, token]);

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave?',
    enabledSaveChanges,
    dropReviewing
  );

  const tabs: any = useMemo(
    () =>
      id && [
        {
          key: 'Description',
          label: `Description`,
          children: (
            <TabOrderJobDescription
              key={updatedKeyForTab}
              isJob={false}
              isFormDisabled={isFormDisabled}
              currencyCode={currencyCode}
              timezone={dataPurchase?.data?.timezone}
              isPurchase={true}
            />
          ),
        },
        {
          key: 'Items',
          label: `Items`,
          children: (
            <PurchaseItems
              id={dataPurchase?.data?.id}
              currencyCode={currencyCode}
            />
          ),
        },
        {
          key: 'Operations',
          label: `Operations`,
          children: (
            <PurchaseOperationsInPurchase id={dataPurchase?.data?.id} />
          ),
        },
        {
          key: 'History',
          label: 'History',
          children: (
            <HistoryTab
              id={id}
              tableIndicatorClassName={'historyInPurchase'}
              tableName={tablesNames.HistoriesInPurchaseCol}
            />
          ),
        },
      ],
    [id, updatedKeyForTab, isFormDisabled, currencyCode, dataPurchase]
  );
  useEffect(() => {
    if (dataPurchase && dataPurchase?.data) {
      setLoading(false);
    }
  }, [dataPurchase]);

  useEffect(() => {
    if (
      dataPurchase &&
      ((dataPurchase?.hasOwnProperty('error') &&
        dataPurchase.error.hasOwnProperty('code')) ||
        dataPurchase?.toString()?.includes('Error'))
    ) {
      message.warning('Requested purchase is not available to review.');
      navigate('/purchases');
    }
    if (
      !isLoadingPurchase &&
      !errorPurchase &&
      !isErrorPurchase &&
      dataPurchase?.data &&
      dataPurchase?.data?.hasOwnProperty('id') &&
      id
    ) {
      if (dataPurchase?.data?.id === id) {
        setCurrencyCode(dataPurchase?.data?.currency?.code);
        dispatch(setLastStepBreadcrumb(dataPurchase?.data?.code));
        const initialValuesCmpl = {
          memo: dataPurchase?.data?.memo,
        };

        setInitialValuesForm((prevState: any) => ({
          ...prevState,
          ...initialValuesCmpl,
        }));
      }
    }
  }, [
    isLoadingPurchase,
    isErrorPurchase,
    dataPurchase,
    errorPurchase,
    id,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setLastStepBreadcrumb(''));
      setEnabledSaveChange(false);
      if (dataPurchase) {
        dropReviewing();
      }
    };
  }, [dataPurchase, dispatch, dropReviewing]);

  useEffect(() => {
    if (initialValuesForm && purchaseForm) {
      purchaseForm.setFieldsValue(initialValuesForm);
    }
  }, [purchaseForm, initialValuesForm]);

  const submitForm = useCallback(
    (allFields: PurchaseUpdateObjectT) => {
      const data = {
        id,
        ...allFields,
      };

      setLoading(true);

      mutate({
        data: data,
        token: token,
        otherProps: queryNames.Purchases,
        method: 'PUT',
      });

      mutationDropReview({
        data: { object_id: dataPurchase?.data?.id, object: APP_CONSTANS.ORDER },
        token: token,
        otherProps: `${APP_PATH.EDIT_ORDER}`,
        method: 'DELETE',
      });
    },
    [dataPurchase, id, mutate, mutationDropReview, token]
  );

  const resetForm = useCallback(() => {
    dataToStore.current = [];
    purchaseForm.resetFields();
    setEnabledSaveChange(false);
    setIsFormDisabled(true);
    mutationDropReview({
      data: { object_id: dataPurchase?.data?.id, object: APP_CONSTANS.ORDER },
      token: token,
      otherProps: `${APP_PATH.EDIT_ORDER}`,
      method: 'DELETE',
    });
  }, [purchaseForm, dataPurchase, mutationDropReview, token]);

  return (
    <MainContainer expanded className={styles.jobPage}>
      <Spin spinning={loading}>
        <div>
          <div className={styles.jobPage__main}>
            <PurchaseHeaderActions
              id={id}
              stateId={dataPurchase?.data?.state?.id}
              isFormDisabled={isFormDisabled}
              setIsFormDisabled={setIsFormDisabled}
              resetForm={resetForm}
              isDeleted={dataPurchase?.data?.hasOwnProperty('deleted_at')}
              refetchPurchase={refetchPurchase}
              submitForm={purchaseForm.submit}
              setShowModal={setShowModal}
            />

            <div className={styles.jobPage__content}>
              <Form
                form={purchaseForm}
                layout="vertical"
                onFinish={submitForm}
                className={clsx(styles.form, 'orderJobForm')}
                initialValues={initialValuesForm}
                onFieldsChange={async (changedFields) => {
                  if (changedFields.length === 0) {
                    setEnabledSaveChange(false);
                    return;
                  }
                  if (dataPurchase?.data?.deleted_at) {
                    setEnabledSaveChange(false);
                    return;
                  }
                  setEnabledSaveChange(true);
                  setUpdatedKeyForTab(Date.now());
                }}
                disabled={isFormDisabled}
              >
                <div className={clsx(styles.jobPage__contentBlock)}>
                  <JobHeaderWrapper
                    objectType={APP_CONSTANS.PURCHASE}
                    currency={currencyCode}
                    {...purchaseObject}
                    accountId={dataPurchase?.data?.account?.id}
                    createdAt={convertUNIXToLocale(
                      dataPurchase?.data?.created_at,
                      null
                    )}
                    updatedAt={convertUNIXToLocale(
                      dataPurchase?.data?.updated_at,
                      null
                    )}
                    paidAt={convertUNIXToLocale(
                      dataPurchase?.data?.paid_at,
                      null
                    )}
                  />
                  <Tabs
                    defaultActiveKey="Items"
                    tabPosition="top"
                    items={tabs}
                    className={styles.tabs}
                    destroyInactiveTabPane={true}
                  />
                </div>
              </Form>
              {dataPurchase?.data?.id && !isLoadingPurchase && (
                <FloatComments
                  hasInternalTab={true}
                  inDrawer={true}
                  objectType={APP_CONSTANS.PURCHASE}
                  objectId={dataPurchase?.data?.id}
                  refetchInterval={60000}
                />
              )}
            </div>
          </div>
        </div>
        {showModal && id && (
          <ModalPurchasePayout
            id={id}
            code={dataPurchase?.data?.code}
            showModal={showModal}
            setShowModal={setShowModal}
            currencyCode={currencyCode}
            purchaseMethodId={dataPurchase?.data?.purchase_method_id}
            dataDefaultPayoutProvider={
              dataDefaultPayoutProvider?.data[0]?.value
            }
            accountId={dataPurchase?.data?.account_id}
            previousPath={previousPath}
          />
        )}
      </Spin>
    </MainContainer>
  );
};

export default PurchasePage;
