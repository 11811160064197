import { MarkerF, MarkerProps } from '@react-google-maps/api';
import { FC } from 'react';
import { Address } from 'types/global';

export type MapUserLocationMarkerProps = Omit<MarkerProps, 'position'> & {
  position: Address | null;
};

export const MapUserLocationMarker: FC<MapUserLocationMarkerProps> = ({
  position,
  ...rest
}) => {
  if (!position) {
    return null;
  }

  return (
    <MarkerF
      position={position}
      clickable={false}
      title="Current location"
      {...rest}
    />
  );
};
