import {
  AppstoreOutlined,
  BlockOutlined,
  FileTextOutlined,
  GlobalOutlined,
  SettingOutlined,
  TeamOutlined,
  WalletOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { APP_PATH } from 'features/Routes/pathes';
import { APP_CONSTANS } from 'shared/constants';

const {
  ACCOUNTS,
  ORDER_STATES,
  USERS,
  FULFILMENTS,
  ORDERS,
  INDUSTRIES,
  COMPLICATIONS,
  COUNTRIES,
  REGIONS,
  GEONAMES,
  TIMEZONES,
  CURRENCIES,
  JOB_STATES,
  CONTENT_STATES,
  REQUEST_STATES,
  PAYMENT_STATES,
  INVOICE_STATES,
  GLOBAL_MAP,
  SUBSCRIPTIONS,
  COMPLETED_JOBS,
  USERS_REQUESTS,
  CONTENTS,
  TEMPLATES,
  FULFILMENT_REQUESTS,
  PENDING_REGIST_INV,
  USERS_API_KEYS,
  HISTORY,
  PREFERENCES,
  MANAGE_SYSTEM,
  PURCHASE_STATES,
  PURCHASE_PROVIDERS,
  PAYOUT_STATES,
  PURCHASES,
  PURCHASE_METHODS,
  PURCHASE_OPERATIONS,
  PAYMENT_OPERATIONS,
  PROVIDER_TARGETS,
  PURCHASE_TARGETS,
  CUSTOMER,
  INVOICES,
  PAYMENT_METHODS,
  PAYMENT_PROVIDERS,
} = APP_PATH;

const { BASE_OLD_ADMIN_URL, BASE_APP_REACT, LS_TOKEN, TOKEN_URL } =
  APP_CONSTANS;

export default {
  route: {
    path: '/',
    routes: [
      {
        name: ' ',
        path: '/',
        routes: [
          {
            path: '/',
            name: 'Switch job',
            icon: <UserSwitchOutlined />,
            routes: [
              {
                path: `${BASE_OLD_ADMIN_URL}${CUSTOMER}`,
                name: 'Open Customer',
                target: '_blank',
              },
              {
                path: `${BASE_APP_REACT}?auth_token=${
                  new URLSearchParams(location.search).get(TOKEN_URL) ||
                  localStorage.getItem(LS_TOKEN)
                }`,
                name: 'Open Photographer',
                target: '_blank',
              },
            ],
            roles: [APP_CONSTANS.USER_TYPES.SUPERUSER],
          },
          {
            path: '/',
            name: 'Users',
            icon: <TeamOutlined />,
            routes: [
              {
                // canAcceptBadge: true,
                path: ACCOUNTS,
                name: 'Accounts',
              },
              {
                path: USERS,
                name: 'Users',
              },
              {
                path: USERS_REQUESTS,
                name: 'Requests',
              },
              {
                path: PENDING_REGIST_INV,
                name: 'Registrations',
              },
              {
                path: USERS_API_KEYS,
                name: 'API Keys',
              },
            ],
            roles: [
              APP_CONSTANS.USER_TYPES.SUPERUSER,
              APP_CONSTANS.USER_TYPES.FINANCE,
              APP_CONSTANS.USER_TYPES.SALES,
              APP_CONSTANS.USER_TYPES.SUPPORT,
            ],
          },
          {
            path: '/',
            name: 'Orders',
            icon: <FileTextOutlined />,
            routes: [
              {
                path: ORDERS,
                name: 'Orders',
              },
              {
                path: FULFILMENTS,
                name: 'Fulfilments',
              },
              {
                path: CONTENTS,
                name: 'Contents',
              },
              {
                path: TEMPLATES,
                name: 'Templates',
              },
              {
                path: FULFILMENT_REQUESTS,
                name: 'Requests',
              },
            ],
            roles: [
              APP_CONSTANS.USER_TYPES.SUPERUSER,
              APP_CONSTANS.USER_TYPES.FINANCE,
              APP_CONSTANS.USER_TYPES.SALES,
              APP_CONSTANS.USER_TYPES.EDITOR,
              APP_CONSTANS.USER_TYPES.SUPPORT,
            ],
          },
          {
            path: '/',
            name: 'Finances',
            icon: <WalletOutlined />,
            routes: [
              {
                path: INVOICES,
                name: 'Invoices',
              },
              // {
              //   path: FINANCES,
              //   name: 'Pricing',
              // },
              {
                path: COMPLETED_JOBS,
                name: 'Completed jobs',
              },
              {
                path: PURCHASES,
                name: 'Purchases',
              },
              {
                path: PURCHASE_OPERATIONS,
                name: 'Purchase Operations',
              },
              {
                path: PAYMENT_OPERATIONS,
                name: 'Payment Operations',
              },
              {
                path: SUBSCRIPTIONS,
                name: 'Subscriptions',
              },
            ],
            roles: [
              APP_CONSTANS.USER_TYPES.SUPERUSER,
              APP_CONSTANS.USER_TYPES.FINANCE,
              APP_CONSTANS.USER_TYPES.SALES,
              // APP_CONSTANS.USER_TYPES.SUPPORT,
            ],
          },
          {
            path: '/',
            name: 'System',
            icon: <SettingOutlined />,
            routes: [
              {
                name: 'Manage system',
                path: MANAGE_SYSTEM,
              },
              {
                name: 'Industries',
                path: INDUSTRIES,
              },
              {
                name: 'Complications',
                path: COMPLICATIONS,
              },
              {
                path: HISTORY,
                name: 'History',
              },
              {
                path: PREFERENCES,
                name: 'Preferences',
              },
            ],
            roles: [APP_CONSTANS.USER_TYPES.SUPERUSER],
          },
          {
            path: '/',
            name: 'References',
            icon: <BlockOutlined />,
            routes: [
              {
                name: 'Countries',
                path: COUNTRIES,
              },
              {
                name: 'Currencies',
                path: CURRENCIES,
              },
              {
                name: 'Geonames',
                path: GEONAMES,
              },
              {
                name: 'Regions',
                path: REGIONS,
              },
              {
                name: 'Timezones',
                path: TIMEZONES,
              },
              {
                name: 'Content States',
                path: CONTENT_STATES,
              },
              {
                name: 'Invoice States',
                path: INVOICE_STATES,
              },
              {
                name: 'Job States',
                path: JOB_STATES,
              },
              {
                name: 'Order States',
                path: ORDER_STATES,
              },
              {
                name: 'Request States',
                path: REQUEST_STATES,
              },
            ],
            roles: [APP_CONSTANS.USER_TYPES.SUPERUSER],
          },
          {
            path: '/',
            name: 'Payment Refs',
            icon: <BlockOutlined />,
            routes: [
              {
                name: 'Payment States',
                path: PAYMENT_STATES,
              },
              {
                name: 'Payout States',
                path: PAYOUT_STATES,
              },
              {
                name: 'Payment Methods',
                path: PAYMENT_METHODS,
              },
              {
                name: 'Payment Providers',
                path: PAYMENT_PROVIDERS,
              },
              {
                path: PROVIDER_TARGETS,
                name: 'Provider Targets',
              },
              {
                name: 'Purchase Methods',
                path: PURCHASE_METHODS,
              },
              {
                name: 'Purchase Providers',
                path: PURCHASE_PROVIDERS,
              },
              {
                name: 'Purchase States',
                path: PURCHASE_STATES,
              },
              {
                path: PURCHASE_TARGETS,
                name: 'Purchase Targets',
              },
            ],
            roles: [APP_CONSTANS.USER_TYPES.SUPERUSER],
          },
          // ↓↓↓ Last element should be empty to fix styles ↓↓↓
          {
            path: '/',
            name: '',
            routes: [],
            roles: ['all'],
          },
        ],
      },
      {
        path: GLOBAL_MAP,
        name: 'Global Map',
        icon: <GlobalOutlined />,
        roles: [
          APP_CONSTANS.USER_TYPES.SUPERUSER,
          APP_CONSTANS.USER_TYPES.SUPPORT,
        ],
      },
      {
        path: `${APP_CONSTANS.BASE_OLD_ADMIN_URL}admin/dashboard`,
        name: 'Go to old UI',
        icon: <AppstoreOutlined />,
        target: '_blank',
        e2e: 'nav_to_old_ui',
        roles: [
          APP_CONSTANS.USER_TYPES.SUPERUSER,
          APP_CONSTANS.USER_TYPES.FINANCE,
          APP_CONSTANS.USER_TYPES.SALES,
          // APP_CONSTANS.USER_TYPES.EDITOR,
          APP_CONSTANS.USER_TYPES.SUPPORT,
        ],
      },
    ],
  },
  location: {
    pathname: '/',
  },
};
