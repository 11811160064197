import Table from '../Table';
import { columns } from './columns';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import general_styles from '../../../shared/styles/styles.module.scss';
import { useLocation } from 'react-router-dom';

export const PaymentMethodsTable: React.FC<any> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'paymentMethodsTable',
  ...rest
}) => {
  const { pathname } = useLocation();

  const renderPurchaseStatus = (value: string, record: any) => {
    const statusName = record?.['state.name'];

    return (
      <span
        className={general_styles.purchaseOrInvoiceStatus}
        data-purchase-id={value}
      >
        {statusName}
      </span>
    );
  };

  const renderData = (value: any, record: any) => {
    const id = record?.id;
    const currency = record?.['currency.code'];
    const accountId = record?.['account.id'];
    const stateId = record?.['state.id'];

    return [
      {
        name: 'code',
        action: renderLink(value, `/purchases/all/${id}`, pathname),
      },
      {
        name: 'account.preferences.e9318297-eb4f-4ad5-a914-e7031cc16c54',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'state.id',
        action: renderPurchaseStatus(stateId, record),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_fee',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_total',
        action: renderMoneyWithCurrency(value, currency),
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="items"
        columnsForRequest={columns}
        defaultSorter={{
          field: 'pay_meth.created_at',
          order: 'descend',
        }}
        {...rest}
      />
    </>
  );
};
