import { queryNames } from 'api/queryNames';
import { Drawer, Form } from 'antd';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { Loader } from 'components/ui_components/Loader';
import { PaymentOperationCols } from './columns';
import { ItemsGenerator } from '../ItemsGenerator';
import { ItemT } from '../ItemsGenerator/types';
import { tablesNames } from 'api/tablesNames';

export const DrawerPaymentOperation: React.FC<any> = ({
  show,
  setShow,
  id,
}) => {
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const queryClient = useQueryClient();

  const headers = queryClient.getQueryData([
    tablesNames.PaymentOperationsHeader,
  ]) as any;

  const { data: dataPaymentOperation, isFetching: isLoadingPaymentOperation } =
    useQuery({
      queryKey: [queryNames.PaymentOperationsById],
      meta: {
        token,
        getAllData: true,
        paymentsApi: true,
        columnParams: PaymentOperationCols,
        additionalSearchParams: `?id=${id}`,
      },
      queryFn: useGetData,
      enabled: !!id,
    });

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (
      dataPaymentOperation &&
      dataPaymentOperation?.data &&
      headers &&
      headers?.data
    ) {
      form.setFieldsValue(dataPaymentOperation.data);
    }
  }, [form, dataPaymentOperation, headers]);

  return (
    <Drawer
      width={window.innerWidth < 700 ? '100%' : '700px'}
      title="Invoice operation details"
      placement="right"
      onClose={onClose}
      open={show}
    >
      {!isLoadingPaymentOperation &&
      headers &&
      headers?.data &&
      dataPaymentOperation?.data ? (
        <Form
          className={styles.form}
          layout="vertical"
          form={form}
          autoComplete="off"
          disabled
        >
          {Object.keys(dataPaymentOperation?.data).map((k, i) => (
            <ItemsGenerator
              key={k + i}
              item={{ [k]: dataPaymentOperation?.data[k] } as ItemT}
              headers={headers}
              currencyCode={dataPaymentOperation?.data?.currency?.code}
            />
          ))}
        </Form>
      ) : (
        <Loader />
      )}
    </Drawer>
  );
};
