import Table from '../Table';
import { columns } from './columns';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { PurchaseItemsTableT } from './types';
// import { Button, Dropdown, MenuProps, message } from 'antd';
// import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { usePostData } from 'api/usePostData';
// import { queryNames } from 'api/queryNames';
// import { useSelector } from 'react-redux';
import { useState } from 'react';
// import styles from './styles.module.scss';
import { DrawerPurchaseItems } from '../PurchasesTable/components/DrawerPurchaseItems';
import {
  renderActionLink,
  renderLink,
} from 'shared/utils/DataInTables/renderLinks';

export const PurchaseItemsTable: React.FC<PurchaseItemsTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'purchaseItemsTable',
  currencyCode,
  disableFilteringAndSearching = false,
  ...rest
}) => {
  // const queryClient: any = useQueryClient();
  // const token = useSelector((state: any) => state.auth.token);
  const [currentId, setCurrentId] = useState<undefined | string>();
  const [show, setShow] = useState<boolean>(false);

  // const mutation = useMutation({
  //   mutationFn: usePostData,
  //   retry: 1,
  //   onError: () => {
  //     message.error('Network Error');
  //   },
  //   onSuccess: (data, variables: any) => {
  //     if (data && data.hasOwnProperty('error')) {
  //       message.error(`Saving data error. ${data.error?.message}`);
  //       return;
  //     } else {
  //       queryClient.invalidateQueries(`${queryNames.PurchaseItems}`);
  //       if (variables?.method === 'DELETE') {
  //         message.success(
  //           `${variables?.data?.title || 'Item'} was ${
  //             variables?.otherProps?.split('/')[1]
  //           } deleted`
  //         );
  //       } else {
  //         message.success(`${variables?.data?.title || 'Item'} was restored`);
  //       }
  //     }
  //   },
  // });

  // const restoreOption = () => {
  //   mutation.mutate({
  //     data: { id: currentId },
  //     token,
  //     otherProps: `${queryNames.PurchaseItems}/restore`,
  //     method: 'PUT',
  //   });
  // };

  // const deleteOption = (type: string) => {
  //   mutation.mutate({
  //     data: { id: currentId },
  //     token,
  //     otherProps: `${queryNames.PurchaseItems}/${type}`,
  //     method: 'DELETE',
  //   });
  // };

  // const actionForItems: MenuProps['items'] = [
  //   {
  //     key: 'Soft_Delete',
  //     label: (
  //       <Button
  //         type="text"
  //         onClick={(event: React.MouseEvent<HTMLElement>) => {
  //           event.stopPropagation();
  //           deleteOption('soft');
  //         }}
  //         block
  //         disabled={false}
  //       >
  //         Soft Delete
  //       </Button>
  //     ),
  //   },
  // ];

  // const actionForDeletedItems: MenuProps['items'] = [
  //   {
  //     key: 'Restore',
  //     label: (
  //       <Button type="text" onClick={restoreOption} block disabled={false}>
  //         Restore
  //       </Button>
  //     ),
  //   },
  //   {
  //     key: 'Hard_Delete',
  //     label: (
  //       <Button
  //         type="text"
  //         onClick={() => deleteOption('hard')}
  //         block
  //         disabled={false}
  //       >
  //         Hard Delete
  //       </Button>
  //     ),
  //   },
  // ];

  const showDrawerAndLink = (value: any, id: string) => {
    const onClick = () => {
      setCurrentId(id);
      setShow(true);
    };
    return renderActionLink(value, onClick);
  };

  const renderData = (value: any, record: any) => {
    const id: string = record?.id;
    const currency = record?.['currency.code'] || currencyCode;
    const jobId = record?.['fulfilment.id'];

    return [
      {
        name: 'code',
        action: showDrawerAndLink(value, id),
      },
      {
        name: 'title',
        action: renderLink(value, `/fulfilments/all/${jobId}`),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="items"
        columnsForRequest={columns}
        disableFilteringAndSearching={disableFilteringAndSearching}
        defaultSorter={{
          field: 'pur_item.code',
          order: 'descend',
        }}
        {...rest}
      />
      {show && (
        <DrawerPurchaseItems
          show={show}
          setShow={setShow}
          id={currentId}
          currencyCode={currencyCode}
        />
      )}
    </>
  );
};
