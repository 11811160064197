import { APP_CONSTANS } from 'shared/constants';

export const getIsDeletedMeta = (isDeleted: string | null) => {
  if (isDeleted === APP_CONSTANS.ONLY_DELETED) {
    return { onlyDeleted: true };
  }
  if (isDeleted === APP_CONSTANS.WITH_DELETED) {
    return { includedDeleted: true };
  }
  return {};
};
