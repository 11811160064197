import styles from './styles.module.scss';
import { ModalDrawerURLST } from './types';

export const ModalDrawerURLS: React.FC<ModalDrawerURLST> = ({
  releaseUrl,
  originalUrl,
  contentUrl,
}) => {
  return (
    <div className={styles.urls}>
      <p className={styles.urls__label}>Original URL</p>
      <p className={styles.urls__value}>
        <a href={originalUrl}>{originalUrl}</a>
      </p>
      <p className={styles.urls__label}>Release URL</p>
      <p className={styles.urls__value}>
        <a href={releaseUrl}>{releaseUrl}</a>
      </p>
      <p className={styles.urls__label}>Preview URL</p>
      <p className={styles.urls__value}>
        <a href={contentUrl}>{contentUrl}</a>
      </p>
    </div>
  );
};
