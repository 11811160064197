import { JobType } from 'shared/constants';
// import jobMarkerDrone from './images/jobMarkerDrone/jobMarkerDrone.svg';
import jobMarkerDronePending from './images/jobMarkerDrone/jobMarkerDronePending.svg';
import jobMarkerDroneAccepted from './images/jobMarkerDrone/jobMarkerDroneAccepted.svg';
import jobMarkerDroneApproved from './images/jobMarkerDrone/jobMarkerDroneApproved.svg';
import jobMarkerDroneCancelled from './images/jobMarkerDrone/jobMarkerDroneCancelled.svg';
import jobMarkerDroneCompleted from './images/jobMarkerDrone/jobMarkerDroneCompleted.svg';
import jobMarkerDroneDraft from './images/jobMarkerDrone/jobMarkerDroneDraft.svg';
import jobMarkerDroneExpired from './images/jobMarkerDrone/jobMarkerDroneExpired.svg';
import jobMarkerDronePublished from './images/jobMarkerDrone/jobMarkerDronePublished.svg';
import jobMarkerDroneRejected from './images/jobMarkerDrone/jobMarkerDroneRejected.svg';
import jobMarkerDroneReturned from './images/jobMarkerDrone/jobMarkerDroneReturned.svg';
import jobMarkerDroneSubmitted from './images/jobMarkerDrone/jobMarkerDroneSubmitted.svg';

// import jobMarkerPhoto from './images/jobMarkerPhoto/jobMarkerPhoto.svg';
import jobMarkerPhotoPending from './images/jobMarkerPhoto/jobMarkerPhotoPending.svg';
import jobMarkerPhotoAccepted from './images/jobMarkerPhoto/jobMarkerPhotoAccepted.svg';
import jobMarkerPhotoApproved from './images/jobMarkerPhoto/jobMarkerPhotoApproved.svg';
import jobMarkerPhotoCancelled from './images/jobMarkerPhoto/jobMarkerPhotoCancelled.svg';
import jobMarkerPhotoCompleted from './images/jobMarkerPhoto/jobMarkerPhotoCompleted.svg';
import jobMarkerPhotoDraft from './images/jobMarkerPhoto/jobMarkerPhotoDraft.svg';
import jobMarkerPhotoExpired from './images/jobMarkerPhoto/jobMarkerPhotoExpired.svg';
import jobMarkerPhotoPublished from './images/jobMarkerPhoto/jobMarkerPhotoPublished.svg';
import jobMarkerPhotoRejected from './images/jobMarkerPhoto/jobMarkerPhotoRejected.svg';
import jobMarkerPhotoReturned from './images/jobMarkerPhoto/jobMarkerPhotoReturned.svg';
import jobMarkerPhotoSubmitted from './images/jobMarkerPhoto/jobMarkerPhotoSubmitted.svg';

// import jobMarkerVideo from './images/jobMarkerVideo/jobMarkerVideo.svg';
import jobMarkerVideoPending from './images/jobMarkerVideo/jobMarkerVideoPending.svg';
import jobMarkerVideoAccepted from './images/jobMarkerVideo/jobMarkerVideoAccepted.svg';
import jobMarkerVideoApproved from './images/jobMarkerVideo/jobMarkerVideoApproved.svg';
import jobMarkerVideoCancelled from './images/jobMarkerVideo/jobMarkerVideoCancelled.svg';
import jobMarkerVideoCompleted from './images/jobMarkerVideo/jobMarkerVideoCompleted.svg';
import jobMarkerVideoDraft from './images/jobMarkerVideo/jobMarkerVideoDraft.svg';
import jobMarkerVideoExpired from './images/jobMarkerVideo/jobMarkerVideoExpired.svg';
import jobMarkerVideoPublished from './images/jobMarkerVideo/jobMarkerVideoPublished.svg';
import jobMarkerVideoRejected from './images/jobMarkerVideo/jobMarkerVideoRejected.svg';
import jobMarkerVideoReturned from './images/jobMarkerVideo/jobMarkerVideoReturned.svg';
import jobMarkerVideoSubmitted from './images/jobMarkerVideo/jobMarkerVideoSubmitted.svg';

export const getJobMarkerIcon = (jobType: JobType, jobStatus: string) => {
  if (jobType === JobType.PHOTO) {
    switch (jobStatus) {
      case 'Accepted': {
        return { icon: jobMarkerPhotoAccepted, color: '#c16a00' };
      }
      case 'Approved': {
        return { icon: jobMarkerPhotoApproved, color: '#2e8206' };
      }
      case 'Available': {
        return { icon: jobMarkerPhotoApproved, color: '#2e8206' };
      }
      case 'Cancelled': {
        return { icon: jobMarkerPhotoCancelled, color: '#ffffff' };
      }
      case 'Completed': {
        return { icon: jobMarkerPhotoCompleted, color: '#ffffff' };
      }
      case 'Draft': {
        return { icon: jobMarkerPhotoDraft, color: '#3f505a' };
      }
      case 'Declined': {
        return { icon: jobMarkerPhotoCancelled, color: '#ffffff' };
      }
      case 'Expired': {
        return { icon: jobMarkerPhotoExpired, color: '#bd223d' };
      }
      case 'Pending': {
        return { icon: jobMarkerPhotoPending, color: '#007D9C' };
      }
      case 'Published': {
        return { icon: jobMarkerPhotoPublished, color: '#ffffff' };
      }
      case 'Rejected': {
        return { icon: jobMarkerPhotoRejected, color: '#cb1b16' };
      }
      case 'Returned': {
        return { icon: jobMarkerPhotoReturned, color: '#3F505A' };
      }
      case 'Submitted': {
        return { icon: jobMarkerPhotoSubmitted, color: '#c16a00' };
      }
      default: {
        return { icon: jobMarkerPhotoDraft, color: '#3f505a' };
      }
    }
  } else if (jobType === JobType.VIDEO) {
    switch (jobStatus) {
      case 'Accepted': {
        return { icon: jobMarkerVideoAccepted, color: '#c16a00' };
      }
      case 'Approved': {
        return { icon: jobMarkerVideoApproved, color: '#2e8206' };
      }
      case 'Available': {
        return { icon: jobMarkerVideoApproved, color: '#2e8206' };
      }
      case 'Cancelled': {
        return { icon: jobMarkerVideoCancelled, color: '#ffffff' };
      }
      case 'Completed': {
        return { icon: jobMarkerVideoCompleted, color: '#ffffff' };
      }
      case 'Draft': {
        return { icon: jobMarkerVideoDraft, color: '#3f505a' };
      }
      case 'Declined': {
        return { icon: jobMarkerVideoCancelled, color: '#ffffff' };
      }
      case 'Expired': {
        return { icon: jobMarkerVideoExpired, color: '#bd223d' };
      }
      case 'Pending': {
        return { icon: jobMarkerVideoPending, color: '#007D9C' };
      }
      case 'Published': {
        return { icon: jobMarkerVideoPublished, color: '#ffffff' };
      }
      case 'Rejected': {
        return { icon: jobMarkerVideoRejected, color: '#cb1b16' };
      }
      case 'Returned': {
        return { icon: jobMarkerVideoReturned, color: '#3F505A' };
      }
      case 'Submitted': {
        return { icon: jobMarkerVideoSubmitted, color: '#c16a00' };
      }
      default: {
        return { icon: jobMarkerVideoDraft, color: '#3f505a' };
      }
    }
  } else if (jobType === JobType.DRONE) {
    switch (jobStatus) {
      case 'Accepted': {
        return { icon: jobMarkerDroneAccepted, color: '#c16a00' };
      }
      case 'Approved': {
        return { icon: jobMarkerDroneApproved, color: '#2e8206' };
      }
      case 'Available': {
        return { icon: jobMarkerDroneApproved, color: '#2e8206' };
      }
      case 'Cancelled': {
        return { icon: jobMarkerDroneCancelled, color: '#ffffff' };
      }
      case 'Completed': {
        return { icon: jobMarkerDroneCompleted, color: '#ffffff' };
      }
      case 'Draft': {
        return { icon: jobMarkerDroneDraft, color: '#3f505a' };
      }
      case 'Declined': {
        return { icon: jobMarkerDroneCancelled, color: '#ffffff' };
      }
      case 'Expired': {
        return { icon: jobMarkerDroneExpired, color: '#bd223d' };
      }
      case 'Pending': {
        return { icon: jobMarkerDronePending, color: '#007D9C' };
      }
      case 'Published': {
        return { icon: jobMarkerDronePublished, color: '#ffffff' };
      }
      case 'Rejected': {
        return { icon: jobMarkerDroneRejected, color: '#cb1b16' };
      }
      case 'Returned': {
        return { icon: jobMarkerDroneReturned, color: '#3F505A' };
      }
      case 'Submitted': {
        return { icon: jobMarkerDroneSubmitted, color: '#c16a00' };
      }
      default: {
        return { icon: jobMarkerDroneDraft, color: '#3f505a' };
      }
    }
  } else {
    return null;
  }
};
