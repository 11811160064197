import { Form, Input, InputNumber } from 'antd';
import { ItemGeneratorT } from './types';
import styles from './styles.module.scss';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { moneyFromCurrency } from 'shared/utils/DataInTables/renderMoneyFromCurrency';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';

export const ItemsGenerator: React.FC<ItemGeneratorT> = ({
  item,
  headers,
  currencyCode,
}) => {
  if (!item || !headers) return <></>;

  const [key, value] = Object.entries(item)[0];

  if (!key) return <></>;
  let valueFromObject: any;
  let header: any;
  if (typeof value === 'object' && value !== null) {
    const keysInObject = Object.keys(value);
    if (keysInObject.length !== 1) return <></>;
    const field = `${key}.${keysInObject[0]}`;
    header = headers?.data
      ? headers.data?.columns?.find((e) => e.field === field)
      : undefined;
    valueFromObject = item[key][keysInObject[0]];
  } else {
    header = headers?.data
      ? headers.data?.columns?.find((e) => e.name === key)
      : undefined;
  }

  if (!header) return <></>;

  const baseItem = (
    child:
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | React.ReactFragment
      | React.ReactPortal
  ) => (
    <Form.Item
      key={key}
      label={header.title}
      name={header.name}
      initialValue={valueFromObject || value}
    >
      {child}
    </Form.Item>
  );

  if (header.field === 'tx_data') {
    return (
      <div className={styles.txBlock}>
        <label>Payment transaction data</label>
        <pre className={styles.txData}>
          {JSON.stringify(JSON.parse(value), null, 2)}
        </pre>
      </div>
    );
  }

  switch (header.type) {
    case 'datetime': {
      const input = (
        <InputNumber
          className={styles.inputNum}
          formatter={() => {
            return convertUNIXToLocale(value);
          }}
        />
      );
      return baseItem(input);
    }

    case 'string':
    case 'serial': {
      const input = <Input />;
      return baseItem(input);
    }

    case 'number':
    case 'amount': {
      const inputNumber = (
        <InputNumber
          className={styles.inputNum}
          min={0}
          formatter={(formatterValue: any) =>
            renderMoneyWithCurrency(formatterValue, currencyCode)
          }
          parser={(parserValue: any) => {
            const amount = moneyFromCurrency(parserValue);
            let roundedAmount = Math.round(Number(amount) * 1000);
            return roundedAmount;
          }}
          precision={2}
          step={1000}
        />
      );
      return header.name === 'duration'
        ? baseItem(<Input />)
        : baseItem(inputNumber);
    }

    default: {
      return <></>;
    }
  }
};
