import { Button, Form, Input, message } from 'antd';
import clsx from 'clsx';
import { convertUNIXToLocale } from '../../../../../shared/utils/convertUNIXtoLocaleTime';
import { MainContainer } from '../../../../ui_components/MainContainer';
import styles from './styles.module.scss';
import general_styles from '../../../../../shared/styles/styles.module.scss';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { usePostData } from '../../../../../api/usePostData';
import { useSelector } from 'react-redux';
import { Loader } from '../../../../ui_components/Loader';
import { useParams } from 'react-router-dom';
import useGetData from '../../../../../api/useGetData';
import { handleChangeFields } from '../../../../../shared/utils/savingDataOnPages';
import { APP_CONSTANS } from 'shared/constants';
const { TextArea } = Input;

type InfoT = {
  type: string[];
  table: string;
  title: string;
  meta?: any;
};

export const Info: FunctionComponent<InfoT> = ({
  type,
  table,
  title,
  meta = {},
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const [form] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const params = useParams();
  const { id } = params;
  const dataToStore = useRef([]);

  const {
    data,
    isFetching: isLoading,
    refetch,
    remove,
  } = useQuery({
    queryKey: type,
    meta: {
      token,
      additionalSearchParams: `?id=${id}`,
      meta,
      getAllData: true,
    },
    queryFn: useGetData,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (dataOnSuccess) => {
      if (dataOnSuccess && dataOnSuccess.hasOwnProperty('error')) {
        message.error(`Saving data error. ${dataOnSuccess.error?.message}`);
        return;
      }
      message.success('All data saved');
    },
  });

  useEffect(() => {
    return () => {
      remove();
    };
  }, [remove]);

  useEffect(() => {
    if (!data || !data?.data?.id) return;
    if (data && data?.data?.id !== id) {
      refetch();
    }
    if (data && data?.data.id === id) {
      const formattedValues: { [key: string]: any } = {};
      for (let key in data.data) {
        if (key) {
          if (key.includes('_at')) {
            formattedValues[key] = convertUNIXToLocale(data.data[key]);
          }
          if (typeof data.data[key] === 'object') {
            formattedValues[key] = JSON.stringify(data.data[key]);
          }
        }
      }
      setInitialValues({ ...data.data, ...formattedValues });
    }
  }, [id, data, refetch]);

  const formFieldsChangeHandler = (changedFields: any[]) => {
    const changedData = handleChangeFields(changedFields, dataToStore.current);
    setSubmitButtonDisabled(changedData.length === 0);
  };

  const submitForm = useCallback(
    (allFields: any) => {
      const dataSave: any = {};
      const dataToBack = dataToStore.current;

      dataToBack.forEach((dataItem: any) => {
        for (let key in dataItem) {
          if (key) dataSave[key] = dataItem[key];
        }
      });
      dataSave.id = allFields.id;
      mutate({
        data: dataSave,
        token,
        otherProps: table,
        method: 'PUT',
        url: meta?.paymentsApi
          ? `${APP_CONSTANS.BASE_PAYMENTS_URL}/${table}`
          : undefined,
      });
      dataToStore.current = [];
      setSubmitButtonDisabled(true);
    },
    [mutate, table, token, meta]
  );

  const checkIfReadonly = useCallback((label: string) => {
    if (label.includes('_at') || label.includes('id')) {
      return true;
    } else {
      return false;
    }
  }, []);

  return data &&
    data?.data.id === id &&
    Object.keys(initialValues).length > 0 ? (
    <MainContainer title={title} expanded>
      <Form
        form={form}
        className={styles.form}
        onFinish={submitForm}
        initialValues={initialValues}
        onFieldsChange={formFieldsChangeHandler}
      >
        <div className={styles.btnBlock}>
          <Button
            type="primary"
            htmlType="submit"
            className={clsx(general_styles.btn, general_styles.primary__btn)}
            disabled={submitButtonDisabled}
          >
            Save changes
          </Button>
        </div>
        <div className={styles.formItems}>
          {isLoading !== null &&
            !isLoading &&
            Object.entries(data.data).map((info: any) => (
              <Form.Item
                name={info[0]}
                key={info[0]}
                label={info[0]}
                className={clsx(
                  info[0] === 'description' && styles.inputLong,
                  styles.formItem
                )}
              >
                {typeof info[1] === 'object' ? (
                  <TextArea disabled={true} rows={4} />
                ) : (
                  <Input disabled={checkIfReadonly(info[0])} />
                )}
              </Form.Item>
            ))}
        </div>
      </Form>
    </MainContainer>
  ) : (
    <Loader />
  );
};
