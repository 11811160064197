import { tablesNames } from 'api/tablesNames';
import { FC } from 'react';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { JobsTable } from 'components/ui_components/JobsTable';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';
import { queryNames } from 'api/queryNames';
import clsx from 'clsx';

export const JobsAll: FC<any> = ({
  rowSelection,
  actionsColumn,
  defaultSorter,
  ...rest
}) => {
  return (
    <JobsTable
      tableIndicatorClassName="completedNotPaidTableTabAll"
      customClassName={clsx(
        styles.completedJobsTable,
        'rowSelectionTableCentered'
      )}
      rowSelection={{ ...rowSelection }}
      rowSelectionType="checkbox"
      toolbar={null}
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.FINANCE_COMPLETED_BUT_NOT_PAID_JOBS}
      meta={null}
      headerName={tablesNames.CompletedButNotPaidJobsHeader}
      actionsColumn={actionsColumn}
      tableName={tablesNames.CompletedButNotPaidJobsAllCol}
      hideInSetting={hideInSetting}
      additionalSearchParams={`?state_ids=${APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE.COMPLETED_JOB}&has_payout=false&has_purchase=false`}
      initialTableHeight={550}
      defaultSorter={defaultSorter}
      {...rest}
    />
  );
};
