import { FC } from 'react';
import { Modal } from 'antd';
import { ModalWindowProps } from './types';
import styles from './styles.module.scss';
import clsx from 'clsx';

export const ModalWindow: FC<ModalWindowProps> = ({
  title,
  visible,
  onOk,
  onCancel,
  okText,
  cancelText,
  width,
  okButtonProps,
  children,
  closable,
  footer,
  className,
  ...rest
}) => {
  return (
    <Modal
      title={
        typeof title === 'string' ? (
          <span className={styles.modal_title}>{title}</span>
        ) : (
          title
        )
      }
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      width={width}
      okButtonProps={okButtonProps}
      closable={closable === undefined ? true : closable}
      footer={footer}
      className={clsx(styles.modal, className)}
      forceRender={true}
      {...rest}
    >
      {children}
    </Modal>
  );
};
