export const hideInSetting = [
  'owner.middle_name',
  'actor.middle_name',
  'actor_id',
  'actor.id',
  'id',
  'object_id',
  'owner.id',
  'parent_id',
  'owner_id',
];
