export const ACTION_TYPES = Object.freeze({
  GET_COMPLETED_BUT_NOT_PAID_JOBS:
    '@@COMPLETED_BUT_NOT_PAID_JOBS/GET_COMPLETED_BUT_NOT_PAID_JOBS',

  GET_COMPLETED_BUT_NOT_PAID_JOBS_PENDING:
    '@@COMPLETED_BUT_NOT_PAID_JOBS/GET_COMPLETED_BUT_NOT_PAID_JOBS_PENDING',
  GET_COMPLETED_BUT_NOT_PAID_JOBS_FULFILLED:
    '@@COMPLETED_BUT_NOT_PAID_JOBS/GET_COMPLETED_BUT_NOT_PAID_JOBS_FULFILLED',
  GET_COMPLETED_BUT_NOT_PAID_JOBS_REJECTED:
    '@@COMPLETED_BUT_NOT_PAID_JOBS/GET_COMPLETED_BUT_NOT_PAID_JOBS_REJECTED',

  SET_PAID_JOBS: '@@COMPLETED_BUT_NOT_PAID_JOBS/SET_PAID_JOBS',
  IS_LOADING: '@@COMPLETED_BUT_NOT_PAID_JOBS/IS_LOADING',
});
