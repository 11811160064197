import { FC, ReactElement } from 'react';
import { MapJobMarkerProps } from '../MapJobMarker';
import { MapMarkerClusterer } from '../MapMarkerClusterer';

const CLUSTERER_OPTIONS = {
  minimumClusterSize: 2,
  maxZoom: 21,
};

interface MapJobMarkerClustererProps {
  children: ReactElement<MapJobMarkerProps> | ReactElement<MapJobMarkerProps>[];
}

export const MapJobMarkerClusterer: FC<MapJobMarkerClustererProps> = ({
  children,
}) => (
  <MapMarkerClusterer options={CLUSTERER_OPTIONS}>
    {children}
  </MapMarkerClusterer>
);
