import React from 'react';
import { Select, message } from 'antd';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import styles from './styles.module.scss';
import { useMutation, useQuery } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { Notifier } from './Notifier';
import { APP_CONSTANS } from 'shared/constants';

export const ModalUserSettings: React.FC<any> = ({
  visibleModal,
  setVisibleModal,
}) => {
  const token = useSelector((state: any) => state.auth.token);

  const { data: defaultLimit, refetch: refetchLimit } = useQuery({
    queryKey: [queryNames.DefaultLimit],
    meta: {
      token,
      getAllData: true,
    },
    queryFn: useGetData,
  });

  const mutationLimit = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      message.success('Default limit was changed');
      refetchLimit();
    },
  });

  const paginationOptions = [
    {
      value: APP_CONSTANS.GLOBAL_FETCH_LIMIT,
      label: `${APP_CONSTANS.GLOBAL_FETCH_LIMIT} / page`,
    },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' },
    { value: 150, label: '150 / page' },
  ];

  const onPaginationChange = (value: number) => {
    mutationLimit.mutate({
      data: { limit: value },
      token,
      otherProps: `${queryNames.DefaultLimit}`,
      method: 'POST',
    });
  };

  return (
    <ModalWindow
      title="Settings"
      width="600px"
      visible={visibleModal}
      onCancel={() => setVisibleModal(false)}
      okButtonProps={{
        style: {
          background: '#03A3BF',
          borderRadius: 2,
          border: 'none',
        },
      }}
      closable={true}
      footer={null}
    >
      <div>
        <div className={styles.homeItem}>
          <div>
            <span>Default limit for tables</span>
            <Select
              defaultValue={
                defaultLimit?.limit || APP_CONSTANS.GLOBAL_FETCH_LIMIT
              }
              style={{ width: 150 }}
              options={paginationOptions}
              onChange={onPaginationChange}
              size="middle"
              className={styles.selectLimit}
            />
          </div>

          <Notifier />
        </div>
      </div>
    </ModalWindow>
  );
};
