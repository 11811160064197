export enum GlobalMapRadioButtonGroupOptionValue {
  PHOTOGRAPHERS = 'photographers',
  ORDERS = 'orders',
}

export const defaultSelectedJobFilters = {
  filters: {
    'ord.state_id': {
      isJson: false,
      key: ['351fe340-4615-40df-98b2-23097e779f8f'],
    },
  },
  tableFilters: {
    state_id: ['351fe340-4615-40df-98b2-23097e779f8f'],
  },
};

export const defaultSelectedPhotographerFilters = {
  filters: {
    'usr.type': {
      isJson: false,
      key: ['photographer'],
    },
  },
};

export const statusActiveSearchParams = 'value_equal=usr.status%2Cactive';
