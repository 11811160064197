import { FunctionComponent } from 'react';
import { queryNames } from '../../../../../../../../../api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { TabContentListT } from './types';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { ContentTable } from 'components/ui_components/ContentTable';

export const TabContentList: FunctionComponent<TabContentListT> = ({ id }) => {
  const meta = { additionalSearchParams: `?user_ids=${id}` };

  return (
    <ContentTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.FULFILMENT_CONTENTS}
      meta={meta}
      headerName={tablesNames.FulfilmentContentsHeader}
      toolbar={null}
      actionsColumn={null}
      tableName={tablesNames.FulfilmentContentsInUserPageCol}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
