import { FC } from 'react';
import styles from './styles.module.scss';
import { Button } from 'antd';
import { useUserPermissionGranted } from 'hooks/api/user/useUserPermissionGranted';
import { APP_CONSTANS } from 'shared/constants';
import { EditOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import globalStyles from 'shared/styles/styles.module.scss';

export const PurchaseHeaderActions: FC<any> = ({
  stateId,
  isFormDisabled,
  setIsFormDisabled,
  resetForm,
  isDeleted,
  enabledSaveChanges,
  submitForm,
  setShowModal,
}) => {
  const isSuperuser = useUserPermissionGranted({
    enabledUserTypes: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  });

  const edit = () => {
    setIsFormDisabled(!isFormDisabled);
  };

  return (
    <div className={styles.actions}>
      {isSuperuser && (
        <div className={styles.actions__btns}>
          <div>
            {isFormDisabled ? (
              <>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={edit}
                  disabled={isDeleted}
                  icon={<EditOutlined />}
                >
                  Edit
                </Button>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={() => setShowModal(true)}
                  className={clsx(globalStyles.btn, globalStyles.success__btn)}
                  disabled={
                    stateId === APP_CONSTANS.PURCHASE_STATUSES_BY_NAME.Payed ||
                    stateId === APP_CONSTANS.PURCHASE_STATUSES_BY_NAME.Pending
                  }
                >
                  Payout
                </Button>
              </>
            ) : (
              <>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="button"
                  onClick={resetForm}
                  disabled={isDeleted}
                >
                  Cancel
                </Button>
                <Button
                  size={window.innerWidth > 768 ? 'middle' : 'small'}
                  htmlType="submit"
                  type="primary"
                  disabled={isDeleted ? true : enabledSaveChanges}
                  onClick={submitForm}
                >
                  Save changes
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
