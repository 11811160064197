import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

export const DroneLicense: FunctionComponent<any> = ({
  userId,
  firstName,
  middleName,
  lastName,
  droneData,
}) => {
  const formatToLocalTime = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
  };

  return (
    <div className={styles.extraData}>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>User:</span>
        <Link to={`/users/all/${userId}`}>
          {`${firstName} ${middleName} ${lastName}`}
        </Link>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Certificate number:</span>
        <span className={styles.extraData__value}>
          {droneData?.serial_number || 'Not set'}
        </span>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Date of issue:</span>
        <span className={styles.extraData__value}>
          {droneData?.issue_date
            ? formatToLocalTime(droneData?.issue_date)
            : 'Not set'}
        </span>
      </p>
    </div>
  );
};
