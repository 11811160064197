import { Button, Form, Input, Select, message } from 'antd';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import styles from './styles.module.scss';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import useGetData from 'api/useGetData';
import { queryNames } from 'api/queryNames';
import { APP_CONSTANS } from 'shared/constants';
import {
  AccountsT,
  ModalPurchaseMethodT,
  PurchaseMethodsT,
  PurchaseTargetsT,
} from './types';

export const ModalPurchaseMethod: React.FC<ModalPurchaseMethodT> = ({
  visible,
  setVisible,
  userId,
  data = null,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [paymentId, setPaymentId] = useState<any>(null);
  const [accountId, setAccountId] = useState<any>(null);
  const [paymentObj, setPaymentObj] = useState<any>(null);
  const [purchaseForm] = Form.useForm();
  const queryClient: any = useQueryClient();

  const { isLoading: isLoadingTargets, data: dataTargets } = useQuery({
    queryKey: [queryNames.PurchaseTargets],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
      additionalSearchParams: '?is_active=true&is_linked=true',
      columnParams: ['id', 'name', 'client_text', 'extra_text'],
    },
    queryFn: useGetData,
  });

  const { data: dataMethods } = useQuery({
    queryKey: [queryNames.PurchaseMethods],
    meta: {
      token,
      getAllData: true,
      paymentsApi: true,
      additionalSearchParams: `?user_ids=${userId}`,
      columnParams: ['id', 'client_id'],
    },
    queryFn: useGetData,
    enabled: !!userId,
  });

  const { data: dataAccounts } = useQuery({
    queryKey: [queryNames.ACCOUNTS_ALL, 'addPurchaseMethodModal'],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `?user_ids=${userId}`,
      columnParams: ['id', 'name'],
    },
    queryFn: useGetData,
    enabled: !!userId,
  });

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (succsessData, variables) => {
      if (succsessData.hasOwnProperty('error')) {
        message.error(`Server Error ${succsessData.error?.message}`);
      } else {
        const isPostMethod = variables?.method === 'POST';
        message.success(
          `Purchase method was successfully ${
            isPostMethod ? ' created' : 'updated'
          }!`
        );
        queryClient.invalidateQueries(
          `${APP_CONSTANS.BASE_PAYMENTS_URL}/${queryNames.PurchaseMethods}`
        );
        closeModal();
      }
    },
  });

  useEffect(() => {
    if (data && purchaseForm) {
      purchaseForm.setFieldsValue(data);
      setPaymentId(data['purchase_target.id']);
    }
    if (dataAccounts?.data?.length === 1 && purchaseForm) {
      purchaseForm.setFieldsValue({ account_id: dataAccounts?.data[0]?.id });
    }
  }, [data, dataAccounts, purchaseForm]);

  useEffect(() => {
    if (paymentId && !isLoadingTargets && dataTargets) {
      setPaymentObj(
        dataTargets.data.find(
          (target: PurchaseTargetsT) => target.id === paymentId
        )
      );
    }
  }, [paymentId, isLoadingTargets, dataTargets]);

  const accOptions = useMemo(() => {
    return dataAccounts?.data?.map((acc: AccountsT) => {
      return {
        value: acc.id,
        label: acc.name,
      };
    });
  }, [dataAccounts]);

  const typeOptions = useMemo(() => {
    return dataTargets?.data?.map((target: PurchaseTargetsT) => {
      return {
        value: target.id,
        label: target.name,
      };
    });
  }, [dataTargets]);

  const closeModal = () => {
    setVisible(false);
  };

  const createNewPayment = (allFields: any) => {
    if (
      !data &&
      dataMethods?.data?.length > 0 &&
      dataMethods?.data?.find(
        (method: PurchaseMethodsT) => method.client_id === allFields.client_id
      )
    ) {
      message.error('This email already existed');
    } else {
      const dataToBack: any = {
        user_id: userId,
        name: `${paymentObj.name} - ${allFields.client_id}${
          paymentObj?.extra_text ? ', ' + allFields.extra_id : ''
        }`,
        purchase_target_id: paymentObj.id,
        status: 'active',
        ...allFields,
      };
      if (data) {
        dataToBack.id = data.id;
      }
      mutation.mutate({
        data: dataToBack,
        token,
        url: `${APP_CONSTANS.BASE_PAYMENTS_URL}/${queryNames.PurchaseMethods}`,
        method: data ? 'PUT' : 'POST',
      });
      closeModal();
    }
  };

  return (
    <ModalWindow
      title={`${data ? 'Update' : 'Create'} Payment method`}
      visible={visible}
      width="670px"
      okButtonProps={{
        style: {
          background: '#03A3BF',
          borderRadius: 2,
          border: 'none',
        },
      }}
      onCancel={closeModal}
      closable={true}
      footer={null}
    >
      <Form
        form={purchaseForm}
        layout="vertical"
        onFinish={createNewPayment}
        key="PaymentMethodForm"
      >
        {!data && (
          <>
            <Form.Item name="account_id">
              <Select
                value={accountId}
                onChange={setAccountId}
                options={accOptions}
                placeholder="Choose Account"
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Select
                value={paymentId}
                options={typeOptions}
                onChange={setPaymentId}
                placeholder="Choose payment type"
                size="large"
              />
            </Form.Item>
          </>
        )}

        {paymentObj && paymentObj.hasOwnProperty('client_text') && (
          <Form.Item
            name="client_id"
            label={paymentObj.client_text}
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input correct email',
              },
            ]}
          >
            <Input placeholder={paymentObj.client_text} size="large" />
          </Form.Item>
        )}

        {paymentObj && paymentObj.hasOwnProperty('extra_text') && (
          <Form.Item
            name="extra_id"
            label={paymentObj.extra_text}
            rules={[
              {
                required: true,
                message: 'Please enter a valid additional text',
              },
            ]}
          >
            <Input placeholder={paymentObj.extra_text} size="large" />
          </Form.Item>
        )}

        <div className={styles.footer}>
          <Button onClick={closeModal} size="large">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" size="large">
            Save
          </Button>
        </div>
      </Form>
    </ModalWindow>
  );
};
