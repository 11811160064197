import { Button, Tag } from 'antd';
import { FunctionComponent, useState } from 'react';
import Table from '../Table';
import { AddressTableT } from './types';
import styles from './styles.module.scss';
import { columns } from './columns';
import { ModalUserAddress } from 'components/modals/user_address/user_address';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';

export const AddressTable: FunctionComponent<AddressTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  toolBarRender,
  refetchData,
  setRefetchData,
  mainAddressId,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'addressesTable',
  ...rest
}) => {
  const [showModalAddress, setShowModalAddress] = useState<boolean>(false);
  const [addressId, setAddressId] = useState<string | null>(null);
  const token = useSelector((state: any) => state.auth.token);

  const { data: dataAddress, refetch: refetchAddress } = useQuery({
    queryKey: [
      queryKey.includes('account')
        ? queryNames.ACCOUNT_ADDRESS_GET
        : queryNames.ACCOUNTS_getAddressById,
      addressId,
    ],
    meta: {
      token,
      getAllData: true,
      additionalSearchParams: `?id=${addressId}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!addressId,
  });

  const openModalAddress = (id: string) => {
    setShowModalAddress(true);
    setAddressId(id);
  };

  const renderMainAddress = (value: string, record: any) => {
    const isMainAddress =
      record && record.hasOwnProperty('id') && record.id === mainAddressId;
    return (
      <p className={styles.address}>
        {isMainAddress && (
          <Tag className={styles.tag} color="blue" key="Main">
            Main
          </Tag>
        )}
        <Button type="link" onClick={() => openModalAddress(record.id)}>
          {value}
        </Button>
      </p>
    );
  };

  const renderData = (value: string, record?: any) => {
    return [
      {
        name: 'name',
        action: renderMainAddress(value, record),
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        key={mainAddressId}
        searchingByAllTable={false}
        toolbar={toolbar}
        toolBarRender={toolBarRender}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        headerName={headerName}
        refetchData={refetchData}
        setRefetchData={setRefetchData}
        renderDataColumn={renderData}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="addresses"
        columnsForRequest={columns}
        {...rest}
      />

      {showModalAddress && dataAddress && (
        <ModalUserAddress
          mode="Change"
          visible={showModalAddress}
          setHidden={setShowModalAddress}
          address={dataAddress?.data}
          userId={dataAddress?.data?.user_id}
          accountId={dataAddress?.data?.account_id}
          refetchUserAddress={refetchAddress}
          setRefetchData={setRefetchData}
          addressType={queryKey}
        />
      )}
    </>
  );
};
