import { theme } from 'theme';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import routes from './_routes';
import { Link, useLocation } from 'react-router-dom';
import variables from 'shared/styles/styles.variables.module.scss';
import styles from './styles.module.scss';
import logoBig from 'assets/images/logo.svg';
import logoSmall from 'assets/images/logoSmall.svg';
import { Badge, ConfigProvider, message } from 'antd';
import enUs from 'antd/locale/en_US';
import BreadCrumbs from 'components/ui_components/BreadCrumbs/bread_crumbs.component';
import { allRoutes } from 'features/Routes/AllRoutes/allRoutes';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { APP_CONSTANS } from 'shared/constants';
import { ProLayout, ProSettings } from '@ant-design/pro-components';
import { UserMenu } from './components/UserMenu';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { usePostData } from 'api/usePostData';
import { initialisePush } from './pushNotify';
import { VersionAlert } from './components/VersionAlert';

interface AdminPageContainerI {
  children?: React.ReactNode;
}

type Props = AdminPageContainerI;

export const AdminPageContainer: FunctionComponent<Props> = ({ children }) => {
  const location = useLocation();

  const [settings] = useState<Partial<ProSettings> | undefined>({
    // fixSiderbar: true,
    // splitMenus: true,
    layout: 'mix',
    siderMenuType: 'group',
    menu: {
      collapsedShowGroupTitle: true,
    },
  });
  const [pathname] = useState('/');
  const [collapsed, setCollapsed] = useState<boolean | undefined>();
  const [routesByRole, setRoutesByRole] = useState<any>();
  const tokenAPI = useSelector((state: any) => state.auth.token);
  const getAllData = true;
  const breadcrumbs = useBreadcrumbs(allRoutes);
  const typeAll = 'all';

  const title = useMemo(() => {
    const activeBreadcrumb: any =
      breadcrumbs[breadcrumbs.length - 1].match?.route;
    const breadcrumbTitle =
      activeBreadcrumb?.title || activeBreadcrumb?.breadcrumb;

    return breadcrumbTitle
      ? `${breadcrumbTitle} - ${APP_CONSTANS.COMPANY_NAME}`
      : APP_CONSTANS.DEFAULT_TITLE;
  }, [breadcrumbs]);

  const token = {
    bgLayout: '#f5f5f5',
    sider: {
      // colorBgCollapsedButton: variables.primary20,
      colorMenuBackground: variables.neutral700,
      colorBgMenuItemCollapsedHover: variables.primary600,
      colorBgMenuItemCollapsedSelected: variables.primary600,
      colorBgMenuItemCollapsedElevated: variables.neutral700,
      colorMenuItemDivider: 'rgba(255,255,255,0.15)',
      colorBgMenuItemHover: variables.primary600,
      // colorBgMenuItemSelected: variables.neutral700,
      // colorTextMenuSelected: variables.neutral50,
      colorTextMenuItemHover: 'white',
      colorTextMenu: 'white',
      colorTextMenuSecondary: 'white',
      // colorTextMenuTitle: variables.neutral50,
      // colorTextMenuActive: variables.neutral50,
      // colorTextSubMenuSelected: 'white',
    },
  };

  const {
    isLoading: userTypeIsLoading,
    isError: userTypeIsError,
    data: userType,
  } = useQuery({
    queryKey: [queryNames.AUTH_getCurrentUser],
    meta: {
      tokenAPI,
      getAllData,
    },
    queryFn: useGetData,
  });

  const { data: defaultLimit } = useQuery({
    queryKey: [queryNames.DefaultLimit],
    meta: {
      token: tokenAPI,
      getAllData: true,
    },
    queryFn: useGetData,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        if (data.error?.code === 403) {
          message.error(`Server Error ${data.error?.message}`);
        } else {
          message.error(`Server Error ${data.error?.message}`);
        }
        return;
      }
    },
  });

  useEffect(() => {
    if (defaultLimit && !defaultLimit.hasOwnProperty('limit')) {
      mutate({
        data: { limit: 20 },
        token: tokenAPI,
        otherProps: `${queryNames.DefaultLimit}`,
        method: 'POST',
      });
    }
  }, [defaultLimit, tokenAPI, mutate]);

  const getRouteItemsByRole = useCallback(
    (routeItem: any) => {
      if (
        (routeItem.hasOwnProperty('roles') &&
          routeItem?.roles?.includes(userType?.data?.type)) ||
        routeItem?.roles?.includes(typeAll)
      ) {
        return routeItem;
      }
      return {};
    },
    [userType?.data?.type]
  );

  useEffect(() => {
    if (
      !userTypeIsLoading &&
      !userTypeIsError &&
      userType &&
      userType.hasOwnProperty('data') &&
      localStorage.getItem(APP_CONSTANS.LS_TOKEN)
    ) {
      const generalRoutes: any = routes.route.routes.filter((item: any) => {
        if (
          (item.hasOwnProperty('roles') &&
            item?.roles?.includes(userType?.data?.type)) ||
          item?.roles?.includes(typeAll)
        ) {
          return item;
        }
        if (item.hasOwnProperty('routes')) {
          let innerRoutes = item.routes.filter(getRouteItemsByRole);
          item.routes = innerRoutes;
          return item;
        }
        return {};
      });
      let newRoutes: any = { ...routes };
      newRoutes.route.routes = generalRoutes;

      setRoutesByRole(newRoutes);
      if (tokenAPI) {
        initialisePush(tokenAPI);
      }
    }
  }, [
    userTypeIsLoading,
    userTypeIsError,
    userType,
    getRouteItemsByRole,
    tokenAPI,
  ]);

  return (
    <ConfigProvider theme={theme} locale={enUs}>
      <ProLayout
        pageTitleRender={() => title}
        token={token}
        contentStyle={{ padding: 0 }}
        className={styles.layout}
        prefixCls="ff"
        {...routesByRole}
        location={{
          pathname,
        }}
        avatarProps={{
          render: () => <UserMenu />,
        }}
        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            // props.layout !== 'side' && document.body.clientWidth > 1400 ? (
            //   <SearchInput />
            // ) : undefined,
            // <InfoCircleFilled key="InfoCircleFilled" />,
          ];
        }}
        onCollapse={(isCollapsed) => setCollapsed(isCollapsed)}
        logo={<img src={logoSmall} alt="FotoFetch logo" />}
        headerTitleRender={(logo, headerTitle, _) => {
          const defaultLogo = (
            <Link className={styles.smallLogo} to="/" data-title={headerTitle}>
              {logo}
            </Link>
          );
          const bigLogo = (
            <Link className={styles.logo} to="/">
              <img src={logoBig} alt="FotoFetch logo" />
            </Link>
          );
          const logoConstuctor = (isCollapsed: boolean = false) => (
            <div className={styles.logoBreadcrumbs}>
              {isCollapsed ? defaultLogo : bigLogo}
              <span className={styles.breadcrumbs}>
                <BreadCrumbs
                  crumbs={breadcrumbs.map((breadcrumb) => breadcrumb)}
                />
              </span>
            </div>
          );
          if (document.body.clientWidth > 992) {
            return logoConstuctor(collapsed);
          }
          if (_.isMobile) return defaultLogo;
          return defaultLogo;
        }}
        siderWidth={250}
        menuFooterRender={(props) => {
          if (props?.collapsed) return undefined;
          return (
            <div className={styles.menuFooter}>
              <p style={{ color: 'rgba(255, 255, 255, 0.35)' }}>
                ©{new Date().getFullYear()} FotoFetch
              </p>
              <p style={{ color: 'rgba(255, 255, 255, 0.35)' }}>
                v. {APP_CONSTANS.VERSION_ADMIN}
              </p>
            </div>
          );
        }}
        menuContentRender={(props, defaultDom) => {
          return (
            <div className={styles.siderList} data-e2e="siderList">
              {defaultDom}
            </div>
          );
        }}
        menuItemRender={(item, dom) => {
          if (!item?.path) return dom;
          if (item?.canAcceptBadge) {
            return (
              <Badge count={1} dot>
                <Link to={item.path} className={styles.linksInMenu}>
                  {dom}
                </Link>
              </Badge>
            );
          }
          if (item?.target === '_blank') {
            return (
              <a
                className={styles.linksInMenu}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                data-e2e={item?.e2e}
              >
                {dom}
              </a>
            );
          }
          return (
            <Link to={item.path} className={styles.linksInMenu}>
              <span
                className={
                  item?.path && location.pathname.includes(item?.path)
                    ? styles.siderActiveItem
                    : ''
                }
              >
                {dom}
              </span>
            </Link>
          );
        }}
        {...settings}
        theme="dark"
      >
        <VersionAlert />
        {children}
      </ProLayout>
    </ConfigProvider>
  );
};
