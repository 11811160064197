import { CameraOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Form, InputNumber, Tooltip } from 'antd';
import useGetData from 'api/useGetData';
import { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { APP_CONSTANS } from 'shared/constants';
import { moneyFromCurrency } from 'shared/utils/DataInTables/renderMoneyFromCurrency';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import general_styles from '../../../shared/styles/styles.module.scss';
import Table from '../Table';
import styles from './styles.module.scss';
import { JobsTableT } from './types';
import { renderTitleLink } from 'shared/utils/DataInTables/renderLinks';
import { queryNames } from 'api/queryNames';
import { columns } from './columns';
import { JobsPageTable } from 'components/pages/main/orders_section/Fulfilments/types';

export const JobsTable: FunctionComponent<JobsTableT> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'jobsTable',
  customClassName,
  rowSelection,
  rowSelectionType,
  additionalSearchParams,
  rowComponent = null,
  dataSource,
  headerTitle,
  renderFromComponentData = null,
  disableFilteringAndSearching = false,
  columnsFromComponent,
  type = JobsPageTable.ALL,
  ...rest
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [complicationChildren, setComplicationChildren] = useState<any>(null);
  const { PAYPAL, VENMO } = APP_CONSTANS.PURCHASE_TARGETS;

  const { data: dataPreferences } = useQuery({
    queryKey: [queryNames.Preferences],
    meta: { token, getAllData: true },
    queryFn: useGetData,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const { data, isFetching } = useQuery({
    queryKey: [queryNames.COMPLICATION_CHILDREN],
    meta: { token, getAllData: true },
    queryFn: useGetData,
    staleTime: 10000,
  });

  useEffect(() => {
    if (data && data.hasOwnProperty('data')) {
      setComplicationChildren(data.data);
    }
  }, [data]);

  const renderLink = (value: string, path: string) => {
    return (
      <Link
        to={path}
        onClick={(event: React.MouseEvent<HTMLElement>) =>
          event.stopPropagation()
        }
      >
        {value}
      </Link>
    );
  };

  // const renderTitle = (value: string) => {
  //   return (
  //     <b className={styles.dataTitle}>
  //       {value && typeof value === 'string' && value.includes('_')
  //         ? value.replace('_', ' ')
  //         : value}
  //     </b>
  //   );
  // };

  const renderType = (value: string) => {
    let complicationName = '';
    if (value) {
      const compl = complicationChildren.find((c: any) => c.id === value);
      complicationName = compl ? compl.name : value;
    }
    return (
      <span className={styles.orderType}>
        {value === APP_CONSTANS.COMPLICATION_CHILDREN.IMAGE ? (
          <CameraOutlined />
        ) : value === APP_CONSTANS.COMPLICATION_CHILDREN.VIDEO ? (
          <VideoCameraOutlined />
        ) : (
          ''
        )}
        <span>{complicationName}</span>
      </span>
    );
  };

  const renderJobStatus = (value: string, record: any) => {
    const statusName = record?.['state.name'];

    return (
      <span className={general_styles.jobStatus} data-job-status={value}>
        {statusName}
      </span>
    );
  };

  const renderPayoutJob = (value: string | number, orderCurrency: any) => {
    if (typeof value !== 'number') {
      return 'Not paid';
    }
    const payout = renderMoneyWithCurrency(value, orderCurrency);
    return (
      <div className={styles.copyBlock}>
        <span className={styles.tooltipValue}>{payout}</span>
      </div>
    );
  };

  const renderPayMethod = (value: string) => {
    const currentMethod = dataPreferences?.data.find(
      (pref: any) => pref.id === value
    );
    return <span>{currentMethod?.pref_code}</span>;
  };

  const renderInputPayoutJob = (
    payout: string | number,
    jobId: string,
    orderCurrency: any
  ) => {
    if (payout === '0' || !payout) {
      return 'Not paid';
    }

    return (
      <Form.Item
        name={`${orderCurrency}**${jobId}`}
        className={styles.payoutItem}
        initialValue={payout}
      >
        <InputNumber
          value={payout}
          style={{ width: '100%' }}
          min={1000}
          formatter={(value: any) =>
            renderMoneyWithCurrency(value, orderCurrency)
          }
          parser={(value: any) => {
            const usd = moneyFromCurrency(value);
            let roundedUSD = Math.round(Number(usd) * 1000);
            return roundedUSD;
          }}
          precision={2}
          step={1000}
        />
      </Form.Item>
    );
  };

  const renderTooltip = (value: string) => {
    return (
      <div>
        {value !== '-' ? (
          <Tooltip overlayClassName={styles.tooltipEmail} title={value}>
            <div className={styles.tooltipBlock}>
              <span className={styles.email}>{value}</span>
            </div>
          </Tooltip>
        ) : (
          value
        )}
      </div>
    );
  };

  const renderQuantityContentByStatus = (value: any) => {
    if (typeof value !== 'number') {
      return '0';
    }
    return value;
  };

  const renderPurchaseMethod = (value: string) => {
    return value === PAYPAL ? 'Paypal' : value === VENMO ? 'Venmo' : value;
  };

  const renderData = (value: string, record: any) => {
    const orderCurrency = record?.['order.currency.code'];
    const jobId = record?.id;
    const orderId = record?.order_id;
    const userId = record?.user_id;
    const orderUserId = record?.['order.user_id'];
    const orderAccountId = record?.['order.account.id'];
    const accountId = record?.['account.id'];

    return [
      {
        name: 'code',
        action: renderLink(value, `/fulfilments/${type}/${jobId}`),
      },
      {
        name: 'order.code',
        action: renderLink(value, `/orders/all/${orderId}`),
      },
      {
        name: 'user.first_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'user.last_name',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'account.id',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.name',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'account.code',
        action: renderLink(value, `/accounts/all/${accountId}`),
      },
      {
        name: 'order.account.code',
        action: renderLink(value, `/accounts/all/${orderAccountId}`),
      },
      {
        name: 'order.title',
        action: renderTitleLink(value, `/fulfilments/${type}/${jobId}`),
        // action: renderTitle(value),
      },
      {
        name: 'order.user.id',
        action: renderLink(value, `/users/all/${orderUserId}`),
      },
      {
        name: 'order.user.email',
        action: renderLink(value, `/users/all/${orderUserId}`),
      },
      {
        name: 'user.email',
        action: renderLink(value, `/users/all/${userId}`),
      },
      {
        name: 'order.account.name',
        action: renderLink(value, `/accounts/all/${orderAccountId}`),
      },
      {
        name: 'order.content_type',
        action: renderType(value),
      },
      {
        name: 'state.id',
        action: renderJobStatus(value, record),
      },
      {
        name: 'payout',
        action: renderPayoutJob(value, orderCurrency),
      },
      {
        name: 'order.payout',
        action: queryKey.includes('formPaidJobs')
          ? renderInputPayoutJob(value, jobId, orderCurrency)
          : renderPayoutJob(value, orderCurrency),
      },
      {
        name: 'order.total',
        action: renderPayoutJob(value, orderCurrency),
      },
      {
        name: 'account.preferences.a6a3f067-7629-465e-9487-ac2fd7a0a710',
        action: renderPayMethod(value),
      },
      {
        name: 'account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd',
        action: renderTooltip(value),
      },
      {
        name: 'account.preferences.a8612805-f592-449c-a6e5-96f7b780911c',
        action: renderTooltip(value),
      },
      {
        name: 'account.preferences.a81ce075-8b13-498f-8906-e2e7d183e38f',
        action: renderTooltip(value),
      },
      {
        name: 'account.preferences.e9318297-eb4f-4ad5-a914-e7031cc16c54',
        action: renderTooltip(value),
      },
      {
        name: 'counter.submitted',
        action: renderQuantityContentByStatus(value),
      },
      {
        name: 'account.purchase_method.id',
        action: renderPurchaseMethod(value),
      },
    ];
  };

  return complicationChildren && !isFetching ? (
    <Table
      tableIndicatorClassName={tableIndicatorClassName}
      customClassName={customClassName}
      searchingByAllTable={false}
      rowSelection={rowSelection}
      rowSelectionType={rowSelectionType}
      toolbar={toolbar}
      queryKey={queryKey}
      meta={meta}
      renderDataColumn={
        renderFromComponentData ? renderFromComponentData : renderData
      }
      headerName={headerName}
      actionsColumn={actionsColumn}
      defaultEnabledSettings={defaultEnabledSettings}
      parametrForSavingSettings={tableName}
      hideInSetting={hideInSetting}
      rowComponent={rowComponent}
      additionalSearchParams={additionalSearchParams}
      dataSource={dataSource}
      headerTitle={headerTitle}
      disableFilteringAndSearching={disableFilteringAndSearching}
      columnsFromComponent={columnsFromComponent}
      quantityTitleText="jobs"
      defaultSorter={{
        field: 'job.created_at',
        order: 'descend',
      }}
      columnsForRequest={columns}
      showSelectAll={false}
      {...rest}
    />
  ) : (
    <></>
  );
};
