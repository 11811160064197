export const colors = {
  primary: {
    20: '#F3F7F9',
    50: '#E6F1F3',
    100: '#C3E0E5',
    400: '#4EBCCF',
    500: '#03A3BF',
    600: '#0091B5',
    700: '#007D9C',
  },
  secondary: {
    400: '#EA5972',
    500: '#DD3552',
    600: '#CB2E49',
    700: '#BD223D',
  },
  neutral: {
    0: '#FFFFFF',
    50: '#EBF0F3',
    100: '#D5DBDF',
    300: '#B3BFC6',
    400: '#94A5AE',
    500: '#70838E',
    600: '#50626C',
    700: '#3F505A',
    900: '#132B3A',
  },
  error: {
    100: '#FF9C94',
    500: '#FC594C',
    700: '#CB1B16',
  },
  other: {
    red500: '#C9342D',
    red700: '#A11F02',
    green500: '#38A306',
    green700: '#2E8206',
    yellow500: '#F7941D',
    yellow700: '#D67500',
    yellow800: '#C16A00',
  },
};
