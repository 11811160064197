import { Map } from 'components/unsorted/Map';
import { MapJobMarkerClusterer } from 'components/unsorted/Map/components/MapJobMarkerClusterer';
import { MapPhotographerMarkerClusterer } from 'components/unsorted/Map/components/MapPhotographerMarkerClusterer';
import { MapUserLocationMarker } from 'components/unsorted/Map/components/MapUserLocationMarker';
import { GoogleMapType } from 'components/unsorted/Map/types';
import { getJobMarkers } from 'components/unsorted/Map/utils/getJobMarkers';
import { getPhotographerMarkers } from 'components/unsorted/Map/utils/getPhotographerMarkers';
import { useGetAllOrders } from 'hooks/api/order/useGetAllOrders';
import { useGetPhotographers } from 'hooks/api/user/useGetPhotographers';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getColumnParamsString } from 'shared/utils/getColumnSearchParams';
import { Address } from 'types/global';
import { calculateRadiusFromBounds } from '../../utils/calculateRadiusFromBounds';
import { createMapControlButton } from '../../utils/createMapControlButton';
import { getCenterAndRadiusAdditionalSearchParams } from '../../utils/getCenterAndRadiusAdditionalSearchParams';
import styles from './styles.module.scss';
import { Spin } from 'antd';
import { getIsDeletedMeta } from '../../utils/getIsDeletedMeta';
import { statusActiveSearchParams } from '../../utils/constants';
import { photographerCols } from './columns';

const DEFAULT_MAP_ZOOM = 10;

interface GlobalMapMapSectionProps {
  radius: number | undefined;
  setRadius: Dispatch<SetStateAction<number | undefined>>;
  setDefaultRadius: Dispatch<SetStateAction<number | undefined>>;
  center: Address | undefined;
  setCenter: Dispatch<SetStateAction<Address | undefined>>;
  showOrders: boolean;
  showPhotographers: boolean;
  photographerFilters: any;
  jobFilters: any;
  location: Address | null;
  dataRequestEnabled: boolean;
  enableDataRequest: () => void;
  photographerIsDeleted: string | null;
  jobIsDeleted: string | null;
  width: number;
  // isLoading: boolean;
}

const MAX_RADIUS = 5301;
const orderColumns = [
  'state_id',
  'content_type',
  'complications',
  'location_lat',
  'location_long',
  'id',
  'payout',
  'title',
];

export const GlobalMapMapSection: FC<GlobalMapMapSectionProps> = ({
  radius,
  setRadius,
  setDefaultRadius,
  center,
  setCenter,
  showOrders,
  showPhotographers,
  photographerFilters,
  jobFilters,
  location,
  enableDataRequest,
  photographerIsDeleted,
  jobIsDeleted,
  width,
  // isLoading,
}) => {
  const [mapRef, setMapRef] = useState<GoogleMapType>();
  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
  const [mapHasUpdated, setMapHasUpdated] = useState(false);

  const additionalSearchParams = getCenterAndRadiusAdditionalSearchParams({
    center,
    radius,
  });
  const photographerAdditionalSearchParams = additionalSearchParams
    ? `${additionalSearchParams}&${statusActiveSearchParams}`
    : `?${statusActiveSearchParams}`;

  const { data: photographersData, isLoading: photographersDataLoading } =
    useGetPhotographers({
      additionalSearchParams: `${photographerAdditionalSearchParams}`,
      meta: {
        getAllData: true,
        columnParams: photographerCols,
        filters: photographerFilters,
        ...getIsDeletedMeta(photographerIsDeleted),
      },
      enabled: showPhotographers,
    });

  const orderColumnsString = getColumnParamsString(orderColumns);
  const ordersAdditionalParams = additionalSearchParams
    ? `${additionalSearchParams}&${orderColumnsString}`
    : `?${orderColumnsString}`;
  const { data: ordersData, isLoading: ordersDataLoading } = useGetAllOrders({
    additionalSearchParams: ordersAdditionalParams,
    enabled: showOrders,
    meta: { filters: jobFilters, ...getIsDeletedMeta(jobIsDeleted) },
  });

  const { data: orders } = ordersData || {};
  const { data: photographers } = photographersData || {};

  const handleMapUpdate = (mapInstance?: google.maps.Map) => {
    setMapHasUpdated(true);
    enableDataRequest();

    const mapBounds = mapInstance?.getBounds()?.toJSON();
    const mapRadius = Math.min(
      calculateRadiusFromBounds(mapBounds) || MAX_RADIUS,
      MAX_RADIUS
    );
    const mapRefCenter = mapInstance?.getCenter()?.toJSON();
    const mapRefZoom = mapInstance?.getZoom();
    const mapZoomValue = mapRefZoom || DEFAULT_MAP_ZOOM;

    // TODO: It's a workaround to refresh map when none of the radius or center hasn't change
    if (mapRadius) setRadius(mapRadius + Math.random() / 1000);
    if (mapRefCenter) setCenter(mapRefCenter);
    if (mapZoom) setMapZoom(mapZoomValue);
  };

  const handleBoundsChange = () => {
    if (!radius) {
      const mapBounds = mapRef?.getBounds()?.toJSON();
      const mapRadius = calculateRadiusFromBounds(mapBounds);
      if (mapRadius) {
        setDefaultRadius(mapRadius);
      }
    }
  };

  const { markers: orderMarkers } = useMemo(
    () => getJobMarkers(orders),
    [orders]
  );

  const { markers: photographerMarkers } = useMemo(
    () => getPhotographerMarkers(photographers),
    [photographers]
  );

  const updateButton = createMapControlButton({
    textContent: '↻ Update',
    onClick: () => handleMapUpdate(mapRef),
    className: styles.updateButton,
  });

  useEffect(() => {
    mapRef?.controls[google.maps.ControlPosition.RIGHT_TOP].push(updateButton);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapRef?.controls]);

  const markersLoading = useMemo(
    () => !!ordersDataLoading && !!photographersDataLoading && mapHasUpdated,
    [mapHasUpdated, ordersDataLoading, photographersDataLoading]
  );

  return (
    <div className={styles.map}>
      {markersLoading && <Spin size="large" className={styles.mapSpinner} />}
      <Map
        getMapReference={(mapReference) => {
          setMapRef(mapReference);
        }}
        onBoundsChanged={handleBoundsChange}
        zoom={mapZoom}
        center={center}
        options={{ minZoom: 2, mapTypeControl: width > 1200 }}
      >
        {location && <MapUserLocationMarker position={location} />}
        {showPhotographers && (
          <MapPhotographerMarkerClusterer>
            {photographerMarkers}
          </MapPhotographerMarkerClusterer>
        )}
        {showOrders && (
          <MapJobMarkerClusterer>{orderMarkers}</MapJobMarkerClusterer>
        )}
      </Map>
    </div>
  );
};
