export const columns = [
  'id',
  'fulfilment_id',
  'order_id',
  'State.id',
  'State.name',
  'deleted_at',
  'original_name',
  'original_url',
  'content_type',
  'content_filename',
  'content_meta',
  'content_size',
  'content_flags',
  'content_url',
  'content_exif',
  'content_key',
  'comments',
  'release_key',
  'release_url',
  'taken_at',
  'updated_at',
  'created_at',
  'preview_url',
  'Order.timezone',
];