import { Button, message } from 'antd';
import { usePostGlobalMapNearby } from 'hooks/api/unsorted/usePostGlobalMapNearby';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { formatFilters } from 'shared/utils/getFiltersSearchParams';
import {
  GlobalMapRadioButtonGroupOptionValue,
  defaultSelectedPhotographerFilters,
} from '../../utils/constants';
import { GlobalMapFiltersSection } from '../GlobalMapFiltersSection';
import styles from './styles.module.scss';
import { debounce } from 'lodash';

export interface GlobalMapHeaderProps {
  selectedTables: GlobalMapRadioButtonGroupOptionValue[];
  handleSetSelectedTables: Dispatch<
    SetStateAction<GlobalMapRadioButtonGroupOptionValue[]>
  >;
  defaultFilters: {
    radius?: number;
    location_lat?: number;
    location_lng?: number;
  };
  photographerFilters?: object;
  jobFilters?: object;
  setNewTab?: any;
  width: number;
}

export const GlobalMapHeader: FC<GlobalMapHeaderProps> = ({
  selectedTables,
  handleSetSelectedTables,
  defaultFilters: { radius, location_lat, location_lng },
  photographerFilters,
  jobFilters,
  setNewTab,
  width,
}) => {
  const { sendNotifications } = usePostGlobalMapNearby();
  const disabled = !radius || !location_lat || !location_lng;

  const handleClick = () => {
    const formattedPhotographerFilters = formatFilters({
      ...defaultSelectedPhotographerFilters.filters,
      ...photographerFilters,
    });
    const formattedJobFilters = formatFilters(jobFilters);

    if (!disabled) {
      sendNotifications(
        {
          radius,
          location_lat,
          location_lng,
          photographerFilters: formattedPhotographerFilters,
          jobFilters: formattedJobFilters,
        },
        {
          onSuccess: () => {
            void message.success(
              'Process of sending jobs to selected photographers started.'
            );
          },
        }
      );
    }
  };
  return (
    <div className={styles.header}>
      <GlobalMapFiltersSection
        selectedOptions={selectedTables}
        setSelectedOptions={handleSetSelectedTables}
        setNewTab={setNewTab}
      />
      <Button
        disabled={disabled}
        type="primary"
        size="small"
        onClick={handleClick}
      >
        {width < 550 ? 'Send notify' : 'Send jobs notify'}
      </Button>
    </div>
  );
};
