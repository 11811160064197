import ProTable from '@ant-design/pro-table';
import styles from './styles.module.scss';
import { TablePropsT, DataTableT } from './types';
import { TableSettingsObjectT } from './components/SettingsForTable/types';
import { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import { Button, InputRef, message, TablePaginationConfig } from 'antd';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { useSelector } from 'react-redux';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import useGetData from '../../../api/useGetData';
import { flattenObject } from '../../../shared/utils/flattenObject';
import { formatSettings } from 'shared/utils/tableSettingsSorter';
import { usePostData } from 'api/usePostData';
import { UrlObject } from 'url';
import { SettingsForTable } from './components/SettingsForTable';
import { Loader } from '../Loader';
import { columnsGenerator } from './components/columnsGenerator/columnsGenerator';
import pluralize from 'pluralize';
import { CsvSvg, XlsSvg } from './components/toolbarIcons';
import { isEqual } from 'lodash';
import { copyTextToTheClipboard } from 'shared/utils/copyTextToTheClipboard';
import { queryNames } from 'api/queryNames';
import { useSearchParams } from 'react-router-dom';
import { SearchFilterTags } from './components/SearchFilterTags';

// For tables with rowSelectionType = checkbox
// isCheckCurrentContent?: any;
// isCheckAllContent?: any;
// selectedContentsIds?: any;
// setIsCheckAllContent?: any;
// setIsCheckCurrentContent?: any;
// setSelectedContentsIds?: any;

const Table = ({
  dataSource,
  columnsFromComponent,
  headerTitle = null,
  searchingByAllTable = false,
  expandedRowRender,
  toolbar,
  toolBarRender = false,
  rowSelection,
  settingsOptions = null,
  rowSelectionType,
  rowComponent,
  handleOnRow,
  customClassName,
  tableStyle,
  rowExpandable,
  queryKey,
  headerName,
  meta,
  renderDataColumn,
  additionalSearchParams,
  refetchData,
  setRefetchData,
  tableIndicatorClassName = '',
  actionsColumn,
  hideInSetting = null,
  defaultEnabledSettings,
  parametrForSavingSettings = null,
  initialTableHeight = 430,
  tableAlertRender,
  tableAlertOptionRender,
  disableFilteringAndSearching = false,
  quantityTitleText,
  getFilters,
  showTotalAmountInTitle = true,
  rowClassName,
  defaultFilters,
  defaultTableFilters,
  defaultSorter,
  dataRequestEnabled = true,
  numbered = false,
  getIsDeleted,
  setCountOfData = null,
  isCheckCurrentContent,
  isCheckAllContent = false,
  setIsCheckAllContent,
  setIsCheckCurrentContent,
  setSelectedContentsIds,
  selectAllCols,
  columnsForRequest,
  showSelectAll,
  loadingFromComponent = null,
  expandIcon = null,
  setDataFromTableComponent = null,
  setFiltersFromTableComponent = null,
  resetAllFiltering = false,
  hideSortingTags = false,
}: TablePropsT<any>) => {
  const [dataForTable, setDataForTable] = useState<any>([]);
  const [sorter, setSorter] = useState(
    defaultSorter && Array.isArray(defaultSorter)
      ? defaultSorter
      : defaultSorter
      ? [defaultSorter]
      : null
  ) as any;
  const [filters, setFilters] = useState(defaultFilters) as any;
  const [tableFilters, setTableFilters] = useState(defaultTableFilters) as any;
  const [tableHeight, setTableHeight] = useState<any>();
  const token = useSelector((state: any) => state.auth.token);
  const [columns, setColumns] = useState<any>([]);
  const [quantityTitle, setQuantityTitle] = useState<any>('');
  const searchInput = useRef<InputRef>(null);
  const [searchKeys, setSearchKeys] = useState<any>({});
  const [rangeKeys, setRangeKeys] = useState<string[]>([]);
  const [codeKeys, setCodeKeys] = useState<string[]>([]);
  const [renderColumns, setRenderColumns] = useState<string[]>([]);
  const [defaultColumnsState, setDefaultColumnsState] = useState<any>();
  const [defaultColumnsStateInSettings, setDefaultColumnsStateInSettings] =
    useState<any>();
  const [isDeleted, setIsDeleted] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadingSelectAllEntries, setLoadingSelectAllEntries] =
    useState<boolean>(false);
  const [loadingResetSettings, setLoadingResetSettings] =
    useState<boolean>(false);
  const [columnParams, setColumnParams] = useState<any>([]);
  const [defaultSortingElems, setDefaultSortingElems] = useState<any>([]);
  const [limit, setLimit] = useState<number | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);
  const firstRender = useRef<number>(1);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching: isLoadingData,
    isFetchingNextPage,
    isError,
    refetch,
  } = useInfiniteQuery({
    queryKey: Array.isArray(queryKey)
      ? [...queryKey, isDeleted, columnParams, limit, filters]
      : [queryKey, isDeleted, columnParams, limit, filters],
    meta: {
      token,
      sorter,
      filters,
      additionalSearchParams,
      meta,
      saveUrl: true,
      columnParams,
      limit,
    }, //updatedMeta: updatedMeta.current
    queryFn: useGetData,
    getNextPageParam: (lastPage: any) => lastPage.nextCursor,
    staleTime: 1000,
    enabled:
      !dataSource &&
      !!limit &&
      dataRequestEnabled &&
      !!columnParams?.length &&
      defaultColumnsState &&
      defaultEnabledSettings &&
      Object.keys(defaultEnabledSettings).every((elem: string) =>
        Object.keys(defaultColumnsState).includes(elem)
      ),
  });

  const { isLoading: isLoadingDefaultLimit, data: dataDefaultLimit } = useQuery(
    {
      queryKey: [queryNames.DefaultLimit],
      meta: {
        token,
        getAllData: true,
      },
      queryFn: useGetData,
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const { isLoading: isLoadingLimit, data: dataLimitForTable } = useQuery({
    queryKey: [
      `${queryNames.ColumnsState}settings_${
        Array.isArray(queryKey) ? [...queryKey] : queryKey
      }`,
      queryKey,
    ],
    meta: { token, getAllData: true },
    queryFn: useGetData,
    staleTime: 1000,
    enabled: !!queryKey && !dataSource,
  });

  const { data: dataPreferenceOptions } = useQuery({
    queryKey: [queryNames.PreferenceOptions],
    meta: { token, getAllData: true },
    queryFn: useGetData,
    cacheTime: Infinity,
    staleTime: Infinity,
  });

  const { isLoading: isLoadingPreferences, data: dataPreferences } = useQuery({
    queryKey: [queryNames.Preferences],
    meta: { token, getAllData: true },
    queryFn: useGetData,
    cacheTime: Infinity,
    staleTime: Infinity,
    enabled: !!dataPreferenceOptions?.data[0]?.id && dataRequestEnabled,
    select: (dataPrefs: any) =>
      dataPreferenceOptions &&
      dataPrefs?.data &&
      dataPrefs?.data?.map((e: any) => ({
        ...e,
        options: dataPreferenceOptions.data.filter(
          (p: { preference_id: string }) => p.preference_id === e.id
        ),
      })),
  });

  const { isError: isErrorHeader, data: dataHeader } = useQuery({
    queryKey: [headerName],
    meta: { token, meta: { paymentsApi: meta?.paymentsApi }, getAllData: true }, // TODO: meta only for payments add
    queryFn: useGetData,
    enabled: !!headerName && (!dataSource || !columnsFromComponent),
    staleTime: Infinity,
  });

  const {
    isLoading: isLoadingColumnsState,
    isError: isErrorColumnsState,
    data: dataColumnsState,
    refetch: refetchColumnsState,
  } = useQuery({
    queryKey: [queryNames.ColumnsState, parametrForSavingSettings],
    meta: { token, getAllData: true, searchParams: parametrForSavingSettings },
    queryFn: useGetData,
    enabled: !!parametrForSavingSettings && !dataSource,
  });

  useEffect(() => {
    if (!isLoadingDefaultLimit && !isLoadingLimit) {
      const defaultLimit = dataLimitForTable?.limit
        ? dataLimitForTable.limit
        : dataDefaultLimit?.limit;
      setLimit(defaultLimit);
    }
  }, [
    isLoadingLimit,
    isLoadingDefaultLimit,
    dataLimitForTable,
    dataDefaultLimit,
  ]);

  // For getting table data to component page
  useEffect(() => {
    if (setDataFromTableComponent) {
      setDataFromTableComponent(dataForTable);
    }
  }, [setDataFromTableComponent, dataForTable]);

  // For getting filters data to component page
  useEffect(() => {
    if (setFiltersFromTableComponent && filters) {
      setFiltersFromTableComponent(filters);
    }
  }, [setFiltersFromTableComponent, filters]);

  const { isLoading: isLoadingContentIds, data: contentIds } = useQuery({
    queryKey: Array.isArray(queryKey)
      ? [...queryKey, isCheckAllContent, columnParams, isDeleted]
      : [queryKey, isCheckAllContent, columnParams, isDeleted],
    meta: {
      token,
      sorter,
      filters,
      getAllData: true,
      columnParams,
      meta: {
        ...meta,
        additionalSearchParams: `${meta?.additionalSearchParams || ''}${
          additionalSearchParams || ''
        }&columns=id${selectAllCols || ''}`,
      },
    },
    queryFn: useGetData,
    enabled: isCheckAllContent,
  });

  useEffect(() => {
    if (
      rowSelectionType === 'checkbox' &&
      showSelectAll &&
      setSelectedContentsIds
    ) {
      // For Select All by 'Arrow'
      if (
        isCheckAllContent &&
        isCheckCurrentContent &&
        !isLoadingContentIds &&
        contentIds
      ) {
        const contentsOnlyQuids = contentIds?.data?.map(
          (content: any) => content.id
        );
        setSelectedContentsIds(contentsOnlyQuids);
        setLoadingSelectAllEntries(false);
      }

      // For UnSelect by 'Arrow' or 'Checkbox'
      if (
        !isCheckCurrentContent &&
        !isCheckAllContent &&
        setSelectedContentsIds
      ) {
        setSelectedContentsIds([]);
      }
    }
  }, [
    rowSelectionType,
    isCheckCurrentContent,
    contentIds,
    isLoadingContentIds,
    isCheckAllContent,
    setSelectedContentsIds,
    showSelectAll,
  ]);

  // End checkbox functionallity

  const save = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network error. Settings was not saved.');
    },
    onSuccess: (dataSettingsSuccess) => {
      if (dataSettingsSuccess && dataSettingsSuccess.hasOwnProperty('error')) {
        message.error(`Error. ${dataSettingsSuccess.error?.message}`);
        return;
      }
      message.success('Settings saved');
      refetchColumnsState();
    },
  });

  const saveCsvXls = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Faild start exporting. Network error.');
    },
    onSuccess: (dataSuccess) => {
      if (dataSuccess && dataSuccess.hasOwnProperty('error')) {
        message.error(`Error. ${dataSuccess.error?.message}`);
        return;
      }
      message.success('Export starting...');
    },
  });

  useEffect(() => {
    if (renderDataColumn) {
      const renderData = renderDataColumn('');
      const fieldNames = renderData.map((e: any) => e.name);
      setRenderColumns(fieldNames);
    }
  }, [renderDataColumn]);

  const selectInput = useCallback(() => {
    setTimeout(() => searchInput?.current?.select(), 100);
  }, []);

  useEffect(() => {
    if (getFilters) {
      getFilters(filters);
    }
  }, [getFilters, filters]);

  useEffect(() => {
    if (
      dataHeader &&
      Object.keys(dataHeader).length &&
      dataHeader?.data &&
      dataHeader?.data?.columns &&
      !isErrorHeader
    ) {
      const header = dataHeader.data.columns;
      const filtersWithPrefix = filters;
      const filtersWithoutPrefix = filters
        ? Object.fromEntries(
            Object.entries(filters).map(([k, v]) => [k.split('__')[0], v])
          )
        : filters;
      const columnsGenerated = columnsGenerator(
        header,
        disableFilteringAndSearching,
        hideInSetting,
        renderColumns,
        renderDataColumn,
        actionsColumn,
        setSearchKeys,
        setRangeKeys,
        setCodeKeys,
        selectInput,
        searchInput,
        tableFilters,
        numbered,
        sorter,
        defaultSortingElems,
        filtersWithoutPrefix,
        searchKeys,
        setSearchParams,
        setFilters,
        filtersWithPrefix
      );
      setColumns(columnsGenerated);
    }
  }, [
    dataHeader,
    isErrorHeader,
    actionsColumn,
    renderColumns,
    renderDataColumn,
    tableFilters,
    numbered,
    disableFilteringAndSearching,
    hideInSetting,
    selectInput,
    sorter,
    defaultSortingElems,
    searchKeys,
    filters,
    setSearchParams,
    setFilters,
  ]);

  useEffect(() => {
    if (defaultEnabledSettings && !parametrForSavingSettings) {
      // console.log('has not columns state')
      const formattedSettings = formatSettings(columns, defaultEnabledSettings);
      setDefaultColumnsState(formattedSettings);
      setDefaultColumnsStateInSettings(formattedSettings);
    }
    if (
      !isLoadingColumnsState &&
      dataColumnsState &&
      !isErrorColumnsState &&
      columns &&
      !!parametrForSavingSettings
    ) {
      // console.log('has columns state')
      const formattedSettings = formatSettings(columns, defaultEnabledSettings);
      setDefaultColumnsStateInSettings(formattedSettings);
      setDefaultColumnsState({ ...formattedSettings, ...dataColumnsState });
    }
  }, [
    dataColumnsState,
    isLoadingColumnsState,
    isErrorColumnsState,
    columns,
    defaultEnabledSettings,
    parametrForSavingSettings,
  ]);

  useEffect(() => {
    if (refetchData) {
      refetch();
      setRefetchData(false);
    }
  }, [refetchData, refetch, setRefetchData]);

  useEffect(() => {
    if (
      !isLoadingData &&
      isLoadingData !== null &&
      !isError &&
      data &&
      dataPreferences &&
      !isLoadingPreferences &&
      dataPreferenceOptions &&
      dataPreferenceOptions?.data &&
      dataRequestEnabled
    ) {
      if (data.hasOwnProperty('data') && !data.hasOwnProperty('pages')) {
        data.pages = [];
        data.pages.push(data);
      }
      const dataFlat = [...data.pages.map((arr: any) => arr.data)].flat();
      const flattenedData = flattenObject(dataFlat);
      if (setCountOfData && data && data.hasOwnProperty('pages')) {
        const dataCount = data?.pages[0];
        setCountOfData(dataCount);
      }
      setDataForTable(flattenedData);
    }

    if (!queryKey && dataSource) {
      setDataForTable(dataSource);
    }
  }, [
    dataRequestEnabled,
    isLoadingData,
    isError,
    data,
    dataSource,
    dataPreferences,
    isLoadingPreferences,
    dataPreferenceOptions,
    queryKey,
    setCountOfData,
  ]);

  useEffect(() => {
    if (columnsFromComponent) {
      setColumns(columnsFromComponent);
    }
  }, [columnsFromComponent]);

  const exportCSV = (type: string) => {
    const fullUrl: UrlObject = new URL(sessionStorage.getItem('lastGet') || '');
    const url: string = `${fullUrl?.pathname?.slice(1)}${fullUrl?.search}`;
    const columnsUpdated = Object.entries(defaultColumnsState)
      .filter((e: any) => e[1]?.show !== false)
      .map((e: any[]) => {
        const elementFromColumns = columns.find(
          (c: any) => c?.dataIndex === e[0]
        );
        return {
          field: e[0],
          fixed: e[1]?.fixed ? e[1]?.fixed : '',
          order: e[1]?.order,
          title: elementFromColumns?.title || '',
          type: elementFromColumns?.type || '',
        };
      });
    saveCsvXls.mutate({
      data: { columns_updated: columnsUpdated, url },
      token,
      otherProps: `reports/objects/${type}`,
    });
  };

  const toolbarExportCsv = (
    <Button
      style={{ padding: '2px 0 0' }}
      key="CSV_button"
      onClick={() => exportCSV('csv')}
      size="small"
      type="link"
    >
      <CsvSvg />
    </Button>
  );

  const toolbarExportXls = (
    <Button
      style={{ padding: '2px 0 0' }}
      key="CSV_button"
      onClick={() => exportCSV('xls')}
      size="small"
      type="link"
    >
      <XlsSvg />
    </Button>
  );

  const reduceSumOfData = (dataSum: any) => {
    return dataSum && dataSum?.pages?.length > 1
      ? dataSum?.pages?.reduce(
          (acc: any, el: any) =>
            (acc.hasOwnProperty('data') ? acc.data.length : acc) +
            el.data.length
        )
      : dataSum?.pages[0]?.data?.length;
  };

  useEffect(() => {
    if (
      hasNextPage &&
      tableIndicatorClassName &&
      data &&
      data?.pages &&
      data?.pages.length
    ) {
      const node = document.querySelector<HTMLElement>(
        `${
          tableIndicatorClassName ? `.${tableIndicatorClassName}` : ''
        } .ant-table-body`
      );
      if (node) {
        setTimeout(() => {
          if (node.scrollHeight === node.clientHeight && hasNextPage) {
            fetchNextPage();
          }
        }, 300);
        const scrollTableListen = () => {
          const percent =
            (node.scrollTop / (node.scrollHeight - node.clientHeight)) * 100;
          if (percent >= 70 && !isFetchingNextPage && hasNextPage) {
            //>= 99.5
            fetchNextPage();
          } else {
            // console.log('percent is: ', percent);
            // console.log('hasNextPage: ', hasNextPage);
            // console.log('isFetchingNextPage: ', isFetchingNextPage);
            // console.log('node: ', node);
          }
        };
        node.addEventListener('scroll', scrollTableListen, { passive: true });
        return () => {
          node.removeEventListener('scroll', scrollTableListen);
        };
      }
    }
    return undefined;
  }, [
    hasNextPage,
    isFetchingNextPage,
    data,
    fetchNextPage,
    tableIndicatorClassName,
  ]);

  useEffect(() => {
    if (sorter && sorter.field && dataRequestEnabled) {
      refetch();
      setDataForTable([]);
    }
    if (filters && !isEqual(filters, defaultFilters) && dataRequestEnabled) {
      refetch();
      setDataForTable([]);
    }
  }, [sorter, filters, refetch, defaultFilters, dataRequestEnabled]);

  // Saving filters and sorting
  const handleTableSettingsChange = useCallback(
    (
      _: TablePaginationConfig,
      filtersNew: Record<string, FilterValue | null>,
      sorterNew: SorterResult<DataTableT> | SorterResult<DataTableT>[]
    ) => {
      //TODO: disable... disable props = false
      let sorterByAlias: any = !Array.isArray(sorterNew)
        ? [sorterNew]
        : sorterNew;
      sorterByAlias.map((sorterObj: any) => {
        if (defaultSortingElems?.includes(sorterObj.field)) {
          setDefaultSortingElems((prevDefaultSortingElems: string[]) => {
            prevDefaultSortingElems.filter(
              (el: string) => el !== sorterObj.field
            );
          });
        }
        if (!sorterObj.hasOwnProperty('column') || !sorterObj?.column) {
          const currentColumn: any = columns.find(
            (column: any) =>
              column.alias === sorterObj.field ||
              column.dataIndex === sorterObj.field
          );
          sorterObj['column'] = currentColumn;
        }
        if (
          sorterObj.column &&
          sorterObj.column.hasOwnProperty('alias') &&
          !sorterObj.field.includes('preference')
        ) {
          sorterObj.field = sorterObj.column.alias;
        }
        if (
          sorterObj.column &&
          sorterObj.column.hasOwnProperty('alias') &&
          sorterObj.field.includes('preference')
        ) {
          sorterObj.field = `${sorterObj.column.alias}::${sorterObj.column.name}`;
        }
        return sorterObj;
      });
      if (sorterByAlias) {
        if (!defaultSorter) {
          setSorter(sorterByAlias);
        } else {
          const activeSorters = sorterByAlias.filter(
            (sorterObj: any) => sorterObj.column && sorterObj.order
          );
          if (activeSorters.length > 1) {
            activeSorters.unshift(activeSorters.pop());
          }
          setSorter(activeSorters);
        }
      }
      if (
        sorterByAlias &&
        sorterByAlias.length === 0 &&
        !defaultSorter &&
        !disableFilteringAndSearching
      ) {
        setSorter([]);
      }

      if (
        filtersNew &&
        Object.entries(filtersNew).length > 0 &&
        !disableFilteringAndSearching
      ) {
        setTableFilters(filtersNew);
        let filterByAlias: any = {};
        for (let key in filtersNew) {
          if (columns.length > 0 && key) {
            const currentColumn: any = columns.find(
              (column: any) => column.dataIndex === key
            );
            let { alias }: any = currentColumn;
            const { isJson } = currentColumn;

            if (
              (searchKeys.hasOwnProperty(currentColumn.alias) ||
                !currentColumn?.filters) &&
              currentColumn?.isJson
            ) {
              filterByAlias[
                `${currentColumn?.alias}%3A%3A${currentColumn?.name}__search`
              ] = { key: searchKeys[currentColumn.alias], isJson: isJson };
            } else if (
              rangeKeys.includes(key) ||
              currentColumn?.type === 'datetime'
            ) {
              filterByAlias[`${alias}__range`] = {
                key: filtersNew[key],
                isJson: isJson,
              };
            } else if (
              codeKeys.includes(key) ||
              currentColumn?.dataIndex === 'code' ||
              currentColumn?.dataIndex.includes('.code')
            ) {
              filterByAlias[`${alias}__code`] = {
                key: filtersNew[key],
                isJson: isJson,
              };
            } else if (
              (searchKeys.hasOwnProperty(currentColumn.alias) ||
                !currentColumn?.filters) &&
              !currentColumn?.isJson
            ) {
              filterByAlias[`${alias}__search`] = {
                key: searchKeys[currentColumn.alias],
                isJson: isJson,
              };
            } else if (currentColumn.isJson) {
              alias += `%3A%3A${currentColumn.name}%3A%3A`;
              filterByAlias[alias] = { key: filtersNew[key], isJson: isJson };
            } else {
              filterByAlias[alias] = { key: filtersNew[key], isJson: isJson };
            }
            setFilters(filterByAlias);
          }
        }
      } else if (
        filtersNew &&
        Object.entries(filtersNew).length === 0 &&
        defaultFilters &&
        !disableFilteringAndSearching
      ) {
        setTableFilters(defaultFilters);
        setFilters(defaultFilters);
      } else if (
        filtersNew &&
        Object.entries(filtersNew).length === 0 &&
        !defaultFilters &&
        !disableFilteringAndSearching
      ) {
        setTableFilters({});
        setFilters({});
      }
    },
    [
      defaultSorter,
      disableFilteringAndSearching,
      defaultFilters,
      defaultSortingElems,
      columns,
      searchKeys,
      rangeKeys,
      codeKeys,
    ]
  );

  const renderFiltersSortingFromUrl = useCallback(
    (searching: string) => {
      if (searching && searching.length > 0) {
        const searchParamsFromUrl = searching.split('&');
        const sortingParams = searchParamsFromUrl.find(
          (params: string) =>
            params.includes('ascend') || params.includes('descend')
        );
        const filteringParams = searchParamsFromUrl.filter(
          (params: string) =>
            !params.includes('ascend') && !params.includes('descend')
        );
        let sorterObj: any = {};
        let filterObj: any = {};
        let searchingObj: any = filteringParams;
        if (sortingParams) {
          const searchKey = sortingParams.split('=')[0];
          const searchColumnKey = searchKey.split('.');
          sorterObj = {
            field: searchKey,
            order: sortingParams.split('=')[1],
            columnKey:
              searchColumnKey.length > 1 ? searchColumnKey[1] : searchColumnKey,
          };
        }
        if (filteringParams && filteringParams.length > 0) {
          const quantityOfActiveFiltersSS = sessionStorage.getItem(
            'quantityOfActiveFilters'
          );
          // Filtering
          if (quantityOfActiveFiltersSS && +quantityOfActiveFiltersSS > 0) {
            let countOfFilters = +quantityOfActiveFiltersSS;
            while (countOfFilters > 0) {
              const filterKeySS = sessionStorage.getItem(
                `filterItemKey${countOfFilters}`
              );
              const filterActiveItems = filteringParams
                .filter((el) => el.includes(`${filterKeySS}`))
                .map((el) => el.split('=')[1]);
              searchingObj = filteringParams.filter(
                (el) => !el.includes(`${filterKeySS}`)
              );
              filterObj[`${filterKeySS}`] = filterActiveItems;
              countOfFilters -= 1;
            }
          }
          // Seacrhing
          searchingObj.forEach((searchParam: string) => {
            const key = searchParam.split('=')[0];
            const value = searchParam.split('=')[1];
            filterObj[key] = [value];
          });
        }
        handleTableSettingsChange({}, filterObj, sorterObj);
      } else {
        handleTableSettingsChange({}, {}, {});
      }
    },
    [handleTableSettingsChange]
  );

  const formatKey = (key: string) => {
    const formattedKey = key.replaceAll('::', '%3A%3A');
    return formattedKey;
  };

  useEffect(() => {
    const handleChangeUrl = () => {
      const location = window.location.search;
      const formatLocation = location.slice(1);
      setSearchParams(formatLocation);
      renderFiltersSortingFromUrl(formatLocation);
    };
    window.addEventListener('popstate', handleChangeUrl);
    return () => {
      window.removeEventListener('popstate', handleChangeUrl);
    };
  }, [renderFiltersSortingFromUrl, setSearchParams]);

  useEffect(() => {
    let searching = '';
    // Filters
    if (filters) {
      let indexActiveFilters = 1;
      const quantityOfActiveFilters: any =
        Object.values(filters).filter((el: any) => el?.length > 1)?.length || 0;
      sessionStorage.setItem(
        `quantityOfActiveFilters`,
        quantityOfActiveFilters
      );
      for (let filterKey in filters) {
        if (filters[filterKey]?.key && Array.isArray(filters[filterKey]?.key)) {
          searching += `&${filterKey}=${filters[filterKey].key.join(
            `&${filterKey}=`
          )}`;

          if (filters[filterKey].length > 1) {
            sessionStorage.setItem(
              `filterItemKey${indexActiveFilters}`,
              filterKey
            );
            indexActiveFilters += 1;
          }
        } else if (filters[filterKey]?.key) {
          searching += `&${filterKey}=${filters[filterKey].key}`;
        }
      }
    }

    if (firstRender.current === 1) {
      let defaultFilter: any = {}; // { alias: {key: null/''/[], isJson: false/true}}
      const searchObj: { [key: string]: any } = {};
      const urlSearchParams = new URLSearchParams(window.location.search);

      for (const [key, value] of urlSearchParams.entries()) {
        if (!value.includes('ascend') && !value.includes('descend')) {
          const formattedKey = key.includes('::') ? formatKey(key) : key;
          if (defaultFilter.hasOwnProperty(formattedKey)) {
            defaultFilter[formattedKey].key.push(value);
          } else {
            defaultFilter[formattedKey] = {
              key: [value],
              isJson: formattedKey.includes('%3A%3A'),
            };
          }
          if (formattedKey.includes('__search')) {
            const aliasFromKey = formattedKey.replace('__search', '');
            searchObj[aliasFromKey] = [value];
            setSearchKeys(searchObj);
          }
        }
      }
      if (Object.keys(defaultFilter)?.length > 0 && !resetAllFiltering) {
        setFilters(defaultFilter);
      }
    }
    // if (tableFilters) {
    //   let indexActiveFilters = 1;
    //   const quantityOfActiveFilters: any = Object.values(tableFilters).filter((el: any) => el?.length > 1)?.length || 0;
    //   sessionStorage.setItem(`quantityOfActiveFilters`, quantityOfActiveFilters);
    //   for (let filterKey in tableFilters) {
    //     if (tableFilters[filterKey] && Array.isArray(tableFilters[filterKey])) {
    //       searching += `&${filterKey}=${tableFilters[filterKey].join(`&${filterKey}=`)}`;
    //       if (tableFilters[filterKey].length > 1) {
    //         sessionStorage.setItem(`filterItemKey${indexActiveFilters}`, filterKey);
    //         indexActiveFilters++;
    //       }
    //     } else if (tableFilters[filterKey]) {
    //       searching += `&${filterKey}=${tableFilters[filterKey]}`;
    //     }
    //   }
    // }

    //Sorters
    if (sorter && sorter.length > 0) {
      const sorterParams = sorter.map(
        (sortObj: any) => `&${sortObj.field}=${sortObj.order}`
      );
      searching += sorterParams.join('');
    }

    if (firstRender.current === 1 && !resetAllFiltering) {
      let defaultSorters = [];
      const urlSearchParams = new URLSearchParams(window.location.search);
      for (const [key, value] of urlSearchParams.entries()) {
        if (value.includes('ascend') || value.includes('descend')) {
          defaultSorters.push({
            field: key,
            order: value,
          });
        }
      }
      defaultSorters.length > 0 &&
        !hideSortingTags &&
        setSorter(defaultSorters);
      firstRender.current = 2;
    }
    const searchParamsNew = searching.slice(1);
    if (!hideSortingTags) {
      setSearchParams(searchParamsNew, { replace: true });
      setTableFilters(filters);
      setDefaultSortingElems(
        sorter?.map((sorterElem: any) => sorterElem.field)
      );
    } else {
      setSearchParams('', { replace: true });
    }
  }, [sorter, filters, resetAllFiltering, hideSortingTags, setSearchParams]);

  // Columns for Requests

  const removeDuplicates = (columnNames: string[]) => {
    return columnNames.filter(
      (item: string, index: number) =>
        columnNames.indexOf(item) === index && item && item?.length > 0
    );
  };

  useEffect(() => {
    if (
      dataHeader &&
      dataHeader.hasOwnProperty('data') &&
      defaultColumnsState &&
      Object.keys(defaultColumnsState).length > 0
    ) {
      const columnsArr: any = [];
      for (let key in defaultColumnsState) {
        if (
          !defaultColumnsState[key].hasOwnProperty('show') &&
          !defaultColumnsState[key].show !== false
        ) {
          let columnName;
          const arrKey = key.split('.');
          if (arrKey.length > 1) {
            const fieldNameIndex = key.lastIndexOf('.');
            const fieldName = key.substring(0, fieldNameIndex);
            const currentCol = dataHeader.data.columns.find(
              (header: any) => header.field === fieldName
            );
            if (
              currentCol &&
              currentCol.hasOwnProperty('json_fields') &&
              currentCol.json_fields
            ) {
              columnName = currentCol?.name;
            } else {
              columnName = dataHeader.data.columns.find(
                (header: any) => header.field === key
              )?.name;
            }
          } else {
            columnName = dataHeader.data.columns.find(
              (header: any) => header.field === key
            )?.name;
          }
          columnsArr.push(columnName);
        }
      }
      const generalCols = columnsArr.concat(columnsForRequest);
      setColumnParams(
        generalCols && generalCols?.length > 0
          ? removeDuplicates(generalCols)
          : []
      );
    }
  }, [
    defaultColumnsState,
    dataHeader,
    defaultColumnsStateInSettings,
    columnsForRequest,
  ]);

  useEffect(() => {
    let currentQuantity;
    let generalQuantity;
    if (
      data &&
      data.pages &&
      !data.pages[0].hasOwnProperty('error') &&
      !dataSource
    ) {
      currentQuantity = reduceSumOfData(data);
      generalQuantity = data.pages[0].count;
    } else if (dataSource) {
      currentQuantity = dataSource.length;
      generalQuantity = dataSource.length;
    }
    const title = pluralize(quantityTitleText || 'entries', currentQuantity);

    const tableQuantityTitle = (
      <div className={styles.headerBlock}>
        <span className={styles.subTitle}>
          {showTotalAmountInTitle ? (
            `Showing ${currentQuantity} of ${generalQuantity} ${title}`
          ) : (
            <>
              Showing:{' '}
              <span
                className={styles.subTitleBold}
              >{`${currentQuantity} ${title}`}</span>
            </>
          )}
        </span>
      </div>
    );

    setQuantityTitle(
      currentQuantity && generalQuantity ? tableQuantityTitle : ''
    );
  }, [
    data,
    dataSource,
    quantityTitleText,
    showTotalAmountInTitle,
    searchParams,
    dataHeader,
  ]);

  const saveSettings = (newValues: TableSettingsObjectT) => {
    save.mutate({
      data: newValues,
      token,
      otherProps: `${queryNames.ColumnsState}${parametrForSavingSettings}`,
    });
  };

  // START --> Auto-height for Table
  const calculateTableHeight = useCallback(
    (isFullScreen: boolean = false) => {
      if (!divRef?.current) return;
      const { top } = divRef.current.getBoundingClientRect();
      const windowHeight = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const correction = 150;
      const newTableHeight = windowHeight - top - correction;
      if (newTableHeight < 300 && initialTableHeight) {
        setTableHeight(initialTableHeight);
        return;
      }
      if (newTableHeight < 300 && !initialTableHeight) {
        setTableHeight(450);
        return;
      }
      setTableHeight(
        isFullScreen ? windowHeight : windowHeight - top - correction
      );
    },
    [initialTableHeight]
  );

  const divRefNode = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;
      divRef.current = node;
      calculateTableHeight();
    },
    [calculateTableHeight]
  );

  useEffect(() => {
    const fullScreen = () => {
      if (document.fullscreenElement && hasNextPage) {
        fetchNextPage();
        setTimeout(() => calculateTableHeight(true), 100);
      } else {
        setTimeout(() => calculateTableHeight(), 100);
      }
    };
    document.addEventListener('fullscreenchange', fullScreen);
    return () => {
      document.removeEventListener('fullscreenchange', fullScreen);
    };
  }, [hasNextPage, fetchNextPage, calculateTableHeight]);

  // END --> Auto-height for Table

  useEffect(() => {
    if (getIsDeleted) {
      getIsDeleted(isDeleted);
    }
  }, [getIsDeleted, isDeleted]);

  // TODO: Resolve DOMException error on click;
  const cellComponent: any = ({ ...props }) => (
    <td
      {...props}
      onContextMenu={(e) => {
        e.stopPropagation();
        if ((e as any).target.tagName.toLowerCase() !== 'a') {
          e.preventDefault();
          const { innerText } = e.target as any;
          void copyTextToTheClipboard(e, innerText);
        }
      }}
    ></td>
  );

  const components: any = {
    body: {
      row: rowComponent,
      cell: cellComponent,
    },
  };

  const emptyTableAlertRender = () => false;
  const emptyTableAlertOptionRender = () => false;

  const customSettings = (
    <SettingsForTable
      columnsState={defaultColumnsState}
      defaultColumnsState={defaultColumnsStateInSettings}
      hide={hideInSetting ? hideInSetting : []}
      columns={columns}
      saveSettings={saveSettings}
      setIsDeleted={setIsDeleted}
      setLoadingResetSettings={setLoadingResetSettings}
      setLimit={setLimit}
      defaultLimit={
        dataLimitForTable?.limit
          ? dataLimitForTable.limit
          : dataDefaultLimit?.limit
      }
      queryKey={queryKey}
    />
  );

  //toolBarRender - ACTIONS,  we need to use when we want to show buttons at settings , csv, xls

  return (
    <>
      {columns &&
      columns.length > 0 &&
      ((queryKey &&
        defaultColumnsState &&
        Object.keys(defaultColumnsStateInSettings).length > 0) ||
        !queryKey) ? (
        <>
          {parametrForSavingSettings && !hideSortingTags && (
            <SearchFilterTags
              searchParams={searchParams}
              dataHeader={dataHeader}
              setSearchParams={setSearchParams}
              defaultSorter={defaultSorter}
              setSorter={setSorter}
              setDefaultSortingElems={setDefaultSortingElems}
              setFilters={setFilters}
              setSearchKeys={setSearchKeys}
            />
          )}
          <div ref={divRefNode} style={{ height: 0 }}></div>

          {(!dataSource && tableHeight) || dataSource ? (
            <ProTable<DataTableT>
              // actionRef={() => null}
              components={components}
              dataSource={dataForTable}
              columns={columns}
              // loading={isLoadingData}
              loading={
                loadingFromComponent !== null
                  ? loadingFromComponent
                  : isLoadingData && !isFetchingNextPage
              }
              columnsState={{
                value: defaultColumnsState,
                defaultValue: defaultColumnsStateInSettings,
              }}
              headerTitle={
                headerTitle !== null
                  ? headerTitle
                  : parametrForSavingSettings
                  ? quantityTitle
                  : ''
              }
              // caption={headerTitle !== null ? headerTitle : quantityTitle}
              options={
                settingsOptions !== null
                  ? settingsOptions
                  : {
                      fullScreen: true,
                      reload: false,
                      setting: false,
                      // setting: {
                      //   draggable: true,
                      //   checkable: true,
                      //   checkedReset: true,
                      //   // listsHeight: 300,
                      //   // extra: <p></p>,
                      //   // children: <p></p>,
                      // },
                      density: false,
                    }
              }
              className={clsx(
                styles.customTable,
                'generalTable',
                customClassName && customClassName,
                tableIndicatorClassName && tableIndicatorClassName
              )}
              showSorterTooltip={false}
              search={searchingByAllTable || false}
              rowKey="id"
              pagination={false}
              // pagination={{
              // defaultPageSize: 100,
              // showSizeChanger: true,
              // onShowSizeChange: onShowSizeChange,
              // pageSizeOptions: [20, 50, 100, 150, 200],
              // showTitle: false,
              // itemRender: () => '', // render pages - hide html pages in css styles
              // showTotal: () => '', // hide text
              // }}
              expandable={{
                expandedRowRender: expandedRowRender ? expandedRowRender : null,
                rowExpandable: rowExpandable ? rowExpandable : null,
                expandIcon: expandIcon && expandIcon,
              }}
              toolbar={{ ...toolbar }}
              rowSelection={
                rowSelection
                  ? {
                      type: rowSelectionType,
                      // columnTitle: showSelectAll ? headerCheckbox : null,
                      selections:
                        showSelectAll && rowSelectionType === 'checkbox'
                          ? [
                              {
                                key: 'selectAllBtn',
                                text: 'Select All',
                                onSelect: () => {
                                  setLoadingSelectAllEntries(true);
                                  setIsCheckAllContent(true);
                                  setIsCheckCurrentContent(true);
                                },
                              },
                            ]
                          : null,
                      onSelectAll: (selected) => {
                        if (!selected) {
                          setIsCheckAllContent && setIsCheckAllContent(false);
                          setIsCheckCurrentContent &&
                            setIsCheckCurrentContent(false);
                        }
                      },
                      ...rowSelection,
                    }
                  : undefined
              }
              toolBarRender={
                toolBarRender &&
                Array.isArray(toolBarRender) &&
                !parametrForSavingSettings
                  ? () => [...toolBarRender]
                  : !toolBarRender && !parametrForSavingSettings
                  ? () => []
                  : toolBarRender && Array.isArray(toolBarRender)
                  ? () => [
                      ...toolBarRender,
                      toolbarExportXls,
                      toolbarExportCsv,
                      customSettings,
                    ]
                  : () => [toolbarExportXls, toolbarExportCsv, customSettings]
              }
              bordered={false}
              scroll={{ y: tableHeight, scrollToFirstRowOnChange: false }}
              onRow={handleOnRow}
              tableStyle={tableStyle}
              onChange={handleTableSettingsChange}
              tableAlertRender={
                tableAlertRender ? tableAlertRender : emptyTableAlertRender
              }
              tableAlertOptionRender={
                tableAlertOptionRender
                  ? tableAlertOptionRender
                  : emptyTableAlertOptionRender
              }
              rowClassName={rowClassName}
            />
          ) : (
            <p>
              hei: {tableHeight}, daats{typeof dataSource}
            </p>
          )}
        </>
      ) : (
        <Loader />
      )}

      {(loadingSelectAllEntries || loadingResetSettings) && (
        <div className={styles.selectAllLoader}>
          <Loader />
        </div>
      )}
    </>
  );
};

export default Table;
