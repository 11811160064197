export const getCurrensySymbol = (
  currency: string,
  locale: string = 'en-US'
) => {
  if (currency) {
    const symbol = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    })
      .formatToParts(1)
      .find((x) => x.type === 'currency')?.value;
    return symbol;
  }
  return '';
};
