export const defaultEnabledSettings = {
  code: { order: 1 },
  'order.title': { order: 2 },
  'order.code': { order: 3 },
  'user.first_name': { order: 4 },
  'user.last_name': { order: 5 },
  'user.email': { order: 6 },
  completed_at: { order: 7 },
  'account.preferences.a6a3f067-7629-465e-9487-ac2fd7a0a710': { order: 8 },
  'account.preferences.e9318297-eb4f-4ad5-a914-e7031cc16c54': { order: 9 },
  'order.payout': { order: 10 },
};
