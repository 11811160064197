export const ACTION_TYPES = Object.freeze({
  GET_INVOICES: '@@INVOICE/GET_INVOICES',
  GET_INVOICES_PENDING: '@@INVOICE/GET_INVOICES_PENDING',
  GET_INVOICES_FULFILLED: '@@INVOICE/GET_INVOICES_FULFILLED',
  GET_INVOICES_REJECTED: '@@INVOICE/GET_INVOICES_REJECTED',

  GET_INVOICE_ITEMS: '@@INVOICE/GET_INVOICE_ITEMS',
  GET_INVOICE_ITEMS_PENDING: '@@INVOICE/GET_INVOICE_ITEMS_PENDING',
  GET_INVOICE_ITEMS_FULFILLED: '@@INVOICE/GET_INVOICE_ITEMS_FULFILLED',
  GET_INVOICE_ITEMS_REJECTED: '@@INVOICE/GET_INVOICE_ITEMS_REJECTED',

  GET_NOT_INVOICE_ORDERS: '@@INVOICE/GET_NOT_INVOICE_ORDERS',
  GET_NOT_INVOICE_ORDERS_PENDING: '@@INVOICE/GET_NOT_INVOICE_ORDERS_PENDING',
  GET_NOT_INVOICE_ORDERS_FULFILLED:
    '@@INVOICE/GET_NOT_INVOICE_ORDERS_FULFILLED',
  GET_NOT_INVOICE_ORDERS_REJECTED: '@@INVOICE/GET_NOT_INVOICE_ORDERS_REJECTED',

  SET_INVOICE_MEMO: '@@INVOICE/SET_INVOICE_MEMO',
});
