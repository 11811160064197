import { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';

export const ExtendTime: FunctionComponent<any> = ({
  userId,
  firstName,
  middleName,
  lastName,
  extraData,
  description,
}) => {
  const {
    jobCode,
    orderCode,
    orderTitle,
    jobExpiredAt,
    extendTime,
    jobId,
    orderId,
  } = extraData;
  return (
    <div className={styles.extraData}>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>User:</span>
        <Link to={`/users/all/${userId}`}>
          {`${firstName} ${middleName} ${lastName}`}
        </Link>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Fulfilment code:</span>
        <Link to={`/fulfilments/all/${jobId}`}>{jobCode}</Link>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Order code:</span>
        <Link to={`/orders/all/${orderId}`}>{orderCode}</Link>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Order title:</span>
        <span className={styles.extraData__value}>{orderTitle}</span>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Fulfilment expired at:</span>
        <span className={styles.extraData__value}>
          {convertUNIXToLocale(jobExpiredAt)}
        </span>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>Extend time:</span>
        <span className={styles.extraData__value}>{extendTime}</span>
      </p>
      <p className={styles.extraData__item}>
        <span className={styles.extraData__label}>
          Comment from Photographer:
        </span>
        <span className={styles.extraData__value}>{description}</span>
      </p>
    </div>
  );
};
