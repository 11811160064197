export const hideInSetting = [
  'id',
  'purchase_id',
  'fulfilment_id',
  'purchase.code',
  'purchase.id',
  'purchase.account_id',
  'purchase.currency_id',
  'purchase.state_id',
  'purchase.memo',
  'purchase.amount',
  'purchase.payed_sum',
  'purchase.start_date',
  'purchase.end_date',
  'purchase.paid_at',
  'purchase.created_at',
  'purchase.updated_at',
  'fulfilment.code',
  'fulfilment.id',
  'fulfilment.order_id',
  'fulfilment.user_id',
  'fulfilment.account_id',
  'fulfilment.state_id',
  'fulfilment.payout',
  'fulfilment.created_at',
  'fulfilment.updated_at',
  'fulfilment.expired_at',
  'fulfilment.completed_at',
  'fulfilment.deleted_at',
];
