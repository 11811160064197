import { Alert, Button } from 'antd';
import clsx from 'clsx';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import { FC, useCallback } from 'react';
import styles from './styles.module.scss';
import globalStyles from '../../../shared/styles/styles.module.scss';
import { ModalActionConfirmT } from './types';

export const ModalActionConfirm: FC<ModalActionConfirmT> = ({
  action,
  title,
  okText,
  cancelText = 'Cancel',
  message,
  showModal,
  setShowModal,
}) => {
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <ModalWindow
      title={title}
      visible={showModal}
      width={window.innerWidth > 700 ? '600px' : '95vw'}
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      {message && <Alert type="warning" message={message} showIcon />}

      <div className={styles.actions}>
        <Button
          className={clsx(globalStyles.btn, globalStyles.secondary__btn)}
          style={{ marginRight: '8px' }}
          onClick={closeModal}
        >
          {cancelText}
        </Button>

        <Button
          htmlType="button"
          type="primary"
          onClick={() => action?.call && action.call()}
        >
          {okText}
        </Button>
      </div>
    </ModalWindow>
  );
};
