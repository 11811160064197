import { PhotographerRate } from 'shared/constants';
import photographerMarkerCrown from './images/photographerMarkerCrown.svg';
import photographerMarkerCrownDrone from './images/photographerMarkerCrownDrone.svg';
import photographerMarkerStar from './images/photographerMarkerStar.svg';
import photographerMarkerStarDrone from './images/photographerMarkerStarDrone.svg';
import photographerMarkerTrophy from './images/photographerMarkerTrophy.svg';
import photographerMarkerTrophyDrone from './images/photographerMarkerTrophyDrone.svg';

export const getPhotographerMarkerIcon = (
  rate: PhotographerRate,
  droneLicense: boolean
) => {
  switch (rate) {
    case PhotographerRate.UNRATED: {
      return droneLicense
        ? photographerMarkerStarDrone
        : photographerMarkerStar;
    }
    case PhotographerRate.ENTHUSIAST: {
      return droneLicense
        ? photographerMarkerTrophyDrone
        : photographerMarkerTrophy;
    }
    case PhotographerRate.PROFESSIONAL: {
      return droneLicense
        ? photographerMarkerCrownDrone
        : photographerMarkerCrown;
    }
    default: {
      return null;
    }
  }
};
