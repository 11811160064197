export const ACTION_TYPES = Object.freeze({
  GET_ACCOUNTS: '@@ACCOUNTS/GET_ACCOUNTS',
  GET_ACCOUNTS_PENDING: '@@ACCOUNTS/GET_ACCOUNTS_PENDING',
  GET_ACCOUNTS_FULFILLED: '@@ACCOUNTS/GET_ACCOUNTS_FULFILLED',
  GET_ACCOUNTS_REJECTED: '@@ACCOUNTS/GET_ACCOUNTS_REJECTED',
  GET_ACCOUNT_BY_ID: '@@ACCOUNTS/GET_ACCOUNT_BY_ID',
  GET_ACCOUNT_BY_ID_PENDING: '@@ACCOUNTS/GET_ACCOUNT_BY_ID_PENDING',
  GET_ACCOUNT_BY_ID_FULFILLED: '@@ACCOUNTS/GET_ACCOUNT_BY_ID_FULFILLED',
  GET_ACCOUNT_BY_ID_REJECTED: '@@ACCOUNTS/GET_ACCOUNT_BY_ID_REJECTED',
  GET_LIST_OF_ACCOUNTS: '@@ACCOUNTS/GET_LIST_OF_ACCOUNTS',
  GET_LIST_OF_ACCOUNTS_PENDING: '@@ACCOUNTS/GET_LIST_OF_ACCOUNTS_PENDING',
  GET_LIST_OF_ACCOUNTS_FULFILLED: '@@ACCOUNTS/GET_LIST_OF_ACCOUNTS_FULFILLED',
  GET_LIST_OF_ACCOUNTS_REJECTED: '@@ACCOUNTS/GET_LIST_OF_ACCOUNTS_REJECTED',

  GET_LIST_OF_ACCOUNT_PREF: '@@ACCOUNTS/GET_LIST_OF_ACCOUNT_PREF',
  GET_LIST_OF_ACCOUNT_PREF_PENDING:
    '@@ACCOUNTS/GET_LIST_OF_ACCOUNT_PREF_PENDING',
  GET_LIST_OF_ACCOUNT_PREF_FULFILLED:
    '@@ACCOUNTS/GET_LIST_OF_ACCOUNT_PREF_FULFILLED',
  GET_LIST_OF_ACCOUNT_PREF_REJECTED:
    '@@ACCOUNTS/GET_LIST_OF_ACCOUNT_PREF_REJECTED',

  REMOVE_LIST_OF_ACCOUNTS: '@@ACCOUNTS/REMOVE_LIST_OF_ACCOUNTS',
});
