import React from 'react';
import styles from './styles.module.scss';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';

export const columnsNested = (
  currencyCode: string | undefined,
  id: string,
  setCurrentOption: any,
  setShow: any
) => {
  const link = (value: any, record: any) => (
    <p
      className={styles.link}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setCurrentOption(record);
        setShow(true);
      }}
    >
      {value}
    </p>
  );

  return [
    {
      title: 'Id',
      width: 200,
      dataIndex: 'id',
      key: 'id' + id,
      render: (value: any, record: any) => link(value, record),
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
      key: 'transaction_type' + id,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status' + id,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount' + id,
      render: (value: any) => {
        return renderMoneyWithCurrency(value, currencyCode);
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration' + id,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at' + id,
      render: (value: any) => convertUNIXToLocale(value, null),
    },
  ];
};
