import { ACTION_TYPES } from './types';
import { AccountsDataT } from './data_types';

const INITIAL_STATE = {
  accounts: <AccountsDataT>{},
  isLoadingAccounts: null,
  account: <AccountsDataT>{},
  isLoadingAccount: null,
  listOfAccounts: <AccountsDataT>{},
  isLoadingListOfAccounts: null,
  listOfAccountPref: <AccountsDataT>{},
  isLoadingListOfAccountPref: null,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }: any) => {
  switch (type) {
    case ACTION_TYPES.GET_ACCOUNTS_PENDING:
      return { ...state, isLoadingAccounts: true };
    case ACTION_TYPES.GET_ACCOUNTS_FULFILLED:
      return { ...state, accounts: payload, isLoadingAccounts: false };
    case ACTION_TYPES.GET_ACCOUNTS_REJECTED:
      return { ...state, accounts: payload, isLoadingAccounts: false };

    case ACTION_TYPES.GET_ACCOUNT_BY_ID_PENDING:
      return { ...state, isLoadingAccount: true };
    case ACTION_TYPES.GET_ACCOUNT_BY_ID_FULFILLED:
      return { ...state, account: payload, isLoadingAccount: false };
    case ACTION_TYPES.GET_ACCOUNT_BY_ID_REJECTED:
      return { ...state, account: payload, isLoadingAccount: false };

    case ACTION_TYPES.GET_LIST_OF_ACCOUNTS_PENDING:
      return { ...state, isLoadingListOfAccounts: true };
    case ACTION_TYPES.GET_LIST_OF_ACCOUNTS_FULFILLED:
      return {
        ...state,
        listOfAccounts: payload,
        isLoadingListOfAccounts: false,
      };
    case ACTION_TYPES.GET_LIST_OF_ACCOUNTS_REJECTED:
      return {
        ...state,
        listOfAccounts: payload,
        isLoadingListOfAccounts: false,
      };

    case ACTION_TYPES.GET_LIST_OF_ACCOUNT_PREF_PENDING:
      return { ...state, isLoadingListOfAccountPref: true };
    case ACTION_TYPES.GET_LIST_OF_ACCOUNT_PREF_FULFILLED:
      return {
        ...state,
        listOfAccountPref: payload,
        isLoadingListOfAccountPref: false,
      };
    case ACTION_TYPES.GET_LIST_OF_ACCOUNT_PREF_REJECTED:
      return {
        ...state,
        listOfAccountPref: payload,
        isLoadingListOfAccountPref: false,
      };

    case ACTION_TYPES.REMOVE_LIST_OF_ACCOUNTS:
      return { ...state, listOfAccounts: {}, isLoadingListOfAccounts: null };

    default:
      return state;
  }
};
