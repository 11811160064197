import { QueryMeta, useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { useSelector } from 'react-redux';
import { additionalSearchParamsToQueryKeys } from 'shared/utils/api/additionalSearchParamsToQueryKeys';

export const useGetUsers = ({
  additionalSearchParams,
  meta,
  baseQueryKey,
  enabled = true,
}: {
  additionalSearchParams?: string;
  meta?: QueryMeta;
  baseQueryKey?: string;
  enabled?: boolean;
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const parsedSearchParams = additionalSearchParams
    ? additionalSearchParamsToQueryKeys(additionalSearchParams)
    : [];

  const baseQueryKeyValue = baseQueryKey || queryNames.USERS_ALL;

  return useQuery({
    queryKey: [baseQueryKeyValue, ...parsedSearchParams],
    meta: {
      ...(meta ? meta : {}),
      additionalSearchParams: additionalSearchParams
        ? additionalSearchParams
        : '',
      // TODO: Pass token to base query
      token,
    },
    // TODO: Pass useGetData to base query
    queryFn: useGetData,
    enabled,
  });
};
