import { MarkerF, MarkerProps } from '@react-google-maps/api';
import { FC } from 'react';
import { JobMarkerData } from '../../types';
import { getJobMarkerIcon } from './getJobMarkerIcon';

export type MapJobMarkerProps = Pick<MarkerProps, 'clusterer' | 'onClick'> &
  JobMarkerData;

export const MapJobMarker: FC<MapJobMarkerProps> = ({
  jobType,
  jobStatus,
  position,
  price,
  clusterer,
  onClick,
  title,
}) => {
  const { icon: markerIconUrl, color: markerIconColor } =
    getJobMarkerIcon(jobType, jobStatus) || {};

  const labelStyles = {
    text: `$${price}`,
    fontFamily: 'Lato',
    color: markerIconColor,
    fontWeight: '700',
  };

  const markerIcon = markerIconUrl
    ? { url: markerIconUrl, labelOrigin: new google.maps.Point(35, 14) }
    : undefined;
  return (
    <MarkerF
      onClick={onClick}
      position={position}
      clusterer={clusterer}
      label={labelStyles}
      icon={markerIcon}
      title={title}
    />
  );
};
