import { SettingOutlined } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Drawer,
  Tooltip,
  Tree,
  message,
  Input,
  Select,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { DataNode, TreeProps } from 'antd/es/tree';
import { useCallback, useEffect, useState } from 'react';
import { APP_CONSTANS } from 'shared/constants';
import styles from './styles.module.scss';
import { SettingTableProps, TableSettingsObjectT } from './types';
import { useSelector } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { queryNames } from 'api/queryNames';

const { Search } = Input;

export const SettingsForTable = ({
  columnsState,
  defaultColumnsState,
  hide,
  columns,
  saveSettings,
  setIsDeleted,
  setLoadingResetSettings,
  setLimit,
  defaultLimit,
  queryKey,
}: SettingTableProps) => {
  const checkList = [
    { label: 'With deleted', value: APP_CONSTANS.WITH_DELETED },
    { label: 'Deleted only', value: APP_CONSTANS.ONLY_DELETED },
  ];

  const token = useSelector((state: any) => state.auth.token);
  const [open, setOpen] = useState(false);
  const [treeData, setTreeData] = useState<DataNode[] | []>([]);
  const [treeDataSelected, setTreeDataSelected] = useState<string[]>([]);
  const [treeDataFiltered, setTreeDataFiltered] = useState<
    DataNode[] | undefined
  >();
  const [isSelected, setIsSelected] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const saveLimit = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {},
    onSuccess: (dataSuccess) => {
      if (dataSuccess && dataSuccess.hasOwnProperty('error')) {
        message.error(`Error. ${dataSuccess.error?.message}`);
        return;
      }
      message.success('Limit changed');
    },
  });

  const paginationOptions = [
    { value: 20, label: '20 / page' },
    { value: 50, label: '50 / page' },
    { value: 100, label: '100 / page' },
    { value: 150, label: '150 / page' },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSearchValue('');
    onSearch('');
  };

  const save = (all: DataNode[], selected: string[], type?: string) => {
    if (all.length && selected.length) {
      const formattedData: TableSettingsObjectT = {};
      all.forEach(
        (element, i) =>
          (formattedData[element.key.toString()] = {
            order: i,
            ...(selected.includes(element.key.toString())
              ? {}
              : { show: false }),
          })
      );
      saveSettings(formattedData);
    }
    if (type === 'reset') {
      setLoadingResetSettings(false);
      message.success('Settings are resetting');
    }
  };

  const asTreeData = useCallback(
    (arr: string[], isChecked: boolean = false) => {
      if (!arr.length) return [];
      const availavle: DataNode[] = [];
      arr.forEach((i) => {
        const row = columns?.find((e: any) => e?.dataIndex === i);
        const isDisabled = !!hide?.includes(i);
        if (!row || !row?.title) return;
        availavle.push({
          title: row.title,
          key: i,
          disabled: isDisabled,
          ...(isChecked
            ? {}
            : isDisabled
            ? { style: { display: 'none' } }
            : {}),
        });
        return;
      });
      return availavle;
    },
    [columns, hide]
  );

  useEffect(() => {
    if (columnsState && defaultColumnsState && hide) {
      const data = columnsState || defaultColumnsState;
      const dataInOrder: { [key: string]: any } = {};
      Object.entries(data).forEach(([dataItemKey, dataItemValue]) => {
        const { order } = dataItemValue || {};
        if (order !== undefined) {
          dataInOrder[order] = dataItemKey;
        }
      });
      const sortedDataKeys: string[] = Object.values(dataInOrder) || [];

      const checked: string[] = sortedDataKeys.filter(
        (key: string) => columnsState[key]?.show !== false
      );
      const unchecked: string[] = sortedDataKeys.filter(
        (key) => columnsState[key]?.show === false
      );

      const updatedData = [
        ...asTreeData(checked, true),
        ...asTreeData(unchecked),
      ];

      if (!treeDataFiltered) {
        setTreeData(updatedData);
      }
      setTreeDataSelected(checked);
    }
  }, [columnsState, defaultColumnsState, hide, treeDataFiltered, asTreeData]);

  const onCheck: TreeProps['onCheck'] = (checkedKeys) => {
    setTreeDataSelected(checkedKeys as string[]);
    save(
      treeDataFiltered ? treeDataFiltered : treeData,
      checkedKeys as string[]
    );
  };

  const handleResetColumnsToDefault = () => {
    setOpen(false);
    setLoadingResetSettings(true);
    const checked: string[] = Object.keys(defaultColumnsState).filter(
      (key) => defaultColumnsState[key]?.show !== false
    );
    const unchecked: string[] = Object.keys(defaultColumnsState).filter(
      (key) => defaultColumnsState[key]?.show === false
    );
    const updatedData = [
      ...asTreeData(checked, true),
      ...asTreeData(unchecked),
    ];
    save(updatedData, checked, 'reset');
  };

  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: DataNode[],
      key: React.Key,
      callback: (node: DataNode, i: number, data: DataNode[]) => void
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
      return undefined;
    };
    const data = [...treeData];

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }
    setTreeData(data);
    save(data, treeDataSelected);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked && e.target.name) {
      !isSelected && setIsSelected(e.target.name);
      setIsDeleted(e.target.name);
    } else {
      setIsSelected(null);
      setIsDeleted(null);
    }
  };

  const onSearch = (value: string) => {
    if (value.length > 0) {
      const filteredSettings: any = [...treeData];
      filteredSettings.map((data: any) => {
        if (!data.title.toLowerCase().includes(value.toLowerCase())) {
          data.style = { display: 'none' };
        }
      });
      setTreeDataFiltered(filteredSettings);
    } else {
      setTreeDataFiltered(undefined);
    }
  };

  // Pagination select
  const onPaginationChange = (value: number) => {
    setLimit(value);
    saveLimit.mutate({
      data: { limit: value },
      token,
      otherProps: `${queryNames.ColumnsState}settings_${
        Array.isArray(queryKey) ? [...queryKey] : queryKey
      }`,
    });
  };

  const content = (
    <div className={styles.content}>
      <Search
        placeholder="Search"
        allowClear
        size="middle"
        onSearch={onSearch}
        style={{ marginBottom: 20 }}
        defaultValue=""
        enterButton
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <div className={styles.treeWrapper}>
        <Tree
          onCheck={onCheck}
          onDrop={onDrop}
          treeData={
            treeDataFiltered &&
            Array.isArray(treeDataFiltered) &&
            treeDataFiltered.length
              ? treeDataFiltered
              : treeData
          }
          checkable
          checkedKeys={treeDataSelected}
          blockNode
          draggable
          selectable={false}
        />
      </div>
      <div className={styles.isDeletedFooter}>
        {checkList.map((item) => (
          <Checkbox
            disabled={isSelected ? isSelected !== item.value : false}
            name={item.value}
            key={item.value}
            onChange={onChange}
          >
            {item.label}
          </Checkbox>
        ))}
        <Select
          defaultValue={defaultLimit}
          style={{ width: 150 }}
          options={paginationOptions}
          onChange={onPaginationChange}
          size="middle"
          className={styles.selectLimit}
        />
      </div>
      <div className={styles.listFooter}>
        <div>
          Selected: {treeDataSelected.length} of{' '}
          {treeDataFiltered
            ? treeDataFiltered.filter((e) => !e?.style).length
            : treeData.filter((e) => !e?.style).length}
        </div>
        <Button
          onClick={handleResetColumnsToDefault}
          type="link"
          disabled={false}
        >
          Reset
        </Button>
      </div>
    </div>
  );

  return (
    <>
      {treeData.length ? (
        <Tooltip title="Manage columns">
          <Button
            type="link"
            className={styles.button}
            onClick={showDrawer}
            disabled={false}
          >
            <SettingOutlined />
          </Button>
        </Tooltip>
      ) : (
        <></>
      )}
      <Drawer
        rootStyle={{ top: 56 }}
        title="Settings"
        placement="right"
        onClose={onClose}
        open={open}
      >
        {content}
      </Drawer>
    </>
  );
};
