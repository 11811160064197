import { FC, useState } from 'react';
import Table from '../Table';
import { columns } from './columns';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import { DrawerHistory } from './components/DrawerHistory';
import { HistoryTableProps } from './types';

export const HistoryTable: FC<HistoryTableProps> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  tableName,
  hideInSetting,
  tableIndicatorClassName,
  actionsColumn,
  additionalSearchParams,
  ...rest
}) => {
  const [currentHistoryId, setCurrentHistoryId] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const renderTitle = (value: string, record: any) => {
    const title = record?.title;
    const objectType = record?.object;
    const objectId = record?.object_id;
    const isUser = objectType === 'user';
    const isOrder = objectType === 'order';
    const isFulfilment = objectType === 'fulfilment';
    const isAccount = objectType === 'account';
    if (!title) return value;
    if (isUser) return renderLink(title, `/users/all/${objectId}`);
    if (isAccount) return renderLink(title, `/accounts/all/${objectId}`);
    if (isOrder) return renderLink(title, `/orders/all/${objectId}`);
    if (isFulfilment) return renderLink(title, `/fulfilments/all/${objectId}`);

    return value;
  };

  const renderData = (value: string, record: any) => {
    const actorId = record?.actor_id;
    const ownerId = record?.owner_id;

    return [
      {
        name: 'actor.first_name',
        action: actorId ? renderLink(value, `/users/all/${actorId}`) : value,
      },
      {
        name: 'actor.last_name',
        action: actorId ? renderLink(value, `/users/all/${actorId}`) : value,
      },
      {
        name: 'owner.first_name',
        action: ownerId ? renderLink(value, `/users/all/${ownerId}`) : value,
      },
      {
        name: 'owner.last_name',
        action: ownerId ? renderLink(value, `/users/all/${ownerId}`) : value,
      },
      {
        name: 'title',
        action: renderTitle(value, record),
      },
    ];
  };

  const showHistoryInfo = (id: string) => {
    setCurrentHistoryId(id);
    setOpen(true);
  };

  const historyActionsColumn = {
    key: 'historyActionCol',
    title: 'Actions',
    width: 180,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => {
      return <a onClick={() => showHistoryInfo(record.id)}>Show info</a>;
    },
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        renderDataColumn={renderData}
        headerName={headerName}
        parametrForSavingSettings={tableName}
        defaultEnabledSettings={defaultEnabledSettings}
        hideInSetting={hideInSetting}
        columnsForRequest={columns}
        actionsColumn={actionsColumn || historyActionsColumn}
        additionalSearchParams={additionalSearchParams}
        defaultSorter={{
          field: 'hst.created_at',
          order: 'descend',
        }}
        {...rest}
      />

      {open && currentHistoryId && (
        <DrawerHistory
          id={currentHistoryId}
          setId={setCurrentHistoryId}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};
