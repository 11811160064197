export const ACTION_TYPES = Object.freeze({
  GET_COUNTRIES: '@@REFERENCE/GET_COUNTRIES',
  GET_COUNTRIES_PENDING: '@@REFERENCE/GET_COUNTRIES_PENDING',
  GET_COUNTRIES_FULFILLED: '@@REFERENCE/GET_COUNTRIES_FULFILLED',
  GET_COUNTRIES_REJECTED: '@@REFERENCE/GET_COUNTRIES_REJECTED',

  GET_COUNTRY_BY_ID: '@@REFERENCE/GET_COUNTRY_BY_ID',
  GET_COUNTRY_BY_ID_PENDING: '@@REFERENCE/GET_COUNTRY_BY_ID_PENDING',
  GET_COUNTRY_BY_ID_FULFILLED: '@@REFERENCE/GET_COUNTRY_BY_ID_FULFILLED',
  GET_COUNTRY_BY_ID_REJECTED: '@@REFERENCE/GET_COUNTRY_BY_ID_REJECTED',

  GET_COUNTRY_REGIONS: '@@REFERENCE/GET_COUNTRY_REGIONS',
  GET_COUNTRY_REGIONS_PENDING: '@@REFERENCE/GET_COUNTRY_REGIONS_PENDING',
  GET_COUNTRY_REGIONS_FULFILLED: '@@REFERENCE/GET_COUNTRY_REGIONS_FULFILLED',
  GET_COUNTRY_REGIONS_REJECTED: '@@REFERENCE/GET_COUNTRY_REGIONS_REJECTED',

  GET_COUNTRY_REGIONS_BY_ID: '@@REFERENCE/GET_COUNTRY_REGIONS_BY_ID',
  GET_COUNTRY_REGIONS_BY_ID_PENDING:
    '@@REFERENCE/GET_COUNTRY_REGIONS_BY_ID_PENDING',
  GET_COUNTRY_REGIONS_BY_ID_FULFILLED:
    '@@REFERENCE/GET_COUNTRY_REGIONS_BY_ID_FULFILLED',
  GET_COUNTRY_REGIONS_BY_ID_REJECTED:
    '@@REFERENCE/GET_COUNTRY_REGIONS_BY_ID_REJECTED',

  GET_GEONAMES: '@@REFERENCE/GET_GEONAMES',
  GET_GEONAMES_PENDING: '@@REFERENCE/GET_GEONAMES_PENDING',
  GET_GEONAMES_FULFILLED: '@@REFERENCE/GET_GEONAMES_FULFILLED',
  GET_GEONAMES_REJECTED: '@@REFERENCE/GET_GEONAMES_REJECTED',

  GET_GEONAMES_BY_ID: '@@REFERENCE/GET_GEONAMES_BY_ID',
  GET_GEONAMES_BY_ID_PENDING: '@@REFERENCE/GET_GEONAMES_BY_ID_PENDING',
  GET_GEONAMES_BY_ID_FULFILLED: '@@REFERENCE/GET_GEONAMES_BY_ID_FULFILLED',
  GET_GEONAMES_BY_ID_REJECTED: '@@REFERENCE/GET_GEONAMES_BY_ID_REJECTED',

  GET_TIMEZONES: '@@REFERENCE/GET_TIMEZONES',
  GET_TIMEZONES_PENDING: '@@REFERENCE/GET_TIMEZONES_PENDING',
  GET_TIMEZONES_FULFILLED: '@@REFERENCE/GET_TIMEZONES_FULFILLED',
  GET_TIMEZONES_REJECTED: '@@REFERENCE/GET_TIMEZONES_REJECTED',

  GET_TIMEZONES_BY_ID: '@@REFERENCE/GET_TIMEZONES_BY_ID',
  GET_TIMEZONES_BY_ID_PENDING: '@@REFERENCE/GET_TIMEZONES_BY_ID_PENDING',
  GET_TIMEZONES_BY_ID_FULFILLED: '@@REFERENCE/GET_TIMEZONES_BY_ID_FULFILLED',
  GET_TIMEZONES_BY_ID_REJECTED: '@@REFERENCE/GET_TIMEZONES_BY_ID_REJECTED',

  GET_CURRENCIES: '@@REFERENCE/GET_CURRENCIES',
  GET_CURRENCIES_PENDING: '@@REFERENCE/GET_CURRENCIES_PENDING',
  GET_CURRENCIES_FULFILLED: '@@REFERENCE/GET_CURRENCIES_FULFILLED',
  GET_CURRENCIES_REJECTED: '@@REFERENCE/GET_CURRENCIES_REJECTED',

  GET_CURRENCY_BY_ID: '@@REFERENCE/GET_CURRENCY_BY_ID',
  GET_CURRENCY_BY_ID_PENDING: '@@REFERENCE/GET_CURRENCY_BY_ID_PENDING',
  GET_CURRENCY_BY_ID_FULFILLED: '@@REFERENCE/GET_CURRENCY_BY_ID_FULFILLED',
  GET_CURRENCY_BY_ID_REJECTED: '@@REFERENCE/GET_CURRENCY_BY_ID_REJECTED',

  GET_ORDER_STATES: '@@REFERENCE/GET_ORDER_STATES',
  GET_ORDER_STATES_PENDING: '@@REFERENCE/GET_ORDER_STATES_PENDING',
  GET_ORDER_STATES_FULFILLED: '@@REFERENCE/GET_ORDER_STATES_FULFILLED',
  GET_ORDER_STATES_REJECTED: '@@REFERENCE/GET_ORDER_STATES_REJECTED',

  GET_ORDER_STATES_BY_ID: '@@REFERENCE/GET_ORDER_STATES_BY_ID',
  GET_ORDER_STATES_BY_ID_PENDING: '@@REFERENCE/GET_ORDER_STATES_BY_ID_PENDING',
  GET_ORDER_STATES_BY_ID_FULFILLED:
    '@@REFERENCE/GET_ORDER_STATES_BY_ID_FULFILLED',
  GET_ORDER_STATES_BY_ID_REJECTED:
    '@@REFERENCE/GET_ORDER_STATES_BY_ID_REJECTED',

  GET_ORDER_PAYMENT_STATES: '@@REFERENCE/GET_ORDER_PAYMENT_STATES',
  GET_ORDER_PAYMENT_STATES_PENDING:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_PENDING',
  GET_ORDER_PAYMENT_STATES_FULFILLED:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_FULFILLED',
  GET_ORDER_PAYMENT_STATES_REJECTED:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_REJECTED',

  GET_ORDER_PAYMENT_STATES_BY_ID: '@@REFERENCE/GET_ORDER_PAYMENT_STATES_BY_ID',
  GET_ORDER_PAYMENT_STATES_BY_ID_PENDING:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_BY_ID_PENDING',
  GET_ORDER_PAYMENT_STATES_BY_ID_FULFILLED:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_BY_ID_FULFILLED',
  GET_ORDER_PAYMENT_STATES_BY_ID_REJECTED:
    '@@REFERENCE/GET_ORDER_PAYMENT_STATES_BY_ID_REJECTED',

  GET_FULFILMENT_STATES: '@@REFERENCE/GET_FULFILMENT_STATES',
  GET_FULFILMENT_STATES_PENDING: '@@REFERENCE/GET_FULFILMENT_STATES_PENDING',
  GET_FULFILMENT_STATES_FULFILLED:
    '@@REFERENCE/GET_FULFILMENT_STATES_FULFILLED',
  GET_FULFILMENT_STATES_REJECTED: '@@REFERENCE/GET_FULFILMENT_STATES_REJECTED',

  GET_FULFILMENT_STATES_BY_ID: '@@REFERENCE/GET_FULFILMENT_STATES_BY_ID',
  GET_FULFILMENT_STATES_BY_ID_PENDING:
    '@@REFERENCE/GET_FULFILMENT_STATES_BY_ID_PENDING',
  GET_FULFILMENT_STATES_BY_ID_FULFILLED:
    '@@REFERENCE/GET_FULFILMENT_STATES_BY_ID_FULFILLED',
  GET_FULFILMENT_STATES_BY_ID_REJECTED:
    '@@REFERENCE/GET_FULFILMENT_STATES_BY_ID_REJECTED',

  GET_CONTENT_STATES: '@@REFERENCE/GET_CONTENT_STATES',
  GET_CONTENT_STATES_PENDING: '@@REFERENCE/GET_CONTENT_STATES_PENDING',
  GET_CONTENT_STATES_FULFILLED: '@@REFERENCE/GET_CONTENT_STATES_FULFILLED',
  GET_CONTENT_STATES_REJECTED: '@@REFERENCE/GET_CONTENT_STATES_REJECTED',

  GET_CONTENT_STATES_BY_ID: '@@REFERENCE/GET_CONTENT_STATES_BY_ID',
  GET_CONTENT_STATES_BY_ID_PENDING:
    '@@REFERENCE/GET_CONTENT_STATES_BY_ID_PENDING',
  GET_CONTENT_STATES_BY_ID_FULFILLED:
    '@@REFERENCE/GET_CONTENT_STATES_BY_ID_FULFILLED',
  GET_CONTENT_STATES_BY_ID_REJECTED:
    '@@REFERENCE/GET_CONTENT_STATES_BY_ID_REJECTED',

  GET_REQUEST_STATES: '@@REFERENCE/GET_REQUEST_STATES',
  GET_REQUEST_STATES_PENDING: '@@REFERENCE/GET_REQUEST_STATES_PENDING',
  GET_REQUEST_STATES_FULFILLED: '@@REFERENCE/GET_REQUEST_STATES_FULFILLED',
  GET_REQUEST_STATES_REJECTED: '@@REFERENCE/GET_REQUEST_STATES_REJECTED',

  GET_REQUEST_STATES_BY_ID: '@@REFERENCE/GET_REQUEST_STATES_BY_ID',
  GET_REQUEST_STATES_BY_ID_PENDING:
    '@@REFERENCE/GET_REQUEST_STATES_BY_ID_PENDING',
  GET_REQUEST_STATES_BY_ID_FULFILLED:
    '@@REFERENCE/GET_REQUEST_STATES_BY_ID_FULFILLED',
  GET_REQUEST_STATES_BY_ID_REJECTED:
    '@@REFERENCE/GET_REQUEST_STATES_BY_ID_REJECTED',
});
