import { CompletedNotPaidJobs } from 'components/pages/main/finances_section/CompletedNotPaidJobs/completedNotPaidJobs';
import GlobalMap from 'components/pages/main/GlobalMap';
import { Info } from 'components/pages/main/reference_section/Info/info';
import { User } from 'components/pages/main/users_section/User/user';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { tablesNames } from 'api/tablesNames';
import { APP_PATH } from '../pathes';
import {
  defaultEnabledSettingsAnyStates,
  defaultEnabledSettingsCountry,
  defaultEnabledSettingsCurrency,
  defaultEnabledSettingsGeonames,
  defaultEnabledSettingsRegion,
  defaultEnabledSettingsTimezones,
  defaultEnabledSettingsPurchaseMethods,
  defaultEnabledSettingsPaymentMethods,
} from 'components/pages/main/reference_section/ReferenceTable/defaultEnableSettings';
import { APP_CONSTANS } from 'shared/constants';
import { queryNames } from 'api/queryNames';
import PurchasePage from 'components/pages/main/finances_section/PurchasePage';
import InvoicePage from 'components/pages/main/finances_section/InvoicePage';

const Orders = lazy(
  () => import('components/pages/main/orders_section/Orders')
);
const FulfilmentPage = lazy(
  () => import('components/pages/main/orders_section/FulfilmentPage')
);

const CreateOrderPage = lazy(
  () => import('components/pages/main/orders_section/CreateOrderPage')
);
const OrderPage = lazy(
  () => import('components/pages/main/orders_section/OrderPage')
);
const AdminHomepage = lazy(() => import('components/pages/main/AdminHomepage'));
const Users = lazy(
  () => import('components/pages/main/users_section/Users/users')
);
const Accounts = lazy(
  () => import('components/pages/main/users_section/Accounts/index')
);
const Fulfilments = lazy(
  () => import('components/pages/main/orders_section/Fulfilments')
);
const References = lazy(
  () => import('components/pages/main/reference_section/References')
);
const AccountPage = lazy(
  () => import('components/pages/main/users_section/Account')
);
const PendingRegInvPage = lazy(
  () =>
    import(
      'components/pages/main/users_section/PendingRegistrationsInvitations'
    )
);

const Requests = lazy(
  () => import('components/pages/main/users_section/Requests')
);

const UsersAPIKeys = lazy(
  () => import('components/pages/main/users_section/ApiKeys')
);

const Contents = lazy(
  () => import('components/pages/main/orders_section/Contents')
);

const Templates = lazy(
  () => import('components/pages/main/orders_section/Templates')
);

const FulfilmentRequests = lazy(
  () => import('components/pages/main/orders_section/FulfilmentRequests')
);
const IndustriesTable = lazy(
  () => import('components/pages/main/SystemSettings/Industries')
);
const ComplicationsTable = lazy(
  () => import('components/pages/main/SystemSettings/Complications')
);
const CmplRelations = lazy(
  () => import('components/ui_components/ComplicationsTable/Relations')
);
const IndustryRelations = lazy(
  () => import('components/ui_components/IndustryTable/Relations')
);
const HistoryTable = lazy(
  () => import('components/pages/main/SystemSettings/History')
);
const SystemPreferences = lazy(
  () => import('components/pages/main/SystemSettings/Preferences')
);
const SystemPreferenceOptions = lazy(
  () => import('components/pages/main/SystemSettings/PreferenceOptions')
);
const Invoices = lazy(
  () => import('components/pages/main/finances_section/Invoices')
);
const Purchases = lazy(
  () => import('components/pages/main/finances_section/Purchases')
);
const PurchaseOperations = lazy(
  () => import('components/pages/main/finances_section/PurchaseOperations')
);
const PaymentOperations = lazy(
  () => import('components/pages/main/finances_section/PaymentOperations')
);
const ManageSystem = lazy(
  () => import('components/pages/main/SystemSettings/ManageSystem')
);
const Subscriptions = lazy(
  () => import('components/pages/main/finances_section/Subscriptions')
);
const ProviderTargets = lazy(
  () => import('components/pages/main/SystemSettings/ProviderTargets')
);

const {
  ROOT,
  FULFILMENTS,
  FULFILMENTS_ALL,
  FULFILMENTS_TAB,
  FULFILMENT,
  ACCOUNTS,
  ACCOUNTS_ALL,
  ACCOUNTS_TAB,
  ACCOUNT,
  SUBSCRIPTIONS,
  COMPLETED_JOBS,
  COMPLETED_JOBS_ALL,
  COMPLETED_JOBS_TAB,
  ORDER_CREATE,
  ORDER,
  ORDERS,
  ORDERS_ALL,
  ORDERS_TAB,
  ORDER_STATES,
  ORDER_STATE,
  USERS,
  USERS_ALL,
  USERS_TAB,
  USER,
  USERS_REQUESTS,
  USERS_REQUEST,
  USERS_API_KEYS,
  COUNTRIES,
  REGIONS,
  REGION,
  GEONAMES,
  GEONAME,
  TIMEZONES,
  TIMEZONE,
  COUNTRY,
  CURRENCIES,
  CURRENCY,
  GLOBAL_MAP,
  JOB_STATES,
  JOB_STATE,
  INVOICE_STATES,
  INVOICE_STATE,
  CONTENT_STATES,
  CONTENT_STATE,
  REQUEST_STATES,
  REQUEST_STATE,
  PAYMENT_STATES,
  PAYMENT_STATE,
  WILDCARD,
  CONTENTS,
  CONTENT,
  TEMPLATES,
  TEMPLATE,
  FULFILMENT_REQUESTS,
  FULFILMENT_REQUEST,
  PENDING_REGIST_INV,
  INDUSTRIES,
  INDUSTRIES_RELATIONS,
  COMPLICATIONS,
  COMPLICATIONS_RELATIONS,
  HISTORY,
  PREFERENCES,
  PREFERENCES_TAB,
  PREFERENCES_OPTIONS,
  MANAGE_SYSTEM,
  PURCHASE_STATES,
  PURCHASE_STATE,
  PURCHASE_PROVIDERS,
  PURCHASE_PROVIDER,
  PAYOUT_STATES,
  PAYOUT_STATE,
  PURCHASES,
  PURCHASES_TAB,
  PURCHASES_NOT_PAID,
  PURCHASE,
  PURCHASE_OPERATIONS,
  PURCHASE_METHODS,
  PURCHASE_METHOD,
  PROVIDER_TARGETS,
  PURCHASE_TARGETS,
  PURCHASE_TARGET,
  INVOICES,
  INVOICES_NOT_PAID,
  INVOICES_TAB,
  INVOICE,
  PAYMENT_METHODS,
  PAYMENT_METHOD,
  PAYMENT_PROVIDERS,
  PAYMENT_PROVIDER,
  PAYMENT_OPERATIONS,
  PAYMENT_OPERATIONS_PAYMENT,
  PAYMENT_OPERATIONS_TAB,
} = APP_PATH;

export interface Route {
  path: string;
  component: any;
  breadcrumb?: string;
  role?: any[];
  title?: string;
}

export const allRoutes: Route[] = [
  {
    path: ROOT,
    breadcrumb: 'Home',
    component: <AdminHomepage />,
  },

  {
    path: USERS,
    breadcrumb: 'Users',
    component: <Navigate to={`/${USERS_ALL}`} replace />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: USERS_TAB,
    component: <Users />,
    title: 'Users',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: USER,
    breadcrumb: 'User',
    component: <User />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },

  {
    path: ACCOUNTS,
    breadcrumb: 'Accounts',
    component: <Navigate to={`/${ACCOUNTS_ALL}`} replace />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: ACCOUNTS_TAB,
    component: <Accounts />,
    title: 'Accounts',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: ACCOUNT,
    breadcrumb: 'Account',
    component: <AccountPage />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: USERS_REQUESTS,
    breadcrumb: 'Requests',
    component: <Requests />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: USERS_REQUEST,
    breadcrumb: 'Requests',
    component: <Requests />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: PENDING_REGIST_INV,
    breadcrumb: 'Pending Registrations and Invitations',
    component: <PendingRegInvPage />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: USERS_API_KEYS,
    breadcrumb: 'API Keys',
    component: <UsersAPIKeys />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },

  {
    path: ORDERS,
    breadcrumb: 'Orders',
    component: <Navigate to={`/${ORDERS_ALL}`} replace />,
    title: 'Orders',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: ORDERS_TAB,
    component: <Orders />,
    title: 'Orders',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: ORDER,
    breadcrumb: 'Order',
    component: <OrderPage type="order" />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: ORDER_CREATE,
    breadcrumb: 'Creating order',
    component: <CreateOrderPage />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.SUPPORT],
  },

  {
    path: FULFILMENTS,
    breadcrumb: 'Fulfilments',
    component: <Navigate to={`/${FULFILMENTS_ALL}`} replace />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: FULFILMENTS_TAB,
    breadcrumb: 'Fulfilments',
    component: <Fulfilments />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: FULFILMENT,
    breadcrumb: 'Fulfilment',
    component: <FulfilmentPage />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: CONTENTS,
    breadcrumb: 'Contents',
    component: <Contents />,
    title: 'Contents',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: CONTENT,
    breadcrumb: 'Content',
    component: <Contents />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: TEMPLATES,
    breadcrumb: 'Templates',
    component: <Templates />,
    title: 'Templates',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },

  {
    path: TEMPLATE,
    breadcrumb: 'Template',
    component: <OrderPage type="tmpl" />,
    title: 'Template',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },

  {
    path: FULFILMENT_REQUESTS,
    breadcrumb: 'Fulfilment Requests',
    component: <FulfilmentRequests />,
    title: 'Fulfilment Requests',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  {
    path: FULFILMENT_REQUEST,
    breadcrumb: 'Fulfilment Requests',
    component: <FulfilmentRequests />,
    title: 'Fulfilment Requests',
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
      APP_CONSTANS.USER_TYPES.EDITOR,
      APP_CONSTANS.USER_TYPES.SUPPORT,
    ],
  },
  // /COMPLETED_JOBS
  {
    path: COMPLETED_JOBS,
    breadcrumb: 'Completed Jobs',
    component: <Navigate to={`/${COMPLETED_JOBS_ALL}`} replace />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: COMPLETED_JOBS_TAB,
    breadcrumb: 'Completed Jobs',
    component: <CompletedNotPaidJobs />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: PURCHASES,
    breadcrumb: 'Purchases',
    component: <Navigate to={`/${PURCHASES_NOT_PAID}`} replace />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.FINANCE],
  },
  {
    path: PURCHASES_TAB,
    title: 'Purchases',
    component: <Purchases />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.FINANCE],
  },
  {
    path: PURCHASE,
    breadcrumb: 'Purchase',
    component: <PurchasePage />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: INVOICES,
    breadcrumb: 'Invoices',
    component: <Navigate to={`/${INVOICES_NOT_PAID}`} replace />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.FINANCE],
  },
  {
    path: INVOICES_TAB,
    title: 'Invoices',
    component: <Invoices />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.FINANCE],
  },
  {
    path: INVOICE,
    breadcrumb: 'Invoice',
    component: <InvoicePage />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.FINANCE],
  },
  {
    path: PURCHASE_OPERATIONS,
    breadcrumb: 'Purchase Operations',
    component: <PurchaseOperations />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: PAYMENT_OPERATIONS,
    breadcrumb: 'Payment Operations',
    component: <Navigate to={`/${PAYMENT_OPERATIONS_PAYMENT}`} replace />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: PAYMENT_OPERATIONS_TAB,
    component: <PaymentOperations />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: SUBSCRIPTIONS,
    breadcrumb: 'Subscriptions',
    component: <Subscriptions />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      APP_CONSTANS.USER_TYPES.FINANCE,
      APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: GLOBAL_MAP,
    breadcrumb: 'Global Map',
    component: <GlobalMap />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER, APP_CONSTANS.USER_TYPES.SUPPORT],
  },

  //Reference
  {
    path: COUNTRIES,
    breadcrumb: 'Countries',
    component: (
      <References
        tableName={tablesNames.CountriesCol}
        title="Countries"
        defaultEnabledSettings={defaultEnabledSettingsCountry}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: COUNTRY,
    breadcrumb: 'Country',
    component: (
      <Info
        type={[queryNames.CountriesById]}
        title="Country Info"
        table={queryNames.REFERENCE_getCountries}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: REGIONS,
    breadcrumb: 'Regions',
    component: (
      <References
        tableName={tablesNames.CountryRegionsCol}
        title="Regions"
        defaultEnabledSettings={defaultEnabledSettingsRegion}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: REGION,
    breadcrumb: 'Region',
    component: (
      <Info
        type={[queryNames.CountryRegionsById]}
        title="Region Info"
        table={queryNames.REFERENCE_getCountryRegions}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  // { path: REGION_BY_COUNTRY_ID, breadcrumb: "Region By Country", component: <ReferenceTable countryId={true} tableName={tablesNames.CountryRegions} title='Regions' />},
  {
    path: GEONAMES,
    breadcrumb: 'Geonames',
    component: (
      <References
        tableName={tablesNames.GeonamesCol}
        title="Geonames"
        defaultEnabledSettings={defaultEnabledSettingsGeonames}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: GEONAME,
    breadcrumb: 'Geoname',
    component: (
      <Info
        type={[queryNames.GeonamesById]}
        title="Geoname Info"
        table={queryNames.GEONAMES}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  // { path: GEONAME_BY_COUNTRY_ID, breadcrumb: "Geoname By Country", component: <ReferenceTable countryId={true} tableName={tablesNames.Geonames} title='Geonames' />},
  {
    path: TIMEZONES,
    breadcrumb: 'Timezones',
    component: (
      <References
        tableName={tablesNames.CountryTimezonesCol}
        title="Timezones"
        defaultEnabledSettings={defaultEnabledSettingsTimezones}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: TIMEZONE,
    breadcrumb: 'Timezone',
    component: (
      <Info
        type={[queryNames.CountryTimezonesById]}
        title="Timezone Info"
        table={queryNames.CountryTimezones}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  // { path: TIMEZONE_BY_COUNTRY_ID, breadcrumb: "Timezone By Country", component: <ReferenceTable countryId={true} tableName={tablesNames.CountryTimezones} title='Timezones' />},
  {
    path: CURRENCIES,
    breadcrumb: 'Currencies',
    component: (
      <References
        tableName={tablesNames.CurrenciesCol}
        title="Currencies"
        defaultEnabledSettings={defaultEnabledSettingsCurrency}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: CURRENCY,
    breadcrumb: 'Currency',
    component: (
      <Info
        type={[queryNames.CurrenciesById]}
        title="Currency Info"
        table={queryNames.Currencies}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: ORDER_STATES,
    breadcrumb: 'Order States',
    component: (
      <References
        tableName={tablesNames.OrderStatesCol}
        title="Order States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: ORDER_STATE,
    breadcrumb: 'Order State',
    component: (
      <Info
        type={[queryNames.OrderStatesById]}
        title="Order State Info"
        table={queryNames.OrderStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: JOB_STATES,
    breadcrumb: 'Job States',
    component: (
      <References
        tableName={tablesNames.FulfilmentStatesCol}
        title="Job States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: JOB_STATE,
    breadcrumb: 'Job State',
    component: (
      <Info
        type={[queryNames.JobStateById]}
        title="Job State Info"
        table={queryNames.FulfilmentStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: INVOICE_STATES,
    breadcrumb: 'Invoice States',
    component: (
      <References
        tableName={tablesNames.InvoiceStatesCol}
        title="Invoice States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: INVOICE_STATE,
    breadcrumb: 'Invoice State',
    component: (
      <Info
        type={[queryNames.InvoiceStateById]}
        title="Invoice State Info"
        table={queryNames.InvoiceStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: CONTENT_STATES,
    breadcrumb: 'Content States',
    component: (
      <References
        tableName={tablesNames.ContentStatesCol}
        title="Content States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: CONTENT_STATE,
    breadcrumb: 'Content State',
    component: (
      <Info
        type={[queryNames.ContentStateById]}
        title="Content State Info"
        table={queryNames.ContentStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: REQUEST_STATES,
    breadcrumb: 'Request States',
    component: (
      <References
        tableName={tablesNames.RequestStatesCol}
        title="Request States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: REQUEST_STATE,
    breadcrumb: 'Request State',
    component: (
      <Info
        type={[queryNames.RequestStateById]}
        title="Request State Info"
        table={queryNames.RequestStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_STATES,
    breadcrumb: 'Payment States',
    component: (
      <References
        tableName={tablesNames.PaymentStatesCol}
        title="Payment States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_STATE,
    breadcrumb: 'Payment State',
    component: (
      <Info
        type={[queryNames.PaymentStateById]}
        title="Payment State Info"
        table={queryNames.PaymentStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_STATES,
    breadcrumb: 'Purchase States',
    component: (
      <References
        tableName={tablesNames.PurchaseStatesCol}
        title="Purchase States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_STATE,
    breadcrumb: 'Purchase State',
    component: (
      <Info
        type={[queryNames.PurchaseStateById]}
        title="Purchase State Info"
        table={queryNames.PurchaseStates}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_PROVIDERS,
    breadcrumb: 'Purchase Providers',
    component: (
      <References
        tableName={tablesNames.PurchaseProvidersCol}
        title="Purchase Providers"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_PROVIDER,
    breadcrumb: 'Purchase Provider',
    component: (
      <Info
        type={[queryNames.PurchaseProviderById]}
        title="Purchase Provider Info"
        table={queryNames.PurchaseProviders}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_METHODS,
    breadcrumb: 'Purchase Methods',
    component: (
      <References
        tableName={tablesNames.PurchaseMethodsCol}
        title="Purchase Methods"
        defaultEnabledSettings={defaultEnabledSettingsPurchaseMethods}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_METHOD,
    breadcrumb: 'Purchase Method',
    component: (
      <Info
        type={[queryNames.PurchaseMethodsById]}
        title="Purchase Method Info"
        table={queryNames.PurchaseMethods}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYOUT_STATES,
    breadcrumb: 'Payout States',
    component: (
      <References
        tableName={tablesNames.PayoutStatesCol}
        title="Payout States"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYOUT_STATE,
    breadcrumb: 'Payout State',
    component: (
      <Info
        type={[queryNames.PayoutStateById]}
        title="Payout State Info"
        table={queryNames.PayoutStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_METHODS,
    breadcrumb: 'Payment Methods',
    component: (
      <References
        tableName={tablesNames.PaymentMethodsCol}
        title="Payment Methods"
        defaultEnabledSettings={defaultEnabledSettingsPaymentMethods}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_METHOD,
    breadcrumb: 'Payment Method',
    component: (
      <Info
        type={[queryNames.PaymentMethodById]}
        title="Payment Method Info"
        table={queryNames.PaymentMethods}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_PROVIDERS,
    breadcrumb: 'Payment Providers',
    component: (
      <References
        tableName={tablesNames.PaymentProvidersCol}
        title="Payment Providers"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PAYMENT_PROVIDER,
    breadcrumb: 'Payment Provider',
    component: (
      <Info
        type={[queryNames.PaymentProviderById]}
        title="Payment Provider Info"
        table={queryNames.PaymentProvider}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PROVIDER_TARGETS,
    breadcrumb: 'Provider Targets',
    component: <ProviderTargets />,
    role: [
      APP_CONSTANS.USER_TYPES.SUPERUSER,
      // APP_CONSTANS.USER_TYPES.FINANCE,
      // APP_CONSTANS.USER_TYPES.SALES,
    ],
  },
  {
    path: PURCHASE_TARGETS,
    breadcrumb: 'Purchase Targets',
    component: (
      <References
        tableName={tablesNames.PurchaseTargetsCol}
        title="Purchase Targets"
        defaultEnabledSettings={defaultEnabledSettingsAnyStates}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PURCHASE_TARGET,
    breadcrumb: 'Purchase Target',
    component: (
      <Info
        type={[queryNames.PurchaseTargetById]}
        title="Purchase Target Info"
        table={queryNames.PurchaseTargets}
        meta={{ paymentsApi: true }}
      />
    ),
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: INDUSTRIES,
    breadcrumb: 'Industries',
    component: <IndustriesTable />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  //System
  {
    path: COMPLICATIONS,
    breadcrumb: 'Complications',
    component: <ComplicationsTable />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: COMPLICATIONS_RELATIONS,
    breadcrumb: 'Complication Relations',
    component: <CmplRelations />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: INDUSTRIES_RELATIONS,
    breadcrumb: 'Industry Relations',
    component: <IndustryRelations />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: HISTORY,
    breadcrumb: 'History',
    component: <HistoryTable />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PREFERENCES,
    breadcrumb: 'Preferences',
    component: <SystemPreferences />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PREFERENCES_TAB,
    breadcrumb: 'Preferences',
    component: <SystemPreferences />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: PREFERENCES_OPTIONS,
    breadcrumb: 'Preference Options',
    component: <SystemPreferenceOptions />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },
  {
    path: MANAGE_SYSTEM,
    breadcrumb: 'Manage system',
    component: <ManageSystem />,
    role: [APP_CONSTANS.USER_TYPES.SUPERUSER],
  },

  //Root
  { path: WILDCARD, breadcrumb: '', component: <Navigate to={ROOT} /> },
];
