import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import clsx from 'clsx';
import { useGetAuthUser } from 'hooks/api/user/useGetAuthUser';
import { useGetUserLocation } from 'hooks/general_hooks/useGetUserLocation';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Address } from 'types/global';
import {
  GlobalMapHeader,
  GlobalMapHeaderProps,
} from './components/GlobalMapHeader';
import { GlobalMapMapSection } from './components/GlobalMapMapSection';
import { GlobalMapOrdersTable } from './components/GlobalMapOrdersTable';
import { GlobalMapPhotographersTable } from './components/GlobalMapPhotographersTable';
import styles from './styles.module.scss';
import {
  GlobalMapRadioButtonGroupOptionValue,
  defaultSelectedJobFilters,
} from './utils/constants';
import { dummyMapData } from 'components/unsorted/Map';
import { Loader } from 'components/ui_components/Loader';
import { debounce } from 'lodash';

const { ORDERS: ordersTableValue, PHOTOGRAPHERS: photographersTableValue } =
  GlobalMapRadioButtonGroupOptionValue;

export const GlobalMap = () => {
  const { data: user } = useGetAuthUser();
  const { id: userId } = user?.data || {};
  const { location, isLoading: isLocationLoading } = useGetUserLocation(
    userId,
    'location'
  );

  const [mapCollapsed, setMapCollapsed] = useState(true);
  const [radius, setRadius] = useState<number | undefined>();
  const [defaultRadius, setDefaultRadius] = useState<number | undefined>();
  const [center, setCenter] = useState<Address | undefined>(
    dummyMapData.center
  );
  const [selectedTables, setSelectedTables] = useState<
    GlobalMapRadioButtonGroupOptionValue[]
  >([ordersTableValue, photographersTableValue]);

  const [photographerFilters, setPhotographerFilters] = useState(undefined);
  const [photographerIsDeleted, setPhotographerIsDeleted] = useState<
    string | null
  >(null);
  const [jobFilters, setJobFilters] = useState(
    defaultSelectedJobFilters.filters
  );
  const [jobIsDeleted, setJobIsDeleted] = useState<string | null>(null);

  // TODO: dataRequestEnabled is an utility to disable loading markers on initial page open
  const [dataRequestEnabled, setDataRequestEnabled] = useState(false);
  const [newTab, setNewTab] = useState<boolean>(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = debounce(() => setWidth(window.innerWidth), 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const showPhotographers = selectedTables.includes(photographersTableValue);
  const showPhotographerMarkers = showPhotographers && dataRequestEnabled;
  const showOrders = selectedTables.includes(ordersTableValue);
  const showOrderMarkers = showOrders && dataRequestEnabled;

  const enableDataRequest = useCallback(() => {
    if (!dataRequestEnabled) {
      setDataRequestEnabled(true);
    }
  }, [dataRequestEnabled]);

  useEffect(() => {
    if (location && !isLocationLoading) {
      setCenter(location);
      enableDataRequest();
    }
  }, [location, isLocationLoading, enableDataRequest]);

  const mapSectionClassName = mapCollapsed
    ? styles.mapSection
    : clsx(styles.mapSection, styles.mapSectionUnfold);

  const tableSectionClassName = mapCollapsed
    ? styles.tableSection
    : clsx(styles.tableSection, styles.tableSectionCollapsed);

  const tableSectionContent = useMemo(
    () => (
      <>
        <GlobalMapPhotographersTable
          show={showPhotographers && mapCollapsed}
          setFilters={setPhotographerFilters}
          setIsDeleted={setPhotographerIsDeleted}
          radius={radius}
          center={center}
          dataRequestEnabled={dataRequestEnabled && showPhotographers}
          resetAllFiltering={newTab}
          selectedTables={selectedTables}
        />
        <GlobalMapOrdersTable
          show={showOrders && mapCollapsed}
          setFilters={setJobFilters}
          setIsDeleted={setJobIsDeleted}
          radius={radius}
          center={center}
          dataRequestEnabled={dataRequestEnabled && showOrders}
          resetAllFiltering={newTab}
          selectedTables={selectedTables}
        />
      </>
    ),
    [
      center,
      dataRequestEnabled,
      mapCollapsed,
      radius,
      showOrders,
      showPhotographers,
      newTab,
      selectedTables,
    ]
  );

  const collapseButtonIcon = mapCollapsed ? (
    <ArrowLeftOutlined />
  ) : (
    <ArrowRightOutlined />
  );

  const handleMapCollapse = () => {
    setMapCollapsed((prevValue) => !prevValue);
  };

  const filters: GlobalMapHeaderProps['defaultFilters'] = {
    radius: radius || defaultRadius,
    location_lat: center?.lat || dummyMapData.center.lat,
    location_lng: center?.lng || dummyMapData.center.lng,
  };

  if (isLocationLoading) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper} data-e2e="mapPage">
      <div className={styles.container}>
        <div className={tableSectionClassName}>
          {mapCollapsed && (
            <GlobalMapHeader
              defaultFilters={filters}
              photographerFilters={photographerFilters}
              jobFilters={jobFilters}
              handleSetSelectedTables={setSelectedTables}
              selectedTables={selectedTables}
              setNewTab={setNewTab}
              width={width}
            />
          )}
          <div className={styles.tableSectionContent}>
            {tableSectionContent}
          </div>
        </div>
        <div className={mapSectionClassName}>
          <Button
            className={styles.collapseMapButton}
            onClick={handleMapCollapse}
            icon={collapseButtonIcon}
          />

          <GlobalMapMapSection
            location={location}
            photographerFilters={photographerFilters}
            photographerIsDeleted={photographerIsDeleted}
            jobFilters={jobFilters}
            jobIsDeleted={jobIsDeleted}
            radius={radius}
            setRadius={setRadius}
            setDefaultRadius={setDefaultRadius}
            center={center}
            setCenter={setCenter}
            showPhotographers={showPhotographerMarkers}
            showOrders={showOrderMarkers}
            dataRequestEnabled={dataRequestEnabled}
            enableDataRequest={enableDataRequest}
            width={width}
          />
        </div>
      </div>
    </div>
  );
};

export default GlobalMap;
