export const extLinkSvg = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5415 15.6431H6.20837V6.30999H10.8749V4.97662H6.20837C5.46843 4.97662 4.875 5.57662 4.875 6.30999V15.6434C4.875 16.3768 5.46831 16.9768 6.20837 16.9768H15.5418C16.2752 16.9768 16.8752 16.3768 16.8752 15.6434V10.9768H15.5418V15.6434L15.5415 15.6431ZM12.2084 4.97656V6.30993H14.6017L8.04826 12.8634L8.9882 13.8033L15.5416 7.24988V9.64319H16.875V4.97662L12.2084 4.97656Z"
      fill="#0091B5"
    />
  </svg>
);
