import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { csvIcon } from './icons/csvIcon';
import { xlsIcon } from './icons/xlsIcon';

export const XlsSvg = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={xlsIcon} {...props} />
);

export const CsvSvg = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={csvIcon} {...props} />
);
