export const hideInSetting = [
  'id',
  'user_id',
  'currency_id',
  'purchase_id',
  'purchase_method_id',
  'purchase_provider_id',
  'payout_status_id',
  'details',
  'provider_tx_id',
  'provider_tx_status',
  'error_message',
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'account.id',
  'account.code',
  'account.status',
  'currency.id',
  'currency.code',
  'currency.name',
  'currency.entity',
  'currency.is_supported',
  'payout_status.id',
  'payout_status.code',
  'payout_status.name',
  'payout_status.description',
  'purchase.code',
  'purchase.id',
  'purchase.account_id',
  'purchase.currency_id',
  'purchase.state_id',
  'purchase.memo',
  'purchase.amount',
  'purchase.payed_sum',
  'purchase.start_date',
  'purchase.end_date',
  'purchase.paid_at',
  'purchase.created_at',
  'purchase.updated_at',
  'purchase_method.id',
  'purchase_method.status',
  'purchase_provider.id',
  'purchase_provider.description',
];
