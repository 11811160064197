import React, { FunctionComponent, useCallback } from 'react';
import { ModalWindow } from '../../ui_components/ModalWindow';
import { Button, Form, Input, message, Space } from 'antd';
import styles from './styles.module.scss';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from '../../../api/usePostData';

type ModalNameT = {
  showedModalName: boolean;
  setShowedModalName: any;
  defaultData?: any;
  userId: string;
  refetchUser: () => void;
  token: string;
  setEnabledSaveChange: any;
};

export const ModalChangeName: FunctionComponent<ModalNameT> = ({
  showedModalName,
  setShowedModalName,
  defaultData,
  userId,
  refetchUser,
  token,
  setEnabledSaveChange,
}) => {
  const [changedNameForm] = Form.useForm();

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: (error, variables, context) => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data, variables, context) => {
      if (data.hasOwnProperty('error')) {
        message.error(`Server Error ${data.error?.message}`);
        return
      }
      message.success('All data saved');
      refetchUser();
      setEnabledSaveChange(false);
    },
  });

  const closeModal = useCallback(() => {
    setShowedModalName(false);
  }, [setShowedModalName]);

  const changeNaming = useCallback(
    (allFields: any) => {
      allFields.id = userId;
      mutation.mutate({
        data: allFields,
        token,
        otherProps: 'users',
        method: 'PUT',
      });
      setShowedModalName(false);
    },
    [setShowedModalName]
  );

  return (
    <ModalWindow
      title="Change name"
      visible={showedModalName}
      width="538px"
      okButtonProps={{
        style: {
          background: '#03A3BF',
          borderRadius: 2,
          border: 'none',
        },
      }}
      onCancel={closeModal}
      closable={true}
      footer={null}
    >
      <Form
        className={styles.form}
        layout="vertical"
        form={changedNameForm}
        onFinish={changeNaming}
        initialValues={defaultData}
      >
        <Form.Item
          label="First name"
          name="first_name"
          rules={[
            {
              required: true,
              message: 'Please input first name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Middle name" name="middle_name">
          <Input />
        </Form.Item>

        <Form.Item
          label="Last name"
          name="last_name"
          rules={[
            {
              required: true,
              message: 'Please input last name',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ margin: 0 }}>
          <Space className={styles.footer}>
            <Button htmlType="button" onClick={() => setShowedModalName(false)}>
              Cancel
            </Button>

            <Button type="primary" htmlType="submit">
              Save changes
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </ModalWindow>
  );
};
