import { FunctionComponent } from 'react';
import { TabOrdersT } from './types';
import { tablesNames } from '../../../../../../../../../api/tablesNames';
import { OrdersTable } from 'components/ui_components/OrdersTable';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import { queryNames } from 'api/queryNames';

export const TabOrders: FunctionComponent<TabOrdersT> = ({ id }) => {
  const meta = { additionalSearchParams: `?user_ids=${id}` };

  return (
    <OrdersTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.ORDERS}
      meta={meta}
      headerName={tablesNames.OrdersHeader}
      toolbar={null}
      actionsColumn={null}
      tableName={tablesNames.UserOrdersCol}
      hideInSetting={hideInSetting}
      resetAllFiltering={true}
    />
  );
};
