import { PhoneOutlined } from '@ant-design/icons';
import { InfoWindowF, InfoWindowProps } from '@react-google-maps/api';
import { Typography } from 'antd';
import { FC } from 'react';
import { Address } from 'types/global';
import styles from './styles.module.scss';

export type MapPhotographerInfoWindowProps = Omit<
  InfoWindowProps,
  'position'
> & {
  position: Address | null;
  phone?: string;
  fullName?: string;
  onHover?: () => void;
  onBlur?: () => void;
};

export const MapPhotographerInfoWindow: FC<MapPhotographerInfoWindowProps> = ({
  position,
  phone,
  fullName,
  onHover,
  onBlur,
  ...rest
}) => {
  if (!position || (!phone && !fullName)) {
    return null;
  }

  return (
    <div
      onMouseOver={onHover}
      onMouseLeave={onBlur}
      className={styles.container}
    >
      <InfoWindowF position={position} {...rest}>
        <div style={{ padding: '12px' }}>
          {fullName && (
            <Typography className={styles.nameText}>{fullName}</Typography>
          )}
          {phone && (
            <span className={styles.phoneContainer}>
              <PhoneOutlined className={styles.phoneIcon} />
              <Typography className={styles.phoneText}>{phone}</Typography>
            </span>
          )}
        </div>
      </InfoWindowF>
    </div>
  );
};
