import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Button, ConfigProvider, Drawer, FloatButton } from 'antd';
import { CommentOutlined, RightOutlined } from '@ant-design/icons';
import { Comments } from '../Comments';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { calcNewMsg } from 'shared/utils/calculateNewComments';
import { FloatCommentsT } from './types';
import useWindowSize from 'hooks/general_hooks/useWindowSize';
import { DrawerStyles } from 'antd/es/drawer/DrawerPanel';
import { APP_CONSTANS } from 'shared/constants';

export const FloatComments: React.FC<FloatCommentsT> = ({
  photographerId = null,
  customerId,
  hasInternalTab,
  jobId = null,
  orderId,
  inDrawer,
  objectType,
  objectId,
  isInternal = false,
  minToOpen = 1200,
  refetchInterval = 20000,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [commentsOpen, setCommentsOpen] = useState<boolean>(false);
  const [commentsBlockOpen, setCommentsBlockOpen] = useState<boolean>(true);
  const [newMsgQuantity, setNewMsgQuantity] = useState<number>(0);
  const [isFloated, setIsFloated] = useState<boolean>(false);
  const commentsDivRef = useRef<HTMLDivElement>(null);
  const { width, height } = useWindowSize();
  const getAllData = true;

  // Comments for Customer -> only orders request
  const { data: orderComments } = useQuery({
    queryKey: [queryNames.COMMENTS_FOR_ORDERS, customerId],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `/${orderId}?relative=true`,
    },
    queryFn: useGetData,
    enabled: [!!customerId, !!orderId].every(Boolean),
    refetchInterval: refetchInterval,
  });

  // Comments for Photographer, Inner by props = 'Object Type', 'Object id'
  const { data: dataComments } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/${objectType}/${objectId}`,
      `comments_${objectType}`,
      objectId,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=${isInternal}&relative=true`,
    },
    queryFn: useGetData,
    enabled:
      !!objectId &&
      objectType !== APP_CONSTANS.ORDER &&
      objectType !== APP_CONSTANS.PURCHASE &&
      objectType !== APP_CONSTANS.INVOICE,
    refetchInterval: refetchInterval,
  });

  // Comments for Internal by 'Object Type' props
  const { data: dataInternalComments } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/${objectType}/${objectId}`,
      objectId,
      hasInternalTab,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true&relative=true`,
    },
    queryFn: useGetData,
    enabled: !!objectId && !!hasInternalTab && !!objectType,
    refetchInterval: refetchInterval,
  });

  const { data: dataInternalOrderComments } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/order/${orderId}`,
      orderId,
      hasInternalTab,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true&relative=true`,
    },
    queryFn: useGetData,
    enabled:
      !!orderId && !!hasInternalTab && objectType === APP_CONSTANS.FULFILMENT,
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    if (!width || !commentsBlockOpen) return;
    setIsFloated(width < minToOpen);

    if (!commentsDivRef?.current) return;
    const elementTop = commentsDivRef.current.getBoundingClientRect().top;
    const top = elementTop < 120 ? 120 : elementTop;
    commentsDivRef.current.style.top = `${top}px`;
    commentsDivRef.current.style.height = `${height - top - 16}px`;
  }, [width, height, commentsDivRef, commentsBlockOpen, minToOpen]);

  useEffect(() => {
    if (setNewMsgQuantity) {
      const generalComments = [];
      if (
        dataInternalComments?.data &&
        dataInternalComments?.data?.length > 0
      ) {
        generalComments.push(...dataInternalComments.data);
      }
      if (
        objectType === APP_CONSTANS.FULFILMENT &&
        dataInternalOrderComments?.data &&
        dataInternalOrderComments?.data?.length > 0
      ) {
        generalComments.push(...dataInternalOrderComments.data);
      }
      if (dataComments?.data && dataComments?.data?.length > 0) {
        generalComments.push(...dataComments.data);
      }
      if (orderComments?.data && orderComments?.data?.length > 0) {
        generalComments.push(...orderComments.data);
      }
      const newMsgs = calcNewMsg({ data: generalComments });
      setNewMsgQuantity(newMsgs);
    }
  }, [
    dataInternalComments,
    dataInternalOrderComments,
    dataComments,
    orderComments,
    setNewMsgQuantity,
    objectType,
  ]);

  const drawerStyles: DrawerStyles = {
    body: {
      padding: '12px',
    },
    header: {
      display: 'none',
    },
  };

  return isFloated ? (
    <>
      <FloatButton
        onClick={() => setCommentsOpen(true)}
        icon={<CommentOutlined />}
        badge={{ count: newMsgQuantity }}
      />

      <ConfigProvider
        drawer={{
          styles: drawerStyles,
        }}
      >
        <Drawer
          placement="right"
          open={commentsOpen}
          onClose={() => setCommentsOpen(false)}
          width={window.innerWidth > 500 ? 464 : '95vw'}
        >
          <Button
            shape="circle"
            icon={<RightOutlined />}
            size="small"
            className={styles.closeIcon}
            onClick={() => setCommentsOpen(false)}
          />
          <Comments
            photographerId={photographerId}
            customerId={customerId}
            hasInternalTab={hasInternalTab}
            jobId={jobId}
            orderId={orderId}
            inDrawer={inDrawer}
            objectType={objectType}
            objectId={objectId}
            setNewMsgQuantity={setNewMsgQuantity}
          />
        </Drawer>
      </ConfigProvider>
    </>
  ) : commentsBlockOpen ? (
    <div className={styles.commentsBlockWrapper} ref={commentsDivRef}>
      <div className={styles.verticalDivider}></div>
      <div
        onClick={() => setCommentsBlockOpen(!commentsBlockOpen)}
        className={styles.commentsHoverElem}
      >
        {'>'}
      </div>
      <Comments
        photographerId={photographerId}
        customerId={customerId}
        hasInternalTab={hasInternalTab}
        jobId={jobId}
        orderId={orderId}
        inDrawer={true}
        objectType={objectType}
        objectId={objectId}
        setNewMsgQuantity={setNewMsgQuantity}
      />
    </div>
  ) : (
    <FloatButton
      onClick={() => setCommentsBlockOpen(!commentsBlockOpen)}
      icon={<CommentOutlined />}
      badge={{ count: newMsgQuantity }}
    />
  );
};
