export const columns = [
  'id',
  'updated_at',
  'created_at',
  'Currency.code',
  'Account.id',
  'User.id',
  'State.id',
  'State.name',
  'Invoice.id',
];
