import { ACTION_TYPES } from './types';

export const getAccounts = (data) => {
  return {
    type: ACTION_TYPES.GET_ACCOUNTS,
    payload: data,
  };
};

export const getAccountById = (data) => {
  return {
    type: ACTION_TYPES.GET_ACCOUNT_BY_ID,
    payload: data,
  };
};

export const getListOfAccounts = (data) => {
  return {
    type: ACTION_TYPES.GET_LIST_OF_ACCOUNTS,
    payload: data,
  };
};

export const getListOfAccountPrefs = (data) => {
  return {
    type: ACTION_TYPES.GET_LIST_OF_ACCOUNT_PREF,
    payload: data,
  };
};

export const resetListOfAccounts = () => {
  return {
    type: ACTION_TYPES.REMOVE_LIST_OF_ACCOUNTS,
    payload: null,
  };
};
