import { ACTION_TYPES } from './types';

export const getToken = (credentials = {}) => {
  return {
    type: ACTION_TYPES.GET_TOKEN,
    payload: { ...credentials },
  };
};

export const set_is_authorizated = (status) => {
  return {
    type: ACTION_TYPES.IS_AUTHORIZATED,
    payload: {
      status,
    },
  };
};

export const setToken = (token) => {
  return {
    type: ACTION_TYPES.SET_TOKEN,
    payload: token,
  };
};

export const setManualLogout = (manualLogout) => {
  return {
    type: ACTION_TYPES.SET_TOKEN,
    payload: manualLogout,
  };
};

export const setAuthToken = (data) => {
  return {
    type: ACTION_TYPES.SET_AUTH_DATA,
    payload: data,
  };
};

export const signOut = () => {
  return {
    type: ACTION_TYPES.SIGN_OUT,
  };
};

export const setVitual = (data) => {
  return {
    type: ACTION_TYPES.IS_VITUAL,
    payload: data,
  };
};

export const setLastStepBreadcrumb = (lastStep) => {
  return {
    type: ACTION_TYPES.SET_LAST_BREADCRUBMS,
    payload: lastStep,
  };
};

export const setAuthUserRole = (role) => {
  return {
    type: ACTION_TYPES.SET_AUTH_USER_ROLE,
    payload: role,
  };
};
