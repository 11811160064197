import { FunctionComponent } from 'react';
import { TabRequestsT } from './types';
import { queryNames } from '../../../../../../../../../api/queryNames';
import { tablesNames } from 'api/tablesNames';
import { RequestsTable } from 'components/ui_components/RequestsTable';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';

export const TabRequests: FunctionComponent<TabRequestsT> = ({
  id,
  statuses,
}) => {
  const meta = { additionalSearchParams: `?user_ids=${id}`, getAllData: true };

  return (
    <RequestsTable
      defaultEnabledSettings={defaultEnabledSettings}
      queryKey={queryNames.USERS_getUserRequests}
      meta={meta}
      headerName={tablesNames.UserRequestsHeader}
      toolbar={null}
      actionsColumn={null}
      tableName={tablesNames.UserRequestsCol}
      hideInSetting={hideInSetting}
      objectType="user_request"
      resetAllFiltering={true}
    />
  );
};
