import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Select, message } from 'antd';
import { usePostData } from 'api/usePostData';
import clsx from 'clsx';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import globalStyles from '../../../shared/styles/styles.module.scss';
import { ModalPurchasePayoutT } from './types';
import { queryNames } from 'api/queryNames';
import { copyTextToTheClipboard } from 'shared/utils/copyTextToTheClipboard';
import { CopyOutlined } from '@ant-design/icons';
import useGetData from 'api/useGetData';
import PurchaseItemsInModal from './components/PurchaseItemsInModal';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { useNavigate } from 'react-router-dom';

export const ModalPurchasePayout: FunctionComponent<ModalPurchasePayoutT> = ({
  showModal,
  setShowModal,
  id,
  code,
  currencyCode,
  purchaseMethodId,
  dataDefaultPayoutProvider,
  accountId,
  previousPath,
}) => {
  const [purchaseItemsPayoutForm] = Form.useForm();
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.auth.token);
  const [totalSum, setTotalSum] = useState<number>(0);
  const [disabledPayout, setDisabledPayout] = useState(true);
  const queryClient: any = useQueryClient();

  const { data: dataPurchaseItemsAmount } = useQuery({
    queryKey: [queryNames.PurchaseItems, id],
    meta: {
      token,
      getAllData: true,
      columnParams: ['amount', 'payed_sum', 'Fulfilment.Order.code'],
      additionalSearchParams: `?purchase_ids=${id}`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  const { data: dataPurchaseMethods } = useQuery({
    queryKey: [queryNames.PurchaseMethods, id],
    meta: {
      token,
      getAllData: true,
      columnParams: [
        'id',
        'name',
        'client_id',
        'extra_id',
        'PurchaseTarget.name',
      ],
      paymentsApi: true,
      additionalSearchParams: `?account_ids=${accountId}`,
    },
    queryFn: useGetData,
    enabled: !!id && !!accountId,
  });

  const { data: dataPurchaseProviders } = useQuery({
    queryKey: [queryNames.PurchaseProviders],
    meta: {
      token,
      getAllData: true,
      columnParams: ['id', 'name', 'is_active'],
      paymentsApi: true,
      // additionalSearchParams: `?account_ids=${accountId}`,
    },
    queryFn: useGetData,
    enabled: !!id && !!accountId,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      message.success('Purchase payout success');
      queryClient.invalidateQueries([queryNames.PurchasesById, id]);
      closeModal();
      if (previousPath) {
        navigate(previousPath);
      }
    },
  });

  useEffect(() => {
    if (
      dataPurchaseItemsAmount &&
      dataPurchaseItemsAmount?.data &&
      Array.isArray(dataPurchaseItemsAmount?.data) &&
      dataPurchaseItemsAmount.data.length
    ) {
      const amounts = dataPurchaseItemsAmount.data.map(
        (e: { amount?: number }) => e.amount
      );
      const amountsSum = amounts.length
        ? amounts.reduce((a: any, b: any) => a + b, 0)
        : 0;
      const payed = dataPurchaseItemsAmount.data.map(
        (e: { payed_sum?: number }) => e.payed_sum
      );
      const payedSum = payed.length
        ? payed.reduce((a: any, b: any) => a + b, 0)
        : 0;
      const sum = amountsSum - payedSum;
      setTotalSum(sum);
    }
  }, [dataPurchaseItemsAmount]);

  useEffect(() => {
    if (purchaseItemsPayoutForm) {
      purchaseItemsPayoutForm.validateFields();
    }
  }, [purchaseMethodId, dataDefaultPayoutProvider, purchaseItemsPayoutForm]);

  const handleFormChange = () => {
    const hasErrors = purchaseItemsPayoutForm
      .getFieldsError()
      .some(({ errors }) => errors.length);
    setDisabledPayout(hasErrors);
  };

  const closeModal = useCallback(() => {
    setShowModal(false);
    purchaseItemsPayoutForm.resetFields();
  }, [purchaseItemsPayoutForm, setShowModal]);

  const payJobs = (allFields: any) => {
    allFields.purchase_id = id;

    if (!allFields?.purchase_id) {
      message.error('Purchase ID - undefined');
      return;
    }
    if (!allFields?.purchase_method_id) {
      message.error('Purchase method - undefined');
      return;
    }
    if (!allFields?.purchase_provider_id) {
      message.error('Purchase provider - undefined');
      return;
    }

    mutate({
      data: allFields,
      token,
      otherProps: queryNames.PurchasePayout,
      method: 'POST',
    });
  };

  const optionsGenerator = (data: {
    count?: number;
    data?: any;
  }): object[] | undefined => {
    if (!data || !data?.count) return;

    const labelWithCopy = (
      name: string,
      clientId?: string,
      extraId?: string,
      putchaseTargetName?: string
    ) => {
      if (!putchaseTargetName && !clientId && !extraId)
        return <span className={styles.optionName}>{name}</span>;
      return (
        <span>
          <span className={styles.optionName}>{putchaseTargetName}:</span>
          {clientId && (
            <span>
              {clientId}
              <CopyOutlined
                className={styles.copyIcon}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void copyTextToTheClipboard(e, clientId);
                }}
              />
            </span>
          )}
          {extraId && (
            <span>
              {extraId}
              <CopyOutlined
                className={styles.copyIcon}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void copyTextToTheClipboard(e, extraId);
                }}
              />
            </span>
          )}
        </span>
      );
    };

    // eslint-disable-next-line consistent-return
    return data?.count
      ? data?.data.map(
          (e: {
            id: string;
            name: string;
            client_id?: string;
            extra_id?: string;
            purchase_target?: { name?: string };
          }) => {
            return {
              value: e.id,
              label: labelWithCopy(
                e?.name,
                e?.client_id,
                e?.extra_id,
                e?.purchase_target?.name
              ),
            };
          }
        )
      : [{ value: purchaseMethodId, label: 'No methods in Account' }];
  };

  const onAmountChange = useCallback(() => {
    const val = purchaseItemsPayoutForm.getFieldsValue();
    const amounts = Object.values(val.amounts) as number[];
    const sum = amounts.length
      ? amounts.reduce((a: any, b: any) => a + b, 0)
      : 0;
    setTotalSum(sum);
  }, [purchaseItemsPayoutForm]);

  return (
    <ModalWindow
      title={`Purchase ${code}`}
      visible={showModal}
      width={window.innerWidth > 1300 ? '1200px' : '95%'}
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      <div className={styles.block}>
        <div className={styles.blockInnerWrapper}>
          <p>
            Total to Payout -{' '}
            <>
              <Button
                type="link"
                style={{ padding: 0, height: 'auto' }}
                onClick={(e) => {
                  void copyTextToTheClipboard(e, (totalSum / 1000).toFixed(2));
                }}
              >
                {renderMoneyWithCurrency(totalSum, currencyCode)}
                <CopyOutlined />
              </Button>
            </>
          </p>

          <Button
            type="link"
            style={{ padding: 0, height: 'auto' }}
            onClick={(e) => {
              const orderCodes = dataPurchaseItemsAmount?.data.map(
                (p: any) => p?.fulfilment?.order?.code
              );
              const ordersCodesStringWithCarriageReturn = orderCodes.length
                ? orderCodes.join('\r')
                : '';
              void copyTextToTheClipboard(
                e,
                ordersCodesStringWithCarriageReturn
              );
            }}
          >
            Copy order codes
            <CopyOutlined />
          </Button>
        </div>
      </div>

      <Form
        form={purchaseItemsPayoutForm}
        onFinish={payJobs}
        onFieldsChange={handleFormChange}
      >
        <Form.Item
          name={'purchase_method_id'}
          label="Purchase method"
          initialValue={purchaseMethodId}
          required
          rules={[
            {
              message: 'Validation pattern fail',
              pattern:
                /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
            },
          ]}
        >
          <Select options={optionsGenerator(dataPurchaseMethods)} />
        </Form.Item>

        <Form.Item
          name={'purchase_provider_id'}
          label="Purchase provider"
          initialValue={
            dataDefaultPayoutProvider || 'a1b3aa90-1ee6-4062-a9e8-c544e896b525'
          }
          required
          validateFirst
          rules={[
            {
              message: 'Validation pattern fail',
              pattern:
                /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
            },
          ]}
        >
          <Select options={optionsGenerator(dataPurchaseProviders)} />
        </Form.Item>

        <PurchaseItemsInModal
          id={id}
          currencyCode={currencyCode}
          onAmountChange={onAmountChange}
        />

        <div className={styles.actions}>
          <Button
            className={clsx(globalStyles.btn, globalStyles.secondary__btn)}
            style={{ marginRight: '8px' }}
            onClick={closeModal}
          >
            Cancel
          </Button>

          <Button
            htmlType="button"
            type="primary"
            onClick={() => purchaseItemsPayoutForm.submit()}
            disabled={disabledPayout}
          >
            Start payout
          </Button>
        </div>
      </Form>
    </ModalWindow>
  );
};
