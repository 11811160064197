export const defaultEnabledSettings = {
  code: { order: 1 },
  'order.title': { order: 2 },
  'order.code': { order: 3 },
  'user.first_name': { order: 4 },
  'user.last_name': { order: 5 },
  'user.email': { order: 6 },
  'account.purchase_method.name': { order: 7 },
  completed_at: { order: 8 },
  'order.payout': { order: 9 },
};
