import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Form, Input, InputNumber } from 'antd';
import { APP_CONSTANS } from 'shared/constants';
import { MapPositioned } from 'components/unsorted/Map/components/MapPositioned';
import { Loader } from '../Loader';
import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { pointsCol } from './columns';
import { PointsT } from './types';
import clsx from 'clsx';
import { NewOrderContext } from 'components/pages/main/orders_section/CreateOrderPage/context';

export const OrderJobLocation: React.FC<{
  isFormDisabled?: boolean;
  isJob?: boolean;
  id?: string;
  extraStyles?: CSSStyleSheet | string;
}> = ({ isFormDisabled = true, isJob = true, id = null, extraStyles }) => {
  const form = Form.useFormInstance();
  const lat = Form.useWatch('location_lat', form);
  const lng = Form.useWatch('location_long', form);
  const isNewOrder = useContext(NewOrderContext);
  const address = useRef(null);
  const [isStaticMap, setIsStaticMap] = useState<boolean>(true);
  const [srcPoints, setSrcPoints] = useState<string | null>(null);
  const [hasCheckInData, setHasCheckInData] = useState<boolean>(false);
  const [hasCheckOutData, setHasCheckOutData] = useState<boolean>(false);
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const {
    isLoading: isLoadingPoints,
    isError: isErrorPoints,
    data: dataPoints,
  } = useQuery({
    queryKey: [queryNames.CheckPoints, id],
    meta: {
      token,
      getAllData,
      columnParams: pointsCol,
      additionalSearchParams: `?fulfilment_ids=${id}`,
    },
    queryFn: useGetData,
    enabled: !!isJob && !!id,
  });

  const openOnGoogleMaps = () => {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
      '_blank'
    );
  };

  const handleLocationFocus = () => {
    setIsStaticMap(false);
  };

  useEffect(() => {
    if (isNewOrder) {
      form.setFieldsValue({
        location_lat: 0,
        location_long: 0,
      });
      setIsStaticMap(false);
    }
  }, [form, isNewOrder]);

  useEffect(() => {
    if (!isLoadingPoints && !isErrorPoints && dataPoints && isJob && id) {
      let markerPoints = '';
      dataPoints?.data?.forEach((point: PointsT, index: number) => {
        if (
          point.hasOwnProperty('check_in_lat') &&
          point.hasOwnProperty('check_in_long')
        ) {
          markerPoints += `&markers=color:green%7Clabel:${index + 1}%7C${
            point.check_in_lat
          },${point.check_in_long}`;
        }
        if (
          point.hasOwnProperty('check_out_lat') &&
          point.hasOwnProperty('check_out_long')
        ) {
          markerPoints += `&markers=color:blue%7Clabel:${index + 1}%7C${
            point.check_out_lat
          },${point.check_out_long}`;
        }
      });
      const maxDistance = dataPoints?.data
        ?.map((point: PointsT) => {
          if (point.check_in_dist && point.check_out_dist) {
            return point.check_in_dist >= point.check_out_dist
              ? point.check_in_dist
              : point.check_out_dist;
          }
          if (point.check_in_dist) {
            return point.check_in_dist;
          }
          if (point.check_out_dist) {
            return point.check_out_dist;
          }
          return 0;
        })
        .sort((a: number, b: number) => b - a)[0];
      if (maxDistance <= 0.3) {
        markerPoints += `&zoom=16`;
      } else if (maxDistance > 0.3 && maxDistance <= 2) {
        markerPoints += `&zoom=14`;
      }
      const checkInData = dataPoints?.data?.filter((points: any) => {
        return (
          points.hasOwnProperty('check_in_lat') &&
          points.hasOwnProperty('check_in_long')
        );
      });
      const checkOutData = dataPoints?.data?.filter((points: any) => {
        return (
          points.hasOwnProperty('check_out_lat') &&
          points.hasOwnProperty('check_out_long')
        );
      });
      setHasCheckInData(checkInData?.length > 0);
      setHasCheckOutData(checkOutData?.length > 0);
      setSrcPoints(markerPoints);
    }
  }, [isLoadingPoints, isErrorPoints, dataPoints, isJob, id]);

  return (
    <section className={clsx(styles.map, extraStyles && extraStyles)}>
      {!isNewOrder ? <h3 className={styles.map__title}>Location</h3> : <></>}

      <div className={styles.map__options}>
        <Form.Item
          label="Latitude"
          name="location_lat"
          hasFeedback={isNewOrder}
          rules={
            isNewOrder
              ? [
                  {
                    required: true,
                    message: 'Provide Latitude',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (isNaN(value)) {
                        return Promise.reject('Value has to be a number');
                      }
                      if (value === 0) {
                        return Promise.reject('Zero is not valid');
                      }
                      if (value < -85 || value > 85) {
                        return Promise.reject('Not in range');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]
              : []
          }
        >
          <InputNumber
            className={styles.latLngInputs}
            min={-85}
            max={85}
            disabled={isFormDisabled}
            onFocus={handleLocationFocus}
          />
        </Form.Item>

        <Form.Item
          label="Longitude"
          name="location_long"
          hasFeedback={isNewOrder}
          rules={
            isNewOrder
              ? [
                  {
                    required: true,
                    message: 'Provide Longitude',
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      if (isNaN(value)) {
                        return Promise.reject('Value has to be a number');
                      }
                      if (value === 0) {
                        return Promise.reject('Zero is not valid');
                      }
                      if (value < -180 || value > 180) {
                        return Promise.reject('Not in range');
                      }
                      return Promise.resolve();
                    },
                  }),
                ]
              : []
          }
        >
          <InputNumber
            className={styles.latLngInputs}
            min={-180}
            max={180}
            disabled={isFormDisabled}
            onFocus={handleLocationFocus}
          />
        </Form.Item>

        <Form.Item label="Address" name="address">
          <Input
            ref={address}
            disabled={true}
            addonAfter={
              isNewOrder ? undefined : !isFormDisabled ? (
                <Button
                  disabled={false}
                  size="small"
                  type="link"
                  onClick={handleLocationFocus}
                >
                  Change address
                </Button>
              ) : undefined
            }
          />
        </Form.Item>
      </div>
      {/* <input
          ref={(c) => {
            if (!c) {
              return;
            }
            const searchBox = new google.maps.places.SearchBox(c);
            searchBox.addListener('places_changed', () => {
              const places = searchBox.getPlaces();
              if (places?.length) {
                console.log('pl', places);

              }
            })
          }}
          placeholder="Search a place..."
          type="text"
        /> */}

      {(lat && lng) || isNewOrder ? (
        <div
          className={
            isStaticMap || isJob || isFormDisabled
              ? styles.map__blockStatic
              : styles.map__blockDynamic
          }
        >
          <Button
            type="link"
            className={styles.map__link}
            onClick={openOnGoogleMaps}
            disabled={false}
          >
            Open in Google Maps
          </Button>

          <div className={styles.mapBlock}>
            {isStaticMap || isJob || isFormDisabled ? (
              <picture className={styles.map__img}>
                <source
                  srcSet={`https://maps.googleapis.com/maps/api/staticmap?center${lat},${lng}&markers=${lat},${lng}&size=640x640&key=${
                    APP_CONSTANS.GOOGLE_MAP_API_KEY
                  }${srcPoints ? srcPoints : '&zoom=18'}`}
                  media="(min-width: 1400px)"
                />
                <img
                  src={`https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&markers=${lat},${lng}&size=333x333&key=${
                    APP_CONSTANS.GOOGLE_MAP_API_KEY
                  }${srcPoints ? srcPoints : '&zoom=18'}`}
                  alt="map"
                />
              </picture>
            ) : (
              <MapPositioned
                coordinates={[{ lat: Number(lat), lng: Number(lng) }]}
                autocomlete={true}
              />
            )}

            {isJob && (isStaticMap || isFormDisabled) && (
              <div>
                <p className={clsx(styles.markerIcon, styles.mainMarkerBg)}>
                  Order position
                </p>

                {hasCheckInData && (
                  <p className={clsx(styles.markerIcon, styles.checkInBg)}>
                    Check In
                  </p>
                )}

                {hasCheckOutData && (
                  <p className={clsx(styles.markerIcon, styles.checkOutBg)}>
                    Check Out
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </section>
  );
};
