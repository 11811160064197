import React from 'react';
import { FrownOutlined, MehOutlined, SmileOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { Form, Radio } from 'antd';
import { GeneralItemsT } from './types';
import clsx from 'clsx';
import { APP_CONSTANS } from 'shared/constants';

export const EvaluationBlock: React.FC<any> = ({
  isJob,
  stateId,
  deletedAt,
}) => {
  const generalItems: GeneralItemsT[] = [
    { rate: 1, label: <FrownOutlined /> },
    { rate: 2, label: <MehOutlined /> },
    { rate: 3, label: <SmileOutlined /> },
  ];

  return !isJob && stateId !== APP_CONSTANS.ORDER_STATUSES_GUID_BY_CODE.COMPLETED_ORDER ? (<></>) : (
    <Form.Item name={['metadata', 'evaluation']} className={styles.formItem}>
      <Radio.Group className={clsx(styles.rateBlock, 'evaluationRadio')} size="small">
        {generalItems.map((item: GeneralItemsT) => (
          <Radio.Button
            key={item.rate}
            value={item.rate}
            className={isJob && !deletedAt ? styles.rate : styles.rateNoSelect}
            disabled={(isJob && deletedAt) || !isJob}
          >
            {item.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
};
