import Table from '../Table';
import { columns } from './columns';
import { Tag } from 'antd';
import styles from './styles.module.scss';

export const PurchaseMethodsTable: React.FC<any> = ({
  defaultEnabledSettings,
  queryKey,
  meta,
  headerName,
  toolbar,
  toolBarRender,
  actionsColumn,
  tableName,
  hideInSetting,
  tableIndicatorClassName = 'purchaseMethodTable',
  // currencyCode,
  disableFilteringAndSearching = false,
  defaultPurchaseId,
  ...rest
}) => {
  const renderPurchaseName = (value: string, record: any) => {
    const isDefault = record?.id === defaultPurchaseId;
    return (
      <p>
        {isDefault && (
          <Tag className={styles.tag} color="blue" key="Main">
            Default
          </Tag>
        )}
        <span>{value}</span>
      </p>
    );
  };

  const renderData = (value: any, record: any) => {
    return [
      {
        name: 'name',
        action: renderPurchaseName(value, record),
      },
    ];
  };

  return (
    <>
      <Table
        tableIndicatorClassName={tableIndicatorClassName}
        searchingByAllTable={false}
        rowSelection={null}
        rowSelectionType={null}
        queryKey={queryKey}
        meta={meta}
        toolbar={toolbar}
        toolBarRender={toolBarRender}
        renderDataColumn={renderData}
        headerName={headerName}
        actionsColumn={actionsColumn}
        defaultEnabledSettings={defaultEnabledSettings}
        parametrForSavingSettings={tableName}
        hideInSetting={hideInSetting}
        quantityTitleText="items"
        columnsForRequest={columns}
        disableFilteringAndSearching={disableFilteringAndSearching}
        defaultSorter={{
          field: 'created_at',
          order: 'descend',
        }}
        {...rest}
      />
    </>
  );
};
