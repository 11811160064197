import { ACTION_TYPES } from './types';

const INITIAL_STATE = {
  completed_but_not_paid_jobs_data: [],
  is_loading_completed_but_not_paid_jobs: false,
};

// eslint-disable-next-line
export default (state = INITIAL_STATE, { type, payload }) => {
  // console.log({ type, payload })
  switch (type) {
    case ACTION_TYPES.IS_LOADING:
      return {
        ...state,
        is_loading_completed_but_not_paid_jobs: payload.status,
      };

    // case ACTION_TYPES.GET_COMPLETED_BUT_NOT_PAID_JOBS:
    //     return { ...state, is_loading_completed_but_not_paid_jobs: false };
    //     break;

    case ACTION_TYPES.GET_COMPLETED_BUT_NOT_PAID_JOBS_PENDING:
      return { ...state, is_loading_completed_but_not_paid_jobs: true };
    case ACTION_TYPES.GET_COMPLETED_BUT_NOT_PAID_JOBS_FULFILLED:
      return {
        ...state,
        completed_but_not_paid_jobs_data: payload,
        is_loading_completed_but_not_paid_jobs: false,
      };
    case ACTION_TYPES.GET_COMPLETED_BUT_NOT_PAID_JOBS_REJECTED:
      return {
        ...state,
        completed_but_not_paid_jobs_data: payload,
        is_loading_completed_but_not_paid_jobs: false,
      };
    // case ACTION_TYPES.GET_INVOICES_REJECTED:
    //     return { ...state, invoices: payload, isLoadingInvoices: false };

    default:
      return state;
  }
};
