import { combineReducers, compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import httpGlobalFetch from './http/middleware';
import auth, { authMiddlewares } from './auth';
import invoices, { invoiceMiddlewares } from './invoices';
import completed_but_not_paid_jobs, {
  completed_jobs_but_not_paid_middlewares,
} from './completed_but_not_paid_jobs';
import users, { usersMiddlewares } from './users';
import accounts, { accountsMiddlewares } from './accounts';
import reference, { referencesMiddlewares } from './reference';
import jobs, { jobsMiddlewares } from './jobs';

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore() {
  const rootReducer = combineReducers({
    auth,
    invoices,
    users,
    completed_but_not_paid_jobs,
    accounts,
    reference,
    jobs,
  });
  return createStore(
    rootReducer,
    undefined,
    composeEnhancers(
      applyMiddleware(
        ...authMiddlewares,
        ...invoiceMiddlewares,
        ...completed_jobs_but_not_paid_middlewares,
        ...usersMiddlewares,
        ...accountsMiddlewares,
        ...referencesMiddlewares,
        ...jobsMiddlewares,
        httpGlobalFetch,
        thunk,
        promise
      )
    )
  );
}

export * from './auth';
