import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, message } from 'antd';
import { tablesNames } from 'api/tablesNames';
import { usePostData } from 'api/usePostData';
import clsx from 'clsx';
import { JobsTable } from 'components/ui_components/JobsTable';
import { ModalWindow } from 'components/ui_components/ModalWindow';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { allSettings, paypalSettings, venmoSettings } from './defaultEnableSettings';
import styles from './styles.module.scss';
import globalStyles from '../../../shared/styles/styles.module.scss';
import { ModalPayJobsT } from './types';
import { APP_CONSTANS } from 'shared/constants';
import { queryNames } from 'api/queryNames';
import pluralize from 'pluralize';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { copyTextToTheClipboard } from 'shared/utils/copyTextToTheClipboard';
import { CopyOutlined } from '@ant-design/icons';
import useGetData from 'api/useGetData';
import { columns } from './columns';
import { CompletedJobsType } from 'components/pages/main/finances_section/CompletedNotPaidJobs/types';

export const ModalPayJobs: FunctionComponent<ModalPayJobsT> = ({
  showModal,
  setShowModal,
  jobIds,
  setSelectedJobsId,
  setIsCheckAllContent,
  setIsCheckCurrentContent,
  currentTab,
}) => {
  const [updatedJobsForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const [currencyPayoutArr, setCurrencyPayoutArr] = useState<any>([]);
  const [totalSumArr, setTotalSumArr] = useState<any>([]);
  const [totalSumNumArr, setTotalSumNumArr] = useState<any>([]);
  const [changedPayoutFields, setChangedPayoutFields] = useState<any>({});
  const [jobCodes, setJobCodes] = useState<string[]>([]);
  const [defaultSettings, setDefaultSettings] = useState<any>(null);
  const queryClient: any = useQueryClient();

  const {
    isLoading: isLoadingJobs,
    isError: isErrorJobs,
    data: dataJobs,
  } = useQuery({
    queryKey: [queryNames.FINANCE_JOBS_TO_PAID, jobIds, 'formPaidJobs'],
    meta: { 
      token,
      getAllData: true,
      columnParams: columns,
      additionalSearchParams: `?ids=${jobIds.join('&ids=')}`,
    },
    queryFn: useGetData,
    enabled: !!jobIds && jobIds.length > 0,
  });

  useEffect(() => {
    return () => {
      setChangedPayoutFields([]);
      setTotalSumArr([]);
      setTotalSumNumArr([]);
    };
  }, []);

  useEffect(() => {
    if (currentTab === CompletedJobsType.ALL) {
      setDefaultSettings(allSettings);
    } else if (currentTab === CompletedJobsType.PAYPAL) {
      setDefaultSettings(paypalSettings);
    } else if (currentTab === CompletedJobsType.VENMO) {
      setDefaultSettings(venmoSettings);
    }
  }, [currentTab])

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: (error, variables, context) => {
      message.error('Network Error');
      setSelectedJobsId([]);
    },
    onSuccess: (data, variables, context) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return
      }
      const dataLength = jobCodes.length;
      message.success(`
        ${pluralize('Job', dataLength)}
        ${' '}
        ${jobCodes.join(', ')}
        ${' '}
        ${pluralize('is', dataLength)}
        successfully paid!
      `);
      queryClient.invalidateQueries(
        `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.CompletedButNotPaidJobs}`
      );
      setSelectedJobsId([]);
      setIsCheckAllContent(false);
      setIsCheckCurrentContent(false);
      closeModal();
    },
  });

  const closeModal = useCallback(() => {
    setShowModal(false);
    updatedJobsForm.resetFields();
  }, [setShowModal]);

  const payJobs = (allFields: any) => {
    const dataToBack = [];
    for (let key in allFields) {
      dataToBack.push({
        fulfilment_id: key.split('**')[1],
        payout: allFields[key],
      });
    }
    mutation.mutate({
      data: dataToBack,
      token,
      otherProps: 'engine/fulfilment_payout_bulk',
      method: 'POST',
    });
  };

  const onlyUniqueElem = (value: string, index: number, array: any) => {
    return array.indexOf(value) === index;
  };

  const copyInfo = (e: React.MouseEvent<HTMLElement>, record: any) => {
    if (currentTab === CompletedJobsType.PAYPAL) {
      void copyTextToTheClipboard(
        e,
        record['account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd']
      );
    } else if (currentTab === CompletedJobsType.VENMO) {
      void copyTextToTheClipboard(
        e,
        record['account.preferences.a8612805-f592-449c-a6e5-96f7b780911c']
      );
    }
  };

  useEffect(() => {
    if (!isLoadingJobs && !isErrorJobs && dataJobs && dataJobs.hasOwnProperty('data')) {
      const jobs = dataJobs.data;
      const codes = jobs.map((job: any) => job.code);
      setJobCodes(codes);
      const orderCurrencies = jobs.map(
        (job: any) => job.order.currency.code
      );
      const uniqueCurrencies = orderCurrencies.filter(onlyUniqueElem);
      let totalSumResult: string[] = [];
      let currencyAndPayoutArr: any[] = [];
      let totalSumNumbers: any[] = [];
      uniqueCurrencies.forEach((currency: string) => {
        const jobsByCurrency = jobs.filter(
          (job: any) => job.order.currency.code === currency
        );
        const orderPayouts = jobsByCurrency.map(
          (job: any) => job?.order.payout
        );
        const sumOfPayout = orderPayouts.reduce(
          (acc: any, payout: number) => acc + payout
        );
        const totalSumOfPayout = renderMoneyWithCurrency(sumOfPayout, currency);
        const currencyPayoutObj = {
          currency: currency,
          payout: orderPayouts,
        };
        totalSumResult.push(totalSumOfPayout);
        totalSumNumbers.push((Number(sumOfPayout) / 1000).toFixed(2));
        currencyAndPayoutArr.push(currencyPayoutObj);
      });
      setTotalSumArr(totalSumResult);
      setTotalSumNumArr(totalSumNumbers);
      setCurrencyPayoutArr(currencyAndPayoutArr);
    }
  }, [isLoadingJobs, isErrorJobs, dataJobs]);

  useEffect(() => {
    if (Object.keys(changedPayoutFields).length > 0) {
      let totalSumResult: any = [];
      let totalSumNumbers: any[] = [];
      currencyPayoutArr.forEach((currencyObj: any) => {
        const jobsByCur = Object.keys(changedPayoutFields).filter((el: any) =>
          el.includes(currencyObj.currency)
        );
        const orderPayouts = jobsByCur.map(
          (key: any) => changedPayoutFields[key]
        );
        const sumOfPayout = orderPayouts.reduce(
          (acc: any, payout: number) => acc + payout
        );
        const totalSumOfPayout = renderMoneyWithCurrency(
          sumOfPayout,
          currencyObj.currency
        );
        totalSumResult.push(totalSumOfPayout);
        totalSumNumbers.push((Number(sumOfPayout) / 1000).toFixed(2));
      });
      setTotalSumArr(totalSumResult);
      setTotalSumNumArr(totalSumNumbers);
    }
  }, [changedPayoutFields, currencyPayoutArr]);

  const actionsColumn = {
    key: 'copyInfo',
    title: 'Actions',
    width: 150,
    fixed: 'right',
    hideInSetting: true,
    render: (record: any) => {
      return (
        <Button type="link" onClick={(e) => copyInfo(e, record)}>
          Copy info
        </Button>
      );
    },
  };

  return (
    <ModalWindow
      title="Payout"
      visible={showModal}
      width="95%"
      onCancel={closeModal}
      closable={false}
      footer={null}
    >
      <div className={styles.block}>
        <p className={styles.title}>
          Selected{' '}
          <span className={styles.textBold}>
            {jobIds.length}
            {pluralize(' job', jobIds.length)}
          </span>
        </p>

        <div className={styles.blockInnerWrapper}>
          <p>
            Total Sum -{' '}
            {totalSumArr.map((sum: any, index: number) => (
              <>
                <b className={styles.textBold}>{sum}</b>
                <CopyOutlined
                  className={styles.copyIcon}
                  onClick={(e) => {
                    void copyTextToTheClipboard(e,totalSumNumArr[index]);
                  }}
                />
              </>
            ))}
          </p>

          <Button
            type="link"
            style={{ padding: 0, height: 'auto' }}
            onClick={(e) => {
              const orderCodes = dataJobs?.data.map((job: any) => job.order.code);
              const ordersCodesStringWithCarriageReturn = orderCodes.join('\r');
              void copyTextToTheClipboard(
                e,
                ordersCodesStringWithCarriageReturn
              );
            }}
          >
            Copy order codes
            <CopyOutlined />
          </Button>
        </div>
      </div>

      <Form
        form={updatedJobsForm}
        onFinish={payJobs}
        onValuesChange={(changedValues: any, allValues: any) => {
          setChangedPayoutFields(allValues);
        }}
      >
        <JobsTable
          tableIndicatorClassName="jobsToPaidTable"
          customClassName={styles.completedJobsTable}
          rowSelection={null}
          rowSelectionType={null}
          toolbar={null}
          defaultEnabledSettings={defaultSettings}
          queryKey={[queryNames.FINANCE_JOBS_TO_PAID, 'formPaidJobs', jobIds]}
          meta={null}
          headerName={tablesNames.JobsToPaidJobsHeader}
          actionsColumn={
            currentTab === CompletedJobsType.ALL ? null : actionsColumn
          }
          disableFilteringAndSearching={true}
          tableName=""
          hideInSetting={[]}
          settingsOptions={false}
          additionalSearchParams={`?ids=${jobIds.join('&ids=')}`}
          resetAllFiltering={true}
        />

        <div className={styles.actions}>
          <Form.Item>
            <Button
              className={clsx(globalStyles.btn, globalStyles.secondary__btn)}
              style={{ marginRight: '8px' }}
              onClick={closeModal}
            >
              Cancel
            </Button>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              Set payout
            </Button>
          </Form.Item>
        </div>
      </Form>
    </ModalWindow>
  );
};
