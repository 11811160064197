import { APP_CONSTANS } from 'shared/constants';
import { Buffer } from 'buffer';

export const sendSubscriptionToServer = (subscription, token) => {
  console.log('Received PushSubscription: ', JSON.stringify(subscription));
  //TODO ->>>>>>
  const auth_token = 'bearer ' + token;
  const path = APP_CONSTANS.BASE_NOTIFY_URL + '/notify/push_subscription';

  const xhr = new XMLHttpRequest();
  xhr.open('POST', path);
  xhr.setRequestHeader('Authorization', auth_token);
  xhr.send(JSON.stringify(subscription));
};

export const sendUnSubscriptionToServer = (subscription, token) => {
  console.log('Remove PushSubscription: ', JSON.stringify(subscription));
  //TODO ->>>>>>
  const auth_token = 'bearer ' + token;
  const path = APP_CONSTANS.BASE_NOTIFY_URL + '/notify/push_subscription';

  const xhr = new XMLHttpRequest();
  xhr.open('DELETE', path);
  xhr.setRequestHeader('Authorization', auth_token);
  xhr.send(JSON.stringify(subscription));
};

export const urlBase64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    // eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/');
  return Buffer.from(base64, 'base64');
};
