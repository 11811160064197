import { Radio, RadioChangeEvent } from 'antd';
import { FC, useMemo } from 'react';
import { GlobalMapRadioButtonGroupOptionValue } from '../../utils/constants';
import styles from './styles.module.scss';

const { ORDERS: ordersValue, PHOTOGRAPHERS: photographersValue } =
  GlobalMapRadioButtonGroupOptionValue;

const { Button: RadioButton, Group: RadioGroup } = Radio;

const photographersOption = {
  value: photographersValue,
  label: 'Photographers',
};
const ordersOption = { value: ordersValue, label: 'Orders' };
const allOption = { value: 'all', label: 'All' };

const radioGroupOptions: {
  value: GlobalMapRadioButtonGroupOptionValue;
  label: string;
}[] = [photographersOption, ordersOption];

interface GlobalMapFiltersSectionProps {
  selectedOptions: GlobalMapRadioButtonGroupOptionValue[];
  setSelectedOptions: (options: GlobalMapRadioButtonGroupOptionValue[]) => void;
  setNewTab?: any;
}

export const GlobalMapFiltersSection: FC<GlobalMapFiltersSectionProps> = ({
  selectedOptions,
  setSelectedOptions,
  setNewTab,
}) => {
  const radioGroupSelectedValue = useMemo(() => {
    if (
      selectedOptions.includes(photographersOption.value) &&
      selectedOptions.includes(ordersOption.value)
    ) {
      return allOption.value;
    }
    return selectedOptions[0];
  }, [selectedOptions]);

  const handleRadioGroupChange = (event: RadioChangeEvent) => {
    const { value } = event.target;
    if (value === allOption.value) {
      setSelectedOptions([photographersOption.value, ordersOption.value]);
    } else {
      setSelectedOptions([value]);
    }
    setNewTab(true);
  };

  return (
    <RadioGroup
      value={radioGroupSelectedValue}
      onChange={handleRadioGroupChange}
      size="small"
    >
      <RadioButton className={styles.radioButton} value={allOption.value}>
        {allOption.label}
      </RadioButton>
      {radioGroupOptions.map(({ value, label }) => (
        <RadioButton className={styles.radioButton} key={value} value={value}>
          {label}
        </RadioButton>
      ))}
    </RadioGroup>
  );
};
