export const ACTION_TYPES = Object.freeze({
  SET_TOKEN: '@@AUTH/SET_TOKEN',
  SET_LAST_BREADCRUBMS: '@@AUTH/SET_LAST_BREADCRUBMS',
  SET_AUTH_DATA: '@@AUTH/SET_AUTH_DATA',
  SET_MANUAL_LOGOUT: '@@AUTH/SET_MANUAL_LOGOUT',
  GET_TOKEN: '@@AUTH/GET_TOKEN',
  GET_TOKEN_PENDING: '@@AUTH/GET_TOKEN_PENDING',
  GET_TOKEN_FULFILLED: '@@AUTH/GET_TOKEN_FULFILLED',
  GET_TOKEN_REJECTED: '@@AUTH/GET_TOKEN_REJECTED',
  SIGN_OUT: '@@AUTH/SIGN_OUT',
  SIGN_OUT_PENDING: '@@AUTH/SIGN_OUT_PENDING',
  SIGN_OUT_FULFILLED: '@@AUTH/SIGN_OUT_FULFILLED',
  SIGN_OUT_REJECTED: '@@AUTH/SIGN_OUT_REJECTED',
  IS_AUTHORIZATED: '@@AUTH/IS_AUTHORIZATED',
  IS_VITUAL: '@@AUTH/IS_VITUAL',
  SET_AUTH_USER_ROLE: '@@AUTH/SET_AUTH_USER_ROLE',
});
