import { tablesNames } from 'api/tablesNames';
import { FC, memo } from 'react';
import { defaultEnabledSettings } from './defaultEnableSettings';
import { hideInSetting } from './hideInSettings';
import styles from './styles.module.scss';
import { queryNames } from 'api/queryNames';
import Table from 'components/ui_components/Table';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import { columns } from './columns';
import { Form, InputNumber } from 'antd';
import { renderLink } from 'shared/utils/DataInTables/renderLinks';
import { getCurrensySymbol } from 'shared/utils/getCurrensySymbol';

export interface PurchaseItemsT {
  id: string;
  currencyCode?: string;
  onAmountChange?: any;
}

const PurchaseItemsInModal: FC<PurchaseItemsT> = ({
  id,
  currencyCode,
  onAmountChange,
}) => {
  const meta = { additionalSearchParams: `?purchase_ids=${id}` };

  const renderInputPayoutJob = (
    value: string | number,
    inputId: string,
    currency: any
  ) => {
    return (
      <Form.Item
        name={['amounts', inputId]}
        className={styles.payoutItem}
        initialValue={value}
      >
        <InputNumber
          value={value}
          style={{ width: '100%' }}
          min={0}
          prefix={getCurrensySymbol(currency)}
          formatter={(valueFormatter: any) =>
            (
              Math.round((valueFormatter / 1000 + Number.EPSILON) * 100) / 100
            ).toString()
          }
          parser={(valueParser: any) => Math.round(Number(valueParser) * 1000)}
          precision={2}
          step={1000}
          onChange={onAmountChange}
        />
      </Form.Item>
    );
  };

  const renderData = (value: any, record: any) => {
    const currency = record?.['currency.code'] || currencyCode;
    const jobId = record?.['fulfilment.id'];

    return [
      {
        name: 'code',
        action: renderLink(value, `/fulfilments/all/${jobId}`),
      },
      {
        name: 'title',
        action: renderLink(value, `/fulfilments/all/${jobId}`),
      },
      {
        name: 'amount',
        action: renderMoneyWithCurrency(value, currency),
      },
      {
        name: 'payed_sum',
        action: renderMoneyWithCurrency(value, currency),
      },
    ];
  };

  const actionsColumn = [
    {
      key: 'orderCodeInItem',
      title: 'Order Code',
      width: 100,
      hideInSetting: true,
      fixed: 'left',
      render: (record: any) => {
        const orderId: string = record?.['fulfilment.order_id'];
        const orderCode: string = record?.['fulfilment.order.code'];
        return renderLink(orderCode, `/orders/all/${orderId}`);
      },
    },
    {
      key: 'toPayout',
      title: 'To Payout',
      width: 130,
      hideInSetting: true,
      fixed: 'right',
      render: (record: any) => {
        const recordId: string = record?.id;
        const currency = record?.['currency.code'] || currencyCode;
        const amount: number = +record?.amount || 0;
        const payedSum: number = +record?.payed_sum || 0;
        return renderInputPayoutJob(amount - payedSum, recordId, currency);
      },
    },
  ];

  return (
    <Table
      tableIndicatorClassName={'PurchaseItemsInModal'}
      //@ts-ignore
      actionsColumn={actionsColumn}
      searchingByAllTable={false}
      rowSelection={null}
      rowSelectionType={null}
      queryKey={[queryNames.PurchaseItems, id]}
      meta={meta}
      toolbar={null}
      toolBarRender={[]}
      renderDataColumn={renderData}
      headerName={tablesNames.PurchaseItemsHeader}
      defaultEnabledSettings={defaultEnabledSettings}
      parametrForSavingSettings={''}
      hideInSetting={hideInSetting}
      quantityTitleText="items"
      columnsForRequest={columns}
      disableFilteringAndSearching={true}
      settingsOptions={false}
      defaultSorter={{
        field: 'code',
        order: 'ascend',
      }}
      resetAllFiltering={true}
    />
  );
};

export default memo(PurchaseItemsInModal);
