import React from 'react';
import styles from './styles.module.scss';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import useGetData from 'api/useGetData';
import { Collapse } from 'antd';
import { JobT } from './types';
import { Link } from 'react-router-dom';
import general_styles from '../../../../../../../../../shared/styles/styles.module.scss';
import { APP_CONSTANS } from 'shared/constants';
import { Comments } from 'components/ui_components/Comments';
import { Loader } from 'components/ui_components/Loader';
const { Panel } = Collapse;

export const TabCommunication: React.FC<any> = ({ userId }) => {
  const token = useSelector((state: any) => state.auth.token);
  const getAllData = true;

  const {
    isLoading: isLoadingJobs,
    data: jobs,
    remove: removeJobs,
  } = useQuery({
    queryKey: [queryNames.FULFILMENTS, userId, 'communication'],
    meta: {
      token,
      getAllData,
      columnParams: ['id', 'State.id', 'State.name','Order.title'],
      additionalSearchParams: `?user_ids=${userId}`,
    },
    queryFn: useGetData,
    staleTime: 10000,
    enabled: !!userId,
  });

  return (
    <div className={styles.com}>
      {!isLoadingJobs && jobs && jobs?.data && jobs?.data?.length > 0 ? (
        <>
        <div className={styles.com__header}>
          <p className={styles.com__bigWidth}>Fulfilment name</p>
          <p className={styles.com__bigWidth}>Status</p>
          <p className={styles.com__smallWidth}></p>
        </div>

        <Collapse
          className={styles.com__collapse}
          expandIconPosition='end'
          bordered={true}
        >
          {jobs?.data?.map((job: JobT) => (
              <Panel
                className={styles.com__panel}
                key={job.id}
                header={
                  <div className={styles.com__headerPanel}>
                    <p className={styles.com__bigWidth}>
                      <Link
                        className={styles.threeDots}
                        to={`/fulfilments/all/${job.id}`}
                      >
                        {job.order.title}
                      </Link>
                    </p>
                    <p className={styles.com__bigWidth}>
                      <span
                        className={general_styles.jobStatus}
                        data-job-status={job.state.id}
                      >
                        {job.state.name}
                      </span>
                    </p>
                  </div>
                }
              >
                <Comments
                  inner={true}
                  isInternal={false}
                  inDrawer={true}
                  objectType="fulfilment"
                  objectId={job.id}
                  fixedHeight={false}
                  customClassname={styles.fixedHeightCom}
                  cancelScrollBottom={true}
                  hideSendingComments={true}
                  showTextIfCommentsEmpty={true}
                />
              </Panel>
            ))}
          </Collapse>
        </>
      ) : !isLoadingJobs && jobs && jobs?.data?.length === 0 ? (
        <p>
          Here will be displayed fulfillments accepted by this Photographer with the possibility to see communication.
        </p>
      ) : <Loader />}
    </div>
  )
}