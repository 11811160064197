import { Button, Tabs, TabsProps, message } from 'antd';
import {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { MainContainer } from '../../../../ui_components/MainContainer';
import { ModalPayJobs } from 'components/modals/ModalPayJobs';
import { CompletedJobsType, JobPayouts, SummaryByCode } from './types';
import { JobsAll } from './components/JobsAll';
import { JobsPaypal } from './components/JobsPaypal';
import { JobsVenmo } from './components/JobsVenmo';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APP_PATH } from 'features/Routes/pathes';
import { queryNames } from 'api/queryNames';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import { APP_CONSTANS } from 'shared/constants';
import useGetData from 'api/useGetData';
import { ModalPurchasePayout } from 'components/modals/ModalPurchasePayout';
import { renderMoneyWithCurrency } from 'shared/utils/DataInTables/renderMoneyWithCurrency';
import styles from './styles.module.scss';

export const CompletedNotPaidJobs: FunctionComponent = () => {
  const [selectedJobsId, setSelectedJobsId] = useState<any>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [showModalPaidJobs, setShowModalPaidJobs] = useState(false);
  const [isCheckCurrentContent, setIsCheckCurrentContent] =
    useState<boolean>(false);
  const [isCheckAllContent, setIsCheckAllContent] = useState<boolean>(false);
  const [actionAfterCreate, setActionAfterCreate] = useState<string>();
  const [currentTab, setCurrentTab] = useState<string>(CompletedJobsType.ALL);
  const [showModalPurchase, setShowModalPurchase] = useState<boolean>(false);
  const [dataPurchase, setDataPurchase] = useState<any>();
  const [generalSum, setGeneralSum] = useState<any>();
  const [currentDataInTable, setCurrentDataInTable] = useState<any>(null);
  const [filtersFromTable, setFiltersFromTable] = useState<any>(null);
  const [currentSumByTable, setCurrentSumByTable] = useState<any>(null);
  const [newTab, setNewTab] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient: any = useQueryClient();
  const token = useSelector((state: any) => state.auth.token);

  const params = useParams<{ type: CompletedJobsType }>();
  const { type } = params;

  const { COMPLETED_JOB } = APP_CONSTANS.FULFILMENT_STATUSES_GUID_BY_CODE;
  const { PAYPAL, VENMO } = APP_CONSTANS.PURCHASE_TARGETS;

  const { data: dataDefaultPayoutProvider } = useQuery({
    queryKey: [queryNames.Preferences, '7d20b429-c631-4592-a0de-976ff2957643'],
    meta: {
      token,
      getAllData: true,
      columnParams: ['value'],
      additionalSearchParams: '?id=7d20b429-c631-4592-a0de-976ff2957643',
    },
    queryFn: useGetData,
  });

  const { data: dataJobPayouts } = useQuery({
    queryKey: [queryNames.CompletedButNotPaidJobs, 'generalSum'],
    meta: {
      token,
      getAllData: true,
      columnParams: [
        'Order.payout',
        'Account.PurchaseMethod.purchase_target_id',
        'Order.Currency.code',
      ],
      additionalSearchParams: `?has_payout=false&has_purchase=false&state_ids=${COMPLETED_JOB}`,
    },
    queryFn: useGetData,
  });

  const { data: dataSupportedCurrency } = useQuery({
    queryKey: [queryNames.Currencies, 'supportedCurrency'],
    meta: {
      token,
      getAllData: true,
      columnParams: ['code'],
      additionalSearchParams: `?supported=true`,
    },
    queryFn: useGetData,
  });

  const { mutate } = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: () => {
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Saving data error. ${data.error?.message}`);
        return;
      }
      message.success(
        `Purchase${
          selectedUserIds?.length > 1 ? 's' : ' ' + data?.data[0]?.code
        } created`
      );
      queryClient.invalidateQueries(
        `${APP_CONSTANS.BASE_API_URL_V2}/${queryNames.CompletedButNotPaidJobs}`
      );
      setSelectedJobsId([]);
      setSelectedUserIds([]);
      setIsCheckAllContent(false);
      setIsCheckCurrentContent(false);
      if (data?.data && Array.isArray(data?.data) && data?.data[0]?.id) {
        if (actionAfterCreate === 'short') {
          setDataPurchase(data.data[0]);
          setShowModalPurchase(true);
        }
        if (actionAfterCreate === 'open') {
          const { id } = data.data[0];
          navigate(`/${APP_PATH.PURCHASES}all/${id}?modal_pay=true`, {
            state: { previousPath: pathname },
          });
        }
      }
    },
  });

  const calcSumOfPayout = (jobs: JobPayouts[]) => {
    return jobs
      .map((job: JobPayouts) => {
        if (job.hasOwnProperty('order.payout')) {
          return job['order.payout'];
        } else {
          return job.order.payout;
        }
      })
      .reduce((payout: any, acc: any) => payout + acc);
  };

  const getCurrencyCodes = useCallback((currencies: { code: string }[]) => {
    return currencies.map((currency: { code: string } | any) => currency.code);
  }, []);

  const createObjDataWithCodes = useCallback(
    (currencyCodes: string[], jobs: JobPayouts[] | any) => {
      let objDataWithCodes: { [key: string]: any } = {};

      currencyCodes.forEach((code: string) => {
        const jobsByCode = jobs?.filter((job: JobPayouts | any) => {
          if (job.hasOwnProperty('order.currency.code')) {
            return job['order.currency.code'] === code;
          } else {
            return job.order.currency.code === code;
          }
        });
        if (jobsByCode.length > 0) {
          objDataWithCodes[code] = jobsByCode;
        }
      });
      return objDataWithCodes;
    },
    []
  );

  const formatDataAndCodes = useCallback(
    (objWithDataAndCodes: any) => {
      const arrDataAndCodes: SummaryByCode = {
        all: [],
        paypal: [],
        venmo: [],
      };

      for (let key in objWithDataAndCodes) {
        if (objWithDataAndCodes.hasOwnProperty(key)) {
          const jobs: JobPayouts[] = objWithDataAndCodes[key];
          const paypalData = jobs.filter((job: JobPayouts | any) => {
            if (
              job.hasOwnProperty('account.purchase_method.purchase_target_id')
            ) {
              return (
                job['account.purchase_method.purchase_target_id'] === PAYPAL
              );
            } else {
              return (
                job.account?.purchase_method?.purchase_target_id === PAYPAL
              );
            }
          });
          const venmoData = jobs.filter((job: JobPayouts | any) => {
            if (
              job.hasOwnProperty('account.purchase_method.purchase_target_id')
            ) {
              return (
                job['account.purchase_method.purchase_target_id'] === VENMO
              );
            } else {
              return job.account?.purchase_method?.purchase_target_id === VENMO;
            }
          });
          const allSum = calcSumOfPayout(jobs);
          const paypalSum =
            paypalData?.length > 0 ? calcSumOfPayout(paypalData) : 0;
          const venmoSum =
            venmoData?.length > 0 ? calcSumOfPayout(venmoData) : 0;

          arrDataAndCodes.all.push(renderMoneyWithCurrency(allSum, key));
          if (paypalSum > 0) {
            arrDataAndCodes.paypal.push(
              renderMoneyWithCurrency(paypalSum, key)
            );
          }
          if (venmoSum > 0) {
            arrDataAndCodes.venmo.push(renderMoneyWithCurrency(venmoSum, key));
          }
        }
      }

      return arrDataAndCodes;
    },
    [PAYPAL, VENMO]
  );

  const createLayoutForSum = useCallback(
    (all: string, paypal: string, venmo: string) => {
      return (
        <span>
          <span className={styles.sumRow}>
            <span className={styles.title}>All - </span>
            <span className={styles.value}>{all}</span>
          </span>
          <span className={styles.sumRow}>
            <span className={styles.title}>Paypal - </span>
            <span className={styles.value}>{paypal}</span>
          </span>
          <span>
            <span className={styles.title}>Venmo - </span>
            <span className={styles.value}>{venmo}</span>
          </span>
        </span>
      );
    },
    []
  );

  const formatDataAndCodesToString = useCallback(
    (summary: SummaryByCode) => {
      const { all, paypal, venmo } = summary;
      const allSum = all.join(' , ');
      const paypalSum = paypal.length > 0 ? paypal.join(' , ') : '0.00';
      const venmoSum = venmo.length > 0 ? venmo.join(' , ') : '0.00';
      return createLayoutForSum(allSum, paypalSum, venmoSum);
    },
    [createLayoutForSum]
  );

  const isFiltersApplied = useCallback((filters: any) => {
    if (filters) {
      const filterValues = Object.values(filters);
      const existingFilterValues = filterValues.filter(
        (value: any) => value?.key && value.key?.length > 0
      );
      return existingFilterValues && existingFilterValues?.length > 0;
    } else {
      return false;
    }
  }, []);

  useEffect(() => {
    if (
      dataJobPayouts &&
      dataJobPayouts?.data &&
      dataJobPayouts?.count > 0 &&
      dataSupportedCurrency &&
      dataSupportedCurrency?.count > 0
    ) {
      const currencyCodes = getCurrencyCodes(dataSupportedCurrency.data);
      const objWithDataAndCodes = createObjDataWithCodes(
        currencyCodes,
        dataJobPayouts.data
      );
      const arrDataAndCodes = formatDataAndCodes(objWithDataAndCodes);
      setGeneralSum(formatDataAndCodesToString(arrDataAndCodes));
    } else {
      setGeneralSum(createLayoutForSum('0.00', '0.00', '0.00'));
    }
  }, [
    createObjDataWithCodes,
    getCurrencyCodes,
    dataJobPayouts,
    dataSupportedCurrency,
    formatDataAndCodes,
    formatDataAndCodesToString,
    createLayoutForSum,
  ]);

  useEffect(() => {
    if (
      currentDataInTable &&
      currentDataInTable.length > 0 &&
      dataSupportedCurrency &&
      dataSupportedCurrency?.count > 0 &&
      filtersFromTable &&
      isFiltersApplied(filtersFromTable)
    ) {
      const currencyCodes = getCurrencyCodes(dataSupportedCurrency.data);
      const objWithDataAndCodes = createObjDataWithCodes(
        currencyCodes,
        currentDataInTable
      );
      const arrDataAndCodes = formatDataAndCodes(objWithDataAndCodes);
      setCurrentSumByTable(formatDataAndCodesToString(arrDataAndCodes));
    } else {
      setCurrentSumByTable(null);
    }
  }, [
    dataSupportedCurrency,
    currentDataInTable,
    getCurrencyCodes,
    createObjDataWithCodes,
    formatDataAndCodes,
    formatDataAndCodesToString,
    filtersFromTable,
    isFiltersApplied,
  ]);

  const payJobsBtn = useMemo(() => {
    const createPurchase = (action?: 'open' | 'short' | undefined) => {
      if (!selectedJobsId.length) return;
      setActionAfterCreate(action);

      mutate({
        data: { fulfilment_ids: [...selectedJobsId] },
        token,
        otherProps: queryNames.PurchasesBulkCreate,
        method: 'POST',
      });
    };
    return (
      <>
        <p className={styles.generalSum}>{generalSum}</p>
        {currentSumByTable && (
          <p className={styles.currentGeneralSum}>
            <span className={styles.currentlyTitle}>Currently:</span>
            {currentSumByTable}
          </p>
        )}
        <div className={styles.actions}>
          <Button
            type="primary"
            key="createPurchase"
            onClick={() => createPurchase()}
            disabled={selectedJobsId?.length === 0}
          >
            Create Purchase{selectedUserIds?.length > 1 ? 's' : ''}
          </Button>
          <Button
            type="primary"
            key="createAndOpenPurchase"
            onClick={() => createPurchase('open')}
            disabled={selectedUserIds?.length !== 1}
          >
            Create and open
          </Button>
          <Button
            type="primary"
            key="createPurchaseShort"
            onClick={() => createPurchase('short')}
            disabled={selectedUserIds?.length !== 1}
          >
            Create and pay
          </Button>
        </div>
      </>
    );
  }, [
    selectedJobsId,
    selectedUserIds,
    mutate,
    token,
    generalSum,
    currentSumByTable,
  ]);

  const rowSelection = {
    onClick: (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();
    },
    onChange: (selectedRowKeys: React.Key[], selectedRows: any = []) => {
      const userIds: string[] = [];
      selectedRows.map(
        (e: { user_id: string }) => e?.user_id && userIds.push(e.user_id)
      );
      const unicUserIds = [...new Set(userIds)];
      setSelectedUserIds(unicUserIds);
      setSelectedJobsId(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      name: record.name,
    }),
    selectedRowKeys: selectedJobsId,
  };

  const handleChangeTab = (key: string) => {
    setIsCheckAllContent(false);
    setIsCheckCurrentContent(false);
    setSelectedJobsId([]);
    setSelectedUserIds([]);
    setCurrentTab(key);
    setNewTab(true);
    navigate(`/${APP_PATH.COMPLETED_JOBS}${key}`, { replace: true });
  };

  const items: TabsProps['items'] = [
    {
      key: CompletedJobsType.ALL,
      label: `All`,
      children: (
        <JobsAll
          rowSelection={rowSelection}
          defaultSorter={{
            field: 'user.email',
            order: 'ascend',
          }}
          isCheckCurrentContent={isCheckCurrentContent}
          isCheckAllContent={isCheckAllContent}
          selectedContentsIds={selectedJobsId}
          setIsCheckAllContent={setIsCheckAllContent}
          setIsCheckCurrentContent={setIsCheckCurrentContent}
          setSelectedContentsIds={setSelectedJobsId}
          setDataFromTableComponent={setCurrentDataInTable}
          setFiltersFromTableComponent={setFiltersFromTable}
          rowSelectionType={'checkbox'}
          showSelectAll={true}
          resetAllFiltering={newTab}
        />
      ),
    },
    {
      key: CompletedJobsType.PAYPAL,
      label: `Paypal`,
      children: (
        <JobsPaypal
          rowSelection={rowSelection}
          defaultSorter={{
            field: 'user.email',
            order: 'ascend',
          }}
          isCheckCurrentContent={isCheckCurrentContent}
          isCheckAllContent={isCheckAllContent}
          selectedContentsIds={selectedJobsId}
          setIsCheckAllContent={setIsCheckAllContent}
          setIsCheckCurrentContent={setIsCheckCurrentContent}
          setSelectedContentsIds={setSelectedJobsId}
          setDataFromTableComponent={setCurrentDataInTable}
          setFiltersFromTableComponent={setFiltersFromTable}
          rowSelectionType={'checkbox'}
          showSelectAll={true}
          resetAllFiltering={newTab}
        />
      ),
    },
    {
      key: CompletedJobsType.VENMO,
      label: `Venmo`,
      children: (
        <JobsVenmo
          rowSelection={rowSelection}
          defaultSorter={{
            field: 'user.email',
            order: 'ascend',
          }}
          isCheckCurrentContent={isCheckCurrentContent}
          isCheckAllContent={isCheckAllContent}
          selectedContentsIds={selectedJobsId}
          setIsCheckAllContent={setIsCheckAllContent}
          setIsCheckCurrentContent={setIsCheckCurrentContent}
          setSelectedContentsIds={setSelectedJobsId}
          setDataFromTableComponent={setCurrentDataInTable}
          setFiltersFromTableComponent={setFiltersFromTable}
          rowSelectionType={'checkbox'}
          showSelectAll={true}
          resetAllFiltering={newTab}
        />
      ),
    },
  ];

  return (
    <MainContainer
      title="Completed but not paid jobs"
      additionalItems={payJobsBtn}
      headerClassName={styles.headerCompletedJobs}
      expanded
    >
      <Tabs
        activeKey={type}
        items={items}
        destroyInactiveTabPane={true}
        onChange={handleChangeTab}
      />

      {showModalPaidJobs && currentTab && (
        <ModalPayJobs
          showModal={showModalPaidJobs}
          setShowModal={setShowModalPaidJobs}
          jobIds={selectedJobsId}
          setSelectedJobsId={setSelectedJobsId}
          setIsCheckAllContent={setIsCheckAllContent}
          setIsCheckCurrentContent={setIsCheckCurrentContent}
          currentTab={currentTab}
        />
      )}

      {showModalPurchase && dataPurchase && (
        <ModalPurchasePayout
          id={dataPurchase?.id}
          code={dataPurchase?.code}
          showModal={showModalPurchase}
          setShowModal={setShowModalPurchase}
          currencyCode={dataPurchase?.currency?.code}
          purchaseMethodId={dataPurchase?.purchase_method_id}
          dataDefaultPayoutProvider={dataDefaultPayoutProvider?.data[0]?.value}
          accountId={dataPurchase?.account_id}
        />
      )}
    </MainContainer>
  );
};
