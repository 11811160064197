import { message } from 'antd';
import { MouseEvent } from 'react';

export const copyTextToTheClipboard = async (
  event: MouseEvent,
  text: string
) => {
  //eslint-disable-next-line
  message.success('Copied!');
  event.stopPropagation();
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }

  return document.execCommand('copy', true, text);
};
