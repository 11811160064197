import { Spin } from 'antd';
import styles from './styles.module.scss';
import { FC } from 'react';

export const Loader: FC<{ disableFullHeight?: boolean }> = ({
  disableFullHeight = false,
}) => {
  return (
    <div className={disableFullHeight ? styles.loaderCompact : styles.loader}>
      <Spin size="large" className={styles.spin} tip="Loading..." />
    </div>
  );
};
