import { ACTION_TYPES } from './types';

export const getUsers = (data) => {
  return {
    type: ACTION_TYPES.GET_USERS,
    payload: data,
  };
};

export const getUserById = (data) => {
  return {
    type: ACTION_TYPES.GET_USERS_BY_ID,
    payload: data,
  };
};

export const updateUser = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_USER,
    payload: data,
  };
};

export const softDeleteUser = (data) => {
  return {
    type: ACTION_TYPES.SOFT_DELETE_USER,
    payload: data,
  };
};

export const hardDeleteUser = (data) => {
  return {
    type: ACTION_TYPES.HARD_DELETE_USER,
    payload: data,
  };
};

export const getUserByAccountId = (data) => {
  return {
    type: ACTION_TYPES.GET_USERS_BY_ACCOUNT_ID,
    payload: data,
  };
};

export const getUserPrefs = (id) => {
  return {
    type: ACTION_TYPES.GET_USERS_PREFS,
    payload: id,
  };
};

export const getUserAddress = (id) => {
  return {
    type: ACTION_TYPES.GET_USER_ADDRESS_BY_USER_ID,
    payload: id,
  };
};

export const getUserRequests = (data) => {
  return {
    type: ACTION_TYPES.GET_USERS_REQUESTS,
    payload: data,
  };
};

export const postNewAddress = (data) => {
  return {
    type: ACTION_TYPES.POST_NEW_USER_ADDRESS,
    payload: data,
  };
};

export const updateAddress = (data) => {
  return {
    type: ACTION_TYPES.PUT_USER_ADDRESS,
    payload: data,
  };
};

export const updateUserPrefs = (data) => {
  return {
    type: ACTION_TYPES.POST_USER_PREFS,
    payload: data,
  };
};

export const resetUser = () => {
  return {
    type: ACTION_TYPES.REMOVE_USERS_BY_ID,
    payload: null,
  };
};

export const softDeleteUserAddress = (data) => {
  return {
    type: ACTION_TYPES.SOFT_DELETE_USER_ADDRESS,
    payload: data,
  };
};

export const hardDeleteUserAddress = (data) => {
  return {
    type: ACTION_TYPES.HARD_DELETE_USER_ADDRESS,
    payload: data,
  };
};

export const universalRequestForSavingData = (data) => {
  return {
    type: ACTION_TYPES.UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA,
    payload: data,
  };
};
