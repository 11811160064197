export const hideInSetting = [
  'user_id',
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'state.name',
  'state_id',
  'comments',
  'state.description',
  'id',
  'extra_data',
];
