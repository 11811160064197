import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import {
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import locale from 'antd/es/date-picker/locale/en_US';
import { APP_CONSTANS } from 'shared/constants';
import { OrderComplicationT, OrderJobComplicationT } from './types';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import clsx from 'clsx';
import { CalendarOutlined } from '@ant-design/icons';
import { getTimeZoneName } from 'shared/utils/getTimezoneName';
import { NewOrderContext } from 'components/pages/main/orders_section/CreateOrderPage/context';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const OrderJobComplications: React.FC<OrderJobComplicationT> = ({
  objectType,
  isFormDisabled,
  isJob,
  currencyCode = '',
  complicationsByType,
  timezone,
}) => {
  const [cmplsWithoutDates, setCmplsWithoutDates] = useState<any>([]);
  const [isDroneShootDisabled, setIsDroneShootDisabled] =
    useState<boolean>(false);
  const orderForm = Form.useFormInstance();
  const isNewOrder = useContext(NewOrderContext);
  const imageOrderType = Form.useWatch(
    ['features', APP_CONSTANS.COMPLICATIONS.IMAGE_ORDER_TYPE],
    orderForm
  );
  const videoOrderType = Form.useWatch(
    ['features', APP_CONSTANS.COMPLICATIONS.VIDEO_ORDER_TYPE],
    orderForm
  );

  useEffect(() => {
    // If Drone Shoot select disabled if Image/Video Order type present in droneShootDisableList array
    const droneShootDisableList = [
      'e69f529a-d0f7-4223-9bc1-b49a5608779b', //OOH - In Lane Approach
      '010e0c19-1814-40e2-b2e4-bf59e6c1b772', //OOH - Approach
      '6e0c0704-37b9-4f45-9cd0-d2202ef06bcf', //Basic Image
      'bb2bbd8b-23b6-4bf1-883e-598726d637b8', //Basic Video & Image
    ];
    if (imageOrderType) {
      setIsDroneShootDisabled(droneShootDisableList.includes(imageOrderType));
    }
    if (videoOrderType) {
      setIsDroneShootDisabled(droneShootDisableList.includes(videoOrderType));
    }
  }, [imageOrderType, videoOrderType]);

  const startValue = Form.useWatch(
    ['features', APP_CONSTANS.COMPLICATIONS.START_DATE],
    orderForm
  );
  const endValue = Form.useWatch(
    ['features', APP_CONSTANS.COMPLICATIONS.END_DATE],
    orderForm
  );

  const { START_DATE, END_DATE } = APP_CONSTANS.COMPLICATIONS;

  const setVal = useCallback(
    (key: string, defaultOption: any, itemOptions: any[]) =>
      orderForm.setFieldValue(
        ['features', key],
        defaultOption ? defaultOption.value : itemOptions[0].value
      ),
    [orderForm]
  );

  useEffect(() => {
    if (complicationsByType && complicationsByType?.features?.length) {
      const items = orderForm.getFieldsValue(true);
      const features: { [key: string]: any } = {};
      Array.isArray(complicationsByType.features && items?.features) &&
        complicationsByType.features.map((e) => {
          if (e.id in items.features) {
            features[e.id] = items.features[e.id];
          } else {
            features[e.id] = undefined;
          }
        });

      Object.entries(features).forEach(([key, value]) => {
        if (value === undefined) {
          const itemInResponse = complicationsByType?.features?.find(
            (e) => e.id === key
          );
          const itemOptions = itemInResponse?.options;
          if (Array.isArray(itemOptions) && itemOptions.length) {
            const defaultOption = itemOptions.find((e) => e.selected);
            setVal(key, defaultOption, itemOptions);
          }
        }
      });
      const cmplsNoDates = complicationsByType.features.filter((cmpl: any) => {
        return cmpl.id !== START_DATE && cmpl.id !== END_DATE;
      });
      setCmplsWithoutDates(cmplsNoDates);
    }
  }, [orderForm, setVal, complicationsByType, START_DATE, END_DATE]);

  const returnOptions = (options: any, formItemType: string) => {
    if (Array.isArray(options) && options.length) {
      options.sort((a: { rank_id: number }, b: { rank_id: number }) => {
        if (
          a.hasOwnProperty('rank_id') &&
          b.hasOwnProperty('rank_id') &&
          Number.isInteger(a.rank_id) &&
          Number.isInteger(b.rank_id)
        )
          return a.rank_id - b.rank_id;
        return 0;
      });
    }
    const childrenOptions = options?.map((option: any) => {
      return {
        value: option.value,
        label: (
          <div
            className={clsx(
              !isJob && formItemType === 'select' && styles.cmpl__priceBlock
            )}
          >
            <Tooltip placement="topLeft" title={option.title}>
              <span className={styles.cmpl__label}>{option.title}</span>
            </Tooltip>

            {!isJob && (
              <span
                className={clsx(
                  styles.cmpl__price,
                  formItemType === 'radio'
                    ? styles.cmpl__price_radio
                    : formItemType === 'select'
                    ? styles.cmpl__price_select
                    : ''
                )}
              >
                {`+${option.price} ${currencyCode}`}
              </span>
            )}
          </div>
        ),
      };
    });
    return childrenOptions;
  };

  const returnPriceDatePicker = (options: any) => {
    const datepickerSet = options.find((option: any) => {
      return (
        option.value === APP_CONSTANS.COMPLICATIONS.START_DATE_SET ||
        option.value === APP_CONSTANS.COMPLICATIONS.END_DATE_SET
      );
    });
    return `+${datepickerSet?.price} ${currencyCode}`;
  };

  const comlicationGenerator = (cmpl: OrderComplicationT | undefined) => {
    // console.log('cmpl', cmpl);
    if (!cmpl) return null;

    const baseItem = (
      child:
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.ReactPortal
    ) => (
      <React.Fragment key={cmpl.id}>
        <Form.Item
          className={styles.cmpl__formItem}
          label={cmpl.title}
          //@ts-ignore
          name={['features', cmpl.id]}
        >
          {child}
        </Form.Item>
      </React.Fragment>
    );
    switch (cmpl.input_type) {
      case 'Select': {
        const select = (
          <Select
            options={cmpl.options ? returnOptions(cmpl.options, 'select') : []}
            disabled={
              cmpl.id === APP_CONSTANS.COMPLICATIONS.DRONE_SHOOT
                ? isDroneShootDisabled
                : isFormDisabled || cmpl.disabled
            }
          />
        );
        return baseItem(select);
      }
      case 'Radio': {
        const radio = (
          <Radio.Group
            options={cmpl.options ? returnOptions(cmpl.options, 'radio') : []}
            disabled={isFormDisabled || cmpl.disabled}
          />
        );
        return baseItem(radio);
      }
      case 'DatePicker': {
        if (objectType !== 'tmpl') {
          const isStart = cmpl.id === APP_CONSTANS.COMPLICATIONS.START_DATE;
          const isEnd = cmpl.id === APP_CONSTANS.COMPLICATIONS.END_DATE;
          const datePicker = (
            <DatePicker
              suffixIcon={
                <span className={styles.timezone}>
                  {timezone && (
                    <span>{getTimeZoneName(undefined, timezone)}</span>
                  )}
                  {!isJob && (
                    <span className={styles.cmpl__price}>
                      {returnPriceDatePicker(cmpl.options)}
                    </span>
                  )}
                  <CalendarOutlined />
                </span>
              }
              locale={locale}
              showNow={false}
              inputReadOnly={true}
              disabledDate={(date) => {
                if (!date) return false;
                // return (
                //   date < dayjs().subtract(1, 'day').endOf('day') ||
                //   date > dayjs(date)
                // );
                if (isStart && endValue) {
                  return (
                    date < dayjs().subtract(1, 'day').endOf('day') ||
                    date >= dayjs(endValue)
                  );
                }
                if (isEnd && startValue) {
                  return date < dayjs(startValue).subtract(1, 'day');
                }
                return date < dayjs().subtract(1, 'day').endOf('day');
              }}
              showTime={{ showSecond: false }}
              className={styles.dates}
              disabled={isFormDisabled || cmpl.disabled}
              onCalendarChange={(changedDate: any) => {
                if (!changedDate) return;
                if (
                  isStart &&
                  endValue &&
                  dayjs(endValue).isSameOrBefore(dayjs(changedDate))
                ) {
                  message.warning('Start date must be before end date', 3);
                }
                if (
                  isEnd &&
                  startValue &&
                  dayjs(startValue).isAfter(dayjs(changedDate))
                ) {
                  message.warning('End date must be after start date', 3);
                }
              }}
            />
          );
          return baseItem(datePicker);
        }
        break;
      }
      case 'Input': {
        const input = <Input disabled={isFormDisabled || cmpl.disabled} />;
        return baseItem(input);
      }
      case 'Number': {
        const number = (
          <InputNumber disabled={isFormDisabled || cmpl.disabled} />
        );
        return baseItem(number);
      }
      case 'CheckBox': {
        const checkBox = (
          <Checkbox disabled={isFormDisabled || cmpl.disabled} />
        );
        return baseItem(checkBox);
      }
      default: {
        return <></>;
      }
    }
    return <></>;
  };

  return (
    <section className={styles.cmpl}>
      {isNewOrder ? (
        <></>
      ) : (
        <h3 className={styles.cmpl__title}>Complications:</h3>
      )}

      {complicationsByType && (
        <>
          <div className={styles.cmpl__jobType}>
            <Form.Item
              label={complicationsByType.content_type.title}
              name={'content_type'}
            >
              <Radio.Group
                options={
                  complicationsByType.content_type.options
                    ? returnOptions(
                        complicationsByType.content_type.options,
                        'radio'
                      )
                    : []
                }
                disabled={isFormDisabled}
              />
            </Form.Item>
          </div>

          <div className={styles.cmpl__block}>
            {cmplsWithoutDates.length > 0 &&
              cmplsWithoutDates.map((cmpl: any) => comlicationGenerator(cmpl))}

            <Form.Item
              label="Quantity"
              name="quantity"
              className={styles.cmpl__formItem}
            >
              <InputNumber
                min={1}
                style={{ width: '100%' }}
                disabled={isFormDisabled}
              />
            </Form.Item>
          </div>

          <div className={clsx(styles.cmpl__block, styles.cmpl__dateBlock)}>
            {comlicationGenerator(
              complicationsByType?.features?.find(
                (cmpl) => cmpl.id === START_DATE
              )
            )}
            {comlicationGenerator(
              complicationsByType?.features?.find(
                (cmpl) => cmpl.id === END_DATE
              )
            )}
          </div>
        </>
      )}
    </section>
  );
};
