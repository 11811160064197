// Using in table component like:
// tableSettingsSorter(defaultDisabledSettings)

import { ColumnsState } from '@ant-design/pro-table';

const hideAllSettings = (columns: any) => {
  const columnsObj = {};
  for (let column of columns) {
    columnsObj[column.dataIndex] = { show: false };
  }
  return columnsObj;
};

export const formatSettings = (
  columns: any,
  defaults: Record<any, ColumnsState>
) => {
  let defaultEnabledSettings = defaults;
  if (!defaults) {
    defaultEnabledSettings = { id: { order: 1 } };
  }

  const hidedSettings = hideAllSettings(columns);
  const sortedSettings = Object.fromEntries(
    Object.entries(hidedSettings).sort()
  );
  const listOfOrdersEnabledSettings = Object.values(defaultEnabledSettings).map(
    (o: any) => (o.order ? o.order : 0)
  );
  let lastOrderOfEnabledSettings = Math.max(...listOfOrdersEnabledSettings);

  for (const key in sortedSettings) {
    if (key) {
      //delete enabled settings from all settings
      for (let defaultEnableSetting in defaultEnabledSettings) {
        if (defaultEnableSetting && key === defaultEnableSetting) {
          delete sortedSettings[key];
        }
      }
      //set order to columns that were hided
      if (Object.prototype.hasOwnProperty.call(sortedSettings, key)) {
        const element: any = sortedSettings[key];
        if (!element.order) {
          lastOrderOfEnabledSettings += 1;
          element.order = lastOrderOfEnabledSettings;
        }
      }
    }
  }
  const concatEnabledAndDisableSettings = {
    ...defaultEnabledSettings,
    ...sortedSettings,
  };
  // console.log('concatEnabledAndDisableSettings - ', concatEnabledAndDisableSettings)
  return concatEnabledAndDisableSettings;
};
