import { ModalWindow } from 'components/ui_components/ModalWindow';
import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { APP_PATH } from 'features/Routes/pathes';
import { useNavigate } from 'react-router';
import { Carousel } from './components/Carousel';

export const ModalContent: React.FC<any> = ({
  visible,
  setVisible,
  contentIds,
  quantityContents,
  commentSection,
  isActions = true,
  isCarousel,
  setContentId,
  sliderIndex = null,
  setSliderIndex = null,
  refetchContentJob,
  refetchContent,
  setRefetchTableViewData = null,
}) => {
  const [replaceInModal, setReplaceInModal] = useState<boolean>(false);
  const [containerCurrentWidth, setContainerCurrentWidth] = useState<number>(0);
  const navigate = useNavigate();

  const closeModal = () => {
    if (setContentId) {
      setContentId([]);
    }
    setVisible(false);
    if (window.location.pathname.includes('fulfilment_contents')) {
      navigate(`/${APP_PATH.CONTENTS}`, { replace: true });
    }
  };

  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver((entries) => {
      setContainerCurrentWidth(entries[0].contentRect.width);
    });
    resizeObserver.observe(node);
    return () => {
      resizeObserver.unobserve(node);
    };
  }, []);

  return (
    <ModalWindow
      title={null}
      visible={visible}
      width="100%"
      onCancel={closeModal}
      closable={false}
      footer={null}
      style={{
        minWidth: '100%',
        width: '100%',
        height: '100%',
        top: '0px',
        padding: '0px',
        margin: '0px',
      }}
      className={clsx('modalWindowContent')}
    >
      <div ref={elementRef}>
        {contentIds && contentIds.length > 0 && (
          <Carousel
            contentIds={contentIds}
            sliderIndex={sliderIndex}
            setSliderIndex={setSliderIndex}
            closeModal={closeModal}
            isActions={isActions}
            replaceInModal={replaceInModal}
            setReplaceInModal={setReplaceInModal}
            isCarousel={isCarousel}
            commentSection={commentSection}
            refetchContentJob={refetchContentJob}
            refetchContentBarView={refetchContent}
            containerCurrentWidth={containerCurrentWidth}
            quantityContents={quantityContents}
            setRefetchTableViewData={setRefetchTableViewData}
          />
        )}
      </div>
    </ModalWindow>
  );
};
