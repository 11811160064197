import { isGuid } from './isGuid';

function flattener(obj: object, parent: string, res: object) {
  for (let key in obj) {
    if (key) {
      let propName = parent ? parent + '.' + key : key;
      if (typeof obj[key] === 'object') {
        flattener(obj[key], propName, res);
      } else {
        let value;
        if (isGuid.test(obj[key]) && key !== 'id') {
          const nameNestedField = key.includes('id')
            ? key.split('_id')[0]
            : key;
          value =
            obj.hasOwnProperty(nameNestedField) &&
            obj[nameNestedField].hasOwnProperty('name')
              ? obj[nameNestedField].name
              : obj[key];
        } else {
          value = obj[key];
        }

        res[propName] = value;
      }
    }
  }
  return res;
}

export function flattenObject(arr: any[] = Array()) {
  const newArray: Array<object> = [];
  if (arr.length) {
    arr.forEach((e: object) => {
      newArray.push(flattener(e, '', {}));
    });
  }
  return newArray;
}
