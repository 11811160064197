import { ReactElement } from 'react';
import { APP_CONSTANS, JobType } from 'shared/constants';
import { Address, Job } from 'types/global';

import { APP_PATH } from 'features/Routes/pathes';
import { priceFloatingPointCorrection } from 'shared/utils/priceFloatingPointCorrection';
import { MapJobMarker, MapJobMarkerProps } from '../components/MapJobMarker';
import { JobMarkerData } from '../types';
import { correctMarkerPosition } from './correctMarkerPosition';

let locationsArray = [-1000, 1000];
export const getJobMarkers = (jobs: Job[]) => {
  const markerCoordinates: Address[] = [];
  const createJobMarker = (job: Job): ReactElement<JobMarkerData> | null => {
    let jobType: JobType = JobType.PHOTO;
    let position: Address | null = null;
    const jobStatus =
      APP_CONSTANS.FULFILMENT_STATUSES_BY_GUID[job.state_id] ||
      APP_CONSTANS.ORDER_STATUSES_BY_GUID[job.order?.state_id] ||
      APP_CONSTANS.ORDER_STATUSES_BY_GUID[job.state_id];

    const orderContentTypeIsVideo =
      job.order?.content_type === APP_CONSTANS.COMPLICATION_CHILDREN.VIDEO;
    const jobContentTypeIsVideo =
      job.content_type === APP_CONSTANS.COMPLICATION_CHILDREN.VIDEO;

    const orderComplicationsIncludeDrone = Boolean(
      job.order?.complications?.includes('Drone')
    );
    const jobComplicationsIncludeDrone = Boolean(
      job.complications?.includes('Drone')
    );

    if (orderContentTypeIsVideo || jobContentTypeIsVideo) {
      jobType = JobType.VIDEO;
    }

    if (
      (job.order?.complications !== '' && orderComplicationsIncludeDrone) ||
      (job.complications !== '' && jobComplicationsIncludeDrone)
    ) {
      jobType = JobType.DRONE;
    }

    if (
      job.order?.location_lat &&
      job.order?.location_long &&
      job.order?.location_lat !== ''
    ) {
      position = correctMarkerPosition(
        locationsArray,
        +job.order?.location_lat,
        +job.order?.location_long
      );
    } else if (job.location_lat && job.location_long) {
      position = correctMarkerPosition(
        locationsArray,
        +job.location_lat,
        +job.location_long
      );
    }

    const payoutPrice = job.order
      ? priceFloatingPointCorrection(job.order?.payout)
      : priceFloatingPointCorrection(job.payout);

    // const pageUrl = job.order
    //   ? `${APP_PATH.FULFILMENTS}`
    //   : `${APP_PATH.ORDERS}`;
    // const markerUrl = `${APP_CONSTANS.BASE_OLD_ADMIN_URL}admin/${pageUrl}${job.id}`;
    const markerUrl = job.order
      ? APP_PATH.getFulfilment(job.id)
      : APP_PATH.getOrder(job.id);

    const markerClickHandler = () => {
      window.open(markerUrl, '_blank', 'noreferrer');
    };

    if (!position) {
      return null;
    }

    const markerData: MapJobMarkerProps = {
      id: job.id,
      position,
      price: payoutPrice,
      jobStatus,
      jobType,
      onClick: markerClickHandler,
      title: job?.title || job?.order?.title,
    };

    markerCoordinates.push(position);
    return <MapJobMarker key={job.id} {...markerData} />;
  };

  const jobMarkers = (() => {
    if (!jobs || jobs.length === 0) {
      return [];
    }

    return jobs.reduce(
      (accumulator: ReactElement<MapJobMarkerProps>[], job) => {
        if (job) {
          const jobMarker = createJobMarker(job);
          return jobMarker ? [...accumulator, jobMarker] : accumulator;
        }
        return accumulator;
      },
      []
    );
  })();

  return { markers: jobMarkers, coordinates: markerCoordinates };
};
