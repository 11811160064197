import { maxBy, minBy } from 'lodash';

export const getMapPosition = (
  coordinates: { lat: number; lng: number }[]
): {
  zoom?: number;
  center?: { lat: number; lng: number };
  bounds?: { minLat: number; maxLat: number; minLng: number; maxLng: number };
} | null => {
  if (coordinates.length === 0) {
    return { zoom: 4 };
  } else if (coordinates.length === 1) {
    return { zoom: 15, center: coordinates[0] };
  }

  const minLat = minBy(coordinates, ({ lat }) => lat)?.lat;
  const maxLat = maxBy(coordinates, ({ lat }) => lat)?.lat;
  const minLng = minBy(coordinates, ({ lng }) => lng)?.lng;
  const maxLng = maxBy(coordinates, ({ lng }) => lng)?.lng;

  const coordinatesExist = minLat && maxLat && minLng && maxLng;
  if (!coordinatesExist) {
    return { zoom: 4 };
  }
  const bounds = { minLat, maxLat, minLng, maxLng };

  const allJobsInOnePlace =
    Math.abs(minLat - maxLat) < 0.005 || Math.abs(minLng - maxLng) < 0.005;

  if (allJobsInOnePlace) {
    return {
      zoom: 15,
      center: { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 },
    };
  }

  return {
    zoom: 2,
    bounds,
  };
};
