import { QueryMeta } from '@tanstack/react-query';
import { useGetOrders } from './useGetOrders';
import { queryNames } from 'api/queryNames';

export const useGetAllOrders = ({
  additionalSearchParams,
  enabled,
  meta,
}: {
  additionalSearchParams: string;
  enabled?: boolean;
  meta?: QueryMeta;
}) => {
  const defaultMeta = {
    getAllData: true,
  };

  const metaValue = meta ? { ...defaultMeta, ...meta } : defaultMeta;

  return useGetOrders({
    baseQueryKey: queryNames.ORDERS_ALL,
    meta: metaValue,
    additionalSearchParams: additionalSearchParams
      ? additionalSearchParams
      : '',
    enabled,
  });
};
