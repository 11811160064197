import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { Button, Checkbox, Form, Input, message } from 'antd';
import { ModalWindow } from '../../../../../../../../ui_components/ModalWindow';
import { APP_CONSTANS } from '../../../../../../../../../shared/constants';
import { useSelector } from 'react-redux';
import {
  ModalNotificationFormFieldData,
  ModalNotificationPropsT,
} from './types';
import { handleChangeFields } from 'shared/utils/savingDataOnPages';
import { useMutation } from '@tanstack/react-query';
import { usePostData } from 'api/usePostData';
import globalStyles from '../../../../../../../../../shared/styles/styles.module.scss';

const ModalNotification: FunctionComponent<ModalNotificationPropsT> = ({
  showedNotification,
  setShowedNotification,
  email,
  type,
  userId,
  dataPrefs,
  refetchPrefs,
}) => {
  const [photographerNotifications, setPhotographerNotifications] = useState(
    []
  );
  const [customerNotifications, setCustomerNotifications] = useState([]);
  const [commonNotifications, setCommonNotifications] = useState([]);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);
  const dataToStore = useRef<any>([]);
  const [notificationForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);

  const mutation = useMutation({
    mutationFn: usePostData,
    retry: 1,
    onError: (error, variables, context) => {
      message.error('Saving data error. Network Error');
    },
    onSuccess: (data, variables, context) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return
      }
      message.success('All data saved');
      dataToStore.current = [];
      refetchPrefs();
    },
  });

  useEffect(() => {
    if (dataPrefs && dataPrefs.hasOwnProperty('data')) {
      const filteredForPhotographer = dataPrefs.data.filter(
        (pref: any) =>
          pref.preference.pref_group === 'notify' &&
          pref.preference.pref_usage === 'photographer'
      );
      const filteredForCustomer = dataPrefs.data.filter(
        (pref: any) =>
          pref.preference.pref_group === 'notify' &&
          pref.preference.pref_usage === 'customer'
      );
      const filteredCommon = dataPrefs.data.filter(
        (pref: any) =>
          pref.preference.pref_group === 'notify' &&
          pref.preference.pref_usage === 'common'
      );
      setPhotographerNotifications(filteredForPhotographer);
      setCustomerNotifications(filteredForCustomer);
      setCommonNotifications(filteredCommon);
    }
  }, [dataPrefs]);

  const onCancel = useCallback(() => {
    setShowedNotification(false);
    dataToStore.current = [];
    notificationForm.resetFields();
  }, [setShowedNotification]);

  const submitNotificationForm = useCallback(
    (allFields: any) => {
      dataToStore.current.map((data: any) => {
        for (let key in data) {
          data.preference_id = key;
          data.value = data[key].toString();
          delete data[key];
        }
      });
      if (dataToStore.current.length > 0) {
        const dataToBack = {
          user_id: userId,
          preferences: dataToStore.current,
        };
        mutation.mutate({
          data: dataToBack,
          token: token,
          otherProps: 'user_preferences/bulk',
        });
      }
      setShowedNotification(false);
    },
    [setShowedNotification]
  );

  const formFieldsChangeHandler = (
    changedFields: ModalNotificationFormFieldData[]
  ) => {
    const changedData = handleChangeFields(changedFields, dataToStore.current);
    setSubmitButtonDisabled(changedData.length === 0);
  };

  const submitButtonTitle = useMemo(
    () =>
      submitButtonDisabled
        ? 'At least one of the fields must be changed in order to be saved'
        : 'Save changes',
    [submitButtonDisabled]
  );

  return (
    <ModalWindow
      title="Notification settings"
      visible={showedNotification}
      width="882px"
      closable={true}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={notificationForm}
        layout="vertical"
        onFinish={submitNotificationForm}
        onFieldsChange={formFieldsChangeHandler}
      >
        <ul className={clsx(styles.notification, 'notification')}>
          <div>
            <p className={styles.notification__title}>
              {type === 'photographer' ? 'Job' : 'Order'} status changed
            </p>
            {type === 'photographer'
              ? photographerNotifications.length > 0 &&
                photographerNotifications.map(
                  (notification: any) =>
                    APP_CONSTANS.NOTIFICATIONS.PHOTOGRAPHER.STATUSES[
                      notification.preference.pref_code
                    ] && (
                      <li key={notification.preference_id}>
                        <Form.Item
                          name={notification.preference_id}
                          valuePropName="checked"
                          initialValue={
                            notification.value === 'true' ? true : false
                          }
                        >
                          <Checkbox>{notification.preference.title}</Checkbox>
                        </Form.Item>
                      </li>
                    )
                )
              : customerNotifications.length > 0 &&
                customerNotifications.map(
                  (notification: any) =>
                    APP_CONSTANS.NOTIFICATIONS.CUSTOMER.STATUSES[
                      notification.preference.pref_code
                    ] && (
                      <li key={notification.preference_id}>
                        <Form.Item
                          name={notification.preference_id}
                          valuePropName="checked"
                          initialValue={
                            notification.value === 'true' ? true : false
                          }
                        >
                          <Checkbox>{notification.preference.title}</Checkbox>
                        </Form.Item>
                      </li>
                    )
                )}
          </div>

          <div>
            <p className={styles.notification__title}>
              Communication with FotoFetch
            </p>
            {type === 'photographer'
              ? photographerNotifications.length > 0 &&
                photographerNotifications.map(
                  (notification: any) =>
                    APP_CONSTANS.NOTIFICATIONS.PHOTOGRAPHER.COMMUNICATIONS[
                      notification.preference.pref_code
                    ] && (
                      <li key={notification.preference_id}>
                        <Form.Item
                          name={notification.preference_id}
                          valuePropName="checked"
                          initialValue={
                            notification.value === 'true' ? true : false
                          }
                        >
                          <Checkbox>{notification.preference.title}</Checkbox>
                        </Form.Item>
                      </li>
                    )
                )
              : customerNotifications.length > 0 &&
                customerNotifications.map(
                  (notification: any) =>
                    APP_CONSTANS.NOTIFICATIONS.CUSTOMER.COMMUNICATIONS[
                      notification.preference.pref_code
                    ] && (
                      <li key={notification.preference_id}>
                        <Form.Item
                          name={notification.preference_id}
                          valuePropName="checked"
                          initialValue={
                            notification.value === 'true' ? true : false
                          }
                        >
                          <Checkbox>{notification.preference.title}</Checkbox>
                        </Form.Item>
                      </li>
                    )
                )}
            <p className={styles.notification__title}>Common</p>
            {commonNotifications.length > 0 &&
              commonNotifications.map((notification: any) => (
                <li key={notification.preference_id}>
                  <Form.Item
                    name={notification.preference_id}
                    valuePropName="checked"
                    initialValue={notification.value === 'true' ? true : false}
                  >
                    <Checkbox>{notification.preference.title}</Checkbox>
                  </Form.Item>
                </li>
              ))}
          </div>
        </ul>

        <Form.Item
          label="The following Email will be used for notifications:"
          className={styles.notification__email}
        >
          <Input placeholder={email} value={email} disabled />
        </Form.Item>

        <Form.Item className={styles.notification__btns}>
          <Button
            htmlType="button"
            onClick={onCancel}
            className={clsx(globalStyles.btn, globalStyles.outline__btn)}
          >
            Cancel
          </Button>

          <Button
            className={clsx(globalStyles.btn, globalStyles.primary__btn)}
            htmlType="submit"
            title={submitButtonTitle}
            disabled={submitButtonDisabled}
          >
            Save changes
          </Button>
        </Form.Item>
      </Form>
    </ModalWindow>
  );
};

export default ModalNotification;
