export const hideInSetting = [
  'account.preferences.ac3e11c9-f8ce-4b75-ac65-b717c8de5df8',
  'account.preferences.ae3ef777-6253-4c93-ab3c-584e61a3ac07',
  'account.preferences.ab9ca8c7-0563-4ff1-98f1-2a14a263464c',
  'account.preferences.e9318297-eb4f-4ad5-a914-e7031cc16c54',
  'account.preferences.a8612805-f592-449c-a6e5-96f7b780911c',
  'account.preferences.a81ce075-8b13-498f-8906-e2e7d183e38f',
  'account.preferences.a99d34dd-af40-45c5-b342-f594517f83bd',
  'account.preferences.715c320d-017f-4f22-a9e8-ebe6d42ba0d0',
  'user.preferences.a3d596a6-25ed-450a-bf57-b2e6cfd35ed6',
  'user.preferences.a032c8a0-d190-4db5-8f5b-6ba8a3761e3e',
  'user.preferences.a3cc8111-d107-49a8-a5e8-4bce9b1cf341',
  'user.preferences.a11e1d21-50d7-4058-a31e-42135f09c218',
  'user.preferences.a1e677d3-252e-48ef-a794-c57b521d54aa',
  'user.preferences.a162356e-6436-4b5c-96f1-29bb0ca2604b',
  'user.preferences.a13e8a1f-d133-431b-8304-eb402ea91c40',
  'user.preferences.a398164f-fa18-4606-b77e-787ad95e7d9b',
  'user.preferences.e1bef4e4-8df9-4732-bc0e-0fe2b2f7cdc2',
  'user.preferences.a9176838-2960-4629-b45a-ea6434b79eaa',
  'user.preferences.588023f8-bfa7-4b0d-abc4-84de6b28da2c',
  'user.preferences.90b184a9-e4d9-4166-a117-a69b25cb9b4b', //Notify
  'user.preferences.8f8323cf-4cf9-4136-a8cb-5f9d27988ac1',
  'user.preferences.8e8c9727-70c9-4c8a-8329-4fe17b004215',
  'user.preferences.8c2261cd-7478-48c7-876d-a02bc258fd76',
  'user.preferences.83829ec8-6051-4f12-a611-bc8e2173399b',
  'user.preferences.8c0bce5e-c748-4f3a-a070-bdb1534381c8',
  'user.preferences.8b7a8a43-e844-45e0-8d6d-61e59f1f6a0f',
  'user.preferences.74fb954f-1b6e-44ee-9baf-db3be3c7c642',
  'user.preferences.8a8fa266-cc56-45b7-b953-67768813695a',
  'user.preferences.896e8667-fe17-4197-9292-f66159e7db17',
  'user.preferences.865d5030-c981-400f-b678-b9a8ccbd4270',
  'user.preferences.85ebfc17-e6de-42b8-b8d4-d1baf4aced79',
  'user.preferences.85739016-c464-4c00-b767-eea5f8eecf12',
  'user.preferences.8553c946-d9b6-44b6-8025-d784f242eb38',
  'user.preferences.83008119-31cf-4f72-9d1c-2e9b7a9508f9',
  'user.preferences.81450748-38a0-4ea0-ac53-046e61daa099',
  'user.preferences.54742f32-5e01-4aed-ada8-2e323c3c9c87',
  'user.preferences.7d47d758-81f7-41cc-8d89-fe153e7fd548',
  'user.preferences.7bbba837-9145-410b-a1bc-abe9d3dd419e',
  'user.preferences.7f20bdc9-003a-452c-8ef8-cfcb1c0e9a3f',
  'user.preferences.7d91eb40-88ab-42f0-8ecc-7c680a9f734a',
  'user.preferences.809879ae-0414-4893-a3f6-bbe69492d6a6',
  'user.preferences.7fce27aa-0619-41e1-a4af-5553cf6d8b62',
  'user.preferences.7b931b3a-acd1-4c39-b368-0f107038dc13',
  'user.preferences.7a5f77b8-a8bc-4a70-b094-1b0287e89235',
  'user.preferences.7877c89d-4f9f-420e-ba8c-eff1e907d318',
  'user.preferences.762549f6-bed5-46c6-8165-72c0f0debfa1',
  'user.preferences.a4917f4f-a949-4038-b9cb-e946ebe660b8',
  'user.preferences.a4d03c9b-550d-404f-ae59-02fe0872cec9',
  'user.preferences.af26a292-cf2e-4d22-b923-e409496dfdeb',
  'user.id',
  'user.email',
  'user.first_name',
  'user.middle_name',
  'user.last_name',
  'user.type',
  'user_id',
  'account_id',
  'status',
  'user.last_login',
  'user.phone',
  'user.status',
];
