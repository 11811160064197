import { APP_PATH } from 'features/Routes/pathes';
import { ReactElement } from 'react';
import { APP_CONSTANS, PhotographerRate } from 'shared/constants';
import { Address, Photographer } from 'types/global';

import { MapJobMarkerProps } from '../components/MapJobMarker';
import { MapPhotographerMarker } from '../components/MapPhotographerMarker';
import { PhotographerMarkerData } from '../types';

export const getPhotographerMarkers = (photographers: Photographer[]) => {
  const markerCoordinates: Address[] = [];

  const createMarker = (
    photographer: Photographer
  ): ReactElement<PhotographerMarkerData> | null => {
    const {
      id,
      address,
      preferences,
      first_name: firstName = '',
      last_name: lastName = '',
      phone,
    } = photographer || {};

    const levelPreference =
      preferences?.[APP_CONSTANS.USER_PREFS_ID.PhotographerLevel] ||
      PhotographerRate.UNRATED;
    const droneLicensePreference =
      preferences?.[APP_CONSTANS.USER_PREFS_ID.DroneLicence];
    const droneLicenseIssuedPreference =
      preferences?.[APP_CONSTANS.USER_PREFS_ID.DroneLicence];
    const droneLicenseNumberPreference =
      preferences?.[APP_CONSTANS.USER_PREFS_ID.DroneLicenceNumber];

    const { lat, lon } = address || {};
    const rate: PhotographerRate = levelPreference;
    const droneLicense =
      !!droneLicensePreference ||
      !!droneLicenseIssuedPreference ||
      !!droneLicenseNumberPreference;

    if (!lat || !lon || !id) {
      return null;
    }

    const position: Address = { lat, lng: lon };

    const markerData: PhotographerMarkerData = {
      id: id,
      position,
      rate,
      droneLicense,
      fullName: `${firstName} ${lastName}`,
      phone,
    };

    const markerClickHandler = () => {
      const userUrl = APP_PATH.getUser(id);
      window.open(userUrl, '_blank', 'noreferrer');
    };

    markerCoordinates.push(position);

    return (
      <MapPhotographerMarker
        onClick={markerClickHandler}
        key={id}
        {...markerData}
      />
    );
  };

  const markers = (() => {
    if (!photographers || photographers.length === 0) {
      return [];
    }

    return photographers.reduce(
      (accumulator: ReactElement<MapJobMarkerProps>[], photographer) => {
        if (photographer) {
          const marker = createMarker(photographer);
          return marker ? [...accumulator, marker] : accumulator;
        }
        return accumulator;
      },
      []
    );
  })();

  return { markers, coordinates: markerCoordinates };
};
