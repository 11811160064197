import React, { useEffect, useMemo, useState } from 'react';
import { Spin, Tabs } from 'antd';
import styles from './styles.module.scss';
import { CommentsBlock } from './components/CommentsBlock';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import clsx from 'clsx';
import { userCols } from './columns';
import { calcNewMsg } from 'shared/utils/calculateNewComments';
import { APP_CONSTANS } from 'shared/constants';

export const Comments: React.FC<any> = ({
  photographerId = null,
  customerId = null,
  hasInternalTab = false,
  orderId = null,
  commentStartText = null,
  inner = false,
  inDrawer = false,
  objectId,
  objectType,
  fixedHeight = false,
  customClassname = null,
  cancelScrollBottom = false,
  isInternal = false,
  hideSendingComments = false,
  showTextIfCommentsEmpty = false,
  relatives = null,
  hasReqItem = null,
}) => {
  const token = useSelector((state: any) => state.auth.token);
  const [isShowEmptyText, setIsShowEmptyText] = useState<boolean>(false);
  const [items, setItems] = useState<any>([]);
  const getAllData = true;

  const { isLoading: isLoadingAuthUserData, isError: isErrorAuthUserData } =
    useQuery({
      queryKey: [queryNames.AUTH_getCurrentUser],
      meta: {
        token,
        getAllData,
      },
      queryFn: useGetData,
    });

  const { data: dataPhotographer, refetch: refetchPhotographer } = useQuery({
    queryKey: [queryNames.USERS_getUserById, 'photographerData'],
    meta: {
      token,
      getAllData,
      columnParams: userCols,
      additionalSearchParams: `?id=${photographerId}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!photographerId,
  });

  const { data: dataCustomer, refetch: refetchCustomer } = useQuery({
    queryKey: [queryNames.USERS_getUserById, 'customerData'],
    meta: {
      token,
      getAllData,
      columnParams: userCols,
      additionalSearchParams: `?id=${customerId}`,
    },
    queryFn: useGetData,
    enabled: !!customerId,
  });

  // Comments for Customer -> only orders request
  const { data: orderComments, refetch: refetchOrderComments } = useQuery({
    queryKey: [queryNames.COMMENTS_FOR_ORDERS, customerId],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `/${orderId}?relative=true`,
    },
    queryFn: useGetData,
    enabled: [!!customerId, !!orderId].every(Boolean),
    refetchInterval: 20000,
  });

  // Comments for Photographer, Inner by props = 'Object Type', 'Object id'
  const { data: dataComments, refetch: refetchComments } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/${objectType}/${objectId}`,
      `comments_${objectType}`,
      objectId,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=${isInternal}&relative=true`,
    },
    queryFn: useGetData,
    enabled: !!objectId,
    refetchInterval: 20000,
  });

  // Comments for Internal by 'Object Type' props
  const { data: dataInternalComments, refetch: refetchInternalComments } =
    useQuery({
      queryKey: [
        `${queryNames.COMMENTS}/${objectType}/${objectId}`,
        objectId,
        hasInternalTab,
      ],
      meta: {
        token,
        getAllData,
        additionalSearchParams: `?internal=true&relative=true`,
      },
      queryFn: useGetData,
      enabled: !!objectId && !!hasInternalTab,
      refetchInterval: 20000,
    });

  // Comments for Internal by  Order props
  const {
    data: dataInternalOrderComments,
    refetch: refetchInternalOrderComments,
  } = useQuery({
    queryKey: [
      `${queryNames.COMMENTS}/order/${orderId}`,
      orderId,
      hasInternalTab,
    ],
    meta: {
      token,
      getAllData,
      additionalSearchParams: `?internal=true&relative=true`,
    },
    queryFn: useGetData,
    enabled:
      !!orderId && !!hasInternalTab && objectType === APP_CONSTANS.FULFILMENT,
    refetchInterval: 20000,
  });

  useEffect(() => {
    if (customerId && dataCustomer?.data?.id !== customerId) {
      refetchCustomer();
      refetchOrderComments();
    }
    if (photographerId && dataPhotographer?.data?.id !== photographerId) {
      refetchPhotographer();
    }
  }, [
    customerId,
    photographerId,
    dataCustomer,
    dataPhotographer,
    refetchCustomer,
    refetchPhotographer,
    refetchOrderComments,
  ]);

  useEffect(() => {
    if (showTextIfCommentsEmpty && dataComments?.data?.length === 0) {
      setIsShowEmptyText(true);
    }
  }, [showTextIfCommentsEmpty, dataComments]);

  const phItem = useMemo(() => {
    return {
      key: 'Photographer',
      label: (
        <p
          className={clsx(
            styles.title,
            calcNewMsg(dataComments) > 0 && styles.newMessage
          )}
        >
          Photographer
        </p>
      ),
      children: (
        <CommentsBlock
          haveInfoUser={true}
          fullName={
            dataPhotographer && dataPhotographer.hasOwnProperty('data') ? (
              `${dataPhotographer?.data?.first_name}
              ${dataPhotographer?.data?.middle_name || ''}
              ${dataPhotographer?.data?.last_name}`
            ) : (
              <Spin />
            )
          }
          email={dataPhotographer?.data?.email}
          phone={dataPhotographer?.data?.phone}
          comments={dataComments?.data}
          refetchComments={refetchComments}
          objectType={objectType}
          objectId={objectId}
          userId={photographerId}
          commentStartText={commentStartText}
          relatives={relatives}
        />
      ),
    };
  }, [
    dataPhotographer,
    dataComments,
    objectId,
    objectType,
    refetchComments,
    relatives,
    commentStartText,
    photographerId,
  ]);

  const customerItem = useMemo(() => {
    return {
      key: 'Customer',
      label: (
        <p
          className={clsx(
            styles.title,
            calcNewMsg(orderComments) > 0 && styles.newMessage
          )}
        >
          Customer
        </p>
      ),
      children: (
        <CommentsBlock
          haveInfoUser={true}
          fullName={
            dataCustomer && dataCustomer.hasOwnProperty('data') ? (
              `${dataCustomer?.data?.first_name}
              ${dataCustomer?.data?.middle_name || ''}
              ${dataCustomer?.data?.last_name}`
            ) : (
              <Spin />
            )
          }
          email={dataCustomer?.data?.email}
          phone={dataCustomer?.data?.phone}
          comments={orderComments?.data}
          refetchComments={refetchOrderComments}
          orderId={orderId}
          userId={customerId}
          objectType="order"
          objectId={orderId}
        />
      ),
    };
  }, [dataCustomer, orderComments, orderId, refetchOrderComments, customerId]);

  const internalItem = useMemo(() => {
    const internalComments =
      objectType === APP_CONSTANS.FULFILMENT
        ? dataInternalComments?.data.concat(dataInternalOrderComments?.data)
        : dataInternalComments?.data;
    internalComments?.sort(
      (comPrev: any, comNext: any) => comPrev.created_at - comNext.created_at
    );
    const refetchCommentsFunc =
      objectType === APP_CONSTANS.FULFILMENT
        ? [refetchInternalComments, refetchInternalOrderComments]
        : refetchInternalComments;
    return {
      key: 'Internal',
      label: (
        <p
          className={clsx(
            styles.title,
            calcNewMsg({ data: internalComments }) && styles.newMessage
          )}
        >
          Internal
        </p>
      ),
      children: (
        <CommentsBlock
          comments={internalComments}
          refetchComments={refetchCommentsFunc}
          isInternal={true}
          objectType={
            objectType === APP_CONSTANS.FULFILMENT
              ? APP_CONSTANS.ORDER
              : objectType
          }
          objectId={objectType === APP_CONSTANS.FULFILMENT ? orderId : objectId}
          commentStartText={commentStartText}
          relatives={relatives}
          fixedHeight={fixedHeight}
        />
      ),
    };
  }, [
    objectType,
    dataInternalComments?.data,
    dataInternalOrderComments?.data,
    refetchInternalComments,
    refetchInternalOrderComments,
    orderId,
    objectId,
    commentStartText,
    relatives,
    fixedHeight,
  ]);

  const innerItem = useMemo(() => {
    return {
      key: 'Inner',
      label: null,
      children:
        showTextIfCommentsEmpty && dataComments?.data?.length === 0 ? (
          <p>No comments.</p>
        ) : (
          <CommentsBlock
            comments={dataComments?.data}
            fixedHeight={fixedHeight}
            refetchComments={refetchComments}
            objectType={objectType}
            objectId={objectId}
            isInternal={true}
            cancelScrollBottom={cancelScrollBottom}
            hideSendingComments={hideSendingComments}
            relatives={relatives}
          />
        ),
    };
  }, [
    dataComments,
    refetchComments,
    objectType,
    objectId,
    fixedHeight,
    cancelScrollBottom,
    hideSendingComments,
    showTextIfCommentsEmpty,
    relatives,
  ]);

  const reqItem = useMemo(() => {
    return {
      key: 'Request',
      label: (
        <p
          className={clsx(
            styles.title,
            calcNewMsg(dataComments) && styles.newMessage
          )}
        >
          Request
        </p>
      ),
      children:
        showTextIfCommentsEmpty && dataComments?.data?.length === 0 ? (
          <p>No comments.</p>
        ) : (
          <CommentsBlock
            comments={dataComments?.data}
            fixedHeight={fixedHeight}
            refetchComments={refetchComments}
            objectType={objectType}
            objectId={objectId}
            isInternal={false}
            relatives={relatives}
          />
        ),
    };
  }, [
    dataComments,
    objectType,
    objectId,
    fixedHeight,
    showTextIfCommentsEmpty,
    relatives,
    refetchComments,
  ]);

  useEffect(() => {
    if (!isLoadingAuthUserData && !isErrorAuthUserData) {
      const tabs = [];
      if (photographerId) {
        tabs.push(phItem);
      }
      if (customerId) {
        tabs.push(customerItem);
      }
      if (inner) {
        tabs.push(innerItem);
      }
      if (hasReqItem) {
        tabs.push(reqItem);
      }
      if (hasInternalTab) {
        tabs.push(internalItem);
      }
      setItems(tabs);
    }
  }, [
    photographerId,
    customerId,
    hasInternalTab,
    inner,
    phItem,
    customerItem,
    internalItem,
    isLoadingAuthUserData,
    isErrorAuthUserData,
    dataComments,
    hasReqItem,
    innerItem,
    reqItem,
  ]);

  return (
    <section
      className={clsx(
        inner && styles.commentsInner,
        !isShowEmptyText && styles.comments,
        inDrawer && styles.inDrawer,
        customClassname ? customClassname : ''
      )}
    >
      <Tabs
        defaultActiveKey="Photographer"
        items={items}
        centered
        tabBarGutter={30}
        className={styles.comments__tab}
      />
    </section>
  );
};
