import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { MainContainer } from 'components/ui_components/MainContainer';
import { Form, Spin, Tabs, TabsProps, message } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { queryNames } from 'api/queryNames';
import useGetData from 'api/useGetData';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { APP_CONSTANS } from 'shared/constants';
import { APP_PATH } from 'features/Routes/pathes';
import { JobHeaderWrapper } from 'components/ui_components/JobHeaderWrapper';
import { usePrompt } from 'hooks/general_hooks/usePrompt';
import { usePostData } from 'api/usePostData';
import { setLastStepBreadcrumb } from 'store';
import { columns } from './columns';
import { FloatComments } from 'components/ui_components/FloatComments';
import { tablesNames } from 'api/tablesNames';
import { InvoiceLines } from './components/InvoiceLines';
import { InvoiceHeaderActions } from './components/InvoiceHeaderActions';
import { ModalInvoicePayRefund } from 'components/modals/ModalInvoicePayRefund';
import { InvoiceOperations } from './components/InvoiceOperations';
import { HistoryTab } from 'components/ui_components/HistoryTab';
import { InvoiceObjT } from './types';
import { CreateEditInvoiceDrawer } from '../Invoices/components/CreateEditInvoiceDrawer';
import { ModalInvoiceChangePaymentType } from 'components/modals/ModalInvoiceChangePaymentType';

const InvoicePage: React.FC<any> = () => {
  const params = useParams();
  let { id } = params;
  const navigate = useNavigate();
  const [purchaseForm] = Form.useForm();
  const token = useSelector((state: any) => state.auth.token);
  const [searchParams, setSearchParams] = useSearchParams();
  const [initialValuesForm, setInitialValuesForm] = useState<any>({});
  const [enabledSaveChanges, setEnabledSaveChange] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);
  const [showModalRefund, setShowModalRefund] = useState<boolean>(false);
  const [showModalChangePaymentType, setShowModalChangePaymentType] =
    useState<boolean>(false);
  const [currencyCode, setCurrencyCode] = useState<any>(null);
  const [linesKey, setLinesKey] = useState<string>('Lines');

  const getAllData = true;
  const dispatch = useDispatch();

  const {
    isLoading: isLoadingInvoice,
    isError: isErrorInvoice,
    data: dataInvoice,
    error: errorInvoice,
    refetch: refetchInvoice,
  } = useQuery({
    queryKey: [queryNames.INVOICES_BY_ID, id],
    meta: {
      token,
      getAllData,
      columnParams: columns,
      additionalSearchParams: `?id=${id}&with_deleted=true`,
    },
    queryFn: useGetData,
    enabled: !!id,
  });

  useQuery({
    queryKey: [tablesNames.InvoicesHeader],
    meta: { token, getAllData: true },
    queryFn: useGetData,
  });

  const { mutate: mutationDropReview } = useMutation({
    mutationFn: usePostData,
    retry: 2,
    onError: () => {
      setLoading(false);
      message.error('Network Error');
    },
    onSuccess: (data) => {
      if (data && data.hasOwnProperty('error')) {
        message.error(`Error. ${data.error?.message}`);
        return;
      }
    },
  });

  useEffect(() => {
    if (searchParams.has('modal_pay')) {
      searchParams.delete('modal_pay');
      setSearchParams(searchParams);
      setTimeout(() => {
        setShowModal(true);
      }, 500);
    }
  }, [searchParams, setSearchParams]);

  const dropReviewing = useCallback(() => {
    mutationDropReview({
      data: { object_id: dataInvoice?.data?.id, object: APP_CONSTANS.INVOICE },
      token: token,
      otherProps: `${APP_PATH.EDIT_ORDER}`,
      method: 'DELETE',
    });
  }, [dataInvoice, mutationDropReview, token]);

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave?',
    enabledSaveChanges,
    dropReviewing
  );

  const tabs: TabsProps['items'] = useMemo(
    () =>
      id
        ? [
            {
              key: linesKey,
              label: `Lines`,
              children: (
                <InvoiceLines
                  id={dataInvoice?.data?.id}
                  currencyCode={currencyCode}
                  refetchInvoice={refetchInvoice}
                />
              ),
            },
            {
              key: 'Operations',
              label: `Operations`,
              children: <InvoiceOperations id={dataInvoice?.data?.id} />,
            },
            {
              key: 'History',
              label: 'History',
              children: (
                <HistoryTab
                  id={id}
                  tableIndicatorClassName={'historyInInvoice'}
                  tableName={tablesNames.HistoriesInInvoiceCol}
                />
              ),
            },
          ]
        : [],
    [id, dataInvoice?.data?.id, linesKey, currencyCode, refetchInvoice]
  );
  useEffect(() => {
    if (dataInvoice && dataInvoice?.data) {
      setLoading(false);
    }
  }, [dataInvoice]);

  const invoiceObject: InvoiceObjT | undefined = useMemo(() => {
    let returnObject;
    if (dataInvoice) {
      returnObject = {
        invoiceObjectCode: dataInvoice?.data?.code,
        invoiceObjectStateId: dataInvoice?.data?.state?.id,
        invoiceObjectStateName: dataInvoice?.data?.state?.name,
        invoiceObjectStateDescription: dataInvoice?.data?.state?.description,
        invoiceObjectAmount: dataInvoice?.data?.amount,
        invoiceObjectDiscount: dataInvoice?.data?.discount,
        invoiceObjectDiscountSum: dataInvoice?.data?.discount_sum,
        invoiceObjectPayedSum: dataInvoice?.data?.payed_sum,
        invoiceObjectStartDate: dataInvoice?.data?.start_date,
        invoiceObjectEndDate: dataInvoice?.data?.end_date,
        invoiceObjectId: dataInvoice?.data?.id,
        invoiceObjectPaymentType: dataInvoice?.data?.payment_type,
        invoiceObjectCurrencyId: dataInvoice?.data?.currency?.id,
        invoiceObjectCurrencyCode: dataInvoice?.data?.currency?.code,
        invoiceObjectAccountName: dataInvoice?.data?.account?.name,
        invoiceObjectAccountId: dataInvoice?.data?.account?.id,
        invoiceObjectExternalId: dataInvoice?.data?.external_id,
        invoiceObjectMemo: dataInvoice?.data?.memo,
        invoiceObjectRefundSum: dataInvoice?.data?.refund_sum,
      };
    }
    return returnObject;
  }, [dataInvoice]);

  useEffect(() => {
    if (
      dataInvoice &&
      ((dataInvoice?.hasOwnProperty('error') &&
        dataInvoice.error.hasOwnProperty('code')) ||
        dataInvoice?.toString()?.includes('Error'))
    ) {
      message.warning('Requested purchase is not available to review.');
      navigate('/purchases');
    }
    if (
      !isLoadingInvoice &&
      !errorInvoice &&
      !isErrorInvoice &&
      dataInvoice?.data &&
      dataInvoice?.data?.hasOwnProperty('id') &&
      id
    ) {
      if (dataInvoice?.data?.id === id) {
        setCurrencyCode(dataInvoice?.data?.currency?.code);
        dispatch(setLastStepBreadcrumb(dataInvoice?.data?.code));
        const initialValuesCmpl = {
          memo: dataInvoice?.data?.memo,
        };

        setInitialValuesForm((prevState: any) => ({
          ...prevState,
          ...initialValuesCmpl,
        }));
      }
    }
  }, [
    isLoadingInvoice,
    isErrorInvoice,
    dataInvoice,
    errorInvoice,
    id,
    dispatch,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setLastStepBreadcrumb(''));
      setEnabledSaveChange(false);
      if (dataInvoice) {
        dropReviewing();
      }
    };
  }, [dataInvoice, dispatch, dropReviewing]);

  useEffect(() => {
    if (initialValuesForm && purchaseForm) {
      purchaseForm.setFieldsValue(initialValuesForm);
    }
  }, [purchaseForm, initialValuesForm]);

  return (
    <MainContainer expanded className={styles.jobPage}>
      <Spin spinning={loading}>
        <div>
          <div className={styles.jobPage__main}>
            <InvoiceHeaderActions
              id={dataInvoice?.data?.id}
              stateId={dataInvoice?.data?.state?.id}
              isDeleted={dataInvoice?.data?.hasOwnProperty('deleted_at')}
              isPaymentTypeInvoice={
                dataInvoice?.data?.payment_type === 'Invoice'
              }
              isRefundAllowed={
                dataInvoice?.data?.hasOwnProperty('payed_sum') &&
                dataInvoice?.data?.payed_sum > 0
              }
              setShowModal={setShowModal}
              setShowModalEdit={setShowModalEdit}
              setShowModalRefund={setShowModalRefund}
              setShowModalChangePaymentType={setShowModalChangePaymentType}
            />
            <div className={styles.jobPage__content}>
              <div className={clsx(styles.jobPage__contentBlock)}>
                <JobHeaderWrapper
                  {...invoiceObject}
                  objectType={APP_CONSTANS.INVOICE}
                  currency={currencyCode}
                  createdAt={convertUNIXToLocale(
                    dataInvoice?.data?.created_at,
                    null
                  )}
                  updatedAt={convertUNIXToLocale(
                    dataInvoice?.data?.updated_at,
                    null
                  )}
                  paidAt={convertUNIXToLocale(dataInvoice?.data?.paid_at, null)}
                  refundedAt={convertUNIXToLocale(
                    dataInvoice?.data?.refunded_at,
                    null
                  )}
                />
                <Tabs
                  defaultActiveKey="Lines"
                  tabPosition="top"
                  items={tabs}
                  className={styles.tabs}
                  // onChange={}
                  destroyInactiveTabPane={true}
                />
              </div>
              {dataInvoice?.data?.id && !isLoadingInvoice && (
                <FloatComments
                  hasInternalTab={true}
                  inDrawer={true}
                  objectType={APP_CONSTANS.INVOICE}
                  objectId={dataInvoice?.data?.id}
                  refetchInterval={60000}
                />
              )}
            </div>
          </div>
        </div>
        {showModal && id && (
          <ModalInvoicePayRefund
            id={id}
            code={dataInvoice?.data?.code}
            total={dataInvoice?.data?.discount_sum}
            payedSum={dataInvoice?.data?.payed_sum}
            showModal={showModal}
            setShowModal={setShowModal}
            currencyCode={currencyCode}
          />
        )}
        {showModalEdit && id && (
          <CreateEditInvoiceDrawer
            show={showModalEdit}
            setShow={setShowModalEdit}
            setRefetchData={refetchInvoice}
            accountIdForEdit={dataInvoice?.data?.account?.id}
            invoiceObject={invoiceObject}
            setLinesKey={setLinesKey}
          />
        )}
        {showModalRefund && id && (
          <ModalInvoicePayRefund
            id={id}
            code={dataInvoice?.data?.code}
            total={dataInvoice?.data?.discount_sum}
            payedSum={dataInvoice?.data?.payed_sum}
            showModal={showModalRefund}
            setShowModal={setShowModalRefund}
            currencyCode={currencyCode}
            isRefund={true}
          />
        )}
        {showModalChangePaymentType && id && (
          <ModalInvoiceChangePaymentType
            id={id}
            accountId={dataInvoice?.data?.account?.id}
            showModal={showModalChangePaymentType}
            setShowModal={setShowModalChangePaymentType}
            invoicePaymentType={dataInvoice?.data?.payment_type}
          />
        )}
      </Spin>
    </MainContainer>
  );
};

export default InvoicePage;
