import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { FC } from 'react';
import { convertUNIXToLocale } from 'shared/utils/convertUNIXtoLocaleTime';
import { getTimeZoneName } from 'shared/utils/getTimezoneName';

type TimeTooltipT = {
  date: number;
  timezone: string;
  locale?: string;
  timezoneDisplayPreset?:
    | 'short'
    | 'long'
    | 'shortOffset'
    | 'longOffset'
    | 'shortGeneric'
    | 'longGeneric';
  defaultLocal?: boolean;
  complicationMode?: boolean;
};

export const TimeTooltip: FC<TimeTooltipT> = ({
  date,
  timezone,
  locale = 'en-US',
  timezoneDisplayPreset = 'short',
  defaultLocal = false,
}) => {
  const convertedTime = convertUNIXToLocale(date, null, timezone);
  const localTime = convertUNIXToLocale(date, null);
  const zoneNameInConverted = getTimeZoneName(
    undefined,
    timezone,
    locale,
    timezoneDisplayPreset
  );
  const zoneNameInLocal = getTimeZoneName(
    undefined,
    undefined,
    locale,
    timezoneDisplayPreset
  );

  const title = `In ${defaultLocal ? zoneNameInConverted : zoneNameInLocal} - ${
    defaultLocal ? convertedTime : localTime
  }`;

  return (
    <Tooltip title={title}>
      <span>
        <InfoCircleOutlined style={{ color: '#4ebccf', marginRight: '4px' }} />
        {defaultLocal ? localTime : convertedTime}
      </span>
    </Tooltip>
  );
};
