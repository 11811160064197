import { FC } from 'react';
import styles from './styles.module.scss';
import { PropsT } from './types';
import { HistoryTable } from '../HistoryTable';
import { queryNames } from 'api/queryNames';
import { defaultSettings } from './defaultEnableSettings';
import { tablesNames } from 'api/tablesNames';
import { hideInSetting } from './hideInSettings';

export const HistoryTab: FC<PropsT> = ({
  id,
  tableIndicatorClassName,
  tableName,
  withPadding = false,
}) => {
  const additionalSearchParams = `?or_both_ids=${id}`;

  return id ? (
    <div
      style={withPadding ? { padding: '12px' } : { padding: 0 }}
      className={styles.wrapper}
    >
      <HistoryTable
        additionalSearchParams={additionalSearchParams}
        defaultEnabledSettings={defaultSettings}
        hideInSetting={hideInSetting}
        queryKey={[queryNames.HISTORIES, id]}
        meta={null}
        headerName={tablesNames.HistoriesHeader}
        tableName={tableName}
        toolbar={null}
        tableIndicatorClassName={tableIndicatorClassName}
        resetAllFiltering={true}
      />
    </div>
  ) : (
    <p>ID is undefined</p>
  );
};
