export const ACTION_TYPES = Object.freeze({
  GET_USERS: '@@USERS/GET_USERS',
  GET_USERS_PENDING: '@@USERS/GET_USERS_PENDING',
  GET_USERS_FULFILLED: '@@USERS/GET_USERS_FULFILLED',
  GET_USERS_REJECTED: '@@USERS/GET_USERS_REJECTED',

  GET_USERS_BY_ID: '@@USERS/GET_USERS_BY_ID',
  GET_USERS_BY_ID_PENDING: '@@USERS/GET_USERS_BY_ID_PENDING',
  GET_USERS_BY_ID_FULFILLED: '@@USERS/GET_USERS_BY_ID_FULFILLED',
  GET_USERS_BY_ID_REJECTED: '@@USERS/GET_USERS_BY_ID_REJECTED',

  GET_USERS_BY_ACCOUNT_ID: '@@USERS/GET_USERS_BY_ACCOUNT_ID',
  GET_USERS_BY_ACCOUNT_ID_PENDING: '@@USERS/GET_USERS_BY_ACCOUNT_ID_PENDING',
  GET_USERS_BY_ACCOUNT_ID_FULFILLED:
    '@@USERS/GET_USERS_BY_ACCOUNT_ID_FULFILLED',
  GET_USERS_BY_ACCOUNT_ID_REJECTED: '@@USERS/GET_USERS_BY_ACCOUNT_ID_REJECTED',

  GET_USERS_PREFS: '@@USERS/GET_USERS_PREFS',
  GET_USERS_PREFS_PENDING: '@@USERS/GET_USERS_PREFS_PENDING',
  GET_USERS_PREFS_FULFILLED: '@@USERS/GET_USERS_PREFS_FULFILLED',
  GET_USERS_PREFS_REJECTED: '@@USERS/GET_USERS_PREFS_REJECTED',

  GET_USER_ADDRESS_BY_USER_ID: '@@USERS/GET_USER_ADDRESS_BY_USER_ID',
  GET_USER_ADDRESS_BY_USER_ID_PENDING:
    '@@USERS/GET_USER_ADDRESS_BY_USER_ID_PENDING',
  GET_USER_ADDRESS_BY_USER_ID_FULFILLED:
    '@@USERS/GET_USER_ADDRESS_BY_USER_ID_FULFILLED',
  GET_USER_ADDRESS_BY_USER_ID_REJECTED:
    '@@USERS/GET_USER_ADDRESS_BY_USER_ID_REJECTED',

  GET_USERS_REQUESTS: '@@USERS/GET_USERS_REQUESTS',
  GET_USERS_REQUESTS_PENDING: '@@USERS/GET_USERS_REQUESTS_PENDING',
  GET_USERS_REQUESTS_FULFILLED: '@@USERS/GET_USERS_REQUESTS_FULFILLED',
  GET_USERS_REQUESTS_REJECTED: '@@USERS/GET_USERS_REQUESTS_REJECTED',

  POST_NEW_USER_ADDRESS: '@@USERS/POST_NEW_USER_ADDRESS',
  POST_NEW_USER_ADDRESS_PENDING: '@@USERS/POST_NEW_USER_ADDRESS_PENDING',
  POST_NEW_USER_ADDRESS_FULFILLED: '@@USERS/POST_NEW_USER_ADDRESS_FULFILLED',
  POST_NEW_USER_ADDRESS_REJECTED: '@@USERS/POST_NEW_USER_ADDRESS_REJECTED',

  PUT_USER_ADDRESS: '@@USERS/PUT_USER_ADDRESS',
  PUT_USER_ADDRESS_PENDING: '@@USERS/PUT_USER_ADDRESS_PENDING',
  PUT_USER_ADDRESS_FULFILLED: '@@USERS/PUT_USER_ADDRESS_FULFILLED',
  PUT_USER_ADDRESS_REJECTED: '@@USERS/PUT_USER_ADDRESS_REJECTED',

  POST_USER_PREFS: '@@USERS/POST_USER_PREFS',
  POST_USER_PREFS_PENDING: '@@USERS/POST_USER_PREFS_PENDING',
  POST_USER_PREFS_FULFILLED: '@@USERS/POST_USER_PREFS_FULFILLED',
  POST_USER_PREFS_REJECTED: '@@USERS/POST_USER_PREFS_REJECTED',

  UPDATE_USER: '@@USERS/UPDATE_USER',

  SOFT_DELETE_USER: '@@USERS/SOFT_DELETE_USER',
  HARD_DELETE_USER: '@@USERS/HARD_DELETE_USER',

  REMOVE_USERS_BY_ID: '@@USERS/REMOVE_USERS_BY_ID',

  SOFT_DELETE_USER_ADDRESS: '@@USERS/SOFT_DELETE_USER_ADDRESS',
  HARD_DELETE_USER_ADDRESS: '@@USERS/HARD_DELETE_USER_ADDRESS',

  UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA:
    '@@USERS/UNIVERSAL_SAVING_REQUEST_FOR_SAVING_DATA',
});
