export const columns = [
  'id',
  'created_at',
  'Order.Currency.code',
  'order_id',
  'user_id',
  'Order.user_id',
  'Order.Account.id',
  'State.name',
  'Account.PurchaseMethod.purchase_target_id',
  'Order.payout',
  'Account.id',
];
