import { MarkerF, MarkerProps } from '@react-google-maps/api';
import { debounce } from 'lodash';
import { FC, useState } from 'react';
import { PhotographerMarkerData } from '../../types';
import { MapPhotographerInfoWindow } from '../MapPhotographerInfoWindow';
import { getPhotographerMarkerIcon } from './getPhotographerMarkerIcon';

export type MapPhotographerMarkerProps = Omit<MarkerProps, 'position'> &
  PhotographerMarkerData;

export const MapPhotographerMarker: FC<MapPhotographerMarkerProps> = ({
  position,
  rate,
  droneLicense = false,
  clusterer,
  onClick,
  phone,
  fullName,
  ...rest
}) => {
  const markerIconUrl = getPhotographerMarkerIcon(rate, droneLicense);
  const markerIcon = markerIconUrl ? { url: markerIconUrl } : undefined;
  const [showTitle, setShowTitle] = useState(false);

  const hideTitle = debounce(() => {
    setShowTitle(false);
  }, 1000);

  const handleTitleHover = () => {
    hideTitle.cancel();
  };

  const handleTitleBlur = () => {
    hideTitle();
  };

  const handleMarkerHover = () => {
    hideTitle.cancel();
    setShowTitle(true);
  };

  const handleMarkerBlur = () => {
    hideTitle();
  };

  return (
    <>
      {showTitle && (
        <MapPhotographerInfoWindow
          onHover={handleTitleHover}
          onBlur={handleTitleBlur}
          phone={phone}
          fullName={fullName}
          position={position}
        />
      )}
      <MarkerF
        {...rest}
        onMouseOver={handleMarkerHover}
        onMouseOut={handleMarkerBlur}
        onClick={onClick}
        position={position}
        clusterer={clusterer}
        icon={markerIcon}
      />
    </>
  );
};
