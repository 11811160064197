export const defaultEnabledSettings = {
  id: { order: 1 },
  amount: { order: 2 },
  payed_sum: { order: 3 },
  payed_fee: { order: 4 },
  payed_total: { order: 5 },
  'purchase_provider.name': { order: 6 },
  'purchase_method.name': { order: 7 },
  created_at: { order: 8 },
};
